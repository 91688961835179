import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IBurnerDetails } from '@/view-models/viewer/burnerReading/burner-details';

export class EMABurnerDetailsService extends BaseService {

  constructor(axiosInstance: AxiosInstance, emaApiUrl: string) {
    super(`${emaApiUrl}/api`, axiosInstance);
  }

  public async getEMABurners(heaterKey: string): Promise<IBurnerDetails[]> {
    const url = `heater/${heaterKey}/burners`;
    return this.httpClient.get<IBurnerDetails[]>(url);
  }
}
