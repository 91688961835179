export default class HelperMethods {
    public static sortLabelArray(labelArray: any, distributeType: any) {
        return labelArray.sort((a: any, b: any) => {
            return Number(a[distributeType]) - Number(b[distributeType]);
        });
    }

    public static isNullOrUndefined(object: any): boolean {
        return object === null || typeof object === 'undefined';
    }
}