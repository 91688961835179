import { IBurnerReading, IVariableInfo, IViewerBurner } from './burner-reading';
import store, { VuexModuleNamespaces } from '@/store';
import { ViewerBurner } from '../assetDiagram/viewer-burner-view-model';
import { BurnerReadingViewerStore } from '@/store/viewer/burnerReading/burnerReadingStore';

// tslint:disable-next-line:max-classes-per-file
export class BurnerReading implements IBurnerReading {
  public equipmentConfigKey: string;
  public dateTime: string; // DateTime
  public equipmentKey: string | null;
  public name: string | null;
  public burners: IViewerBurner[] | null;
  public staleRecommendationThreshold: string;
  public variables: IVariableInfo[] | null;

  constructor(data: any) {
    this.equipmentConfigKey = data.equipmentConfigKey;
    this.dateTime = data.dateTime;
    this.equipmentKey = data.equipmentKey;
    this.name = data.name;
    this.burners = data.burners;
    this.staleRecommendationThreshold = data.staleRecommendationThreshold;
    this.variables = data.variables;
  }

  public static get currentBurnerReading() {
    const burnerReadings: IBurnerReading =
        store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getBurnerReadings.name}`];
    return new BurnerReading(burnerReadings);
  }

  public getBurner(key: string): IViewerBurner | undefined  {
    const foundBurner: IViewerBurner | undefined = this.burners?.find((burner: IViewerBurner) => burner.key === key);
    if (foundBurner) {
      return new ViewerBurner(foundBurner);
    }
    return undefined;
  }

}
