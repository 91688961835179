export interface IBurnerReading {
  equipmentConfigKey: string; // UUID
  dateTime: string; // DateTime
  equipmentKey: string | null;
  name: string | null;
  burners: IViewerBurner[] | null;
}

export interface IViewerBurner {
  key: string;
  name: string | null;
  heatRelease: number;
  equivalenceRatio: number;
  outOfControllableRange: boolean;
  opportunityScore: number;
  airRegisters: IAirRegister[] | null;
  fuelLines: IFuelLine[] | null;
  getBurnerReadingValue(burnerProperty: keyof IViewerBurner,
                        airRegisterProperty?: keyof IAirRegister): any;
  isChangeRecommended(): boolean;
  isBurnerOff(): boolean;
}

export interface IBurnerInfo {
  key: string;
  name: string | null;
  heatRelease: number;
  equivalenceRatio: number;
  outOfControllableRange: boolean;
  opportunityScore: number;
  airRegisters: IAirRegister[] | null;
  fuelLines: IFuelLine[] | null;
}

export interface IAirRegister {
  name: string;
  currentSetting: number;
  recommendedSetting: number;
}

export interface IFuelLine {
  name: string | null;
  isFiring: boolean;
}

export class ViewerBurnerOptionProperties {
  public static airRegister: keyof IViewerBurner = 'airRegisters';
  public static opportunity: keyof IViewerBurner = 'opportunityScore';
  public static equivalenceRatio: keyof IViewerBurner = 'equivalenceRatio';
  public static heatRelease: keyof IViewerBurner = 'heatRelease';
}

// tslint:disable-next-line:max-classes-per-file
export class AirRegisterOptionProperties {
  public static recommendedAirRegister: keyof IAirRegister = 'recommendedSetting';
  public static currentAirRegister: keyof IAirRegister = 'currentSetting';
}

export interface ReadingsCache {
  [name: string]: number;
}

export enum PrimaryDisplayAttribute {
  RECOMMENDED_AIR_REGISTER = 'Recommended Air Register',
  CURRENT_AIR_REGISTER = 'Current Air Register',
  OPPORTUNITY = 'Opportunity Score',
  EQUIVALENCE_RATIO = 'Equivalence Ratio',
  HEAT_RELEASE = 'Heat Release'
}
