// Vue Dependencies
import Vue from 'vue';
// Component Dependencies
import { gridConfig } from '@/assets/configs/gridConfig';
import EntitySelect from '@/components/editor/components/gridMenus/EntitySelect.vue';
// Interface Dependencies
import store, { IRootState, VuexModuleNamespaces } from '..';
import {
  IGridPointerPosition,
  IZoomStatus,
  IArrangeShape,
  IGridContents,
  IHorizontalSpaceline,
  IVerticalSpaceline,
  IObjectCoords,
  IToolTipPosition,
  IMouseEvent,
  ICanvasContents,
} from '@/view-models/assetDiagram/grid-view-models';
import { BurnerFolder } from '@/view-models/burner/burner-folder-view-models';
import { IBurner, Burner } from '@/view-models/burner/burner-view-models';
import { IBurnerDetail } from '@/view-models/burner/burner-details-view-models';

// Vuex Dependencies
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
// Library Dependencies
import { fabric } from 'fabric';
import { throttle, isEqual } from 'lodash';
import { DiagramStore } from '../diagram/diagramStore';
import { ArrangeMode } from '@/view-models/assetDiagram/asset-diagram';
import { BurnerFolderStore } from '@/store/burnerFolder/burnerFolderStore';
import { VariableFolderStore } from '@/store/variableFolder/variableFolderStore';
import { AlignLabelOptions } from '@/enums/DiagramApp';
import HelperMethods from '@/shared/helper-methods';
import { IAssetReportVariableViewModel, VariableTreeNode } from '@/view-models/variables';

export type IGridContext = ActionContext<IGridStoreState, IRootState>;

export type IGridStoreGetters = GetterTree<IGridStoreState, IRootState>;

interface IGridData {
  objectKey: string;
  objectName?: string;
}

interface ICollisionCache {
  top: number;
  left: number;
  result: boolean;
}

export interface IGridStoreState {
  selectedBurnerDetails: IBurnerDetail[];
  editorGrid: fabric.Canvas;
  editorGridBoxSize: number;
  editorGridBoxVariableWidth: number;
  previousEditorGridBoxSize: number;
  editorGridLines: fabric.Group;
  isArrangeShapeMode: boolean;
  arrangeMode: ArrangeMode;
  isLabelAdditionMode: boolean;
  isShadowMode: boolean;
  isGridFrozen: boolean;
  isMouseDown: boolean;
  isUpdatingGridLines: boolean;
  arrangeShapes: IArrangeShape[];
  arrangeShape: fabric.Object | null;
  selectedLabel: fabric.Group | null;
  editingLabel: fabric.IText | null;
  isBurnerSettingsEnabled: boolean;
  isVariableSettingsEnabled: boolean;
  burnerShadow: fabric.Group | null;
  variableShadow: fabric.Group | null;
  collisionCache: ICollisionCache[];
  activeLabelCount: number;
  activeBurnerCount: number;
  activeVariableCount: number;
  gridLayoutChanged: boolean;
  layoutAssetVariablesChanged:string[];
  firstSelectedLabel: fabric.Group | null;
  selectedNode: VariableTreeNode | null;
  selectedVariableDetails: IAssetReportVariableViewModel[];
  isLongTerm: boolean;
  allVariables: IAssetReportVariableViewModel[];
}

export interface IGridStoreMutations extends MutationTree<IGridStoreState> {
  updateEditorGrid(gridState: IGridStoreState, payload: fabric.Canvas): void;
  updateEditorGridWidth(gridState: IGridStoreState, newWidth: number): void;
  updateEditorGridHeight(gridState: IGridStoreState, newHeight: number): void;
  updateEditorGridBoxSize(gridState: IGridStoreState, newBoxSize: number): void;
  updateEditorGridLines(gridState: IGridStoreState, newGridLines: fabric.Line[]): void;
  updateIsGridFrozen(gridState: IGridStoreState, newIsGridFrozen: boolean): void;
  updateSelectedGridLabel(gridState: IGridStoreState, newSelectedGridLabel: fabric.Group): void;
  updateSelectedGridLabelContents(gridState: IGridStoreState): void;
  deleteSelectedLabels(gridState: IGridStoreState): void;
  rotateClock(gridState: IGridStoreState, direction: boolean): void;
  setActiveLabelCount(gridState: IGridStoreState, labelCount: number): void;
  setActiveBurnerCount(gridState: IGridStoreState, labelCount: number): void;
  setActiveVariableCount(gridState: IGridStoreState, variableCount: number): void;
  updateIsLabelAdditionMode(gridState: IGridStoreState, newIsLabelAdditionMode: boolean): void;
  moveSelectedLables(gridState: IGridStoreState, event: KeyboardEvent): void;
  discardActiveSelection(gridState: IGridStoreState): void;
  updateGridLayoutChanged(gridState: IGridStoreState, isLayoutChanged: boolean): void;
  alignLabels(gridState: IGridStoreState, value: string): void;
  alignLabelCords(gridState: IGridStoreState, value: string): void;
  setSelectedNode(gridState: IGridStoreState,node: VariableTreeNode): void;
  setIsLongTerm(gridState: IGridStoreState, value: boolean): void;
  updateAssetVariables(gridState: IGridStoreState, variables: IAssetReportVariableViewModel[]): void;
}

export interface IGridStoreActions extends ActionTree<IGridStoreState, IRootState> {
  setArrangeShapeColor(gridContext: IGridContext, isArrangeShapeActive: boolean): void;
  setArrangeShapeMode(gridContext: IGridContext, isArrangeShapeMode: boolean): void;
  setArrangeMode(gridContext: IGridContext, arrangeMode: ArrangeMode): void;
  drawGridLines(gridContext: IGridContext, isWindowResize: boolean): void;
  trimExcessGrid(gridContext: IGridContext): void;
  getEditorPointerPosition(gridContext: IGridContext, dragEvent: DragEvent): IGridPointerPosition;
  resetZoom(gridContext: IGridContext): void;
  zoomIn(gridContext: IGridContext): void;
  zoomOut(gridContext: IGridContext): void;
  getZoomStatus(gridContext: IGridContext): IZoomStatus;
  setArrangeShapePadding(gridContext: IGridContext): void;
  removeArrangeShapePadding(gridContext: IGridContext): void;
  cancelArrangeShapeMode(gridContext: IGridContext): void;
  lockBurnerAndLabelMovement(gridContext: IGridContext, isMovementAllowed: boolean): void;
  removeBurnerShadows(gridContext: IGridContext): void;
  removeVariableShadows(gridContext: IGridContext): void;
  bringGridObjectsToFront(gridContext: IGridContext): void;
  setGridPointers(gridContext: IGridContext): void;
  setGridObjectMovingHandler(gridContext: IGridContext): void;
  setGridObjectAddedHandler(gridContext: IGridContext): void;
  setGridObjectRemovedHandler(gridContext: IGridContext): void;
  setGridDragEnterHandler(gridContext: IGridContext): void;
  setGridDragLeaveHandler(gridContext: IGridContext): void;
  setGridDragOverHandler(gridContext: IGridContext): void;
  setGridMultiSelectCreateHandler(gridContext: IGridContext): void;
  setGridMultiSelectUpdateHandler(gridContext: IGridContext): void;
  setGridMultiSelectClearHandler(gridContext: IGridContext): void;
  checkGridObjectMoveOverlap(gridContext: IGridContext, objectCoords: IObjectCoords): boolean;
  checkGridObjectDragOverlap(gridContext: IGridContext, objectCoords: IObjectCoords): boolean;
  checkGridActiveObjectDragOverlap(gridContext: IGridContext): Promise<boolean>;
  setGridDropHandler(gridContext: IGridContext): void;
  setGridDoubleClickHandler(gridContext: IGridContext): void;
  setGridMouseUpHandler(gridContext: IGridContext): void;
  setGridMouseDownHandler(gridContext: IGridContext): void;
  setGridListeners(gridContext: IGridContext): void;
  setArrangeShapeControlMouseDownHandler(gridContext: IGridContext, arrangeShapeControlGroup: fabric.Group): void;
  setArrangeShapeScaledHandler(gridContext: IGridContext, sizeControlGroup: fabric.Rect): void;
  setArrangeShapeMovingHandler(gridContext: IGridContext, arrangeShape: fabric.Rect): void;
  setArrangeShapeModifiedHandler(gridContext: IGridContext, sizeControlGroup: fabric.Rect): void;
  setArrangeShapeSizeControlMouseMovingHandler(gridContext: IGridContext, sizeControlGroup: fabric.Rect): void;
  setArrangeShapeSizeControlScaledHandler(gridContext: IGridContext, sizeControlGroup: fabric.Rect): void;
  setArrangeShapeSizeControlModifiedHandler(gridContext: IGridContext, sizeControlGroup: fabric.Rect): void;
  setArrangeShapeMouseUpHandler(gridContext: IGridContext): void;
  setArrangeShapeMouseMoveHandler(gridContext: IGridContext): void;
  setArrangeShapeMouseDownHandler(gridContext: IGridContext): void;
  unselectAllArrangeRects(gridContext: IGridContext): void;
  removeLabelShadows(gridContext: IGridContext): void;
  removeEntityMenu(gridContext: IGridContext): void;
  updateGridContentsfromJSON(gridContext: IGridContext, gridContents: IGridContents): void;
  setLabelShadowMouseDownHandler(gridContext: IGridContext, labelGroup: fabric.Group): void;
  setLabelGroupMouseUpHandler(gridContext: IGridContext, labelGroup: fabric.Group): void;
  setGridDoubleClickEventHandler(gridContext: IGridContext): void;
  getSelectedBurnerKeys(gridContext: IGridContext): string[];
  setGridToolTipMouseOutHandler(gridContext: IGridContext, burnerGroup: fabric.Group): void;
  setGridToolTipMouseOverHandler(gridContext: IGridContext, burnerGroup: fabric.Group): void;
  setBurnerMouseUpHandler(gridContext: IGridContext, burnerGroup: fabric.Group): void;
  displayGroupSelectMenu(gridContext: IGridContext, objectCoords: IObjectCoords): void;
  unhighlightAllBurners(gridContext: IGridContext): void;
  setActiveObjectMouseUpHandler(gridContext: IGridContext, activeSelection: fabric.ActiveSelection): void;
  setActiveObjectMouseDownHandler(gridContext: IGridContext, activeSelection: fabric.ActiveSelection): void;
  deleteSelectedBurners(gridContext: IGridContext): void;
  deleteSelectedVariables(gridContext: IGridContext): string[];
  getSelectedBurnerDetails(gridContext: IGridContext): void;
  hideMenus(gridContext: IGridContext): void;
  forceCloseLabelEdit(gridContext: IGridContext): void;
  hideUnknownBurners(gridContext: IGridContext): void;
  bringControlsToFront(gridContext: IGridContext): void;
}

export function calculateToolTipPosition(target: fabric.Group, gridBoxSize: number, toolTipSpan: HTMLSpanElement): IToolTipPosition | undefined {
  const gridBoxWidth = gridBoxSize;
  const toolTipWidth = toolTipSpan.offsetWidth;
  const toolTipHeight = toolTipSpan.offsetHeight;
  const leftOffset = (toolTipWidth - gridBoxWidth) / 2;
  const grid:
  | HTMLElement
  | null
  | undefined = document
  ?.querySelector('#asset-diagram-builder')
  ?.shadowRoot?.getElementById('grid-container');
  let topVal;
  if (target.top !== undefined && target.left !== undefined) {
    topVal = (target.top) > grid?.scrollTop! + 50 ? (target.top - toolTipHeight) : (target.top + toolTipHeight + 20);
    return { left: target.left - leftOffset, top: topVal };
  }
  return undefined;
}

function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function highlightGridObject(target: fabric.Group): void {
  const hoveredRect: fabric.Object = target.item(0);
  hoveredRect.set('stroke', gridConfig.burnerHighlightColor);
  hoveredRect.set('dirty', true);
}

function unHighlightGridObject(target: fabric.Group): void {
  const hoveredRect: fabric.Object = target.item(0);
  hoveredRect.set('stroke', gridConfig.burnerLineColor);
  hoveredRect.set('dirty', true);
}

async function processDragOverEvent(gridContext: IGridContext, options: fabric.IEvent): Promise < void > {
  const pointerPosition = await gridContext.dispatch('getEditorPointerPosition', options.e);
  const topCoord = Math.round(pointerPosition.y / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize;
  const leftCoord = Math.round(pointerPosition.x / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize;
  if (await gridContext.dispatch('checkGridObjectDragOverlap', { top: topCoord, left: leftCoord })) {
    gridContext.state.burnerShadow ?.set('top', topCoord);
    gridContext.state.burnerShadow ?.set('left', leftCoord);
    gridContext.state.variableShadow ?.set('top', topCoord);
    gridContext.state.variableShadow ?.set('left', leftCoord);
    gridContext.state.editorGrid.renderAll();
  }
}

// Throttle dragover events for performance
const throttledDragOverEvent = throttle(processDragOverEvent, 100);

// Overwrite onKeyDown for IText to trigger autosave debounce.
// tslint:disable-next-line: no-unused-vars
// eslint-disable-next-line no-unused-vars
fabric.IText.prototype.onKeyDown = ((unused) => {
  return () => {
    store.commit(
      `${VuexModuleNamespaces.diagram}/${DiagramStore.mutations.triggerStateChanged.name}`,
      null
    );
  };
})(fabric.IText.prototype.onKeyDown);

export const GridStore = {
  namespaced: true,
  state: {
    editorGrid: new fabric.Canvas('', {}),
    editorGridBoxSize: gridConfig.defaultGridBoxSize,
    editorGridBoxVariableWidth: gridConfig.variableGridBoxWidth,
    previousEditorGridBoxSize: gridConfig.defaultGridBoxSize,
    editorGridLines: new fabric.Group([], {}),
    isArrangeShapeMode: false,
    arrangeMode: ArrangeMode.AddSpace,
    isLabelAdditionMode: false,
    isShadowMode: false,
    isGridFrozen: false,
    isMouseDown: false,
    isUpdatingGridLines: false,
    arrangeShapes: [],
    arrangeShape: null,
    selectedLabel: null,
    editingLabel: null,
    selectedBurnerDetails: [],
    isBurnerSettingsEnabled: false,
    isVariableSettingsEnabled: false,
    burnerShadow: null,
    variableShadow: null,
    collisionCache: [],
    activeLabelCount: 0,
    activeBurnerCount: 0,
    activeVariableCount: 0,
    gridLayoutChanged: false,
    layoutAssetVariablesChanged: [],
    firstSelectedLabel: null,
    selectedNode: null,
    selectedVariableDetails: [],
    isLongTerm: false,
    allVariables: []
  } as IGridStoreState,
  getters: {
    editorGrid(gridState: IGridStoreState): fabric.Canvas {
      return gridState.editorGrid;
    },
    editorGridBoxSize(gridState: IGridStoreState): number {
      return gridState.editorGridBoxSize;
    },
    previousEditorGridBoxSize(gridState: IGridStoreState): number {
      return gridState.previousEditorGridBoxSize;
    },
    currentArrangeShape(gridState: IGridStoreState): fabric.Object | null {
      return gridState.arrangeShape;
    },
    editorGridHeight(gridState: IGridStoreState): number {
      return gridState.editorGrid.getHeight();
    },
    editorGridWidth(gridState: IGridStoreState): number {
      return gridState.editorGrid.getWidth();
    },
    isArrangeShapeMode(gridState: IGridStoreState): boolean {
      return gridState.isArrangeShapeMode;
    },
    arrangeMode(gridState: IGridStoreState): ArrangeMode {
      return gridState.arrangeMode;
    },
    isLabelAdditionMode(gridState: IGridStoreState): boolean {
      return gridState.isLabelAdditionMode;
    },
    isGridFrozen(gridState: IGridStoreState): boolean {
      return gridState.isGridFrozen;
    },
    isMouseDown(gridState: IGridStoreState): boolean {
      return gridState.isMouseDown;
    },
    editingLabel(gridState: IGridStoreState): fabric.IText | null {
      return gridState.editingLabel;
    },
    gridContents(gridState: IGridStoreState): IGridContents {
      // Remove shadows, gridlines, and temporary objects
      const gridObject = gridState.editorGrid.toObject([
        'name',
        'data',
        'hasRotatingPoint',
        'hasControls',
        'lockScalingX',
        'lockScalingY',
        'lockRotation',
        'lockMovementX',
        'lockMovementY',
        'selectable',
        'strokeUniform',
      ]);
      const permanentGridObjects: fabric.Object[] = [];
      gridObject.objects.forEach((object: fabric.Object) => {
        if (
          object.name &&
          ![
            gridConfig.tempArrangeShapeName,
            gridConfig.tempArrangeShapeControlsName,
            gridConfig.gridLineName,
            gridConfig.burnerShadowName,
            gridConfig.variableShadowName
          ].includes(object.name)
        ) {
          permanentGridObjects.push(object);
        }
      });
      gridObject.objects = permanentGridObjects;
      const gridContents: IGridContents = {
        gridHeight: gridState.editorGrid.getHeight(),
        gridWidth: gridState.editorGrid.getWidth(),
        gridBoxSize: gridState.editorGridBoxSize,
        contents: gridObject
      };
      return gridContents;
    },
    selectedBurnerDetails(gridState: IGridStoreState): IBurnerDetail[] {
      return gridState.selectedBurnerDetails;
    },
    selectedVariableDetails(gridState: IGridStoreState): IAssetReportVariableViewModel[] {
      return gridState.selectedVariableDetails;
    },
    isBurnerSettingsEnabled(gridState: IGridStoreState): boolean {
      return gridState.isBurnerSettingsEnabled;
    },
    isVariableSettingsEnabled(gridState: IGridStoreState): boolean {
      return gridState.isVariableSettingsEnabled;
    },
    getActiveLabelCount(gridState: IGridStoreState): number {
      return gridState.activeLabelCount;
    },
    getActiveBurnersCount(gridState: IGridStoreState): number {
      return gridState.activeBurnerCount;
    },
    getActiveVariableCount(gridState: IGridStoreState): number {
      return gridState.activeVariableCount;
    },
    getGridLayoutChanged(gridState: IGridStoreState): boolean {
      return gridState.gridLayoutChanged;
    },
    getIsLayoutAssetVariablesChanged(gridState: IGridStoreState): boolean {
      return gridState.layoutAssetVariablesChanged.length > 0;
    },
    getLayoutAssetVariablesChanged(gridState: IGridStoreState) {
      return gridState.layoutAssetVariablesChanged;
    },
  } as IGridStoreGetters,
  mutations: {
    discardActiveSelection(gridState: IGridStoreState) {
      gridState.editorGrid.discardActiveObject();
      gridState.editorGrid.renderAll();
    },
    setActiveLabelCount(gridState: IGridStoreState, activeLabelCount: number) {
      gridState.activeLabelCount = activeLabelCount;
    },
    setActiveBurnerCount(gridState: IGridStoreState, activeBurnerCount: number) {
      gridState.activeBurnerCount = activeBurnerCount;
    },
    setActiveVariableCount(gridState: IGridStoreState, activeVariableCount: number) {
      gridState.activeVariableCount = activeVariableCount;
    },
    updateEditorGrid(gridState: IGridStoreState, payload: fabric.Canvas) {
      gridState.editorGrid = payload;
    },
    updateEditorGridWidth(gridState: IGridStoreState, newWidth: number) {
      gridState.editorGrid.setWidth(newWidth);
    },
    updateEditorGridHeight(gridState: IGridStoreState, newHeight: number) {
      gridState.editorGrid.setHeight(newHeight);
    },
    updateEditorGridBoxSize(gridState: IGridStoreState, newBoxSize: number) {
      gridState.previousEditorGridBoxSize = gridState.editorGridBoxSize;
      gridState.editorGridBoxSize = newBoxSize;
    },
    updateIsGridFrozen(gridState: IGridStoreState, newIsGridFrozen: boolean) {
      gridState.isGridFrozen = newIsGridFrozen;
      const gridObjects = gridState.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        gridObject.selectable = !newIsGridFrozen;
      }
    },
    updateIsLabelAdditionMode(
      gridState: IGridStoreState,
      newIsLabelAdditionMode: boolean
    ) {
      gridState.isLabelAdditionMode = newIsLabelAdditionMode;
    },
    updateEditorGridLines(
      gridState: IGridStoreState,
      newGridLines: fabric.Line[]
    ) {
      // Block unneeded saves
      gridState.isUpdatingGridLines = true;

      gridState.editorGrid.remove(gridState.editorGridLines);
      gridState.editorGridLines = new fabric.Group(newGridLines, {
        name: gridConfig.gridLineName,
        selectable: false,
        evented: false,
      });
      gridState.editorGrid.add(gridState.editorGridLines);
      gridState.editorGrid.renderAll();
      // Restore listener
      gridState.isUpdatingGridLines = false;
    },
    updateSelectedGridLabel(
      gridState: IGridStoreState,
      newSelectedGridLabel: fabric.Group
    ) {
      gridState.selectedLabel = newSelectedGridLabel;

    },
    updateSelectedGridLabelContents(gridState: IGridStoreState) {
      if (gridState.selectedLabel === null || gridState.isLabelAdditionMode) {
        return;
      } else {
        const labelObjects = gridState.selectedLabel.getObjects() as fabric.IText[];
        let labelText = labelObjects[1].text;
        if (labelText === undefined) {
          labelText = 'Label Text';
        } else if (labelText === 'Label Text') {
          labelText = ' ';
        }
        if (
          gridState.selectedLabel.top !== undefined &&
          gridState.selectedLabel.left !== undefined &&
          labelText !== undefined
        ) {
          const gridLabel = new fabric.IText(labelText, {
            name: gridConfig.labelName,
            top: gridState.selectedLabel.top + gridState.editorGridBoxSize / 6,
            left:
              gridState.selectedLabel.left + gridState.editorGridBoxSize / 6,
            padding: gridState.editorGridBoxSize / 6,
            height: gridState.editorGridBoxSize * 0.75,
            fontSize: gridState.editorGridBoxSize / 3,
            fontFamily: gridConfig.burnerFont,
            fontWeight: gridConfig.burnerFontWeight,
            backgroundColor: gridConfig.labelBackgroundColor,
            fill: gridConfig.labelFontColor,
            editable: true,
          });
          gridState.editingLabel = gridLabel;
          gridState.editorGrid.add(gridLabel);
          gridState.editorGrid.remove(gridState.selectedLabel);
          gridState.isLabelAdditionMode = true;
          gridLabel.enterEditing();
        }
      }
    },
    deleteSelectedLabels(gridState: IGridStoreState) {
      const activeLabelsObj = gridState.editorGrid
        .getActiveObjects()
        .filter((obj) => obj.name === gridConfig.labelName);
      if (Array.isArray(activeLabelsObj) && activeLabelsObj.length) {
        activeLabelsObj.forEach((obj) => gridState.editorGrid.remove(obj));
      }
    },
    rotateClock(gridState: IGridStoreState, direction: boolean): void {
      const activeSelection = gridState.editorGrid.getActiveObject() as fabric.ActiveSelection;
      activeSelection.lockRotation = false;
      activeSelection.hasRotatingPoint = true;
      activeSelection.rotatingPointOffset = 120;
      activeSelection.centeredRotation = false;
      if (direction) {
        activeSelection.rotate(activeSelection.angle  === 270 ? 0 : activeSelection.angle! + 90);
      } else {
        activeSelection.rotate(activeSelection.angle  === 90 ? 0 : activeSelection.angle! - 90);
      }
      gridState.editorGrid.renderAll();
      store.commit('diagram/triggerStateChanged', null, { root: true });
      store.dispatch('grid/setArrangeShapePadding');
    },
    moveSelectedLables(gridState: IGridStoreState, event: KeyboardEvent) {
      const STEP = 1;
      let keyCode = event.keyCode || event.which;
      const activeSelection = gridState.editorGrid.getActiveObject() as fabric.ActiveSelection;
      const gridWidth = gridState.editorGrid.getWidth();
      const gridHeight = gridState.editorGrid.getHeight();
      const objWidth = activeSelection.getScaledWidth();
      const objHeight = activeSelection.getScaledHeight();
      const objLeftCord = activeSelection.left ? activeSelection.left : 0;
      const objTopCord = activeSelection.top ? activeSelection.top : 0;
      switch (keyCode) {
        case 37: // left
          activeSelection.left = objLeftCord > 0 ? objLeftCord - STEP : 0;
          break;
        case 38: // up
          activeSelection.top = objTopCord > 0 ? objTopCord - STEP : 0;
          break;
        case 39: // right
          activeSelection.left = objLeftCord + objWidth < gridWidth ? objLeftCord + STEP : objLeftCord;
          break;
        case 40: // down
          activeSelection.top = objTopCord + objHeight < gridHeight ? objTopCord + STEP : objTopCord;
          break;
      }
      activeSelection.setCoords();
      gridState.editorGrid.renderAll();
      store.commit('diagram/triggerStateChanged', null, { root: true });
      store.dispatch('grid/setArrangeShapePadding');
    },
    alignLabels(gridState: IGridStoreState, value: string): void {
      if (gridState.selectedLabel === null || gridState.isLabelAdditionMode) {
        return;
      } else {
        store.commit('grid/alignLabelCords', value);
        gridState.editorGrid.renderAll();
        store.commit('diagram/triggerStateChanged', null, { root: true });
      }
    },
    updateGridLayoutChanged(gridState: IGridStoreState, isLayoutChanged: boolean) {
      gridState.gridLayoutChanged = isLayoutChanged;
    },
    updateLayoutAssetVariablesChanged(gridState: IGridStoreState, layoutAssetVariablesChanged: string[]) {
      gridState.layoutAssetVariablesChanged = layoutAssetVariablesChanged;
    },
    alignLabelCords(gridState: IGridStoreState, value: string) {
      const activeLabelGroup = gridState.editorGrid.getActiveObjects().filter((obj) => obj.name === gridConfig.labelName);
      const activeSelection = gridState.editorGrid.getActiveObject() as fabric.ActiveSelection;
      let sortedLabelOptions: any;
      let distributeDirectionValue: any;
      let distributeSize: any;
      let distributeTypeValue: any;
      if (value === AlignLabelOptions.AlignLeft || value === AlignLabelOptions.AlignRight || value === AlignLabelOptions.AlignCenter || value === AlignLabelOptions.DistributeHorizontal) {
        distributeDirectionValue = 'left';
        distributeSize = activeSelection.width;
        distributeTypeValue = 'width';
      } else {
        distributeDirectionValue = 'top';
        distributeSize = activeSelection.height;
        distributeTypeValue = 'height';
      }
      sortedLabelOptions = HelperMethods.sortLabelArray(activeLabelGroup, distributeDirectionValue);
      activeLabelGroup.forEach((obj, index) => {
        switch (value) {
          case AlignLabelOptions.AlignLeft:
          case AlignLabelOptions.AlignTop: {
            sortedLabelOptions[index][distributeDirectionValue] = -Number(distributeSize)/2;
            break;
          }
          case AlignLabelOptions.AlignCenter:
          case AlignLabelOptions.AlignMiddle: {
            sortedLabelOptions[index][distributeDirectionValue] = 0;
            break;
          }
          case AlignLabelOptions.AlignBottom:
          case AlignLabelOptions.AlignRight: {
            sortedLabelOptions[index][distributeDirectionValue] = Number(distributeSize)/2 - Number(sortedLabelOptions[index][distributeTypeValue]);
            break;
          }
          case AlignLabelOptions.DistributeHorizontal:
          case AlignLabelOptions.DistributeVertical: {
            const interval = Math.abs(Number(distributeSize) / (sortedLabelOptions.length-1));
            const updatedPosition = -Number(distributeSize)/2 + Number(interval * index);
            if (updatedPosition >= -Number(distributeSize)/2 && updatedPosition <= (Number(distributeSize)/2)) {
              sortedLabelOptions[index][distributeDirectionValue] = updatedPosition;
            }
            sortedLabelOptions[sortedLabelOptions.length - 1][distributeDirectionValue] = sortedLabelOptions[sortedLabelOptions.length - 1][distributeDirectionValue] - sortedLabelOptions[sortedLabelOptions.length - 1][distributeTypeValue];
            break;
          }
          default: {
            break;
          }
        }
        obj.setCoords();
      });
    },
    setSelectedNode(gridState: IGridStoreState, node: VariableTreeNode): void {
      const nodeToSet = Object.assign({}, node);
      gridState.selectedNode = nodeToSet;
    },
    setIsLongTerm(gridState: IGridStoreState, value: boolean): void {
      gridState.isLongTerm = value;
    },
    updateAssetVariables(gridState: IGridStoreState, variables: IAssetReportVariableViewModel[]) {
      gridState.allVariables = variables;
    },
  } as IGridStoreMutations,
  actions: {
    setArrangeShapeColor(
      gridContext: IGridContext,
      isArrangeShapeActive: boolean
    ): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (
          gridObject.name === gridConfig.arrangeShapeName ||
          gridObject.name === gridConfig.tempArrangeShapeName
        ) {
          if (isArrangeShapeActive) {
            gridObject.set('stroke', gridConfig.arrangeSpaceColor);
          } else {
            gridObject.set('stroke', gridConfig.arrangeSpaceColorWhite);
          }
        }
      }
      gridContext.state.editorGrid.renderAll();
    },
    setArrangeShapeMode(
      gridContext: IGridContext,
      isArrangeShapeMode: boolean
    ): void {
      // Remove labelShadow
      gridContext.state.isArrangeShapeMode = isArrangeShapeMode;
      gridContext.dispatch('hideMenus');
      // Disable if grid is frozen
      if (gridContext.state.isGridFrozen) {
        return;
      }
      // Clear active object
      gridContext.state.editorGrid.discardActiveObject();
      // Disable burners ettings menu
      gridContext.state.isBurnerSettingsEnabled = false;
      gridContext.state.isVariableSettingsEnabled = false;
      gridContext.state.selectedBurnerDetails = [];
      // Change color of arrange shapes
      gridContext.dispatch(
        'setArrangeShapeColor',
        gridContext.state.isArrangeShapeMode
      );
      // Grab all burner objects
      const gridObjects = gridContext.state.editorGrid.getObjects();
      // Configure arrange shape mode
      if (gridContext.state.isArrangeShapeMode) {
        // Disable selection
        gridContext.state.editorGrid.selection = false;
        // Make all rect arrange shapes selectable
        for (const gridObject of gridObjects) {
          if (
            (gridObject.name === gridConfig.tempArrangeShapeName ||
              gridObject.name === gridConfig.arrangeShapeName) &&
            gridObject.type === 'rect'
          ) {
            gridObject.selectable = true;
          }
        }
        // Lock burners and show existing line control circles at front
        gridContext.dispatch('lockBurnerAndLabelMovement', false);
        for (const gridObject of gridObjects) {
          if (gridObject.name === gridConfig.arrangeShapeControlsName) {
            gridObject.bringToFront();
            gridObject.opacity = 1;
            gridObject.selectable = true;
          } else if (
            gridObject.name === gridConfig.arrangeShapeSizeControlsName
          ) {
            gridObject.opacity = 1;
            gridObject.selectable = true;
          }
        }
        gridContext.state.editorGrid.renderAll();
        // Set event listeners for line start
        gridContext.dispatch('setArrangeShapeMouseDownHandler');
        // Set event listeners for line adjustment
        gridContext.dispatch('setArrangeShapeMouseMoveHandler');
        // Set event listeners for line end and draw control circle
        gridContext.dispatch('setArrangeShapeMouseUpHandler');
      } else {
        // Enable selection
        gridContext.state.editorGrid.selection = true;
        // Reset grid event listeners
        gridContext.state.editorGrid.off('mouse:down');
        gridContext.state.editorGrid.off('mouse:move');
        gridContext.state.editorGrid.off('mouse:up');
        gridContext.dispatch('setGridMouseUpHandler');
        gridContext.dispatch('setGridMouseDownHandler');
        for (const gridObject of gridObjects) {
          // Make all arrange shapes not selectable
          if (
            gridObject.name === gridConfig.tempArrangeShapeName ||
            gridObject.name === gridConfig.arrangeShapeName
          ) {
            gridObject.selectable = false;
          }
          // Make all lines permanent and hide remove hidden lines
          if (gridObject.name === gridConfig.tempArrangeShapeName) {
            if (gridObject.opacity === 0) {
              gridContext.state.editorGrid.remove(gridObject);
            } else {
              gridObject.name = gridConfig.arrangeShapeName;
            }
            // Bring burners and labels to front
          } else if (
            gridObject.name === gridConfig.burnerGroupName ||
            gridObject.name === gridConfig.variableGroupName ||
            gridObject.name === gridConfig.labelName
          ) {
            gridObject.bringToFront();
            // Make all line controls permanent and hide remove hidden lines
          } else if (
            gridObject.name === gridConfig.tempArrangeShapeControlsName ||
            gridObject.name === gridConfig.tempArrangeShapeSizeControlsName
          ) {
            if (gridObject.opacity === 0) {
              gridContext.state.editorGrid.remove(gridObject);
            } else {
              // Size controls
              if (gridObject.type === 'rect') {
                gridObject.opacity = 0;
                gridObject.selectable = false;
                gridObject.name = gridConfig.arrangeShapeSizeControlsName;
                // Shape controls
              } else {
                gridObject.opacity = 0;
                gridObject.selectable = false;
                gridObject.name = gridConfig.arrangeShapeControlsName;
              }
            }
            // Remove hidden lines that were previously permanent
          } else if (
            gridObject.name === gridConfig.arrangeShapeName &&
            gridObject.opacity === 0
          ) {
            gridContext.state.editorGrid.remove(gridObject);
            // Remove hidden line controls that were previously permanent
          } else if (
            gridObject.name === gridConfig.arrangeShapeControlsName &&
            gridObject.opacity === 0
          ) {
            gridContext.state.editorGrid.remove(gridObject);
            // Hide permanent line controls
          } else if (
            gridObject.name === gridConfig.arrangeShapeControlsName &&
            gridObject.opacity === 1
          ) {
            gridObject.opacity = 0;
            gridObject.selectable = false;
            // Remove hidden size controls that were previously permanent
          } else if (
            gridObject.name === gridConfig.arrangeShapeSizeControlsName &&
            gridObject.opacity === 0
          ) {
            gridContext.state.editorGrid.remove(gridObject);
            // Hide permanent size controls
          } else if (
            gridObject.name === gridConfig.arrangeShapeSizeControlsName &&
            gridObject.opacity === 1
          ) {
            gridObject.opacity = 0;
            gridObject.selectable = false;
          }
        }
        gridContext.state.editorGrid.renderAll();
        gridContext.dispatch('lockBurnerAndLabelMovement', true);
      }
      gridContext.commit('diagram/triggerStateChanged', null, { root: true });
    },
    setArrangeMode(gridContext: IGridContext, arrangeMode: ArrangeMode): void {
      gridContext.state.arrangeMode = arrangeMode;
    },
    drawGridLines(gridContext: IGridContext, isWindowResize: boolean): void {
      // Remove label shadowBurners
      gridContext.dispatch('hideMenus');
      // Adjust grid object size for groups
      let scaleFactor =
        gridContext.state.editorGridBoxSize /
        gridContext.state.previousEditorGridBoxSize;
      if (isWindowResize) {
        scaleFactor = 1;
      }
      const burnerObjects = gridContext.state.editorGrid.getObjects(
        'group'
      ) as fabric.Group[];
      for (const gridObject of burnerObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'rect') {
              groupObject.set('height', gridContext.state.editorGridBoxSize);
              groupObject.set('width', gridContext.state.editorGridBoxSize);
              groupObject.setCoords();
            } else if (groupObject.type === 'text') {
              const controlCircleText = groupObject as fabric.Text;
              const currentFontSize = controlCircleText.get('fontSize');
              if (currentFontSize) {
                controlCircleText.set(
                  'fontSize',
                  currentFontSize * scaleFactor
                );
                groupObject.setCoords();
              }
            }
          });
          const originalHeight = gridObject.get('height');
          const originalLeftCoord = gridObject.get('left');
          const originalTopCoord = gridObject.get('top');
          if (
            originalHeight !== undefined &&
            originalLeftCoord !== undefined &&
            originalTopCoord !== undefined
          ) {
            const newLeftCoord =
              (originalLeftCoord /
                (originalHeight - gridConfig.burnerLineBorderWidth)) *
              gridContext.state.editorGridBoxSize;
            const newTopCoord =
              (originalTopCoord /
                (originalHeight - gridConfig.burnerLineBorderWidth)) *
              gridContext.state.editorGridBoxSize;
            gridObject.set(
              'height',
              gridContext.state.editorGridBoxSize +
                gridConfig.burnerLineBorderWidth
            );
            gridObject.set(
              'width',
              gridContext.state.editorGridBoxSize +
                gridConfig.burnerLineBorderWidth
            );
            gridObject.set('left', newLeftCoord);
            gridObject.set('top', newTopCoord);
            gridObject.setCoords();
          }
        } else if (gridObject.name === gridConfig.variableGroupName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'rect') {
              groupObject.set('height', gridContext.state.editorGridBoxSize);
              groupObject.set('width', gridContext.state.editorGridBoxSize * 2);
              groupObject.setCoords();
            } else if (groupObject.type === 'text') {
              const controlCircleText = groupObject as fabric.Text;
              const currentFontSize = controlCircleText.get('fontSize');
              if (currentFontSize) {
                controlCircleText.set(
                  'fontSize',
                  currentFontSize * scaleFactor
                );
                groupObject.setCoords();
              }
            }
          });
          const originalHeight = gridObject.get('height');
          const originalLeftCoord = gridObject.get('left');
          const originalTopCoord = gridObject.get('top');
          if (
            originalHeight !== undefined &&
            originalLeftCoord !== undefined &&
            originalTopCoord !== undefined
          ) {
            const newLeftCoord =
              (originalLeftCoord /
                (originalHeight - gridConfig.burnerLineBorderWidth)) *
              gridContext.state.editorGridBoxSize;
            const newTopCoord =
              (originalTopCoord /
                (originalHeight - gridConfig.burnerLineBorderWidth)) *
              gridContext.state.editorGridBoxSize;
            gridObject.set(
              'height',
              gridContext.state.editorGridBoxSize +
                gridConfig.burnerLineBorderWidth
            );
            gridObject.set(
              'width',
              (scaleFactor === 1) ? gridContext.state.editorGridBoxVariableWidth : (gridContext.state.editorGridBoxSize * 2) +
                gridConfig.burnerLineBorderWidth
            );
            gridObject.set('left', newLeftCoord);
            gridObject.set('top', newTopCoord);
            gridObject.setCoords();
          }
        } else if (
          gridObject.name === gridConfig.tempArrangeShapeControlsName ||
          gridObject.name === gridConfig.arrangeShapeControlsName
        ) {
          const originalLeftCoord = gridObject.get('left');
          const originalTopCoord = gridObject.get('top');
          if (
            originalLeftCoord !== undefined &&
            originalTopCoord !== undefined
          ) {
            const newLeftCoord =
              (originalLeftCoord + gridConfig.arrangeSpaceCircleSize) *
                scaleFactor -
              gridConfig.arrangeSpaceCircleSize;
            const newTopCoord =
              (originalTopCoord + gridConfig.arrangeSpaceCircleSize) *
                scaleFactor -
              gridConfig.arrangeSpaceCircleSize;
            gridObject.set('left', newLeftCoord);
            gridObject.set('top', newTopCoord);
            gridObject.setCoords();
          }
        } else if (gridObject.name === gridConfig.labelName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'rect') {
              const backgroundWidthVal = groupObject.get('width');
              const backgroundHeightVal = groupObject.get('height');
              const backgroundLeftVal = groupObject.get('left');
              const backgroundTopVal = groupObject.get('top');
              if (
                backgroundWidthVal !== undefined &&
                backgroundHeightVal !== undefined &&
                backgroundLeftVal !== undefined &&
                backgroundTopVal !== undefined
              ) {
                groupObject.set({
                  width: backgroundWidthVal * scaleFactor,
                  height: backgroundHeightVal * scaleFactor,
                  left: backgroundLeftVal * scaleFactor,
                  top: backgroundTopVal * scaleFactor,
                });
                groupObject.setCoords();
              }
            } else if (groupObject.type === 'i-text') {
              const labelText = groupObject as fabric.IText;
              const textWidthVal = labelText.get('width');
              const textHeightVal = labelText.get('height');
              const textLeftVal = labelText.get('left');
              const textTopVal = labelText.get('top');
              const fontSizeVal = labelText.get('fontSize');
              if (
                textWidthVal !== undefined &&
                textHeightVal !== undefined &&
                textLeftVal !== undefined &&
                textTopVal !== undefined &&
                fontSizeVal !== undefined
              ) {
                labelText.set({
                  width: textWidthVal * scaleFactor,
                  height: textHeightVal * scaleFactor,
                  left: textLeftVal * scaleFactor,
                  top: textTopVal * scaleFactor,
                  fontSize: fontSizeVal * scaleFactor,
                });
                labelText.setCoords();
              }
            }
          });
          const heightVal = gridObject.get('height');
          const widthVal = gridObject.get('width');
          const leftVal = gridObject.get('left');
          const topVal = gridObject.get('top');
          if (
            heightVal !== undefined &&
            widthVal !== undefined &&
            leftVal !== undefined &&
            topVal !== undefined
          ) {
            gridObject.set({
              left: leftVal * scaleFactor,
              top: topVal * scaleFactor,
              width: widthVal * scaleFactor,
              height: heightVal * scaleFactor,
            });
            gridObject.setCoords();
          }
        }
      }
      // Adjust grid object size for arrange shape lines
      const lineObjects = gridContext.state.editorGrid.getObjects(
        'line'
      ) as fabric.Line[];
      for (const gridObject of lineObjects) {
        if (
          gridObject.name === gridConfig.arrangeShapeName ||
          gridObject.name === gridConfig.tempArrangeShapeName
        ) {
          const x1Val = gridObject.get('x1');
          const y1Val = gridObject.get('y1');
          const x2Val = gridObject.get('x2');
          const y2Val = gridObject.get('y2');
          const topVal = gridObject.get('top');
          const leftVal = gridObject.get('left');
          if (
            x1Val !== undefined &&
            x2Val !== undefined &&
            y1Val !== undefined &&
            y2Val !== undefined &&
            topVal !== undefined &&
            leftVal !== undefined
          ) {
            gridObject.set({
              x1: x1Val * scaleFactor,
              x2: x2Val * scaleFactor,
              y1: y1Val * scaleFactor,
              y2: y2Val * scaleFactor,
              top: topVal * scaleFactor,
              left: leftVal * scaleFactor,
            });
            gridObject.setCoords();
          }
        }
      }
      // Adjust grid object size for size control rectangles and arrange shape rectangles
      const rectObjects = gridContext.state.editorGrid.getObjects(
        'rect'
      ) as fabric.Rect[];
      for (const gridObject of rectObjects) {
        if (
          gridObject.name === gridConfig.arrangeShapeSizeControlsName ||
          gridObject.name === gridConfig.tempArrangeShapeSizeControlsName
        ) {
          const sizeControlHeight = gridObject.get('height');
          const sizeControlWidth = gridObject.get('width');
          const sizeControlLeftCoord = gridObject.get('left');
          const sizeControlTopCoord = gridObject.get('top');
          if (
            sizeControlHeight !== null &&
            sizeControlHeight !== undefined &&
            sizeControlWidth !== null &&
            sizeControlWidth !== undefined &&
            sizeControlLeftCoord !== null &&
            sizeControlLeftCoord !== undefined &&
            sizeControlTopCoord !== null &&
            sizeControlTopCoord !== undefined
          ) {
            gridObject.set({
              width: sizeControlWidth * scaleFactor,
              height: sizeControlHeight * scaleFactor,
              left: sizeControlLeftCoord * scaleFactor,
              top: sizeControlTopCoord * scaleFactor,
            });
            gridObject.setCoords();
            // Fire a scaled event to ensure circles match
            gridObject.trigger('scaled');
          }
        } else if (
          gridObject.name === gridConfig.arrangeShapeName ||
          gridObject.name === gridConfig.tempArrangeShapeName
        ) {
          const sizeControlHeight = gridObject.get('height');
          const sizeControlWidth = gridObject.get('height');
          const sizeControlLeftCoord = gridObject.get('left');
          const sizeControlTopCoord = gridObject.get('top');
          if (
            sizeControlHeight !== null &&
            sizeControlHeight !== undefined &&
            sizeControlWidth !== null &&
            sizeControlWidth !== undefined &&
            sizeControlLeftCoord !== null &&
            sizeControlLeftCoord !== undefined &&
            sizeControlTopCoord !== null &&
            sizeControlTopCoord !== undefined
          ) {
            gridObject.set({
              width: sizeControlWidth * scaleFactor,
              height: sizeControlHeight * scaleFactor,
              left: sizeControlLeftCoord * scaleFactor,
              top: sizeControlTopCoord * scaleFactor,
            });
            gridObject.setCoords();
            // Fire a scaled event to ensure circles match
            gridObject.trigger('scaled');
          }
        }
      }
      // Adjust grid size from objects
      let highestYCoord = 0;
      let highestXCoord = 0;
      let desiredGridWidth = gridContext.state.editorGrid.getWidth();
      let desiredGridHeight = gridContext.state.editorGrid.getHeight();
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        let leftCoord = gridObject.get('left');
        let topCoord = gridObject.get('top');
        // Add width/height to rect
        if (gridObject.type === 'rect') {
          const objectWidth = (gridObject as fabric.Rect).get('width');
          const objectHeight = (gridObject as fabric.Rect).get('height');
          const scaleX = (gridObject as fabric.Rect).get('scaleX');
          const scaleY = (gridObject as fabric.Rect).get('scaleY');
          if (
            leftCoord !== null &&
            leftCoord !== undefined &&
            objectWidth !== null &&
            objectWidth !== undefined &&
            topCoord !== null &&
            topCoord !== undefined &&
            objectHeight !== null &&
            objectHeight !== undefined &&
            scaleX &&
            scaleY
          ) {
            leftCoord = leftCoord + (objectWidth * scaleX) / 2;
            topCoord = topCoord + (objectHeight * scaleY) / 2;
          }
        }
        // Add width/height to ellipse
        if (gridObject.type === 'ellipse') {
          const objectWidth = (gridObject as fabric.Ellipse).get('width');
          const objectHeight = (gridObject as fabric.Ellipse).get('height');
          const scaleX = (gridObject as fabric.Ellipse).get('scaleX');
          const scaleY = (gridObject as fabric.Ellipse).get('scaleY');
          if (
            leftCoord !== null &&
            leftCoord !== undefined &&
            objectWidth !== null &&
            objectWidth !== undefined &&
            topCoord !== null &&
            topCoord !== undefined &&
            objectHeight !== null &&
            objectHeight !== undefined &&
            scaleX &&
            scaleY
          ) {
            leftCoord = leftCoord + (objectWidth * scaleX) / 2;
            topCoord = topCoord + (objectHeight * scaleY) / 2;
          }
        }
        if (
          gridObject.type !== gridConfig.gridLineName &&
          leftCoord !== null &&
          leftCoord !== undefined &&
          leftCoord > highestXCoord
        ) {
          highestXCoord = leftCoord;
        }
        if (
          gridObject.type !== gridConfig.gridLineName &&
          topCoord !== null &&
          topCoord !== undefined &&
          topCoord > highestYCoord
        ) {
          highestYCoord = topCoord;
        }
      }
      if (desiredGridWidth < highestXCoord) {
        desiredGridWidth = highestXCoord + gridContext.state.editorGridBoxSize;
      }
      if (desiredGridHeight < highestYCoord) {
        desiredGridHeight = highestYCoord + gridContext.state.editorGridBoxSize;
      }
      gridContext.commit('updateEditorGridWidth', desiredGridWidth);
      gridContext.commit('updateEditorGridHeight', desiredGridHeight);
      // Create grid lines and add to grid
      const gridlines = [];
      for (
        let i = 0;
        i <
        gridContext.getters.editorGridWidth /
          gridContext.getters.editorGridBoxSize;
        i++
      ) {
        gridlines.push(
          new fabric.Line(
            [
              i * gridContext.getters.editorGridBoxSize,
              0,
              i * gridContext.getters.editorGridBoxSize,
              gridContext.getters.editorGridHeight,
            ],
            { stroke: gridConfig.gridLineColor, selectable: false }
          )
        );
      }
      for (
        let i = 0;
        i <
        gridContext.getters.editorGridHeight /
          gridContext.getters.editorGridBoxSize;
        i++
      ) {
        gridlines.push(
          new fabric.Line(
            [
              0,
              i * gridContext.getters.editorGridBoxSize,
              gridContext.getters.editorGridWidth,
              i * gridContext.getters.editorGridBoxSize,
            ],
            { stroke: gridConfig.gridLineColor, selectable: false }
          )
        );
      }
      gridContext.commit('updateEditorGridLines', gridlines);
      gridContext.dispatch('bringGridObjectsToFront');
    },
    trimExcessGrid(gridContext: IGridContext): void {
      // Trim excess grid space that is no longer needed
      const gridElement:
        | HTMLElement
        | null
        | undefined = document
        ?.querySelector('#asset-diagram-builder')
        ?.shadowRoot?.getElementById('grid-container');
      if (gridElement !== null && gridElement !== undefined) {
        let desiredGridHeight = gridElement.offsetHeight;
        let desiredGridWidth = gridElement.offsetWidth;
        const gridObjects = gridContext.state.editorGrid.getObjects();
        let highestYCoord = 0;
        let highestXCoord = 0;
        for (const gridObject of gridObjects) {
          const leftCoord = gridObject.get('left');
          const topCoord = gridObject.get('top');
          if (
            gridObject.type !== 'line' &&
            leftCoord &&
            leftCoord > highestXCoord
          ) {
            highestXCoord = leftCoord;
          }
          if (
            gridObject.type !== 'line' &&
            topCoord &&
            topCoord > highestYCoord
          ) {
            highestYCoord = topCoord;
          }
        }
        if (desiredGridWidth < highestXCoord) {
          desiredGridWidth =
            highestXCoord + gridContext.state.editorGridBoxSize;
        }
        if (desiredGridHeight < highestYCoord) {
          desiredGridHeight =
            highestYCoord + gridContext.state.editorGridBoxSize;
        }
        gridContext.commit('updateEditorGridWidth', desiredGridWidth);
        gridContext.commit('updateEditorGridHeight', desiredGridHeight);
      }
    },
    getEditorPointerPosition(
      gridContext: IGridContext,
      dragEvent: DragEvent
    ): IGridPointerPosition {
      const point = gridContext.state.editorGrid.getPointer(dragEvent);
      const gridPointerPosition: IGridPointerPosition = {
        x: point.x,
        y: point.y,
      };
      return gridPointerPosition;
    },
    zoomIn(gridContext: IGridContext): void {
      gridContext.dispatch('forceCloseLabelEdit');
      gridContext.state.editorGrid.discardActiveObject();
      const newBoxSize =
        gridContext.getters.editorGridBoxSize * (1 + gridConfig.zoomFactor);
      gridContext.commit('updateEditorGridBoxSize', newBoxSize);
      gridContext.dispatch('drawGridLines', false);
      gridContext.dispatch('setArrangeShapePadding');
      gridContext.state.editorGrid.renderAll();
    },
    zoomOut(gridContext: IGridContext): void {
      gridContext.dispatch('forceCloseLabelEdit');
      gridContext.state.editorGrid.discardActiveObject();
      const newBoxSize =
        gridContext.getters.editorGridBoxSize * (1 - gridConfig.zoomFactor);
      gridContext.commit('updateEditorGridBoxSize', newBoxSize);
      gridContext.dispatch('drawGridLines', false);
      gridContext.dispatch('setArrangeShapePadding');
      gridContext.state.editorGrid.renderAll();
    },
    resetZoom(gridContext: IGridContext): void {
      gridContext.dispatch('forceCloseLabelEdit');
      gridContext.state.editorGrid.discardActiveObject();
      const newBoxSize = gridConfig.defaultGridBoxSize;
      gridContext.commit('updateEditorGridBoxSize', newBoxSize);
      gridContext.dispatch('drawGridLines', false);
      gridContext.dispatch('setArrangeShapePadding');
      gridContext.state.editorGrid.renderAll();
    },
    getZoomStatus(gridContext: IGridContext): IZoomStatus {
      const nextZoomInValue =
        gridContext.getters.editorGridBoxSize * (1 + gridConfig.zoomFactor);
      const nextZoomOutValue =
        gridContext.getters.editorGridBoxSize * (1 - gridConfig.zoomFactor);
      const maxBoxSize =
        gridConfig.defaultGridBoxSize * gridConfig.maxZoomMagnification;
      const minBoxSize =
        gridConfig.defaultGridBoxSize / gridConfig.minZoomMagnification;
      return {
        zoomInDisabled: nextZoomInValue >= maxBoxSize ? true : false,
        zoomOutDisabled: nextZoomOutValue <= minBoxSize ? true : false,
        gridBoxSize: gridContext.getters.editorGridBoxSize,
      };
    },
    cancelArrangeShapeMode(gridContext: IGridContext): void {
      gridContext.state.editorGrid.selection = true;
      gridContext.state.editorGrid.discardActiveObject();
      gridContext.state.isArrangeShapeMode = false;
      gridContext.state.editorGrid.off('mouse:down');
      gridContext.state.editorGrid.off('mouse:move');
      gridContext.state.editorGrid.off('mouse:up');
      gridContext.dispatch('setGridMouseUpHandler');
      gridContext.dispatch('setGridMouseDownHandler');
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        // Remove temporary lines
        if (gridObject.name === gridConfig.tempArrangeShapeName) {
          gridContext.state.editorGrid.remove(gridObject);
          // Show permanent lines
        } else if (gridObject.name === gridConfig.arrangeShapeName) {
          gridObject.opacity = 1;
          // Remove temporary line controls
        } else if (
          gridObject.name === gridConfig.tempArrangeShapeControlsName ||
          gridObject.name === gridConfig.tempArrangeShapeSizeControlsName
        ) {
          gridContext.state.editorGrid.remove(gridObject);
          // Hide permanent line controls
        } else if (
          gridObject.name === gridConfig.arrangeShapeControlsName ||
          gridObject.name === gridConfig.arrangeShapeSizeControlsName
        ) {
          gridObject.opacity = 0;
          gridObject.selectable = false;
          // Bring burners and labels to front
        } else if (
          gridObject.name === gridConfig.burnerGroupName ||
          gridObject.name === gridConfig.labelName
        ) {
          gridObject.bringToFront();
        }
      }
      // Change arrange shape colors
      gridContext.dispatch('setArrangeShapeColor', false);
      // Configure grid
      gridContext.dispatch('lockBurnerAndLabelMovement', true);
      gridContext.dispatch('setArrangeShapePadding');
      gridContext.state.editorGrid.renderAll();
    },
    bringGridObjectsToFront(gridContext: IGridContext): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name !== gridConfig.gridLineName) {
          gridObject.bringToFront();
        }
      }
      gridContext.state.editorGrid.renderAll();
    },
    lockBurnerAndLabelMovement(
      gridContext: IGridContext,
      isMovementAllowed: boolean
    ): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (
          gridObject.name === gridConfig.burnerGroupName ||
          gridObject.name === gridConfig.variableGroupName ||
          gridObject.name === gridConfig.labelName
        ) {
          gridObject.lockMovementX = !isMovementAllowed;
          gridObject.lockMovementY = !isMovementAllowed;
          gridObject.selectable = isMovementAllowed;
        }
      }
    },
    removeBurnerShadows(gridContext: IGridContext): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerShadowName) {
          gridContext.state.editorGrid.remove(gridObject);
        }
      }
      gridContext.state.editorGrid.renderAll();
    },
    removeVariableShadows(gridContext: IGridContext): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.variableShadowName) {
          gridContext.state.editorGrid.remove(gridObject);
        }
      }
      gridContext.state.editorGrid.renderAll();
    },
    setGridPointers(gridContext: IGridContext): void {
      gridContext.state.editorGrid.defaultCursor = 'pointer';
      gridContext.state.editorGrid.hoverCursor = 'pointer';
      gridContext.state.editorGrid.moveCursor = 'grabbing';
    },
    setArrangeShapePadding(gridContext: IGridContext): void {
      // Remove existing arrange shape padding
      gridContext.dispatch('removeArrangeShapePadding');
      // Find all arrange shapes
      const horizontalSpacelines: IHorizontalSpaceline[] = [];
      const verticalSpacelines: IVerticalSpaceline[] = [];
      const lineObjects = gridContext.state.editorGrid.getObjects(
        'line'
      ) as fabric.Line[];
      for (const gridObject of lineObjects) {
        if (
          gridObject.name === gridConfig.arrangeShapeName ||
          gridObject.name === gridConfig.tempArrangeShapeName
        ) {
          if (gridObject.opacity === 1) {
            const x1Val = gridObject.get('x1');
            const y1Val = gridObject.get('y1');
            const x2Val = gridObject.get('x2');
            const y2Val = gridObject.get('y2');
            const topVal = gridObject.get('top');
            const leftVal = gridObject.get('left');
            const widthVal = gridObject.get('width');
            const heightVal = gridObject.get('height');
            if (
              x1Val !== undefined &&
              x2Val !== undefined &&
              y1Val !== undefined &&
              y2Val !== undefined &&
              topVal !== undefined &&
              leftVal !== undefined &&
              widthVal !== undefined &&
              heightVal !== undefined
            ) {
              if (y1Val === y2Val) {
                horizontalSpacelines.push({
                  low: leftVal - widthVal / 2 - 0.1,
                  high: leftVal + widthVal / 2 - 0.1,
                  top: topVal,
                  left: leftVal,
                  y: topVal,
                });
              } else if (x2Val === x1Val) {
                verticalSpacelines.push({
                  low: topVal - heightVal / 2 - 0.1,
                  high: topVal + heightVal / 2 - 0.1,
                  top: topVal,
                  left: leftVal,
                  x: leftVal,
                });
              }
            }
          }
        }
      }
      // Adjust burners for spacelines
      const burnerObjects = gridContext.state.editorGrid.getObjects(
        'group'
      ) as fabric.Group[];
      const selectedBurnerKeys: string[] = [];
      const selectedVariableKeys: string[] = [];
      const gridObjects = gridContext.state.editorGrid.getActiveObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          selectedBurnerKeys.push(gridObject.data?.objectKey);
        }
        if (gridObject.name === gridConfig.variableGroupName) {
          selectedVariableKeys.push(gridObject.data?.objectKey);
        }
      }
      for (const gridObject of burnerObjects) {
        if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          // Skip if burner is selected
          if (selectedBurnerKeys.indexOf(gridObject.data.objectKey) > -1) {
            continue;
          }
          if (selectedVariableKeys.indexOf(gridObject.data.objectKey) > -1) {
            continue;
          }
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            const burnerGroupX = gridObject.get('left');
            const burnerGroupY = gridObject.get('top');
            if (burnerGroupX !== undefined && burnerGroupY !== undefined) {
              if (groupObject.type === 'rect') {
                let lineOnTop = false;
                let lineOnBottom = false;
                let lineOnLeft = false;
                let lineOnRight = false;
                horizontalSpacelines.forEach((horizontalSpaceline) => {
                  // Find spaceline on top of burner square
                  if (Math.abs(burnerGroupY - horizontalSpaceline.y) < 0.01) {
                    if (
                      horizontalSpaceline.low <= burnerGroupX &&
                      burnerGroupX <= horizontalSpaceline.high
                    ) {
                      lineOnTop = true;
                    }
                  }
                  // Find spaceline on bottom of burner square
                  if (
                    Math.abs(
                      burnerGroupY +
                        gridContext.state.editorGridBoxSize -
                        horizontalSpaceline.y
                    ) < 0.01
                  ) {
                    if (
                      horizontalSpaceline.low <= burnerGroupX &&
                      burnerGroupX <= horizontalSpaceline.high
                    ) {
                      lineOnBottom = true;
                    }
                  }
                });
                // Add horizontal padding
                if (lineOnTop && lineOnBottom) {
                  groupObject.set(
                    'height',
                    gridContext.state.editorGridBoxSize -
                      2 * gridConfig.arrangeSpacePadSize
                  );
                } else if (lineOnTop) {
                  groupObject.set(
                    'height',
                    gridContext.state.editorGridBoxSize -
                      gridConfig.arrangeSpacePadSize
                  );
                  gridObject.set(
                    'top',
                    burnerGroupY +
                      gridConfig.arrangeSpacePadSize -
                      2 * gridConfig.burnerLineBorderWidth
                  );
                } else if (lineOnBottom) {
                  groupObject.set(
                    'height',
                    gridContext.state.editorGridBoxSize -
                      gridConfig.arrangeSpacePadSize
                  );
                  gridObject.set(
                    'top',
                    burnerGroupY -
                      gridConfig.arrangeSpacePadSize +
                      2 * gridConfig.burnerLineBorderWidth
                  );
                }
                verticalSpacelines.forEach((verticalSpaceline) => {
                  // Find spaceline on left of burner square
                  if (Math.abs(burnerGroupX - verticalSpaceline.x) < 0.01) {
                    if (
                      verticalSpaceline.low <= burnerGroupY &&
                      burnerGroupY <= verticalSpaceline.high
                    ) {
                      lineOnLeft = true;
                    }
                  }
                  // Find spaceline on right of burner square
                  if (
                    Math.abs(
                      burnerGroupX +
                        gridContext.state.editorGridBoxSize -
                        verticalSpaceline.x
                    ) < 0.01
                  ) {
                    if (
                      verticalSpaceline.low <= burnerGroupY &&
                      burnerGroupY <= verticalSpaceline.high
                    ) {
                      lineOnRight = true;
                    }
                  }
                });
                // Add vertical padding
                if (lineOnLeft && lineOnRight) {
                  groupObject.set(
                    'width',
                    gridContext.state.editorGridBoxSize -
                      2 * gridConfig.arrangeSpacePadSize
                  );
                } else if (lineOnLeft) {
                  groupObject.set(
                    'width',
                    gridContext.state.editorGridBoxSize -
                      gridConfig.arrangeSpacePadSize
                  );
                  gridObject.set(
                    'left',
                    burnerGroupX +
                      gridConfig.arrangeSpacePadSize -
                      2 * gridConfig.burnerLineBorderWidth
                  );
                } else if (lineOnRight) {
                  groupObject.set(
                    'width',
                    gridContext.state.editorGridBoxSize -
                      gridConfig.arrangeSpacePadSize
                  );
                  gridObject.set(
                    'left',
                    burnerGroupX -
                      gridConfig.arrangeSpacePadSize +
                      2 * gridConfig.burnerLineBorderWidth
                  );
                }
              }
            }
          });
        }
      }
    },
    removeArrangeShapePadding(gridContext: IGridContext): void {
      const burnerObjects = gridContext.state.editorGrid.getObjects(
        'group'
      ) as fabric.Group[];
      const selectedBurnerKeys: string[] = [];
      const selectedVariableKeys: string[] = [];
      const gridObjects = gridContext.state.editorGrid.getActiveObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          selectedBurnerKeys.push(gridObject.data?.objectKey);
        }
        if (gridObject.name === gridConfig.variableGroupName) {
          selectedVariableKeys.push(gridObject.data?.objectKey);
        }
      }
      for (const gridObject of burnerObjects) {
        if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          // Skip if burner is selected
          if (selectedBurnerKeys.indexOf(gridObject.data.objectKey) > -1 || selectedVariableKeys.indexOf(gridObject.data.objectKey) > -1) {
            continue;
          }
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'rect') {
              groupObject.set('height', gridContext.state.editorGridBoxSize);
              groupObject.set('width', (gridObject.name === gridConfig.variableGroupName) ? (gridContext.state.editorGridBoxSize * 2) : gridContext.state.editorGridBoxSize);
              groupObject.setCoords();
            }
          });
          const currentTop = gridObject.get('top');
          const currentLeft = gridObject.get('left');
          if (currentTop !== undefined && currentLeft !== undefined) {
            const newTop =
              Math.round(currentTop / gridContext.state.editorGridBoxSize) *
              gridContext.state.editorGridBoxSize;
            const newLeft =
              Math.round(currentLeft / gridContext.state.editorGridBoxSize) *
              gridContext.state.editorGridBoxSize;
            gridObject.set('top', newTop);
            gridObject.set('left', newLeft);
            gridObject.setCoords();
          }
        }
      }
    },
    setGridObjectMovingHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('object:moving', async (options: fabric.IEvent) => {
          // Snap objects to grid
          if (options.target !== undefined && options.target.left !== undefined && options.target.top !== undefined && !gridContext.state.isShadowMode) {
            let cacheHit = false;
            // Find if target is in collision cache
            const topCoord = Math.round(options.target.top / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize;
            const leftCoord = Math.round(options.target.left / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize;
            gridContext.state.collisionCache.forEach((cachedCollision) => {
              if (Math.abs(cachedCollision.left - leftCoord) < 0.01 && Math.abs(cachedCollision.top - topCoord) < 0.01) {
                // Collision is found in cache, i.e., we have already performed this calculation
                if (cachedCollision.result) {
                  // No collision found. Allow movement and exit function
                  options.target?.set({ left: leftCoord, top: topCoord });
                } else {
                  // Collision found. Reset to original and exit function
                  if (options.transform !== undefined) {
                    options.target?.set({ left: options.transform.original.left, top: options.transform.original.top });
                  }
                }
                cacheHit = true;
              }
            });
            if (!cacheHit) {
              // Hide tooltip when moving
              const toolTipSpan: HTMLSpanElement | null | undefined = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('grid-tooltip');
              if (toolTipSpan) {
                toolTipSpan.classList.remove('show');
                toolTipSpan.style.left = 0 + 'px';
                toolTipSpan.style.top = 0 + 'px';
              }
              if (options.target.type === 'activeSelection') {
                if (options.target.left < 0 || options.target.top < 0) {
                  // Attempting to move off grid.  Forbid movement and add to cache
                  if (options.transform !== undefined) {
                    options.target.set({ left: options.transform.original.left, top: options.transform.original.top });
                    gridContext.state.collisionCache.push({ top: topCoord, left: leftCoord, result: false });
                  }
                } else if (await gridContext.dispatch('checkGridActiveObjectDragOverlap')) {
                  // Valid movement found.  Allow movement and add to cache
                  options.target.set({ left: leftCoord, top: topCoord });
                  gridContext.state.collisionCache.push({ top: topCoord, left: leftCoord, result: true });
                } else {
                  if (options.transform !== undefined) {
                    // Collision found.  Forbid movement and add to cache
                    options.target.set({ left: options.transform.original.left, top: options.transform.original.top });
                    gridContext.state.collisionCache.push({ top: topCoord, left: leftCoord, result: false });
                  }
                }
              } else if (options.target.name !== gridConfig.labelName && options.target.name !== gridConfig.tempArrangeShapeSizeControlsName &&
                options.target.name !== gridConfig.arrangeShapeSizeControlsName && options.target.name !== gridConfig.tempArrangeShapeName &&
                options.target.name !== gridConfig.arrangeShapeName) {
                if (options.target.left < 0 || options.target.top < 0) {
                  if (options.transform !== undefined) {
                    // Attempting to move off grid.  Forbid movement and add to cache
                    options.target.set({ left: options.transform.original.left, top: options.transform.original.top });
                    gridContext.state.collisionCache.push({ top: topCoord, left: leftCoord, result: false });
                  }
                } else if ( await gridContext.dispatch('checkGridObjectMoveOverlap', { top: topCoord, left: leftCoord, key: options.target?.data?.objectKey })) {
                  // Valid movement found.  Allow movement and add to cache
                  options.target.set({ left: leftCoord, top: topCoord });
                  gridContext.state.collisionCache.push({ top: topCoord, left: leftCoord, result: true });
                } else if (options.transform !== undefined) {
                  // Collision found.  Forbid movement and add to cache
                  options.target.set({
                    left: options.transform.original.left,
                    top: options.transform.original.top,
                  });
                  gridContext.state.collisionCache.push({
                    top: topCoord,
                    left: leftCoord,
                    result: false,
                  });
                }
              } else if (options.target.name === gridConfig.labelName) {
                if (options.target.left < 0 || options.target.top < 0) {
                  if (options.transform !== undefined) {
                    options.target.set({
                      left: options.transform.original.left,
                      top: options.transform.original.top,
                    });
                  }
                }
              } else if (options.target.name === gridConfig.tempArrangeShapeSizeControlsName || options.target.name === gridConfig.arrangeShapeSizeControlsName ||
                options.target.name === gridConfig.tempArrangeShapeName || options.target.name === gridConfig.arrangeShapeName) {
                if (options.target.left < 0 || options.target.top < 0) {
                  if (options.transform !== undefined) {
                    options.target.set({
                      left: options.transform.original.left,
                      top: options.transform.original.top,
                    });
                  }
                } else {
                  // Allow half box movements
                  const halfTopCoord =
                    Math.round(
                      options.target.top /
                        (gridContext.state.editorGridBoxSize / 2)
                    ) *
                    (gridContext.state.editorGridBoxSize / 2);
                  const halfLeftCoord =
                    Math.round(
                      options.target.left /
                        (gridContext.state.editorGridBoxSize / 2)
                    ) *
                    (gridContext.state.editorGridBoxSize / 2);
                  options.target.set({
                    left: halfLeftCoord,
                    top: halfTopCoord,
                  });
                }
              }
            }
          }
          gridContext.commit('diagram/triggerStateChanged', null, { root: true });
          gridContext.dispatch('setArrangeShapePadding');
        }
      );
    },
    setGridObjectAddedHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('object:added', () => {
        // Check if object exists other than gridlines
        if (
          gridContext.state.editorGrid.getObjects().length > 1 &&
          !gridContext.state.isUpdatingGridLines
        ) {
          gridContext.commit('diagram/triggerStateChanged', null, {
            root: true,
          });
        }
        gridContext.dispatch('setArrangeShapePadding');
      });
    },
    setGridObjectRemovedHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('object:removed', () => {
        // Check if object exists other than gridlines
        if (
          gridContext.state.editorGrid.getObjects().length > 1 &&
          !gridContext.state.isUpdatingGridLines
        ) {
          gridContext.commit('diagram/triggerStateChanged', null, {
            root: true,
          });
          gridContext.dispatch('setArrangeShapePadding');
        }
      });
    },
    setGridDragEnterHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('dragenter', () => {
        gridContext.state.isShadowMode = true;
        const selectedBurners = gridContext.rootGetters['burnerFolder/selectedBurners'];
        const selectedVariables = gridContext.rootGetters['variableFolder/getSelectedVariables'];
        const shadowBurners = [];
        const shadowVariables: any[] = [];
        let indexLevel = 0;
        let topVal: number = 0;
          topVal = topVal + gridContext.state.editorGridBoxSize;
          selectedVariables.forEach((selectedVariable: VariableTreeNode, index: number) => {
            if (index > 0) {
              const prevVariableKey = selectedVariables[index - 1].key;
              if (
                selectedVariable.key !==
                prevVariableKey
              ) {
                topVal = topVal + gridContext.state.editorGridBoxSize;
                indexLevel = index;
              }
            }
            const shadowVariable = new fabric.Rect({
              left: index > 0 && selectedVariable.key !== selectedVariables[index - 1].key ? 0 : (index - indexLevel) * gridContext.state.editorGridBoxSize,
              top: topVal,
              width: gridConfig.variableGridBoxWidth,
              height: gridContext.state.editorGridBoxSize,
              fill: gridConfig.shadowColor,
              hasRotatingPoint: false,
              hasControls: false,
              lockScalingX: true,
              lockScalingY: true,
              lockRotation: true,
              stroke: gridConfig.shadowBorderColor,
              strokeWidth: 1,
              strokeDashArray: [5, 5],
            });
            shadowVariables.push(shadowVariable);
          });
          const shadows = new fabric.Group(shadowVariables, {
            name: gridConfig.variableShadowName,
            selectable: false,
            evented: false,
          });
          gridContext.state.variableShadow = shadows;
          gridContext.state.editorGrid.add(shadows);
          for (let i = 0; i < selectedBurners.length; i++) {
            if (i > 0) {
              const prevBurnerKey = selectedBurners[i - 1].details!
                .emberHierarchyLevelKey!;
              if (
                selectedBurners[i].details!.emberHierarchyLevelKey! !==
                prevBurnerKey
              ) {
                topVal = topVal + gridContext.state.editorGridBoxSize;
                indexLevel = i;
              }
            }
            const shadowBurner = new fabric.Rect({
              left:
                i > 0 && selectedBurners[i].details!.emberHierarchyLevelKey! !== selectedBurners[i - 1].details!.emberHierarchyLevelKey!
                  ? 0
                  : (i - indexLevel) * gridContext.state.editorGridBoxSize,
              top: topVal,
              width: gridContext.state.editorGridBoxSize,
              height: gridContext.state.editorGridBoxSize,
              fill: gridConfig.shadowColor,
              hasRotatingPoint: false,
              hasControls: false,
              lockScalingX: true,
              lockScalingY: true,
              lockRotation: true,
              stroke: gridConfig.shadowBorderColor,
              strokeWidth: 1,
              strokeDashArray: [5, 5],
            });
            shadowBurners.push(shadowBurner);
          }
          const shadow = new fabric.Group(shadowBurners, {
            name: gridConfig.burnerShadowName,
            selectable: false,
            evented: false,
          });
          gridContext.state.burnerShadow = shadow;
          gridContext.state.editorGrid.add(shadow);
      });
    },
    setGridDragLeaveHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('dragleave', () => {
        gridContext.state.isShadowMode = false;
        gridContext.dispatch('removeBurnerShadows');
        gridContext.dispatch('removeVariableShadows');
      });
    },
    setGridDragOverHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on(
        'dragover',
        async (options: fabric.IEvent) => {
          throttledDragOverEvent(gridContext, options);
        }
      );
    },
    setGridMultiSelectCreateHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on(
        'selection:created',
        (event: fabric.IEvent) => {
          // Disable if grid is frozen
          if (gridContext.state.isGridFrozen) {
            gridContext.state.editorGrid.discardActiveObject();
            return;
          }
          // Skip this logic if grid is in arrange shape mode
          if (gridContext.state.isArrangeShapeMode) {
            return;
          }
          // Skip if trying to select an arrange shape rectangle out of arrange shape mode
          if (
            !gridContext.state.isArrangeShapeMode &&
            gridContext.state.editorGrid.getActiveObject().name ===
              gridConfig.arrangeShapeName &&
            gridContext.state.editorGrid.getActiveObject().type === 'rect'
          ) {
            gridContext.state.editorGrid.discardActiveObject();
            return;
          }
          // Reset collision cache
          gridContext.state.collisionCache = [];
          // Modify active selection style
          gridContext.state.editorGrid.getActiveObject().set('hasRotatingPoint', false);
          gridContext.state.editorGrid.getActiveObject().set('hasControls', false);
          gridContext.state.editorGrid.getActiveObject().set('lockScalingX', true);
          gridContext.state.editorGrid.getActiveObject().set('lockScalingY', true);
          gridContext.state.editorGrid.getActiveObject().set('lockRotation', true);
          gridContext.state.editorGrid.getActiveObject().set('selectable', true);
          // Remove arrange shape
          const activeSelection = gridContext.state.editorGrid.getActiveObject() as fabric.ActiveSelection;
          const activeObjects = gridContext.state.editorGrid.getActiveObjects() as fabric.Object[];
          let selectedBurnerCount = 0;
          let selectedLabelCount = 0;
          let selectedVariableCount = 0;
          store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearSelectedVariables.name}`);
          for (const activeObject of activeObjects) {
            if (
              activeObject.name === gridConfig.arrangeShapeName ||
              activeObject.name === gridConfig.tempArrangeShapeName ||
              activeObject.name === gridConfig.arrangeShapeControlsName ||
              activeObject.name === gridConfig.tempArrangeShapeControlsName
            ) {
              if (activeSelection !== null || activeSelection !== undefined) {
                try {
                  activeSelection.removeWithUpdate(activeObject);
                } catch (err) {
                  gridContext.state.editorGrid.discardActiveObject();
                }
              }
            } else if (activeObject.name === gridConfig.burnerGroupName) {
              selectedBurnerCount += 1;
            } else if (activeObject.name === gridConfig.variableGroupName) {
              const variableKey: string =  activeObject.data.objectKey;
              const hoveredVariable: IAssetReportVariableViewModel = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKey.name}`](variableKey);
              store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.setVariable.name}`, hoveredVariable);
              store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.setSelectedvariables.name}`, hoveredVariable);

              selectedVariableCount += 1;
            } else if (activeObject.name === gridConfig.labelName) {
              selectedLabelCount++;
            }
          }
          gridContext.dispatch('getSelectedBurnerDetails');
          gridContext.commit('setActiveLabelCount', selectedLabelCount);
          gridContext.commit('setActiveBurnerCount', selectedBurnerCount);
          gridContext.commit('setActiveVariableCount', selectedVariableCount);
          // Open group selection menu
          if (selectedBurnerCount > 1) {
            gridContext.dispatch('hideMenus');
            gridContext.state.isBurnerSettingsEnabled = true;
            gridContext.dispatch('displayGroupSelectMenu', {
              top: activeSelection.top,
              left: activeSelection.left,
              mouseEvent: event.e,
            });
          } else if (selectedBurnerCount === 1) {
            gridContext.state.isBurnerSettingsEnabled = true;
          } else if (selectedLabelCount > 0) {
            gridContext.dispatch('displayGroupSelectMenu', {
              top: activeSelection.top,
              left: activeSelection.left,
              mouseEvent: event.e,
            });
          } else if (selectedVariableCount > 0) {
            gridContext.state.isVariableSettingsEnabled = true;
          }
          gridContext.dispatch('setActiveObjectMouseUpHandler', activeSelection);
          gridContext.dispatch('setActiveObjectMouseDownHandler', activeSelection);
        }
      );
    },
    setGridMultiSelectUpdateHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on(
        'selection:updated',
        (event: fabric.IEvent) => {
          // Disable if grid is frozen
          if (gridContext.state.isGridFrozen) {
            gridContext.state.editorGrid.discardActiveObject();
            return;
          }
          // Skip this logic if grid is in arrange shape mode
          if (gridContext.state.isArrangeShapeMode) {
            return;
          }
          // Reset collision cache
          gridContext.state.collisionCache = [];
          // Modify active selection style
          gridContext.state.editorGrid.getActiveObject().set({
            hasRotatingPoint: false,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            selectable: true
          });
          // Remove arrange shape and labels
          const activeSelection = gridContext.state.editorGrid.getActiveObject() as fabric.ActiveSelection;
          const activeObjects = gridContext.state.editorGrid.getActiveObjects() as fabric.Object[];
          let selectedVariableCount = 0;
          let selectedBurnerCount = 0;
          let selectedLabelCount = 0;
          store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearSelectedVariables.name}`);
          for (const activeObject of activeObjects) {
            if (
              activeObject.name === gridConfig.arrangeShapeName ||
              activeObject.name === gridConfig.tempArrangeShapeName ||
              activeObject.name === gridConfig.arrangeShapeControlsName ||
              activeObject.name === gridConfig.tempArrangeShapeControlsName
            ) {
              activeSelection.removeWithUpdate(activeObject);
            } else if (activeObject.name === gridConfig.burnerGroupName) {
              selectedBurnerCount += 1;
            } else if (activeObject.name === gridConfig.variableGroupName) {
              const variableKey: string = event.target?.data.objectKey;
              const hoveredVariable: IAssetReportVariableViewModel = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKey.name}`](variableKey);
              store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.setVariable.name}`, hoveredVariable);
              store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.setSelectedvariables.name}`, hoveredVariable);
              selectedVariableCount += 1;
            } else if (activeObject.name === gridConfig.labelName) {
              selectedLabelCount++;
              if (activeObject !== gridContext.state.firstSelectedLabel) {
                activeObject.borderColor = '#ffffff';
              }
            }
          }
          gridContext.dispatch('getSelectedBurnerDetails');
          gridContext.commit('setActiveLabelCount', selectedLabelCount);
          gridContext.commit('setActiveBurnerCount', selectedBurnerCount);
          gridContext.commit('setActiveVariableCount', selectedVariableCount);
          // Open group selection menu
          if (selectedBurnerCount > 1) {
            gridContext.dispatch('hideMenus');
            gridContext.state.isBurnerSettingsEnabled = true;
            gridContext.dispatch('displayGroupSelectMenu', {
              top: activeSelection.top,
              left: activeSelection.left,
              mouseEvent: event.e,
            });
            gridContext.dispatch('setActiveObjectMouseUpHandler', activeSelection);
            gridContext.dispatch('setActiveObjectMouseDownHandler', activeSelection);
          } else if (selectedBurnerCount === 1) {
            gridContext.state.isBurnerSettingsEnabled = true;
          } else if (selectedLabelCount > 0) {
            gridContext.dispatch('displayGroupSelectMenu', {
              top: activeSelection.top,
              left: activeSelection.left,
              mouseEvent: event.e,
            });
            gridContext.dispatch('setActiveObjectMouseUpHandler', activeSelection);
            gridContext.dispatch('setActiveObjectMouseDownHandler', activeSelection);
          } else if (selectedVariableCount > 0) {
            gridContext.state.isVariableSettingsEnabled = true;
          }
        }
      );
    },
    setGridMultiSelectClearHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('selection:cleared', () => {
        // Skip this logic if grid is in arrange shape mode
        if (gridContext.state.isArrangeShapeMode) {
          gridContext.dispatch('bringControlsToFront');
          return;
        }
        // Reset collision cache
        gridContext.state.collisionCache = [];
        // Reset grid styles
        gridContext.state.isBurnerSettingsEnabled = false;
        gridContext.state.isVariableSettingsEnabled = false;
        gridContext.state.selectedBurnerDetails = [];
        gridContext.dispatch('unhighlightAllBurners');
        gridContext.dispatch('setArrangeShapePadding');
        gridContext.dispatch('hideMenus');
        gridContext.dispatch('drawGridLines', true);
      });
      store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearSelectedVariables.name}`);
    },
    checkGridObjectMoveOverlap(
      gridContext: IGridContext,
      objectCoords: IObjectCoords
    ): boolean {
      // Search for burner overlap
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.data?.objectKey !== objectCoords.key) {
          if (
            (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) &&
            gridObject.top &&
            gridObject.left
          ) {
            const topVal = Math.round(gridObject.top / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize;
            const leftVal = gridObject.name === gridConfig.burnerGroupName ?
            Math.round(gridObject.left / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize :
            Math.round(gridObject.left / gridConfig.variableGridBoxWidth) * gridConfig.variableGridBoxWidth;
            if (Math.abs(topVal - objectCoords.top) === 0 && Math.abs(leftVal - objectCoords.left) === 0) {
                return false;
              }
          }
        }
      }
      return true;
    },
    checkGridObjectDragOverlap(
      gridContext: IGridContext,
      objectCoords: IObjectCoords
    ): boolean {
      const selectedBurnerCount =
        gridContext.rootGetters['burnerFolder/selectedCount'];
      const selectedVariableCount =
        gridContext.rootGetters['variableFolder/selectedCount'];
      const maxLeftCoord =
        (selectedBurnerCount + selectedVariableCount) * gridContext.state.editorGridBoxSize +
        objectCoords.left;
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (
          (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) &&
          gridObject.top &&
          gridObject.left
        ) {
          const topVal =
            Math.round(gridObject.top / gridContext.state.editorGridBoxSize) *
            gridContext.state.editorGridBoxSize;
            const leftVal = gridObject.name === gridConfig.burnerGroupName ?
            Math.round(gridObject.left / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize :
            Math.round(gridObject.left / gridConfig.variableGridBoxWidth) * gridConfig.variableGridBoxWidth;
          if (
            topVal !== undefined &&
            leftVal !== undefined &&
            Math.abs(topVal - objectCoords.top) === 0 &&
            leftVal < maxLeftCoord && leftVal >= objectCoords.left
          ) {
            return false;
          }
        }
      }
      return true;
    },
    async checkGridActiveObjectDragOverlap(
      gridContext: IGridContext
    ): Promise<boolean> {
      // Check for overlaps and add back selected objects
      let noOverlapFound = true;
      const activeSelection = gridContext.state.editorGrid.getActiveObject() as fabric.ActiveSelection;
      const activeSelectionCenterPoint = activeSelection.getCenterPoint();
      const centerPointX = activeSelectionCenterPoint.x;
      const centerPointY = activeSelectionCenterPoint.y;
      const activeObjects = activeSelection.getObjects();
      for (const gridObject of activeObjects) {
        if (gridObject.top !== undefined && gridObject.left !== undefined) {
          const topCoord =
            Math.round(
              (centerPointY + gridObject.top) /
                gridContext.state.editorGridBoxSize
            ) * gridContext.state.editorGridBoxSize;
          const leftCoord = gridObject.name === gridConfig.burnerGroupName ?
          Math.round((centerPointX + gridObject.left) / gridContext.state.editorGridBoxSize) * gridContext.state.editorGridBoxSize :
          Math.round((centerPointX + gridObject.left) / gridConfig.variableGridBoxWidth) * gridConfig.variableGridBoxWidth;
          const overlapStatus = await gridContext.dispatch(
            'checkGridObjectMoveOverlap',
            { top: topCoord, left: leftCoord, key: gridObject.data?.objectKey }
          );
          if (!overlapStatus) {
            noOverlapFound = false;
          }
        }
      }
      return noOverlapFound;
    },
    setGridDropHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('drop', () => {
        gridContext.state.isShadowMode = false;
        const selectedBurners = gridContext.rootGetters['burnerFolder/selectedBurners'];
        const selectedBurnersCount = gridContext.rootGetters['burnerFolder/selectedCount'];
        const selectedVariables = gridContext.rootGetters['variableFolder/getSelectedVariables'];
        const gridObjects = gridContext.state.editorGrid.getObjects();
        let topVal: number | undefined = 0;
        let leftVal: number | undefined = 0;
        for (const gridObject of gridObjects) {
          if (gridObject.name === gridConfig.burnerShadowName) {
            topVal = gridObject.get('top');
            leftVal = gridObject.get('left');
          }
        }
        gridContext.dispatch('removeBurnerShadows');
        gridContext.dispatch('removeVariableShadows');
        if (selectedBurnersCount === 0) {
          let indexLevel = 0;
          selectedVariables.forEach((selectedVariable: VariableTreeNode, index: number) => {
            selectedVariable.isLocked = true;
            if (index > 0) {
              const prevVariableKey = selectedVariables[index - 1].key;
              if (selectedVariable.key !== prevVariableKey) {
                topVal = topVal ? topVal + gridContext.state.editorGridBoxSize : 0;
                indexLevel = index;
              }
            }
            if (topVal !== undefined && leftVal !== undefined) {
            let variableString = selectedVariable.name;
            if (variableString.length >= 10) {
              variableString = '..' + selectedVariable.name.substring(selectedVariable.name.length - 10);
            }
            const variableRectangle = new fabric.Rect({
              width: gridContext.state.editorGridBoxVariableWidth,
              height: gridContext.state.editorGridBoxSize,
              originX: 'center',
              originY: 'center',
              stroke: gridConfig.burnerLineColor,
              strokeWidth: gridConfig.burnerLineBorderWidth,
              fill: gridConfig.previewGridBoxOffColor,
            });
            const variableText = new fabric.Text(variableString, {
              fontSize: gridContext.state.editorGridBoxSize / 3,
              fontFamily: gridConfig.burnerFont,
              fontWeight: gridConfig.burnerFontWeight,
              originX: 'center',
              originY: 'center',
              fill: gridConfig.burnerFontColor,
            });
            const variableIdentifier: IGridData = {
              objectKey: selectedVariable.data?.variableKey!,
              objectName: selectedVariable.name
            };
            const variableGroup = new fabric.Group(
              [variableRectangle, variableText],
              {
                name: gridConfig.variableGroupName,
                top: topVal,
                left:
                leftVal +
                (index > 0 && selectedVariable.key! !== selectedVariables[index - 1].key!
                  ? 0
                  : (index - indexLevel) *
                    gridConfig.variableGridBoxWidth),
                hasRotatingPoint: false,
                hasControls: false,
                lockScalingX: true,
                lockScalingY: true,
                lockRotation: true,
                selectable: true,
                data: variableIdentifier,
              }
            );
            gridContext.dispatch(
              'setGridToolTipMouseOverHandler',
              variableGroup
            );
            gridContext.dispatch(
              'setGridToolTipMouseOutHandler',
              variableGroup
            );
            gridContext.dispatch('setBurnerMouseUpHandler', variableGroup);
            gridContext.state.editorGrid.add(variableGroup);
            }
        });
        } else {
        let indexLevel = 0;
        selectedBurners.forEach(
          (selectedBurner: BurnerFolder, index: number) => {
            selectedBurner.lockSelection();
            selectedBurner.clearSelf();
            if (index > 0) {
              const prevBurnerKey = selectedBurners[index - 1].details!
                .emberHierarchyLevelKey!;
              if (
                selectedBurner.details!.emberHierarchyLevelKey! !==
                prevBurnerKey
              ) {
                topVal = topVal
                  ? topVal + gridContext.state.editorGridBoxSize
                  : 0;
                indexLevel = index;
              }
            }
            if (topVal !== undefined && leftVal !== undefined) {
              let burnerString = selectedBurner.name;
              if (burnerString.length >= 4) {
                burnerString =
                  '..' +
                  selectedBurner.name.substring(selectedBurner.name.length - 4);
              }
              const burnerRectangle = new fabric.Rect({
                width: gridContext.state.editorGridBoxSize,
                height: gridContext.state.editorGridBoxSize,
                originX: 'center',
                originY: 'center',
                stroke: gridConfig.burnerLineColor,
                strokeWidth: gridConfig.burnerLineBorderWidth,
                fill: 'rgba(0,0,0,0)',
              });
              const burnerText = new fabric.Text(burnerString, {
                fontSize: gridContext.state.editorGridBoxSize / 3,
                fontFamily: gridConfig.burnerFont,
                fontWeight: gridConfig.burnerFontWeight,
                originX: 'center',
                originY: 'center',
                fill: gridConfig.burnerFontColor,
              });
              const burnerIdentifier: IGridData = {
                objectKey: selectedBurner.key,
              };
              const burnerGroup = new fabric.Group(
                [burnerRectangle, burnerText],
                {
                  name: gridConfig.burnerGroupName,
                  top: topVal,
                  left:
                    leftVal +
                    (index > 0 && selectedBurner.details!.emberHierarchyLevelKey! !== selectedBurners[index - 1].details!.emberHierarchyLevelKey!
                      ? 0
                      : (index - indexLevel) *
                        gridContext.state.editorGridBoxSize),
                  hasRotatingPoint: false,
                  hasControls: false,
                  lockScalingX: true,
                  lockScalingY: true,
                  lockRotation: true,
                  selectable: true,
                  data: burnerIdentifier,
                }
              );
              gridContext.dispatch(
                'setGridToolTipMouseOverHandler',
                burnerGroup
              );
              gridContext.dispatch(
                'setGridToolTipMouseOutHandler',
                burnerGroup
              );
              gridContext.dispatch('setBurnerMouseUpHandler', burnerGroup);
              gridContext.state.editorGrid.add(burnerGroup);
            }
          }
        );
      }
        gridContext.dispatch('resetZoom');
        gridContext.state.editorGrid.renderAll();
        gridContext.dispatch('setArrangeShapePadding');
        store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearSelectedVariables.name}`);
      });
    },
    setGridDoubleClickHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on(
        'mouse:dblclick',
        async (options: fabric.IEvent) => {
          // Exit if diagram is locked
          if (gridContext.rootGetters['diagram/isDiagramLocked']) {
            return;
          }
          // Exit if in label edit mode or arrange shape addition mode
          if (
            gridContext.state.isArrangeShapeMode ||
            gridContext.state.isLabelAdditionMode
          ) {
            return;
          }
          // Exit if burner already exists on this square
          const pointerPosition = await gridContext.dispatch(
            'getEditorPointerPosition',
            options.e
          );
          let noOverlapFound = true;
          const topCoord =
            Math.floor(
              pointerPosition.y / gridContext.state.editorGridBoxSize
            ) * gridContext.state.editorGridBoxSize;
          const leftCoord =
            Math.floor(
              pointerPosition.x / gridContext.state.editorGridBoxSize
            ) * gridContext.state.editorGridBoxSize;
          noOverlapFound = await gridContext.dispatch(
            'checkGridObjectMoveOverlap',
            { top: topCoord, left: leftCoord }
          );
          if (!noOverlapFound) {
            return;
          }
          gridContext.dispatch('hideMenus');
          const labelShadow = new fabric.Rect({
            width: gridContext.state.editorGridBoxSize,
            height: gridContext.state.editorGridBoxSize,
            fill: gridConfig.shadowColor,
            originX: 'center',
            originY: 'center',
            stroke: gridConfig.shadowBorderColor,
            strokeWidth: 1,
            strokeDashArray: [5, 5],
          });
          const labelText = new fabric.Text('Label', {
            top: gridContext.state.editorGridBoxSize / 4,
            fontSize: gridContext.state.editorGridBoxSize / 4,
            fontFamily: gridConfig.burnerFont,
            fontWeight: gridConfig.burnerFontWeight,
            originX: 'center',
            originY: 'center',
            fill: gridConfig.labelShadowFontColor,
          });
          const labelGroup = new fabric.Group([labelShadow, labelText], {
            name: gridConfig.labelShadowName,
            left:
              Math.floor(
                pointerPosition.x / gridContext.state.editorGridBoxSize
              ) * gridContext.state.editorGridBoxSize,
            top:
              Math.floor(
                pointerPosition.y / gridContext.state.editorGridBoxSize
              ) * gridContext.state.editorGridBoxSize,
            hasRotatingPoint: false,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            selectable: false,
          });
          const labelImageUrl = require('../../assets/images/icons/Add Circle.svg');
          const labelImage = new Image();
          labelImage.onload = () => {
            const labelBackgroundImage = new fabric.Image(labelImage, {
              top:
                -2 * (gridContext.state.editorGridBoxSize / labelImage.width),
              originX: 'center',
              originY: 'center',
              scaleX:
                (gridContext.state.editorGridBoxSize * 0.75 -
                  labelImage.width) /
                labelImage.width,
              scaleY:
                (gridContext.state.editorGridBoxSize * 0.75 -
                  labelImage.height) /
                labelImage.width,
            });
            labelGroup.add(labelBackgroundImage);
            gridContext.state.editorGrid.renderAll();
          };
          labelImage.src = labelImageUrl;
          gridContext.dispatch('setLabelShadowMouseDownHandler', labelGroup);
          gridContext.state.editorGrid.add(labelGroup);
          gridContext.state.editorGrid.renderAll();
        }
      );
    },
    setGridMouseUpHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('mouse:up', (event: fabric.IEvent) => {
        const pointer = gridContext.state.editorGrid.getPointer(event.e);
        if (gridContext.state.isArrangeShapeMode) {
          return;
        } else {
          gridContext.dispatch('hideMenus');
          // Discard selection if off screen
          if (
            pointer.x < 0 ||
            pointer.x > gridContext.state.editorGrid.getWidth() ||
            pointer.y < 0 ||
            pointer.y > gridContext.state.editorGrid.getHeight()
          ) {
            gridContext.state.editorGrid.discardActiveObject();
          }
        }
      });
    },
    setGridMouseDownHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on(
        'mouse:down',
        (options: fabric.IEvent) => {
          const activeObjects = gridContext.state.editorGrid.getActiveObjects();
          if (activeObjects.length === 0) {
            gridContext.state.isBurnerSettingsEnabled = false;
            gridContext.state.isVariableSettingsEnabled = false;
            gridContext.state.selectedBurnerDetails = [];
            gridContext.state.selectedVariableDetails = [];
          }
          if (
            gridContext.state.isLabelAdditionMode &&
            (options.target === null ||
              (options.target && options.target.name !== gridConfig.labelName))
          ) {
            const editingLabel = gridContext.state.editingLabel;
            if (editingLabel !== null && editingLabel.text !== undefined) {
              editingLabel.exitEditing();
              const labelText = editingLabel.text.trim();
              const gridLabel = new fabric.IText(labelText, {
                top: editingLabel.top,
                left: editingLabel.left,
                padding: gridContext.state.editorGridBoxSize / 6,
                height: gridContext.state.editorGridBoxSize * 0.75,
                fontSize: gridContext.state.editorGridBoxSize / 3,
                fontFamily: gridConfig.burnerFont,
                fontWeight: gridConfig.burnerFontWeight,
                fill: gridConfig.labelFontColor,
                editable: false,
              });
              const gridLabelBackground = new fabric.Rect({
                top: editingLabel.getBoundingRect().top,
                left: editingLabel.getBoundingRect().left,
                width: editingLabel.getBoundingRect().width,
                height: editingLabel.getBoundingRect().height,
                fill: gridConfig.labelBackgroundColor,
                selectable: false,
                rx: 5,
                ry: 5,
              });
              const gridLabelGroup = new fabric.Group([gridLabelBackground, gridLabel], {
                  name: gridConfig.labelName,
                  hasRotatingPoint: false,
                  hasControls: false,
                  lockScalingX: true,
                  lockScalingY: true,
                  lockRotation: true,
                  selectable: true,
                }
              );
              gridContext.dispatch(
                'setLabelGroupMouseUpHandler',
                gridLabelGroup
              );
              gridContext.state.editorGrid.add(gridLabelGroup);
              gridContext.state.editorGrid.remove(editingLabel);
              gridContext.state.editingLabel = null;
              gridContext.state.editorGrid.renderAll();
              gridContext.commit('updateIsLabelAdditionMode', false);
              gridContext.dispatch('hideMenus');
            }
          }
        }
      );
    },
    forceCloseLabelEdit(gridContext: IGridContext): void {
      const editingLabel = gridContext.state.editingLabel;
      if (editingLabel !== null && editingLabel.text !== undefined) {
        editingLabel.exitEditing();
        const labelText = editingLabel.text.trim();
        const gridLabel = new fabric.IText(labelText, {
          top: editingLabel.top,
          left: editingLabel.left,
          padding: gridContext.state.editorGridBoxSize / 6,
          height: gridContext.state.editorGridBoxSize * 0.75,
          fontSize: gridContext.state.editorGridBoxSize / 3,
          fontFamily: gridConfig.burnerFont,
          fontWeight: gridConfig.burnerFontWeight,
          fill: gridConfig.labelFontColor,
          editable: false,
        });
        const gridLabelBackground = new fabric.Rect({
          top: editingLabel.getBoundingRect().top,
          left: editingLabel.getBoundingRect().left,
          width: editingLabel.getBoundingRect().width,
          height: editingLabel.getBoundingRect().height,
          fill: gridConfig.labelBackgroundColor,
          selectable: false,
          rx: 5,
          ry: 5,
        });
        const gridLabelGroup = new fabric.Group(
          [gridLabelBackground, gridLabel],
          {
            name: gridConfig.labelName,
            hasRotatingPoint: false,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            selectable: true,
          }
        );
        gridContext.dispatch('setLabelGroupMouseUpHandler', gridLabelGroup);
        gridContext.state.editorGrid.add(gridLabelGroup);
        gridContext.state.editorGrid.remove(editingLabel);
        gridContext.state.editingLabel = null;
        gridContext.state.editorGrid.renderAll();
        gridContext.commit('updateIsLabelAdditionMode', false);
        gridContext.dispatch('hideMenus');
      }
    },
    setGridListeners(gridContext: IGridContext): void {
      gridContext.dispatch('setGridPointers');
      gridContext.dispatch('setGridObjectMovingHandler');
      gridContext.dispatch('setGridDragEnterHandler');
      gridContext.dispatch('setGridDragLeaveHandler');
      gridContext.dispatch('setGridDragOverHandler');
      gridContext.dispatch('setGridDropHandler');
      gridContext.dispatch('setGridObjectAddedHandler');
      gridContext.dispatch('setGridObjectRemovedHandler');
      gridContext.dispatch('setGridDoubleClickHandler');
      gridContext.dispatch('setGridMouseUpHandler');
      gridContext.dispatch('setGridMouseDownHandler');
      gridContext.dispatch('setGridMultiSelectCreateHandler');
      gridContext.dispatch('setGridMultiSelectUpdateHandler');
      gridContext.dispatch('setGridMultiSelectClearHandler');
    },
    setLabelShadowMouseDownHandler(
      gridContext: IGridContext,
      labelGroup: fabric.Group
    ): void {
      labelGroup.on('mousedown', () => {
        const gridLabel = new fabric.IText('Label Text', {
          top: labelGroup.top,
          left: labelGroup.left,
          padding: gridContext.state.editorGridBoxSize / 6,
          height: gridContext.state.editorGridBoxSize * 0.75,
          fontSize: gridContext.state.editorGridBoxSize / 3,
          fontFamily: gridConfig.burnerFont,
          fontWeight: gridConfig.burnerFontWeight,
          fill: gridConfig.labelFontColor,
          editable: false,
        });
        const gridLabelBackground = new fabric.Rect({
          top: gridLabel.getBoundingRect().top,
          left: gridLabel.getBoundingRect().left,
          width: gridLabel.getBoundingRect().width,
          height: gridLabel.getBoundingRect().height,
          fill: gridConfig.labelBackgroundColor,
          selectable: false,
          rx: 5,
          ry: 5,
        });
        const gridLabelGroup = new fabric.Group(
          [gridLabelBackground, gridLabel],
          {
            name: gridConfig.labelName,
            hasRotatingPoint: false,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            selectable: true,
          }
        );
        gridContext.commit('updateSelectedGridLabel', gridLabelGroup);
        gridContext.dispatch('setLabelGroupMouseUpHandler', gridLabelGroup);
        gridContext.state.editorGrid.add(gridLabelGroup);
        gridContext.state.editorGrid.renderAll();
        gridContext.commit('updateSelectedGridLabelContents');
      });
    },
    setLabelGroupMouseUpHandler(
      gridContext: IGridContext,
      labelGroup: fabric.Group
    ): void {
      labelGroup.on('mouseup', (event: fabric.IEvent) => {
        // Exit if diagram is locked
        if (gridContext.rootGetters['diagram/isDiagramLocked']) {
          return;
        }
        // Exit if diagram is in arrange shape addition mode
        if (gridContext.state.isArrangeShapeMode) {
          return;
        }
        gridContext.commit('updateSelectedGridLabel', labelGroup);
        gridContext.dispatch('removeEntityMenu');
        const menuElement = document.createElement('div');
        menuElement.id = 'menu-element';
        const appElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('app');
        if (appElement) {
          appElement.appendChild(menuElement);
        }
        const MenuConstructor = Vue.extend(EntitySelect);
        new MenuConstructor({}).$mount(menuElement);
        const labelElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('entity-select');
        const scrolledLeft = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('grid-container')?.scrollLeft;
        const scrolledTop = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('grid-container')?.scrollTop;
        if (
          labelGroup.top !== undefined &&
          labelGroup.left !== undefined &&
          labelElement !== null &&
          labelElement !== undefined &&
          scrolledLeft !== undefined &&
          scrolledTop !== undefined &&
          appElement !== undefined &&
          appElement !== null
        ) {
          const boundingRectMenu = labelElement.getBoundingClientRect();
          const boundingRectApp = appElement.getBoundingClientRect();
          const maxTop = boundingRectApp.height;
          const maxLeft = boundingRectApp.width;
          const menuTop = boundingRectMenu.height;
          const menuLeft = boundingRectMenu.width;
          const mouseEvent = event.e as IMouseEvent;
          let calculatedTop =
            labelGroup.top +
            (mouseEvent.clientY - mouseEvent.offsetY) -
            scrolledTop;
          let calculatedLeft =
            labelGroup.left +
            (mouseEvent.clientX - mouseEvent.offsetX) -
            scrolledLeft;
          if (calculatedLeft + menuLeft > maxLeft) {
            calculatedLeft -= menuLeft;
          }
          if (calculatedTop + menuTop > maxTop) {
            calculatedTop -= menuTop;
          }
          labelElement.style.position = 'absolute';
          labelElement.style.top = calculatedTop + 'px';
          labelElement.style.left = calculatedLeft + 'px';
        }
      });
    },
    setArrangeShapeControlMouseDownHandler(
      gridContext: IGridContext,
      arrangeShapeControlGroup: fabric.Group
    ): void {
      arrangeShapeControlGroup.on('mousedown', () => {
        gridContext.state.arrangeShapes.forEach((potentialArrangeShape) => {
          if (
            potentialArrangeShape.shapeControl.selectable &&
            arrangeShapeControlGroup === potentialArrangeShape.shapeControl
          ) {
            // Hide shape and control
            potentialArrangeShape.shape.opacity = 0;
            potentialArrangeShape.shape.selectable = false;
            arrangeShapeControlGroup.opacity = 0;
            arrangeShapeControlGroup.selectable = false;
            gridContext.dispatch('setArrangeShapePadding');
            gridContext.state.editorGrid.renderAll();
            // Hide shape size control, if present
            if (
              potentialArrangeShape.sizeControl !== null &&
              potentialArrangeShape.sizeControl !== undefined
            ) {
              potentialArrangeShape.sizeControl.opacity = 0;
              potentialArrangeShape.sizeControl.selectable = false;
            }
          }
        });
      });
    },
    setArrangeShapeMouseUpHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('mouse:up', () => {
        if (gridContext.state.arrangeShape) {
          const groupUUID = uuidv4();
          if (gridContext.state.arrangeShape.type === 'line') {
            const arrangeShape = gridContext.state.arrangeShape as fabric.Line;
            let x1Val = arrangeShape.get('x1');
            let y1Val = arrangeShape.get('y1');
            let x2Val = arrangeShape.get('x2');
            let y2Val = arrangeShape.get('y2');
            if (x1Val === x2Val && y1Val === y2Val) {
              gridContext.state.editorGrid.remove(
                gridContext.state.arrangeShape
              );
            } else if (x2Val !== undefined && y2Val !== undefined) {
              if (x1Val && x1Val < 0) {
                x1Val = 0;
              }
              if (x2Val && x2Val < 0) {
                x2Val = 0;
              }
              if (y1Val && y1Val < 0) {
                y1Val = 0;
              }
              if (y2Val && y2Val < 0) {
                y2Val = 0;
              }
              const arrangeShapeControlCircle = new fabric.Circle({
                radius: gridConfig.arrangeSpaceCircleSize,
                fill: gridConfig.arrangeSpaceColor,
                originX: 'center',
                originY: 'center',
              });
              const arrangeShapeControlText = new fabric.Text('X', {
                fontSize: gridConfig.arrangeSpaceCircleSize * 1.5,
                fontFamily: gridConfig.arrangeShapeControlsFont,
                fontWeight: gridConfig.arrangeShapeControlsFontWeight,
                originX: 'center',
                originY: 'center',
              });
              const arrangeShapeControlGroup = new fabric.Group(
                [arrangeShapeControlCircle, arrangeShapeControlText],
                {
                  name: gridConfig.tempArrangeShapeControlsName,
                  top: y2Val - gridConfig.arrangeSpaceCircleSize,
                  left: x2Val - gridConfig.arrangeSpaceCircleSize,
                  hasRotatingPoint: false,
                  hasControls: false,
                  lockScalingX: true,
                  lockScalingY: true,
                  lockRotation: true,
                  lockMovementX: true,
                  lockMovementY: true,
                  selectable: true,
                }
              );
              // Set group ID
              gridContext.state.arrangeShape.data = groupUUID;
              arrangeShapeControlGroup.data = groupUUID;
              // Set controls
              gridContext.state.editorGrid.add(arrangeShapeControlGroup);
              gridContext.state.arrangeShapes.push({
                shape: gridContext.state.arrangeShape as fabric.Line,
                shapeControl: arrangeShapeControlGroup,
              });
              gridContext.dispatch(
                'setArrangeShapeControlMouseDownHandler',
                arrangeShapeControlGroup
              );
              gridContext.dispatch('setArrangeShapePadding');
              gridContext.state.editorGrid.renderAll();
            }
          } else if (gridContext.state.arrangeShape.type === 'ellipse') {
            const arrangeShape = gridContext.state
              .arrangeShape as fabric.Ellipse;
            const rx = arrangeShape.get('rx');
            const ry = arrangeShape.get('ry');
            if (
              rx === undefined ||
              rx === null ||
              rx === 0 ||
              ry === undefined ||
              ry === null ||
              ry === 0
            ) {
              gridContext.state.editorGrid.remove(
                gridContext.state.arrangeShape
              );
            } else {
              const arrangeShapeControlCircle = new fabric.Circle({
                radius: gridConfig.arrangeSpaceCircleSize,
                fill: gridConfig.arrangeSpaceColor,
                originX: 'center',
                originY: 'center',
                left: -1 * (rx - gridConfig.arrangeSpaceCircleSize),
              });
              const arrangeShapeControlText = new fabric.Text('X', {
                fontSize: gridConfig.arrangeSpaceCircleSize * 1.5,
                fontFamily: gridConfig.arrangeShapeControlsFont,
                fontWeight: gridConfig.arrangeShapeControlsFontWeight,
                originX: 'center',
                originY: 'center',
                left: -1 * (rx - gridConfig.arrangeSpaceCircleSize),
              });
              const topCoord = arrangeShape.get('top');
              const leftCoord = arrangeShape.get('left');
              if (
                topCoord !== undefined &&
                topCoord !== null &&
                leftCoord !== undefined &&
                leftCoord != null
              ) {
                const arrangeShapeControlGroup = new fabric.Group(
                  [arrangeShapeControlCircle, arrangeShapeControlText],
                  {
                    name: gridConfig.tempArrangeShapeControlsName,
                    top: topCoord,
                    left: leftCoord - rx - gridConfig.arrangeSpaceCircleSize,
                    hasRotatingPoint: false,
                    hasControls: false,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockRotation: true,
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: true,
                  }
                );
                const arrangeShapeSizeControlBox = new fabric.Rect({
                  name: gridConfig.tempArrangeShapeSizeControlsName,
                  width: rx * 2,
                  height: ry * 2,
                  originX: 'center',
                  originY: 'center',
                  stroke: gridConfig.arrangeSpaceColorGrey,
                  strokeWidth: gridConfig.arrangeShapeStrokeWidth,
                  fill: 'rgba(0,0,0,0)',
                  top: topCoord,
                  left: leftCoord,
                  hasRotatingPoint: false,
                  hasControls: true,
                  lockScalingX: false,
                  lockScalingY: false,
                  lockRotation: true,
                  lockMovementX: false,
                  lockMovementY: false,
                  selectable: true,
                  strokeUniform: true,
                });
                // Set group ID
                gridContext.state.arrangeShape.data = groupUUID;
                arrangeShapeControlGroup.data = groupUUID;
                arrangeShapeSizeControlBox.data = groupUUID;
                // Set controls
                gridContext.state.editorGrid.add(arrangeShapeSizeControlBox);
                gridContext.state.editorGrid.add(arrangeShapeControlGroup);
                gridContext.state.arrangeShapes.push({
                  shape: gridContext.state.arrangeShape,
                  shapeControl: arrangeShapeControlGroup,
                  sizeControl: arrangeShapeSizeControlBox,
                });
                gridContext.dispatch(
                  'setArrangeShapeSizeControlMouseMovingHandler',
                  arrangeShapeSizeControlBox
                );
                gridContext.dispatch(
                  'setArrangeShapeSizeControlScaledHandler',
                  arrangeShapeSizeControlBox
                );
                gridContext.dispatch(
                  'setArrangeShapeSizeControlModifiedHandler',
                  arrangeShapeSizeControlBox
                );
                gridContext.dispatch(
                  'setArrangeShapeControlMouseDownHandler',
                  arrangeShapeControlGroup
                );
                gridContext.dispatch('setArrangeShapePadding');
                gridContext.state.editorGrid.renderAll();
              }
            }
          } else if (gridContext.state.arrangeShape.type === 'rect') {
            const arrangeShape = gridContext.state.arrangeShape as fabric.Rect;
            const width = arrangeShape.get('width');
            const height = arrangeShape.get('height');
            if (
              width === undefined ||
              width === null ||
              width === 0 ||
              height === undefined ||
              height === null ||
              height === 0
            ) {
              gridContext.state.editorGrid.remove(
                gridContext.state.arrangeShape
              );
            } else {
              const arrangeShapeControlCircle = new fabric.Circle({
                radius: gridConfig.arrangeSpaceCircleSize,
                fill: gridConfig.arrangeSpaceColor,
                originX: 'center',
                originY: 'center',
                left: -1 * (width - gridConfig.arrangeSpaceCircleSize),
              });
              const arrangeShapeControlText = new fabric.Text('X', {
                fontSize: gridConfig.arrangeSpaceCircleSize * 1.5,
                fontFamily: gridConfig.arrangeShapeControlsFont,
                fontWeight: gridConfig.arrangeShapeControlsFontWeight,
                originX: 'center',
                originY: 'center',
                left: -1 * (width - gridConfig.arrangeSpaceCircleSize),
              });
              const topCoord = arrangeShape.get('top');
              const leftCoord = arrangeShape.get('left');
              if (
                topCoord !== undefined &&
                topCoord !== null &&
                leftCoord !== undefined &&
                leftCoord != null
              ) {
                const arrangeShapeControlGroup = new fabric.Group(
                  [arrangeShapeControlCircle, arrangeShapeControlText],
                  {
                    name: gridConfig.tempArrangeShapeControlsName,
                    top: topCoord,
                    left:
                      leftCoord - width / 2 - gridConfig.arrangeSpaceCircleSize,
                    hasRotatingPoint: false,
                    hasControls: false,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockRotation: true,
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: true,
                  }
                );
                // Set group ID
                gridContext.state.arrangeShape.data = groupUUID;
                arrangeShapeControlGroup.data = groupUUID;
                // Set controls
                gridContext.state.editorGrid.add(arrangeShapeControlGroup);
                gridContext.state.arrangeShapes.push({
                  shape: gridContext.state.arrangeShape,
                  shapeControl: arrangeShapeControlGroup,
                });
                gridContext.dispatch(
                  'setArrangeShapeControlMouseDownHandler',
                  arrangeShapeControlGroup
                );
                gridContext.dispatch(
                  'setArrangeShapeScaledHandler',
                  gridContext.state.arrangeShape
                );
                gridContext.dispatch(
                  'setArrangeShapeMovingHandler',
                  gridContext.state.arrangeShape
                );
                gridContext.dispatch(
                  'setArrangeShapeModifiedHandler',
                  gridContext.state.arrangeShape
                );
                gridContext.dispatch('setArrangeShapePadding');
                gridContext.state.editorGrid.renderAll();
              }
            }
          }
        }
        gridContext.state.arrangeShape = null;
        gridContext.state.isMouseDown = false;
      });
    },
    setArrangeShapeMouseMoveHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('mouse:move', (event: fabric.IEvent) => {
        if (!gridContext.state.isMouseDown) {
          return;
        } else {
          const pointer = gridContext.state.editorGrid.getPointer(event.e);
          if (gridContext.state.arrangeShape) {
            if (gridContext.state.arrangeShape.type === 'line') {
              const arrangeShape = gridContext.state
                .arrangeShape as fabric.Line;
              const oldXval = arrangeShape.get('x1');
              const oldYval = arrangeShape.get('y1');
              const newXval =
                Math.round(pointer.x / gridContext.state.editorGridBoxSize) *
                gridContext.state.editorGridBoxSize;
              const newYval =
                Math.round(pointer.y / gridContext.state.editorGridBoxSize) *
                gridContext.state.editorGridBoxSize;
              if (oldXval && oldYval) {
                // Determine whether to scale the line vertically or horizontally
                const percentXDifference = Math.abs(
                  (oldXval - newXval) / ((oldXval + newXval) / 2)
                );
                const percentYDifference = Math.abs(
                  (oldYval - newYval) / ((oldYval + newYval) / 2)
                );
                if (percentXDifference > percentYDifference) {
                  arrangeShape.set({ x2: newXval, y2: oldYval });
                } else {
                  arrangeShape.set({ y2: newYval, x2: oldXval });
                }
                arrangeShape.setCoords();
              }
            } else if (gridContext.state.arrangeShape.type === 'ellipse') {
              const arrangeShape = gridContext.state
                .arrangeShape as fabric.Ellipse;
              const points = [pointer.x, pointer.y, pointer.x, pointer.y];
              points.forEach((point, index) => {
                points[index] =
                  Math.round(point / gridContext.state.editorGridBoxSize) *
                  gridContext.state.editorGridBoxSize;
              });
              const prevTop = arrangeShape.get('top');
              const prevLeft = arrangeShape.get('left');
              const newTop = points[1];
              const newLeft = points[0];
              if (
                prevTop !== null &&
                prevTop !== undefined &&
                prevLeft !== null &&
                prevLeft !== undefined
              ) {
                // Some 3rd grade math to find the distance between the pointer and the center of the circle
                const aSquared = (prevLeft - newLeft) * (prevLeft - newLeft);
                const bSquared = (prevTop - newTop) * (prevTop - newTop);
                const c = Math.sqrt(aSquared + bSquared);
                // Ensure that radius is not bigger than top or left coord to ensure circle stays on grid
                const centerPoint = arrangeShape.getCenterPoint();
                let newRx = c;
                let newRy = c;
                if (c > centerPoint.x) {
                  newRx = centerPoint.x;
                }
                if (c > centerPoint.y) {
                  newRy = centerPoint.y;
                }
                arrangeShape.set({ rx: newRx, ry: newRy });
                arrangeShape.setCoords();
              }
            } else if (gridContext.state.arrangeShape.type === 'rect') {
              const arrangeShape = gridContext.state
                .arrangeShape as fabric.Rect;
              // Round points to grid
              const points = [pointer.x, pointer.y, pointer.x, pointer.y];
              points.forEach((point, index) => {
                points[index] =
                  Math.round(point / gridContext.state.editorGridBoxSize) *
                  gridContext.state.editorGridBoxSize;
              });
              const prevTop = arrangeShape.get('top');
              const prevLeft = arrangeShape.get('left');
              const newTop = points[1];
              const newLeft = points[0];
              if (prevLeft && prevTop) {
                // Some 3rd grade math to find the distance between the pointer and the center of the circle
                const aSquared = (prevLeft - newLeft) * (prevLeft - newLeft);
                const bSquared = (prevTop - newTop) * (prevTop - newTop);
                let c = Math.sqrt(aSquared + bSquared);
                // Round c
                c =
                  Math.round(c / gridContext.state.editorGridBoxSize) *
                  gridContext.state.editorGridBoxSize;
                // Check if c is offgrid
                const centerPoint = arrangeShape.getCenterPoint();
                let newWidth = c;
                let newHeight = c;
                if (0.5 * c > centerPoint.x) {
                  newWidth = 0.5 * centerPoint.x;
                }
                if (0.5 * c > centerPoint.y) {
                  newHeight = 0.5 * centerPoint.y;
                }
                arrangeShape.set({ width: newWidth, height: newHeight });
                arrangeShape.setCoords();
              }
            }
          }
          gridContext.state.editorGrid.renderAll();
        }
      });
    },
    setArrangeShapeMouseDownHandler(gridContext: IGridContext): void {
      gridContext.state.editorGrid.on('mouse:down', (event: fabric.IEvent) => {
        const gridObjects = gridContext.state.editorGrid.getActiveObjects();
        for (const gridObject of gridObjects) {
          if (
            gridObject.name === gridConfig.tempArrangeShapeControlsName ||
            gridObject.name === gridConfig.arrangeShapeControlsName ||
            gridObject.name === gridConfig.tempArrangeShapeSizeControlsName ||
            gridObject.name === gridConfig.arrangeShapeSizeControlsName ||
            gridObject.name === gridConfig.tempArrangeShapeName ||
            gridObject.name === gridConfig.arrangeShapeName
          ) {
            return;
          }
        }
        gridContext.state.isMouseDown = true;
        const pointer = gridContext.state.editorGrid.getPointer(event.e);
        const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        points.forEach((point, index) => {
          points[index] =
            Math.round(point / gridContext.state.editorGridBoxSize) *
            gridContext.state.editorGridBoxSize;
        });
        const shapeType: ArrangeMode = gridContext.state.arrangeMode;
        if (shapeType === ArrangeMode.AddCircle) {
          gridContext.state.arrangeShape = new fabric.Ellipse({
            name: gridConfig.tempArrangeShapeName,
            strokeWidth: gridConfig.arrangeShapeStrokeWidth,
            fill: 'rgba(0,0,0,0)',
            stroke: gridConfig.arrangeSpaceColor,
            originX: 'center',
            originY: 'center',
            hasRotatingPoint: false,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            rx: 0,
            ry: 0,
            top: points[1],
            left: points[0],
            strokeUniform: true,
          });
          gridContext.state.editorGrid.add(gridContext.state.arrangeShape);
        } else if (shapeType === ArrangeMode.AddSpace) {
          gridContext.state.arrangeShape = new fabric.Line(points, {
            name: gridConfig.tempArrangeShapeName,
            strokeWidth: gridConfig.arrangeShapeStrokeWidth,
            fill: gridConfig.arrangeSpaceColor,
            stroke: gridConfig.arrangeSpaceColor,
            originX: 'center',
            originY: 'center',
            hasRotatingPoint: false,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            strokeUniform: true,
          });
          gridContext.state.editorGrid.add(gridContext.state.arrangeShape);
        } else if (shapeType === ArrangeMode.AddSquare) {
          gridContext.state.arrangeShape = new fabric.Rect({
            name: gridConfig.tempArrangeShapeName,
            strokeWidth: gridConfig.arrangeShapeStrokeWidth,
            fill: 'rgba(0,0,0,0)',
            stroke: gridConfig.arrangeSpaceColor,
            originX: 'center',
            originY: 'center',
            top: points[1],
            left: points[0],
            hasRotatingPoint: false,
            hasControls: true,
            lockScalingX: false,
            lockScalingY: false,
            lockRotation: true,
            lockMovementX: false,
            lockMovementY: false,
            selectable: true,
            strokeUniform: true,
          });
          gridContext.state.editorGrid.add(gridContext.state.arrangeShape);
        }
        gridContext.state.editorGrid.renderAll();
      });
    },
    setArrangeShapeSizeControlMouseMovingHandler(
      gridContext: IGridContext,
      sizeControlGroup: fabric.Rect
    ): void {
      sizeControlGroup.on('moving', () => {
        gridContext.state.arrangeShapes.forEach((potentialArrangeShape) => {
          if (
            potentialArrangeShape.shapeControl.selectable &&
            sizeControlGroup === potentialArrangeShape.sizeControl
          ) {
            const shape = potentialArrangeShape.shape as fabric.Ellipse;
            const shapeControl = potentialArrangeShape.shapeControl as fabric.Group;
            const newSizeControlTopCoord = sizeControlGroup.get('top');
            const newSizeControlLeftCoord = sizeControlGroup.get('left');
            const height = sizeControlGroup.get('height');
            const width = sizeControlGroup.get('width');
            const rx = shape.get('rx');
            if (
              newSizeControlTopCoord !== null &&
              newSizeControlTopCoord !== undefined &&
              newSizeControlLeftCoord !== null &&
              newSizeControlLeftCoord !== undefined &&
              height !== null &&
              height !== undefined &&
              width !== null &&
              width !== undefined &&
              rx !== undefined &&
              rx !== null
            ) {
              sizeControlGroup.set('top', newSizeControlTopCoord);
              sizeControlGroup.set('left', newSizeControlLeftCoord);
              shape.set('top', newSizeControlTopCoord);
              shape.set('left', newSizeControlLeftCoord);
              shapeControl.set('top', newSizeControlTopCoord);
              shapeControl.set(
                'left',
                newSizeControlLeftCoord - rx - gridConfig.arrangeSpaceCircleSize
              );
              sizeControlGroup.setCoords();
              shape.setCoords();
              shapeControl.setCoords();
            }
          }
        });
      });
    },
    setArrangeShapeSizeControlScaledHandler(
      gridContext: IGridContext,
      sizeControlGroup: fabric.Rect
    ): void {
      sizeControlGroup.on('scaled', () => {
        gridContext.state.arrangeShapes.forEach((potentialArrangeShape) => {
          if (sizeControlGroup === potentialArrangeShape.sizeControl) {
            const shape = potentialArrangeShape.shape as fabric.Ellipse;
            const shapeControl = potentialArrangeShape.shapeControl as fabric.Group;
            const newSizeControlTopCoord = sizeControlGroup.get('top');
            const newSizeControlLeftCoord = sizeControlGroup.get('left');
            const newHeight = sizeControlGroup.get('height');
            const newWidth = sizeControlGroup.get('width');
            const newHeightScale = sizeControlGroup.get('scaleY');
            const newWidthScale = sizeControlGroup.get('scaleX');
            const rx = shape.get('rx');
            const ry = shape.get('ry');
            if (
              newSizeControlTopCoord !== null &&
              newSizeControlTopCoord !== undefined &&
              newSizeControlLeftCoord !== null &&
              newSizeControlLeftCoord !== undefined &&
              newHeightScale !== null &&
              newHeightScale !== undefined &&
              newWidthScale !== null &&
              newWidthScale !== undefined &&
              rx !== null &&
              rx !== undefined &&
              ry !== null &&
              ry !== undefined &&
              newHeight !== null &&
              newHeight !== undefined &&
              newWidth !== null &&
              newWidth !== undefined
            ) {
              // Snap to half box
              const topCoord =
                Math.round(
                  newSizeControlTopCoord /
                    (gridContext.state.editorGridBoxSize / 2)
                ) *
                (gridContext.state.editorGridBoxSize / 2);
              const leftCoord =
                Math.round(
                  newSizeControlLeftCoord /
                    (gridContext.state.editorGridBoxSize / 2)
                ) *
                (gridContext.state.editorGridBoxSize / 2);
              shape.set('top', topCoord);
              shape.set('left', leftCoord);
              sizeControlGroup.set('top', topCoord);
              sizeControlGroup.set('left', leftCoord);
              shapeControl.set('top', topCoord);
              shapeControl.set(
                'left',
                leftCoord -
                  (newWidth * newWidthScale) / 2 -
                  gridConfig.arrangeSpaceCircleSize
              );
              shape.set('rx', (newWidth * newWidthScale) / 2);
              shape.set('ry', (newHeight * newHeightScale) / 2);
              sizeControlGroup.setCoords();
              shape.setCoords();
              shapeControl.setCoords();
            }
          }
        });
      });
    },
    setArrangeShapeScaledHandler(
      gridContext: IGridContext,
      arrangeShape: fabric.Rect
    ): void {
      arrangeShape.on('scaled', () => {
        gridContext.state.arrangeShapes.forEach((potentialArrangeShape) => {
          if (arrangeShape === potentialArrangeShape.shape) {
            const shape = potentialArrangeShape.shape as fabric.Rect;
            const shapeControl = potentialArrangeShape.shapeControl as fabric.Group;
            const newTopCoord = shape.get('top');
            const newLeftCoord = shape.get('left');
            const newHeight = shape.get('height');
            const newWidth = shape.get('width');
            const newHeightScale = shape.get('scaleY');
            const newWidthScale = shape.get('scaleX');
            if (
              newTopCoord !== null &&
              newTopCoord !== undefined &&
              newLeftCoord !== null &&
              newLeftCoord !== undefined &&
              newHeightScale !== null &&
              newHeightScale !== undefined &&
              newWidthScale !== null &&
              newWidthScale !== undefined &&
              newHeight !== null &&
              newHeight !== undefined &&
              newWidth !== null &&
              newWidth !== undefined
            ) {
              let adjustedWidthScale = newWidthScale;
              let adjustedHeightScale = newHeightScale;
              let adjustedTopCoord = newTopCoord;
              let adjustedLeftCoord = newLeftCoord;
              // Snap width and height to multiple of gridbox size
              if (
                (newHeight * newHeightScale) %
                  gridContext.state.editorGridBoxSize !==
                0
              ) {
                const targetGridBoxLength = Math.round(
                  (newHeight * newHeightScale) /
                    gridContext.state.editorGridBoxSize
                );
                adjustedHeightScale =
                  (targetGridBoxLength * gridContext.state.editorGridBoxSize) /
                  newHeight;
              }
              if (
                (newWidth * newWidthScale) %
                  gridContext.state.editorGridBoxSize !==
                0
              ) {
                const targetGridBoxLength = Math.round(
                  (newWidth * newWidthScale) /
                    gridContext.state.editorGridBoxSize
                );
                adjustedWidthScale =
                  (targetGridBoxLength * gridContext.state.editorGridBoxSize) /
                  newWidth;
              }
              // Snap top and left to multiple of gridbox size / 2
              adjustedLeftCoord =
                Math.round(
                  newLeftCoord / (gridContext.state.editorGridBoxSize / 2)
                ) *
                (gridContext.state.editorGridBoxSize / 2);
              adjustedTopCoord =
                Math.round(
                  newTopCoord / (gridContext.state.editorGridBoxSize / 2)
                ) *
                (gridContext.state.editorGridBoxSize / 2);
              shape.set('top', adjustedTopCoord);
              shape.set('left', adjustedLeftCoord);
              shape.set('scaleY', adjustedHeightScale);
              shape.set('scaleX', adjustedWidthScale);
              shapeControl.set('top', adjustedTopCoord);
              shapeControl.set(
                'left',
                adjustedLeftCoord -
                  (newWidth * adjustedWidthScale) / 2 -
                  gridConfig.arrangeSpaceCircleSize
              );
              shape.setCoords();
              shapeControl.setCoords();
            }
          }
        });
      });
    },
    setArrangeShapeMovingHandler(
      gridContext: IGridContext,
      arrangeShape: fabric.Rect
    ): void {
      arrangeShape.on('moving', () => {
        gridContext.state.arrangeShapes.forEach((potentialArrangeShape) => {
          if (arrangeShape === potentialArrangeShape.shape) {
            const shape = potentialArrangeShape.shape as fabric.Rect;
            const shapeControl = potentialArrangeShape.shapeControl as fabric.Group;
            const newTopCoord = shape.get('top');
            const newLeftCoord = shape.get('left');
            const newHeightScale = shape.get('scaleY');
            const newWidthScale = shape.get('scaleX');
            const newHeight = shape.get('height');
            const newWidth = shape.get('width');
            if (
              newTopCoord !== null &&
              newTopCoord !== undefined &&
              newLeftCoord !== null &&
              newLeftCoord !== undefined &&
              newHeight !== null &&
              newHeight !== undefined &&
              newWidth !== null &&
              newWidth !== undefined &&
              newHeightScale !== null &&
              newHeightScale !== undefined &&
              newWidthScale !== null &&
              newWidthScale !== undefined
            ) {
              shape.set('top', newTopCoord);
              shape.set('left', newLeftCoord);
              shapeControl.set('top', newTopCoord);
              shapeControl.set(
                'left',
                newLeftCoord -
                  (newWidth * newWidthScale) / 2 -
                  gridConfig.arrangeSpaceCircleSize
              );
              shape.setCoords();
              shapeControl.setCoords();
            }
          }
        });
      });
    },
    setArrangeShapeModifiedHandler(
      gridContext: IGridContext,
      arrangeShape: fabric.Rect
    ): void {
      arrangeShape.on('modified', () => {
        gridContext.state.arrangeShapes.forEach((potentialArrangeShape) => {
          if (arrangeShape === potentialArrangeShape.shape) {
            // Check if modification was made out of bounds
            const height = arrangeShape.get('height');
            const width = arrangeShape.get('width');
            const top = arrangeShape.get('top');
            const left = arrangeShape.get('left');
            const heightScale = arrangeShape.get('scaleY');
            const widthScale = arrangeShape.get('scaleX');
            if (
              height !== null &&
              height !== undefined &&
              width !== null &&
              width !== undefined &&
              top !== null &&
              top !== undefined &&
              left !== null &&
              left !== undefined &&
              heightScale !== null &&
              heightScale !== undefined &&
              widthScale !== null &&
              widthScale !== undefined
            ) {
              const heightMin = top;
              const widthMin = left;
              let wasResized = false;
              if (heightMin - (height * heightScale) / 2 < 0) {
                arrangeShape.set('scaleY', heightMin / height);
                wasResized = true;
              }
              if (widthMin - (width * widthScale) / 2 < 0) {
                arrangeShape.set('scaleX', widthMin / width);
                wasResized = true;
              }
              arrangeShape.setCoords();
              // Fire the scale event if resized
              if (wasResized) {
                arrangeShape.trigger('scaled');
              }
            }
          }
        });
      });
    },
    setArrangeShapeSizeControlModifiedHandler(
      gridContext: IGridContext,
      sizeControlGroup: fabric.Rect
    ): void {
      sizeControlGroup.on('modified', () => {
        gridContext.state.arrangeShapes.forEach((potentialArrangeShape) => {
          if (
            potentialArrangeShape.shapeControl.selectable &&
            sizeControlGroup === potentialArrangeShape.sizeControl
          ) {
            // Check if modification was made out of bounds
            const height = sizeControlGroup.get('height');
            const width = sizeControlGroup.get('width');
            const top = sizeControlGroup.get('top');
            const left = sizeControlGroup.get('left');
            const heightScale = sizeControlGroup.get('scaleY');
            const widthScale = sizeControlGroup.get('scaleX');
            if (
              height !== null &&
              height !== undefined &&
              width !== null &&
              width !== undefined &&
              top !== null &&
              top !== undefined &&
              left !== null &&
              left !== undefined &&
              heightScale !== null &&
              heightScale !== undefined &&
              widthScale !== null &&
              widthScale !== undefined
            ) {
              const heightMin = top;
              const widthMin = left;
              let wasResized = false;
              if (heightMin - (height * heightScale) / 2 < 0) {
                sizeControlGroup.set('scaleY', heightMin / height);
                wasResized = true;
              }
              if (widthMin - (width * widthScale) / 2 < 0) {
                sizeControlGroup.set('scaleX', widthMin / width);
                wasResized = true;
              }
              sizeControlGroup.setCoords();
              // Fire the scale event if resized
              if (wasResized) {
                sizeControlGroup.trigger('scaled');
              }
            }
          }
        });
      });
    },
    getSelectedBurnerKeys(gridContext: IGridContext): string[] {
      const selectedBurnerKeys: string[] = [];
      const gridObjects = gridContext.state.editorGrid.getActiveObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          selectedBurnerKeys.push(gridObject.data?.objectKey);
        }
      }
      return selectedBurnerKeys;
    },
    removeLabelShadows(gridContext: IGridContext): void {
      const appElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('app');
      const labelElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('entity-select');
      if (
        appElement !== null &&
        appElement !== undefined &&
        labelElement !== null &&
        labelElement !== undefined &&
        !gridContext.state.isLabelAdditionMode
      ) {
        appElement.removeChild(labelElement);
      }
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.labelShadowName) {
          gridContext.state.editorGrid.remove(gridObject);
        }
      }
      gridContext.state.editorGrid.renderAll();
    },
    removeEntityMenu(gridContext: IGridContext): void {
      const appElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('app');
      const burnerElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('entity-select');
      if (
        appElement !== null &&
        appElement !== undefined &&
        burnerElement !== null &&
        burnerElement !== undefined
      ) {
        appElement.removeChild(burnerElement);
      }
      gridContext.state.editorGrid.renderAll();
    },
    updateGridContentsfromJSON(
      gridContext: IGridContext,
      gridContents: IGridContents
    ): void {
      // Disable event listener for adding objects
      gridContext.state.editorGrid.off('object:added');
      gridContext.commit('updateLayoutAssetVariablesChanged', []);
      const assetVariablesChanged: string[] = [];
      const initialBurnersCount = gridContents.contents.objects.length;
      // Load grid from JSON
      if (gridContents?.contents && gridContents.contents.objects?.length > 0) {
        gridContents.contents.objects = gridContents.contents.objects.filter((item) => {
          if (item.type === 'group' && item.name === 'burnerGroup') {
            return gridContext.rootGetters['burnerFolder/allBurners'].some((burner: IBurner) => burner.key === item.data.objectKey);
          } else {
            return true;
          }
        });
        if (initialBurnersCount !== gridContents.contents.objects.length) {
          gridContext.commit('updateGridLayoutChanged', true);
        } else {
          gridContext.commit('updateGridLayoutChanged', false);
        }
        gridContext.state.editorGrid.loadFromJSON(
          gridContents.contents,
          gridContext.state.editorGrid.renderAll.bind(
            gridContext.state.editorGrid
          )
        );
      } else {
        gridContext.commit('updateGridLayoutChanged', false);
        const emptyGrid: ICanvasContents = {
          version: '3.6.3',
          objects: [],
        };
        gridContext.state.editorGrid.loadFromJSON(
          emptyGrid,
          gridContext.state.editorGrid.renderAll.bind(
            gridContext.state.editorGrid
          )
        );
      }

      // Remove unsupported shapes
      // NOTE: After all the diagram have been revisioned and all the spacelines
      // have been removed, we can discard this code
      const allObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of allObjects) {
        if (
          gridObject.name === 'spaceLine' ||
          gridObject.name === 'spaceLineControls'
        ) {
          gridContext.state.editorGrid.remove(gridObject);
        }
        if (gridObject.name === gridConfig.variableGroupName) {
          gridObject.visible = !GridStore.state.isLongTerm ? false : true;
        }
      }
      gridContext.state.previousEditorGridBoxSize =
        gridContents?.gridBoxSize ?? gridContext.state.editorGridBoxSize;
      // Match arrange shapes with arrange shape controls.  Set label listeners. Mark burners as already on grid
      // TODO - optimize this.  Too many loops.  There were not this many initially, but we added some more logic
      // in to account for new shapes. DBB 9/15/2020
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (let value of gridObjects) {
        if (value.name === gridConfig.arrangeShapeName) {
          if (value.type === 'line') {
            for (let lineValues of gridObjects) {
              if (
                isEqual(value.data, lineValues.data) &&
                lineValues.type === 'group'
              ) {
                gridContext.state.arrangeShapes.push({
                  shape: value as fabric.Line,
                  shapeControl: lineValues as fabric.Group,
                });
                gridContext.dispatch(
                  'setArrangeShapeControlMouseDownHandler',
                  lineValues
                );
                break;
              }
            }
          } else if (value.type === 'rect') {
            for (let rectValues of gridObjects) {
              if (
                isEqual(value.data, rectValues.data) &&
                rectValues.type === 'group'
              ) {
                gridContext.state.arrangeShapes.push({
                  shape: value as fabric.Rect,
                  shapeControl: rectValues as fabric.Group,
                });
                gridContext.dispatch(
                  'setArrangeShapeScaledHandler',
                  value
                );
                gridContext.dispatch(
                  'setArrangeShapeMovingHandler',
                  value
                );
                gridContext.dispatch(
                  'setArrangeShapeModifiedHandler',
                  value
                );
                gridContext.dispatch(
                  'setArrangeShapeControlMouseDownHandler',
                  rectValues
                );
                break;
              }
            }
          } else if (value.type === 'ellipse') {
            for (let lineValues of gridObjects) {
              if (
                isEqual(value.data, lineValues.data) &&
                lineValues.type === 'group'
              ) {
                for (let ellipseValues of gridObjects) {
                  if (
                    isEqual(value.data, ellipseValues.data) &&
                    ellipseValues.type === 'rect'
                  ) {
                    gridContext.state.arrangeShapes.push({
                      shape: value as fabric.Rect,
                      shapeControl: lineValues as fabric.Group,
                      sizeControl: ellipseValues as fabric.Rect,
                    });
                    gridContext.dispatch(
                      'setArrangeShapeSizeControlMouseMovingHandler',
                      ellipseValues
                    );
                    gridContext.dispatch(
                      'setArrangeShapeSizeControlScaledHandler',
                      ellipseValues
                    );
                    gridContext.dispatch(
                      'setArrangeShapeSizeControlModifiedHandler',
                      ellipseValues
                    );
                    gridContext.dispatch(
                      'setArrangeShapeControlMouseDownHandler',
                      lineValues
                    );
                    break;
                  }
                }
              }
            }
          }
        } else if (value.name === gridConfig.labelName) {
          gridContext.dispatch('setLabelGroupMouseUpHandler', value);
        } else if (value.name === gridConfig.burnerGroupName) {
          gridContext.commit(
            'burnerFolder/lockBurnerByKey',
            value.data.objectKey,
            { root: true }
          );
          gridContext.dispatch(
            'setGridToolTipMouseOverHandler',
            value
          );
          gridContext.dispatch('setGridToolTipMouseOutHandler', value);
          gridContext.dispatch('setBurnerMouseUpHandler', value);
        } else if (value.name === gridConfig.variableGroupName) {
          const variableExists: boolean = gridContext.state.allVariables.findIndex((item) => item.variableKey === value.data.objectKey) > -1;
          if (!variableExists) {
            if (value.data.objectName) {
              assetVariablesChanged.push(value.data.objectName);
            } else {
              // Fallback mechanism for the variable which doesn't have the full name stored as part of diagram.
              let variableObject = value as any;
              const variableName: string = variableObject._objects[1].text;
              assetVariablesChanged.push(variableName);
            }
            gridContext.state.editorGrid.remove(value);
            value.lockRotation = false;
            value.centeredRotation = false;
          }
          gridContext.dispatch(
            'setGridToolTipMouseOverHandler',
            value
          );
          gridContext.dispatch('setGridToolTipMouseOutHandler', value);
          gridContext.dispatch('setBurnerMouseUpHandler', value);
          gridContext.commit(
            'variableFolder/lockVariableByKey',
            value.data.objectKey,
            { root: true }
          );
        }
      }
      gridContext.commit('updateLayoutAssetVariablesChanged', assetVariablesChanged);
      // Restore and style grid
      gridContext.dispatch('setGridObjectAddedHandler');
      gridContext.dispatch('drawGridLines', false);
      gridContext.dispatch('setArrangeShapePadding');
      gridContext.dispatch('unhighlightAllBurners');
      gridContext.dispatch('resetZoom');
      gridContext.dispatch('hideUnknownBurners');
    },
    unselectAllArrangeRects(gridContext: IGridContext): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (
          gridObject.name === gridConfig.arrangeShapeName &&
          gridObject.type === 'rect'
        ) {
          gridObject.set('selectable', false);
        }
      }
      gridContext.state.editorGrid.renderAll();
    },
    setGridToolTipMouseOutHandler(
      gridContext: IGridContext,
      burnerGroup: fabric.Group
    ): void {
      burnerGroup.on('mouseout', () => {
        unHighlightGridObject(burnerGroup);
        gridContext.state.editorGrid.renderAll();
        const toolTipSpan:
          | HTMLSpanElement
          | null
          | undefined = document
          ?.querySelector('#asset-diagram-builder')
          ?.shadowRoot?.getElementById('grid-tooltip');
        if (toolTipSpan) {
          toolTipSpan.classList.remove('show');
        }
      });
    },
    setGridToolTipMouseOverHandler(
      gridContext: IGridContext,
      burnerGroup: fabric.Group
    ): void {
      burnerGroup.on('mouseover', (event: fabric.IEvent) => {
        const target: fabric.Group = event.target as fabric.Group;
        const toolTipSpan:
          | HTMLSpanElement
          | null
          | undefined = document
          ?.querySelector('#asset-diagram-builder')
          ?.shadowRoot?.getElementById('grid-tooltip');
        if (target && target.name === gridConfig.burnerGroupName) {
          const burnerKey: string = event.target?.data.objectKey;
          const hoveredBurner: IBurner = Burner.getBurnerByKey(burnerKey);
          highlightGridObject(target as fabric.Group);
          gridContext.state.editorGrid.renderAll();
          if (toolTipSpan) {
            if (hoveredBurner?.name) {
              toolTipSpan.innerHTML = hoveredBurner.name;
              const gridBoxWidth = gridContext.state.editorGridBoxSize;
              toolTipSpan.style.fontSize = gridBoxWidth / 2 - 3 + 'px';
              const toolTipPosition:
                | IToolTipPosition
                | undefined = calculateToolTipPosition(
                event.target as fabric.Group,
                gridContext.state.editorGridBoxSize,
                toolTipSpan
              );
              if (toolTipPosition) {
                toolTipSpan.style.left = toolTipPosition.left + 'px';
                toolTipSpan.style.top = toolTipPosition.top - 5 + 'px';
                toolTipSpan.classList.add('show');
              }
            }
          }
        } else if (target && target.name === gridConfig.variableGroupName) {
          const variableKey: string = event.target?.data.objectKey;
          const hoveredVariable: IAssetReportVariableViewModel = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKey.name}`](variableKey);
          if (hoveredVariable !== undefined) {
            highlightGridObject(target as fabric.Group);
            gridContext.state.editorGrid.renderAll();
            if (toolTipSpan) {
              if (hoveredVariable.displayValues) {
                toolTipSpan.innerHTML = hoveredVariable.displayValues[0];
                const gridBoxWidth = gridContext.state.editorGridBoxSize;
                toolTipSpan.style.fontSize = gridBoxWidth / 2 - 3 + 'px';
                const toolTipPosition:
                  | IToolTipPosition
                  | undefined = calculateToolTipPosition(
                  event.target as fabric.Group,
                  gridContext.state.editorGridBoxSize,
                  toolTipSpan
                );
                if (toolTipPosition) {
                  toolTipSpan.style.left = toolTipPosition.left + 'px';
                  toolTipSpan.style.top = toolTipPosition.top - 5 + 'px';
                  toolTipSpan.classList.add('show');
                }
              }
            }
          }
        }
      });
    },
    setBurnerMouseUpHandler(
      gridContext: IGridContext,
      burnerGroup: fabric.Group
    ): void {

      burnerGroup.on('mouseup', (event: fabric.IEvent) => {
        // Exit if diagram is locked
        if (gridContext.rootGetters['diagram/isDiagramLocked']) {
          return;
        }
        // Exit if diagram is in arrange shape addition mode
        if (gridContext.state.isArrangeShapeMode) {
          return;
        }
        gridContext.dispatch('hideMenus');
        const menuElement = document.createElement('div');
        menuElement.id = 'menu-element';
        const appElement = document
          ?.querySelector('#asset-diagram-builder')
          ?.shadowRoot?.getElementById('app');
        if (appElement) {
          appElement.appendChild(menuElement);
        }
        const MenuConstructor = Vue.extend(EntitySelect);
        new MenuConstructor({}).$mount(menuElement);
        const burnerElement = document
          ?.querySelector('#asset-diagram-builder')
          ?.shadowRoot?.getElementById('entity-select');
        const scrolledLeft = document
          ?.querySelector('#asset-diagram-builder')
          ?.shadowRoot?.getElementById('grid-container')?.scrollLeft;
        const scrolledTop = document
          ?.querySelector('#asset-diagram-builder')
          ?.shadowRoot?.getElementById('grid-container')?.scrollTop;
        if (burnerGroup.name === gridConfig.burnerGroupName) {
          gridContext.state.isVariableSettingsEnabled = false;
          gridContext.state.isBurnerSettingsEnabled = true;
        } else if (burnerGroup.name === gridConfig.variableGroupName) {
          gridContext.state.isBurnerSettingsEnabled = false;
          gridContext.state.isVariableSettingsEnabled = true;
        }
        if (
          burnerGroup.top !== undefined &&
          burnerGroup.left !== undefined &&
          burnerElement !== null &&
          burnerElement !== undefined &&
          scrolledLeft !== undefined &&
          scrolledTop !== undefined &&
          appElement !== null &&
          appElement !== undefined
        ) {
          const boundingRectMenu = burnerElement.getBoundingClientRect();
          const boundingRectApp = appElement.getBoundingClientRect();
          const maxTop = boundingRectApp.height;
          const maxLeft = boundingRectApp.width;
          const menuTop = boundingRectMenu.height;
          const menuLeft = boundingRectMenu.width;
          const mouseEvent = event.e as IMouseEvent;
          const offsetTop = mouseEvent.clientY - mouseEvent.offsetY;
          const offsetLeft = mouseEvent.clientX - mouseEvent.offsetX;
          let calculatedTop = burnerGroup.top + offsetTop - scrolledTop;
          let calculatedLeft = burnerGroup.left + offsetLeft - scrolledLeft;
          if (calculatedLeft + menuLeft > maxLeft) {
            calculatedLeft -= menuLeft;
          }
          if (calculatedTop + menuTop > maxTop) {
            calculatedTop -= menuTop;
          }
          burnerElement.style.position = 'absolute';
          burnerElement.style.top = calculatedTop + 'px';
          burnerElement.style.left = calculatedLeft + 'px';
        }
      });
    },
    displayGroupSelectMenu(gridContext: IGridContext, objectCoords: IObjectCoords): void {
      // Exit if diagram is locked
      if (gridContext.rootGetters['diagram/isDiagramLocked']) {
        return;
      }
      // Exit if diagram is in arrange shape addition mode
      if (gridContext.state.isArrangeShapeMode) {
        return;
      }
      // Remove current menu
      gridContext.dispatch('hideMenus');
      const menuElement = document.createElement('div');
      menuElement.id = 'menu-element';
      const appElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('app');
      if (appElement) {
        appElement.appendChild(menuElement);
      }
      const MenuConstructor = Vue.extend(EntitySelect);
      new MenuConstructor({}).$mount(menuElement);
      const activeElement = document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('entity-select');
      const scrolledLeft = document
        ?.querySelector('#asset-diagram-builder')
        ?.shadowRoot?.getElementById('grid-container')?.scrollLeft;
      const scrolledTop = document
        ?.querySelector('#asset-diagram-builder')
        ?.shadowRoot?.getElementById('grid-container')?.scrollTop;
      if (
        objectCoords.top !== undefined &&
        objectCoords.left !== undefined &&
        activeElement !== null &&
        activeElement !== undefined &&
        objectCoords.mouseEvent !== undefined &&
        scrolledLeft !== undefined &&
        scrolledTop !== undefined &&
        appElement !== null &&
        appElement !== undefined
      ) {
        const boundingRectMenu = activeElement.getBoundingClientRect();
        const boundingRectApp = appElement.getBoundingClientRect();
        const maxTop = boundingRectApp.height;
        const maxLeft = boundingRectApp.width;
        const menuTop = boundingRectMenu.height;
        const menuLeft = boundingRectMenu.width;
        const offsetTop =
          objectCoords.mouseEvent.clientY - objectCoords.mouseEvent.offsetY;
        const offsetLeft =
          objectCoords.mouseEvent.clientX - objectCoords.mouseEvent.offsetX;
        let calculatedTop = objectCoords.top + offsetTop - scrolledTop;
        let calculatedLeft = objectCoords.left + offsetLeft - scrolledLeft;
        if (calculatedLeft + menuLeft > maxLeft) {
          calculatedLeft -= menuLeft;
        }
        if (calculatedTop + menuTop > maxTop) {
          calculatedTop -= menuTop;
        }
        activeElement.style.position = 'absolute';
        activeElement.style.top = calculatedTop + 'px';
        activeElement.style.left = calculatedLeft + 'px';
      }
    },
    unhighlightAllBurners(gridContext: IGridContext): void {
      const burnerObjects = gridContext.state.editorGrid.getObjects(
        'group'
      ) as fabric.Group[];
      for (const gridObject of burnerObjects) {
        if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          unHighlightGridObject(gridObject);
        }
      }
    },
    setActiveObjectMouseUpHandler(gridContext: IGridContext, activeSelection: fabric.ActiveSelection): void {
      activeSelection.on('mouseup', (event: fabric.IEvent) => {
        // Move burner menu
        gridContext.dispatch('hideMenus');
        gridContext.dispatch('displayGroupSelectMenu', {
          top: activeSelection.top,
          left: activeSelection.left,
          mouseEvent: event.e,
        });
        // Hide tooltip if present
        const toolTipSpan: HTMLSpanElement | null | undefined = document ?.querySelector('#asset-diagram-builder') ?.shadowRoot?.getElementById('grid-tooltip');
        if (toolTipSpan) {
          toolTipSpan.classList.remove('show');
          toolTipSpan.style.left = 0 + 'px';
          toolTipSpan.style.top = 0 + 'px';
        }
      });
    },
    setActiveObjectMouseDownHandler(
      gridContext: IGridContext,
      activeSelection: fabric.ActiveSelection
    ): void {
      activeSelection.on('mousedown', () => {
        // Hide burner menu
        gridContext.dispatch('hideMenus');
        // Hide tooltip if present
        const toolTipSpan:
          | HTMLSpanElement
          | null
          | undefined = document
          ?.querySelector('#asset-diagram-builder')
          ?.shadowRoot?.getElementById('grid-tooltip');
        if (toolTipSpan) {
          toolTipSpan.classList.remove('show');
          toolTipSpan.style.left = 0 + 'px';
          toolTipSpan.style.top = 0 + 'px';
        }
      });
    },
    deleteSelectedBurners(gridContext: IGridContext): void {
      // Get selected burners
      const selectedBurnerKeys: string[] = [];
      const activeObjects = gridContext.state.editorGrid.getActiveObjects();
      for (const activeObject of activeObjects) {
        if (activeObject.name === gridConfig.burnerGroupName) {
          selectedBurnerKeys.push(activeObject.data?.objectKey);
        }
      }
      // Remove selected burners
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          if (selectedBurnerKeys.indexOf(gridObject.data?.objectKey) > -1) {
            gridContext.state.editorGrid.remove(gridObject);
            gridContext.commit(
              'burnerFolder/unlockBurnerByKey',
              gridObject.data?.objectKey,
              { root: true }
            );
          }
        }
      }
      // Hide tooltip if present
      const toolTipSpan:
        | HTMLSpanElement
        | null
        | undefined = document
        ?.querySelector('#asset-diagram-builder')
        ?.shadowRoot?.getElementById('grid-tooltip');
      if (toolTipSpan) {
        toolTipSpan.classList.remove('show');
        toolTipSpan.style.left = 0 + 'px';
        toolTipSpan.style.top = 0 + 'px';
      }
    },
    deleteSelectedVariables(gridContext: IGridContext): string[] {
      // Get selected burners
      const selectedVariableKeys: string[] = [];
      const activeObjects = gridContext.state.editorGrid.getActiveObjects();
      for (const activeObject of activeObjects) {
        if (activeObject.name === gridConfig.variableGroupName) {
          selectedVariableKeys.push(activeObject.data?.objectKey);
        }
      }
      // Remove selected variables
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.variableGroupName) {
          if (selectedVariableKeys.indexOf(gridObject.data?.objectKey) > -1) {
            gridContext.state.editorGrid.remove(gridObject);
            gridContext.commit(
              'variableFolder/unlockVariableByKey',
              gridObject.data?.objectKey,
              { root: true }
            );
          }
        }
      }
      store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearSelectedVariables.name}`);
      // Hide tooltip if present
      const toolTipSpan:
        | HTMLSpanElement
        | null
        | undefined = document
        ?.querySelector('#asset-diagram-builder')
        ?.shadowRoot?.getElementById('grid-tooltip');
      if (toolTipSpan) {
        toolTipSpan.classList.remove('show');
        toolTipSpan.style.left = 0 + 'px';
        toolTipSpan.style.top = 0 + 'px';
      }
      return selectedVariableKeys;
    },
    getSelectedBurnerDetails(gridContext: IGridContext): void {
      let selectedBurnerKeys: string[] = [];
      gridContext.state.selectedBurnerDetails = [];
      gridContext.dispatch('getSelectedBurnerKeys').then((response) => {
        selectedBurnerKeys = response;
        const selectedBurners: IBurner[] = Burner.getBurnersByKeys(
          selectedBurnerKeys
        );
        selectedBurners.forEach((burner) => {
          const burnerDetail: IBurnerDetail = {
            burnerName: burner.name,
            burnerPath: '',
            key: burner.key,
          };
          const burnersFolderKey = burner.emberHierarchyLevelKey;
          let path = '';
          if (burnersFolderKey) {
            const burnerPath: string[] = BurnerFolder.getBurnerFolderByEmberHierarchyLevelKey(
              burnersFolderKey
            );
            burnerPath.reverse();
            burnerPath.forEach((level) => {
              path += level + ' > ';
            });
            path = path + burner.name;
          }
          burnerDetail.burnerPath = path;

          // Pushing selected burner details into an array and state
          gridContext.state.selectedBurnerDetails.push(burnerDetail);
        });
        gridContext.state.selectedBurnerDetails.sort((a, b) =>
          a.burnerName < b.burnerName ? -1 : 1
        );
      });
    },
    hideMenus(gridContext: IGridContext): void {
      gridContext.dispatch('removeEntityMenu');
      gridContext.dispatch('removeLabelShadows');
    },
    hideUnknownBurners(gridContext: IGridContext): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          const burnerKey = gridObject.data.objectKey;
          if (
            !store.getters[
              `${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.getBurnerByKey.name}`
            ](burnerKey)
          ) {
            gridObject.visible = false;
          } else {
            gridObject.visible = true;
          }
        }
      }
      gridContext.state.editorGrid.renderAll();
    },
    bringControlsToFront(gridContext: IGridContext): void {
      const gridObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (
          gridObject.name === gridConfig.tempArrangeShapeControlsName ||
          gridObject.name === gridConfig.arrangeShapeControlsName
        ) {
          gridObject.bringToFront();
        }
      }
    },
  } as IGridStoreActions,
};
