import { IViewerBurner, IAirRegister, IFuelLine } from '../burnerReading/burner-reading';

export class ViewerBurner implements IViewerBurner {
  public key: string;
  public name: string | null;
  public heatRelease: number;
  public equivalenceRatio: number;
  public outOfControllableRange: boolean;
  public opportunityScore: number;
  public dryO2: string;
  public wetO2: string;
  public airRegisters: IAirRegister[] | null;
  public fuelLines: IFuelLine[] | null;

  constructor(burner: IViewerBurner) {
    this.key = burner.key;
    this.name = burner.name;
    this.heatRelease = burner.heatRelease;
    this.equivalenceRatio = burner.equivalenceRatio;
    this.outOfControllableRange = burner.outOfControllableRange;
    this.opportunityScore = burner.opportunityScore;
    this.dryO2 = burner.dryO2;
    this.wetO2 = burner.wetO2;
    this.airRegisters = burner.airRegisters;
    this.fuelLines = burner.fuelLines;
  }

  public getBurnerReadingValue(burnerProperty: keyof IViewerBurner,
                               airRegisterProperty?: keyof IAirRegister): any {
      if (burnerProperty === 'airRegisters' && airRegisterProperty) {
        if (this.airRegisters && this.airRegisters.length > 0) {
          let primaryIndex = this.airRegisters.findIndex((item) => item.isPrimary);
          if (!primaryIndex || primaryIndex < 0) { primaryIndex = 0;}
          if (this.airRegisters[primaryIndex][airRegisterProperty] !== null ||
            this.airRegisters[primaryIndex][airRegisterProperty] !== undefined) {
            return this.airRegisters[primaryIndex][airRegisterProperty];
          }
        }
      } else if (burnerProperty === 'airRegisters' &&
                (this.airRegisters?.length === 0 || !this.airRegisters)) {
        return '';
      } else {
        if (this[burnerProperty] !== null ||
          this[burnerProperty] === undefined) {
          return this[burnerProperty];
        }
      }
      return '';
  }

  public isChangeRecommended(): boolean {
    return this.opportunityScore > 0;
  }

  public isBurnerOff(): boolean {
    if (!this.fuelLines) {
      return true;
    }
    return this.fuelLines.every((fuelLine: IFuelLine) => !fuelLine.isFiring);
  }
}
