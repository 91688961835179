import { getAuthToken } from '@/services/viewer/auth/auth';
import { AppViewerStore } from '@/store/viewer/app/appStore';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';
import { UserViewerStore } from '@/store/viewer/user/userStore';

const authInterceptor = async (config: any) => {
  const authorization = 'Authorization';
  const correlationId = 'OnPoint-Correlation-Id';
  const medaData = 'OnPoint-MetaData';
  const userId = UserViewerStore.state && UserViewerStore.state.currentUser ? UserViewerStore.state.currentUser.key : '';
  const timeStamp = AppViewerStore.state && AppViewerStore.state.actionTime ? AppViewerStore.state.actionTime : '';
  let metadata = {};
  if (UserViewerStore.state && UserViewerStore.state.customerAssetInfo) {
    metadata = UserViewerStore.state.customerAssetInfo;
  } else {
    metadata = {
      customerId: UserViewerStore.state && UserViewerStore.state.currentUser ? UserViewerStore.state.currentUser.activeCustomerKey : '',
      customerName: '',
      assetId: AssetViewerStore.state && AssetViewerStore.state.selectedAsset ? AssetViewerStore.state.selectedAsset.key : '',
      assetName: AssetViewerStore.state && AssetViewerStore.state.selectedAsset ? AssetViewerStore.state.selectedAsset.name : '',
    };
  }
  const token = await getAuthToken();
  // Do not add auth token when uploading direct to S3
  if (config.url.indexOf('s3.amazonaws.com') > -1) {
    return config;
  } else {
    config.headers[authorization] = `Bearer ${token}`;
    if (!config.baseURL.includes('hierarchy-builder')) {
      config.headers[correlationId] = `LE|${userId}|${timeStamp}`;
      config.headers[medaData] = btoa(JSON.stringify(metadata));
    }
    return config;
  }
};

export default authInterceptor;
