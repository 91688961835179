


















































































































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue, BDropdown, IconsPlugin } from 'bootstrap-vue';
import { HeatUnit } from '@/services/viewer/utils/heat-release-conversion';
import { PrimaryDisplayAttribute } from '@/view-models/viewer/burnerReading/burner-reading';
import store, { VuexModuleNamespaces } from '@/store';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';
import { BurnerReadingViewerStore } from '@/store/viewer/burnerReading/burnerReadingStore';
import { BurnerLayoutViewerStore } from '@/store/viewer/burnerLayout/burnerLayoutStore';
import { UserViewerStore } from '@/store/viewer/user/userStore';
import { adEventBus, adEvents } from '@/services/viewer/eventBus/asset-diagram-event-bus';
import { Permission } from '@/view-models/viewer/user/user-view-models';
import { changeParentRoute } from '@/services/viewer/utils/from-parent';
import { LogFeature } from '@/view-models/viewer/common/log-model';
import { AppViewerStore, LogPayload } from '@/store/viewer/app/appStore';
import ConfigFactory from '@/services/config/config';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

@Component({
  name: 'viewer-toolbar'
})
export default class ViewerToolbar extends Vue {

  private selectedDisplayAttribute: PrimaryDisplayAttribute = PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER;
  private selectedHeatReleaseMenu: HeatUnit = HeatUnit.MMBTU_HR;
  private selectedLegendMenu: string = 'Legend';
  private HeatUnit = HeatUnit;
  private PrimaryDisplayAttribute = PrimaryDisplayAttribute;
  private isDropdown2Visible: boolean = false;

  get hasEditAccess() {
    return this.getEditAccess();
  }

  get selectedAsset() {
    return store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.selectedAsset.name}`];
  }

  get currentUser(): any {
    return store.state.userViewer.currentUser;
  }

  get isInternalUser(): boolean {
    return this.currentUser.isSuperUser! || !this.currentUser.isCustomerUser;
  }

  get receivedAssetKey(): string {
    return store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.receivedAssetKey.name}`];
  }

  get emberRecommendationsFlag(): boolean {
    const foundAsset =
      store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.findAsset.name}`](this.receivedAssetKey);
    if (foundAsset) {
      return foundAsset.showEmberRecommendations;
    }
    return false;
  }
  get showCurrentValues(): boolean {
    return this.currentUser.isCustomerUser && !this.isInternalUser && !this.emberRecommendationsFlag;
  }
  get showRecommendations(): boolean {
    return this.isInternalUser || (this.currentUser.isCustomerUser && this.emberRecommendationsFlag);
  }
  get getSelectedDisplayAttribute() {
    if (this.selectedDisplayAttribute === PrimaryDisplayAttribute.DRY_O2 || this.selectedDisplayAttribute === PrimaryDisplayAttribute.WET_O2) {
      return this.$t('burner.o2') + ' - ' + this.$t(`viewerToolbar.ddDisplayAttributes.${this.selectedDisplayAttribute}`);
    }
    return this.selectedDisplayAttribute;
  }
  public mounted(): void {
    this.checkEmberRecommendations();
    store.commit(`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.mutations.updateHeatUnit.name}`,
                 HeatUnit.MMBTU_HR);
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === 'BSecondaryDisplayDropdown') {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'BSecondaryDisplayDropdown') {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
  }
  public checkEmberRecommendations() {
    if (this.showCurrentValues) {
      store.commit(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.mutations.setShowEmberRecommendations.name}`,true);
      this.changePrimaryDisplayAttribute(PrimaryDisplayAttribute.CURRENT_AIR_REGISTER);
    } else if (this.showRecommendations) {
      store.commit(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.mutations.setShowEmberRecommendations.name}`,false);
      this.changePrimaryDisplayAttribute(PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER);
    }
  }

  public appRoute(): string {
    return '/utilities/ember/layout-editor/editor/';
  }

  private async getEditAccess() {
    const user = UserViewerStore.state.currentUser;
    return user
      ?.permissions
      ?.includes(Permission.CreateAssetDiagram);
  }

  private async redirectToADB() {
    const logPayload: LogPayload = {
      message: LogFeature['AD-EditAssetClicked'] + store.state.assetViewer.selectedAsset?.name,
      feature: 'AD-EditAssetClicked',
      additionalData: {
        assetKey: this.receivedAssetKey
      }
    };
    store.dispatch(`${VuexModuleNamespaces.appViewer}/${AppViewerStore.actions.addApplicationStartLog.name}`, logPayload);
    changeParentRoute(`${this.appRoute()}${this.selectedAsset.key}`);
  }

  private changeHeatRelease(newHeatReleaseUnit: HeatUnit) {
    this.selectedHeatReleaseMenu = newHeatReleaseUnit;
    store.commit(`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.mutations.updateHeatUnit.name}`,
                 newHeatReleaseUnit);
    adEventBus.$emit(adEvents.changedDisplay,{ currentDisplay: this.selectedDisplayAttribute,
                                         currentHeatReleaseUnit: newHeatReleaseUnit });  }

  private async changePrimaryDisplayAttribute(newDisplayAttribute: PrimaryDisplayAttribute) {
    this.selectedDisplayAttribute = newDisplayAttribute;
    store.commit(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.mutations.updatePrimaryDisplayAttribute.name}`,
                 newDisplayAttribute);
    store.commit(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.mutations.updatePrimaryDisplayValuesFromEMA.name}`);
    adEventBus.$emit(adEvents.changedDisplay, { currentDisplay: newDisplayAttribute,
                                          currentHeatReleaseUnit: this.selectedHeatReleaseMenu});
    this.closeDropdown();
  }

  public closeDropdown() {
    const dropdown = this.$refs.primaryDisplayDropdown as BDropdown;
    const secDropdown = this.$refs.secondaryDisplayDropdown as BDropdown;
    secDropdown.hide();
    dropdown.hide();
  }

  private toggleHeatReleaseMenu() {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.heatReleaseDropdown as BDropdown;
      dropdown.show();
      dropdown.show();
      this.closeDropdown();
  }
  private toggleSecondaryDisplayMenu() {
    const dropdown = this.$refs.secondaryDisplayDropdown as BDropdown;
      dropdown.show();
      dropdown.show();
  }

  private async openEMAApplication() {
    const config = await ConfigFactory.GetConfig();
    window.open( config.get('emaUiUrl') + '/' + this.receivedAssetKey +'/diagram');
  }

  private togglePrimaryDisplayMenu() {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.primaryDisplayDropdown as BDropdown;
    dropdown.show();
    dropdown.show();
    if (this.selectedDisplayAttribute === PrimaryDisplayAttribute.DRY_O2
      ||this.selectedDisplayAttribute === PrimaryDisplayAttribute.WET_O2) {
        const secDropdown = this.$refs.secondaryDisplayDropdown as BDropdown;
        secDropdown.show();
    }
  }
}
