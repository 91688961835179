












































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import AssetDetails from './AssetDetails.vue';
import BurnerSelector from './BurnerSelector.vue';
import VariableSelector from './VariableSelector.vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { BurnerFolderStore } from '@/store/burnerFolder/burnerFolderStore';
import { GridStore } from '@/store/grid/gridStore';
import { adEventBus, adEvents } from '@/services/eventBus/asset-diagram-event-bus';
import inputsTree from '@/store/storeModules/inputs-tree';
import { TreeTraverseHelper } from '@/utils/tree/tree-traverse-helper';
import { VariableTreeNodeData } from '@/view-models/variables';

Vue.use(BootstrapVue);

@Component({
  name: 'side-menu',
  components: {
    AssetDetails,
    BurnerSelector,
    VariableSelector
  }
})
export default class SideMenu extends Vue {

  private isSideMenuOpen: boolean = false;

  private mounted() {
    window.addEventListener('resize', this.resizeSideMenus);
    this.resizeSideMenus();
    adEventBus.$on(adEvents.closeSideMenu, async () => {
      this.$root.$emit('bv::toggle::collapse', 'side-menu-pop-out');
      this.isSideMenuOpen = !this.isSideMenuOpen;
      const rootNode = inputsTree.rootAssetNode;
      const parentKey = inputsTree.nodeByKey(rootNode?.key!, this.treeHelper.allNodes);
      parentKey.childrenKeys.forEach((item) => {
        const gridVariable = inputsTree.nodeByKey(item, this.treeHelper.allNodes);
        if (gridVariable !== undefined){
          const node = inputsTree.nodeByKey(item, this.treeHelper.allNodes);
          inputsTree.closeParent(node);
        }
      });
    });
  }

  public get nodesTable() {
    return inputsTree.filteredNodesTable;
  }

  private get treeHelper(): TreeTraverseHelper<VariableTreeNodeData> {
    return new TreeTraverseHelper<VariableTreeNodeData>(this.nodesTable);
  }

  private toggleSideMenu(): void {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`);
    this.isSideMenuOpen = !this.isSideMenuOpen;
    if (this.isSideMenuOpen) {
      const selectableBurners: NodeList | null | undefined  =
          document?.querySelector('#asset-diagram-builder')?.shadowRoot?.querySelectorAll('*[id^="folder-select-"]');
      if (selectableBurners !== null &&
          selectableBurners !== undefined) {
        selectableBurners.forEach((selectableBurner: Node) => {
          selectableBurner.addEventListener('dragstart', (this.setDragStartEventHandler) as EventListener);
          selectableBurner.addEventListener('dragend', this.setDragEndEventHandler);
        });
      }
    }
  }

  private setDragStartEventHandler(event: DragEvent): void {
    const dragElement = document.createElement('div');
    const selectedCount = store.getters[`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.selectedCount.name}`];
    dragElement.id = 'drag-ghost';
    dragElement.innerHTML = selectedCount;
    dragElement.style.height =
        store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.editorGridBoxSize.name}`] + 'px';
    dragElement.style.width =
        store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.editorGridBoxSize.name}`] + 'px';
    dragElement.style.fontSize =
        (store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.editorGridBoxSize.name}`] / 2.5) + 'px';
    document?.querySelector('#asset-diagram-builder')?.shadowRoot?.appendChild(dragElement);
    document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('drag-ghost')?.classList.add('burner-drag');
    if (event.dataTransfer !== null) {
      event.dataTransfer.setDragImage(dragElement, store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.editorGridBoxSize.name}`],
                                      store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.editorGridBoxSize.name}`]);
    }
  }

  private setDragEndEventHandler(): void {
    const dragElement: HTMLElement | null | undefined =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('drag-ghost');
    if (dragElement !== null && dragElement !== undefined) {
      dragElement.remove();
    }
  }

  private resizeSideMenus(): void {
    const assetDetailsMenu: HTMLElement | null | undefined =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('asset-details');
    const burnerSelectorMenu: HTMLElement | null | undefined =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('burner-selector');
    const editorElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor');
    const toolbarElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor-toolbar-container');
    if ( assetDetailsMenu !== null &&
         assetDetailsMenu !== undefined &&
         burnerSelectorMenu !== null &&
         burnerSelectorMenu !== undefined) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      assetDetailsMenu.style.height = (gridHeight - 50) + 'px';
      burnerSelectorMenu.style.height = (gridHeight - 50) + 'px';
    }
  }

}
