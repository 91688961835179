











import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import AssetViewer from './components/viewer/assetViewer/AssetViewer.vue';
import Icon from './components/viewer/common/Icon.vue';
import Sprite from './components/viewer/common/Sprite.vue';
import Error from './components/viewer/modals/Error.vue';
import { authGuard } from './services/auth/auth';
import { adEventBus } from './services/eventBus/asset-diagram-event-bus';
import store, { VuexModuleNamespaces } from '@/store';
import { AppStore } from '@/store/app/appStore';
import Loading from './components/common/Loading.vue';
import i18n from '@/i18n';

import moment from 'moment';
import { appRouteBase } from './utils/from-parent';
import { BurnerReadingViewerStore } from './store/viewer/burnerReading/burnerReadingStore';
import { UserViewerStore } from './store/viewer/user/userStore';
import { AppViewerStore, LogPayload } from './store/viewer/app/appStore';
import { LogFeature } from './view-models/viewer/common/log-model';
import { AssetVariableStore } from './store/viewer/assetVariable/assetVariableStore';

Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.filter('formatDateLongWithoutTime', (value: string) => {
  return value
    ? moment(Date.parse(value)).format('MMMM D, YYYY')
    : '-';
});

Vue.filter('formatDateLong', (value: number) => {
  return value
    ? moment(String(value)).format('MMMM D, YYYY [at] h:mm a')
    : '-';
});

Vue.filter('formatDateShort', (value: number) => {
  return value
    ? moment(String(value)).format('MM/DD/YY h:mm a')
    : '-';
});

Vue.filter('formatNumberForDisplay', (value: number) => {
  if (value === -999999 || value === undefined || value === null) {
    return '-';
  } else {
    return value.toFixed(2);
  }
});

Vue.filter('formatFirstLetterCapitalized', (value: string) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
  } else {
    return value;
  }
});

const routes = [
  { path: '*', component: AssetViewer, name: 'AssetViewer' },
];

export const assetDiagramRouter = new VueRouter({
  mode: 'history',
  base: appRouteBase(),
  routes
});

assetDiagramRouter.beforeEach(authGuard);
Vue.component('Icon', Icon);

@Component({
  name: 'app',
  components: {
    Error,
    Loading,
    AssetViewer,
    Sprite,
  },
  router: assetDiagramRouter,
  i18n
})
export default class AssetDiagramViewer extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public assetkey!: string;

  @Prop({required: true, default: ''})
  public timestamp!: string;

  @Prop({ required: true })
  public customerAssetDetails!: string;

  private refreshTimer: any;

  private created() {
    // Clear leftover listeners, if present.
    this.clearAdEventBus();
    // Listen for route changes
    this.$router.afterEach(() => {
      store.commit(`${VuexModuleNamespaces.appViewer}/${AppViewerStore.mutations.updateIsLoading.name}`, false);
    });
  }

  private async mounted() {
    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
    const logPayload: LogPayload = {
      message: LogFeature['AD-LE-Opened'] + this.assetkey,
      feature: 'AD-LE-Opened',
      additionalData: {}
    };
    store.dispatch(`${VuexModuleNamespaces.appViewer}/${AppViewerStore.actions.addApplicationStartLog.name}`, logPayload);
    // Used with localhost debug testing
    this.activateRefreshInterval();
  }

  @Watch('timestamp', {​ immediate: true }​)
  public async onEmberRun() {
    store.commit(`${VuexModuleNamespaces.appViewer}/${AppViewerStore.mutations.updateActionTime.name}`);
    await store.dispatch(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.actions.getEMABurnerList.name}`);
    await store.dispatch(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.actions.retrieveBurnerReadingsLT.name}`);

    if (store.getters[`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.getters.getVariablePaths.name}`].length > 0) {
      store.dispatch(`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.actions.retrieveAssetVariablesData.name}`,
                        this.assetkey);
    }
  }

  @Watch('customerAssetDetails', {​ immediate: true, deep: true }​)
  public onCustomerOrAssetChange(): void {
   if (this.customerAssetDetails) {
      store.commit(`${VuexModuleNamespaces.userViewer}/${UserViewerStore.mutations.updateCustomerAssetInfo.name}`, JSON.parse(this.customerAssetDetails));
    }
  }

  private activated() {
    // Used when integrated into parent app
    this.activateRefreshInterval();
  }

  private activateRefreshInterval() {
    // Vue debug mode can call mounted multiple times. Don't set the timer multiple times
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  private deactivated() {
    this.activateRefreshInterval();
  }

  private beforeDestroy() {
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  private handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?assets\/heater\//)) {
      store.commit(`${VuexModuleNamespaces.appViewer}/${AppStore.mutations.updateIsLoading.name}`, false);
    }
  }

  private clearAdEventBus() {
    adEventBus.$off();
  }

  get isLoading() {
    return store.getters[`${VuexModuleNamespaces.appViewer}/${AppStore.getters.isLoading.name}`];
  }
}
