import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState} from '..';

export interface IAppStoreState {
    isLoading: boolean;
    actionTime: number;
}

export interface AppStoreGetters extends GetterTree<IAppStoreState, IRootState> {
  isLoading(state: IAppStoreState): boolean;
}

export interface AppStoreMutations extends MutationTree<IAppStoreState> {
  updateIsLoading(state: IAppStoreState, newIsLoading: boolean): void;
  updateActionTime(state: IAppStoreState): void;
}

export interface AppStoreActions extends ActionTree<IAppStoreState, IRootState> {}

export type AppContext = ActionContext<IAppStoreState, IRootState>;

export const AppStore = {
    namespaced: true as true,
    state: {
        isLoading: true,
        actionTime: new Date().getTime()
    } as IAppStoreState,
    getters:  {
      isLoading(state: IAppStoreState): boolean {
        return state.isLoading;
      }
    } as AppStoreGetters,
    mutations: {
      updateIsLoading(state: IAppStoreState, newIsLoading: boolean) {
        state.isLoading = newIsLoading;
      },
      updateActionTime(state: IAppStoreState) {
        state.actionTime = new Date().getTime();
      },
    } as AppStoreMutations,
    actions: {
    } as AppStoreActions
};
