import { FuelType } from '@/enums/DiagramApp';

export interface IBurnerReading {
  equipmentConfigKey: string; // UUID
  dateTime: string; // DateTime
  equipmentKey: string | null;
  name: string | null;
  burners: IViewerBurner[] | null;
  staleRecommendationThreshold: string;
  variables?: IVariableInfo[] | null;
}

export interface IViewerBurner {
  key: string;
  name: string | null;
  heatRelease: number;
  equivalenceRatio: number;
  outOfControllableRange: boolean;
  opportunityScore: number;
  airRegisters: IAirRegister[] | null;
  fuelLines: IFuelLine[] | null;
  dryO2: string;
  wetO2: string;
  getBurnerReadingValue(burnerProperty: keyof IViewerBurner,
                        airRegisterProperty?: keyof IAirRegister): any;
  isChangeRecommended(): boolean;
  isBurnerOff(): boolean;
}

export interface IVariableInfo {
  variableKey: string;
  data: string;
  unitOfMeasurement: string;
}

export interface IBurnerInfo {
  key: string;
  name: string | null;
  heatRelease: number;
  equivalenceRatio: number;
  outOfControllableRange: boolean;
  opportunityScore: number;
  airRegisters: IAirRegister[] | null;
  fuelLines: IFuelLine[] | null;
  dryO2: string;
  wetO2: string;
}

export interface IAirRegister {
  name: string;
  currentSetting: number;
  recommendedSetting: number;
  isPrimary: boolean;
}

export interface IFuelLine {
  name: string | null;
  isFiring: boolean;
  subType?: FuelType;
  referenceKey?: string;
  sortOrder?: number;
  threeWayValveOnName?: string;
  isOn?: boolean;
}

export class ViewerBurnerOptionProperties {
  public static airRegister: keyof IViewerBurner = 'airRegisters';
  public static opportunity: keyof IViewerBurner = 'opportunityScore';
  public static equivalenceRatio: keyof IViewerBurner = 'equivalenceRatio';
  public static heatRelease: keyof IViewerBurner = 'heatRelease';
  public static dryO2: keyof IViewerBurner = 'dryO2';
  public static wetO2: keyof IViewerBurner = 'wetO2';
}

// tslint:disable-next-line:max-classes-per-file
export class AirRegisterOptionProperties {
  public static recommendedAirRegister: keyof IAirRegister = 'recommendedSetting';
  public static currentAirRegister: keyof IAirRegister = 'currentSetting';
}

export interface ReadingsCache {
  [name: string]: number;
}

export enum PrimaryDisplayAttribute {
  RECOMMENDED_AIR_REGISTER = 'Recommended Air Register',
  CURRENT_AIR_REGISTER = 'Current Air Register',
  OPPORTUNITY = 'Opportunity Score',
  EQUIVALENCE_RATIO = 'Equivalence Ratio',
  HEAT_RELEASE = 'Heat Release',
  DRY_O2 = 'Dry',
  WET_O2 = 'Wet'
}
