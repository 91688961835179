import { render, staticRenderFns } from "./BurnerReadingsNew.vue?vue&type=template&id=1f42a750&scoped=true&"
import script from "./BurnerReadingsNew.vue?vue&type=script&lang=ts&"
export * from "./BurnerReadingsNew.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./BurnerReadingsNew.vue?vue&type=style&index=0&id=1f42a750&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1f42a750",
  null
  ,true
)

export default component.exports