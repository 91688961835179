 // Component Dependencies
import { gridConfig } from '@/assets/configs/gridConfig';
// Interface Dependencies
import { IRootState } from '@/store';
import { IGridContents, ICanvasContents } from '@/view-models/assetDiagram/grid-view-models';

// Vuex Dependencies
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
// Library Dependencies
import { fabric } from 'fabric';

export type IGridContext = ActionContext<IGridStoreState, IRootState>;

export type IGridStoreGetters = GetterTree<IGridStoreState, IRootState>;

export interface IGridStoreState {
  editorGrid: fabric.Canvas;
  editorGridBoxSize: number;
}

export interface IGridStoreMutations extends MutationTree<IGridStoreState> {
  resetStore(state: IGridStoreState): void;
}

export interface IGridStoreActions extends ActionTree<IGridStoreState, IRootState> {
  updateGridContentsfromJSON(gridContext: IGridContext, gridContents: IGridContents): void;
  getSelectedBurnerKeys(gridContext: IGridContext): string[];
  getSelectedBurnerDetails(gridContext: IGridContext): void;
  hideMenus(): void;
  forceCloseLabelEdit(): void;
}

export const GridViewerStore = {
  namespaced: true,
  state: {
    editorGrid: new fabric.Canvas('', {}),
    editorGridBoxSize: gridConfig.defaultGridBoxSize
    } as IGridStoreState,
  getters: {
    editorGrid(gridState: IGridStoreState): fabric.Canvas {
      return gridState.editorGrid;
    },
    editorGridBoxSize(gridState: IGridStoreState): number {
      return gridState.editorGridBoxSize;
    },
    gridContents(gridState: IGridStoreState): IGridContents {
      // Remove shadows, gridlines, and temporary objects
      const gridObject = gridState.editorGrid.toObject(['name',
                                                        'data',
                                                        'hasRotatingPoint',
                                                        'hasControls',
                                                        'lockScalingX',
                                                        'lockScalingY',
                                                        'lockRotation',
                                                        'lockMovementX',
                                                        'lockMovementY',
                                                        'selectable',
                                                        'strokeUniform'
                                                      ]);
      const permanentGridObjects: fabric.Object[] = [];
      gridObject.objects.forEach((object: fabric.Object) => {
        if (object.name &&
            ![gridConfig.tempSpaceLineName,
              gridConfig.tempSpaceLineControlsName,
              gridConfig.gridLineName,
              gridConfig.burnerShadowName].includes(object.name)) {
           permanentGridObjects.push(object);
        }
      });
      gridObject.objects = permanentGridObjects;
      return {
        gridHeight: gridState.editorGrid.getHeight(),
        gridWidth: gridState.editorGrid.getWidth(),
        gridBoxSize: gridState.editorGridBoxSize,
        contents: gridObject
      };
    },
  } as IGridStoreGetters,
  mutations: {
    resetStore(state: IGridStoreState) {
      state.editorGrid = new fabric.Canvas('', {});
    }
  } as IGridStoreMutations,
  actions: {
    forceCloseLabelEdit(): void {
      // Do nothing.  This method was required for compatability purposes.
    },
    updateGridContentsfromJSON(gridContext: IGridContext, gridContents: IGridContents): void {
      // Disable event listener for adding objects
      gridContext.state.editorGrid.off('object:added');
      // Load grid from JSON
      if (gridContents?.contents && gridContents.contents.objects?.length > 0) {
        gridContext.state.editorGrid.loadFromJSON(gridContents.contents,
                  gridContext.state.editorGrid.renderAll.bind(gridContext.state.editorGrid));
      } else {
        const emptyGrid: ICanvasContents = {
          version: '3.6.3',
          objects: []
        };
        gridContext.state.editorGrid.loadFromJSON(emptyGrid,
                  gridContext.state.editorGrid.renderAll.bind(gridContext.state.editorGrid));
      }

      // Remove unsupported shapes
      // NOTE: After all the diagram have been revisioned and all the spacelines
      // have been removed, we can discard this code
      const allObjects = gridContext.state.editorGrid.getObjects();
      for (const gridObject of allObjects) {
        if (gridObject.name === 'spaceLine' || gridObject.name === 'spaceLineControls') {
          gridContext.state.editorGrid.remove(gridObject);
        }
      }
    },
    hideMenus(): void {
      // Do nothing.  This method was required for compatability purposes.
   }
  } as IGridStoreActions
};
