// Interface Dependencies
import { IRootState } from '@/store';
// Vuex Dependencies
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
// Library Dependencies
import sharedAxiosInstance from '@/services/viewer/common/api-service';
import ConfigFactory from '@/services/config/config';
import { IAssetVariablesServiceResponse, IVariableData, IVariableDetail } from '@/view-models/viewer/assetVariables/asset-variables-response';
import { BurnerReadingService } from '@/services/viewer/burnerReading/burner-reading-service';

export type IVariableContext = ActionContext<IVariableStoreViewerState, IRootState>;

export type IVariableStoreGetters = GetterTree<IVariableStoreViewerState, IRootState>;

export interface IVariableStoreViewerState {
  showVariablePanel: boolean;
  variableDetails: IVariableDetail[];
  variablePaths: string[];
  assetVariablesData: IAssetVariablesServiceResponse | null;
}

export interface IVariableStoreMutations extends MutationTree<IVariableStoreViewerState> {
  setShowVariablePanel(state: IVariableStoreViewerState, showVariablePanel: boolean): void;
  setVariableDetails(state: IVariableStoreViewerState, variabledetails: IVariableDetail[]): void;
  updateAssetVariablesData(state: IVariableStoreViewerState, assetVariablesData: IAssetVariablesServiceResponse): void;
}

export interface IVariableStoreActions extends ActionTree<IVariableStoreViewerState, IRootState> {
    retrieveAssetVariablesData(context: IVariableContext, assetKey: string): void;
}

export const AssetVariableStore = {
  namespaced: true,
  state: {
    showVariablePanel: false,
    variablePaths: [],
    assetVariablesData: null,
    variableDetails: []
  } as IVariableStoreViewerState,
  getters: {
    showVariablePanel(state: IVariableStoreViewerState): boolean {
      return state.showVariablePanel;
   },
    getVariableDetails(state: IVariableStoreViewerState): IVariableDetail[] {
      return state.variableDetails;
   },
    getVariablePaths(state: IVariableStoreViewerState): string[] {
      return state.variableDetails?.map((data: IVariableDetail) => data.path) ?? [];
   },
   getAssetVariablesData(state: IVariableStoreViewerState): IAssetVariablesServiceResponse | null {
    return state.assetVariablesData;
   },
   getAssetVariableslist(state: IVariableStoreViewerState): IVariableData[] {
    return state.assetVariablesData?.assetVariables ?? [];
   },
  } as IVariableStoreGetters,
  mutations: {
    setShowVariablePanel(state: IVariableStoreViewerState, showVariablePanel: boolean) {
      state.showVariablePanel = showVariablePanel;
    },
    setVariableDetails(state: IVariableStoreViewerState, variableDetails: IVariableDetail[]) {
      state.variableDetails = variableDetails;
    },
    updateAssetVariablesData(state: IVariableStoreViewerState, assetVariablesData: IAssetVariablesServiceResponse) {
      state.assetVariablesData = assetVariablesData;
    }
  } as IVariableStoreMutations,
  actions: {
    async retrieveAssetVariablesData(variableContext: IVariableContext, assetKey: string)
      : Promise<IAssetVariablesServiceResponse> {
    const conf = await ConfigFactory.GetConfig();
    const burnerReadingService = new BurnerReadingService(
      sharedAxiosInstance,
      process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL
        ? process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL
        : conf.get('adbApiUrl')
    );
    const response = await burnerReadingService.getVariableDetails(assetKey, variableContext.getters.getVariablePaths);
    variableContext.commit('updateAssetVariablesData', response);
    return response;
    }
  } as IVariableStoreActions
};
