export interface IUser {
    key: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    orgKey: string;
    roleKeys?: string[];
    isCustomerUser: boolean;
    createdAt: string;
    createdBy: string;
    username: string;
    activeCustomerKey: string;
    activeEmberCustomerKey: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
    status: string;
    lastLogin: string;
    firstLogin: string | null;
    emailResendAt: string;
    userAssignments: UserAssignment[];
    permissions?: string[];
    isSuperUser?: boolean;
    isAdminCompanyUser?: boolean;
    systemRolePermissions: string[];
}

export enum Permission {
    Unknown = 'Unknown',
    CreateUser = 'CreateUser',
    ManageUsers = 'ManageUsers',
    SetUserAssignments = 'SetUserAssignments',
    ReadBurnerStatus = 'ReadBurnerStatus',
    WriteBurnerStatus = 'WriteBurnerStatus',
    ManageCustomers = 'ManageCustomers',
    ReadHeaters = 'ReadHeaters',
    CreateHeaters = 'CreateHeaters',
    ManageHeaters = 'ManageHeaters',
    DeleteLogs = 'DeleteLogs',
    ManageKESCompanies = 'ManageKESCompanies',
    ShowRecommendationOverride = 'ShowRecommendationOverride',
    KESPortalAccess = 'KESPortalAccess',
    ViewAssetDiagram = 'ViewAssetDiagram',
    CreateAssetDiagram = 'CreateAssetDiagram',
    ViewReports = 'ViewReports',
    ManageReports = 'ManageReports',
    ViewDashboards = 'ViewDashboards',
    ManageDashboards = 'ManageDashboards',
    ManagePerformance = 'ManagePerformance',
    ViewPerformance = 'ViewPerformance',
    CreateHydraulicModel = 'CreateHydraulicModel',
    ViewHydraulicModel = 'ViewHydraulicModel',
    ManageAssetConfiguration = 'ManageAssetConfiguration',
    ViewAssetConfiguration = 'ViewAssetConfiguration',
}
export interface UserAssignment {
    key: string;
    userKey: string;
    createdAt: string | null;
    assignedEntityOrgKey: string;
    assignedEntityKey: string;
    assignedEntityType: AssignedEntityType;
}

export enum AssignedEntityType {
    AssetGrouping = 'AssetGrouping',
    CustomerSite = 'CustomerSite',
    Heater = 'Heater',
    Tower = 'Tower',
}

export interface IMetadataDetails {
    customerId: string;
    customerName: string;
    assetId: string;
    assetName: string;
}
