import BaseService from '@/services/common/base-service';
import { ILog } from '@/view-models/common/log-model';
import { AxiosInstance } from 'axios';

export default class LoggerService extends BaseService {

	constructor(logApiUrl: string, axios: AxiosInstance) {
		super(`${logApiUrl}`, axios);
	}

	public async addLogs(msg: string, feature: string, additionalData: { [key: string]: string }) {
		const payload = generateLoggerPayload(msg, feature, additionalData);
    await this.httpClient.post('aov/Loggers/ui-log', payload);
  }
}

const getBrowserDetails = () => {
  const browserData = navigator.userAgent;
  let browserName, verOffset, browserVersion;
  if ((verOffset = browserData.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    browserVersion = browserData.substring(verOffset + 6);
  } else if ((verOffset = browserData.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    browserVersion = browserData.substring(verOffset + 5);
  } else if ((verOffset = browserData.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    browserVersion = browserData.substring(verOffset + 7);
  } else if ((verOffset = browserData.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    browserVersion = browserData.substring(verOffset + 7);
  } else if ((verOffset = browserData.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    browserVersion = browserData.substring(verOffset + 8);
  }
  browserVersion = parseInt(''+ browserVersion, 10);
  return {
    name: browserName,
    version: browserVersion.toString()
  };
};

const generateLoggerPayload = (msg: string, feature: string, additionalData: { [key: string]: string }): ILog => {
  const browserData = getBrowserDetails();
  return {
    message: msg,
    applicationName: 'Layout_Viewer_FE',
    browserName: browserData.name ? browserData.name : '',
    browserVersion: browserData.version ? browserData.version : '',
    frontendFeature: feature,
    logLevel: 'Information',
    additionalProperty: additionalData,
    stackTrace: 'N/A'
  };
};