export enum HeatUnit {
  MMBTU_HR = 'MMBTU/hr',
  MW = 'MW',
  GCAL_HR = 'Gcal/hr'
}

export interface HeatCalculation {
  watts: number | string; // measurement in watts from Ember
  desiredUnit: HeatUnit;  // desired display unit
}

export function convertHeatUnits(calculation: HeatCalculation): string {
  // Discard unknown value
  if (calculation.watts === -999999) {
    return '-';
  }
  if (typeof calculation.watts === 'number') {
    // Convert watts to megawatts
    const megawatts = calculation.watts / 1000000;
    if (calculation.desiredUnit === HeatUnit.GCAL_HR) {
      // Convert megawatts to gcal/hr
      return (megawatts * 0.8598452278589853).toFixed(2);
    } else if (calculation.desiredUnit === HeatUnit.MMBTU_HR) {
      // Convert megawatts to mmbtu/hr
      return (megawatts * 3.41214163312794).toFixed(2);
    } else if (calculation.desiredUnit === HeatUnit.MW) {
      // Return megawatts
      return (megawatts).toFixed(2);
    } else {
      return (calculation.watts).toFixed(2);
    }
  } else {
    return '-';
  }
}
