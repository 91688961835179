






















import { Vue, Component } from 'vue-property-decorator';
import PreviewToolbar from './PreviewToolbar.vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { AppStore } from '@/store/app/appStore';
import { AssetStore } from '@/store/assetStore/assetStore';
import { DiagramStore } from '@/store/diagram/diagramStore';
import { ErrorStore } from '@/store/error/errorStore';
import { PreviewGridStore } from '@/store/previewGrid/previewGridStore';
import { BurnerFolderStore } from '@/store/burnerFolder/burnerFolderStore';
import { GridStore } from '@/store/grid/gridStore';
import EditorToolbar from '@/components/editor/components/EditorToolbar.vue';
import Loading from '@/components/common/Loading.vue';
import IAssetDiagramServiceResponse from '@/view-models/assetDiagram/asset-response-view-models';
import { fabric } from 'fabric';

@Component({
  name: 'preview',
  components: {
    PreviewToolbar,
    Loading
  }
})

export default class Preview extends Vue {

  private selectedAssetKey = '';
  private isLoading: boolean = false;
  private isResizing: number = 0;
  private successfullyLoadedDiagram: boolean = false;
  private diagram: IAssetDiagramServiceResponse | undefined;

  public async mounted() {
    this.isLoading = true;
    // Hide selection menus from editor grid, if present
    store.dispatch('grid/hideMenus');
    // Initialize preview canvas
    const canvasElement: HTMLElement | null | undefined =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('preview-canvas');
    if (canvasElement !== null && canvasElement !== undefined)  {
      store.commit(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.mutations.updatePreviewGrid.name}`,
                   new fabric.Canvas(canvasElement as HTMLCanvasElement, {
        selection: false,
        selectionFullyContained: true,
        preserveObjectStacking: true,
        includeDefaultValues: false,
        hoverCursor: 'default'
      }));
    }
    // Refresh asset info if navigating directly to this screen
    const selectedAsset = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
    this.selectedAssetKey = selectedAsset?.key;
    if (this.$route?.params.id && this.$route.params.id !== this.selectedAssetKey) {
      // Refresh asset selection
      if (!(await this.findAssetAndSetSelected())) {
        return;
      }
    }

    // Load Burners
    await this.loadAndLockBurners();

    // Load and bind diagram to appropriate stores
    await this.loadDiagram();
    if (this.successfullyLoadedDiagram) {
      await this.bindDiagram();
    }

    // Load the grid contents into the preview
    let gridObjects = store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.gridContents.name}`];
    store.dispatch(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.actions.updateGridContentsfromJSON.name}`,
                   gridObjects);

    // Initiallly size the preview grid and set listeners
    window.addEventListener('resize', this.sizeGridOnWindowResize);
    this.resizeGrid(false);

    // Set the preview object size
    store.dispatch(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.actions.resizeObjectsForPreview.name}`,
                   false);

    // Align objects to top left
    store.dispatch(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.actions.alignTopLeft.name}`);

    // Trim excess grid
    this.resizeGrid(true);

    this.isLoading = false;
  }

  private async loadAndLockBurners(): Promise<void> {
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => {
        await store.dispatch(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.actions.loadBurners.name}`);
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.updateLoading.name}`, false);
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.lockAll.name}`);
      },
      errorMsg: 'Error loading burners. ',
      routeHomeAfterError: true
    });
  }

  private async bindDiagram() {
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => {
        await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.bindAssetDiagram.name}`,
                             this.diagram);
        await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.initializeDiagramSaver.name}`);
        if (this.diagram?.locked) {
          store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateIsGridFrozen.name}`, true);
        } else {
          store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateIsGridFrozen.name}`, false);
        }
      }, errorMsg: 'Error loading and binding asset diagram. 1',
      routeHomeAfterError: true
    });
  }

  private async findAssetAndSetSelected(): Promise<boolean> {
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
        action: async () =>
            store.dispatch(`${VuexModuleNamespaces.asset}/${AssetStore.actions.loadAssets.name}`),
        errorMsg: 'Error loading asset',
        routeHomeAfterError: true
      });

    const foundAsset =
        store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.findAsset.name}`](this.$route.params.id);
    if (!foundAsset) {
      this.$router.push({ name: 'NotFound' });
      return false;
    }
    store.commit(`${VuexModuleNamespaces.asset}/${AssetStore.mutations.selectAsset.name}`, foundAsset);
    (this.$refs.editorToolbar as EditorToolbar)?.getPublishedVersion();
    this.selectedAssetKey = foundAsset?.key;
    return true;
  }

  private async loadDiagram() {
    this.successfullyLoadedDiagram = false;
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => {
        this.diagram = await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.retrieveAssetDiagram.name}`, this.selectedAssetKey);
        this.successfullyLoadedDiagram = true;
      },
      errorMsg: 'Error loading and binding asset diagram. ',
      routeHomeAfterError: true
    });
  }

  private sizeGridOnWindowResize(): void {
    window.clearTimeout(this.isResizing);
    this.isResizing = window.setTimeout(() => {
      this.resizeGrid(false);
    }, 200);
  }

  private resizeGrid(isTrimToFit: boolean): void {
    const editorElement =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('preview');
    const toolbarElement =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('preview-toolbar-container');
    const gridElement: HTMLElement | null | undefined =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('preview-grid');
    if ( gridElement !== null && gridElement !== undefined ) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      const gridWidth = editorElement?.clientWidth ?? 1000;
      store.commit(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.mutations.updatePreviewGridWidth.name}`,
                   gridWidth);
      store.commit(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.mutations.updatePreviewGridHeight.name}`,
                   gridHeight);
      if (isTrimToFit) {
        store.dispatch(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.actions.trimGridToFit.name}`);
      } else {
        store.dispatch(`${VuexModuleNamespaces.previewGrid}/${PreviewGridStore.actions.resizeObjectsForPreview.name}`,
                       true);
      }
    }
  }

}
