import { AxiosInstance } from 'axios';
import BaseService from './base-service';

export default class ControllerBaseService extends BaseService {
  constructor(protected controller: string, axios: AxiosInstance) {
    super(controller, axios);
  }

  protected url(uri?: string): string {
    const suffix = uri == null ? '' : `/${uri}`;
    return `${this.controller}${suffix}`;
  }
}
