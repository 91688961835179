import BaseService from '@/services/common/base-service';
import { AssetDiagram } from '@/view-models/assetDiagram/asset-diagram-view-models';
import { Status } from '@/view-models/assetDiagram/asset-diagram';
import { AxiosInstance, AxiosResponse } from 'axios';
import IAssetDiagramServiceResponse from '@/view-models/assetDiagram/asset-response-view-models';
import { gzip, ungzip } from 'node-gzip';

export class AssetDiagramService extends BaseService {

  constructor(axiosInstance: AxiosInstance, adbApiUrl: string) {
    super(adbApiUrl, axiosInstance);
  }

  public async saveCurrentAssetDiagram(publish: boolean): Promise<IAssetDiagramServiceResponse> {
    const assetDiagram = AssetDiagram.currentAssetDiagram;
    assetDiagram.status = publish ? Status.Published : assetDiagram.status;
    // Compress asset diagram
    const compressed = await gzip(assetDiagram.diagram);
    const compressedString = compressed.toString('hex');
    assetDiagram.diagram = compressedString;
    // Save diagram
    const response = await this.httpClient.post<IAssetDiagramServiceResponse>('aov/AssetsDiagram', assetDiagram);
    // Decompress asset diagram in response
    const decompressedBuffer = Buffer.from(response.diagram, 'hex');
    const decompressed = await ungzip(decompressedBuffer);
    response.diagram = decompressed.toString();
    return response;
  }
  public async getAssetDiagram(assetKey: string, published: boolean, equipmentConfigKey: string | undefined): Promise<IAssetDiagramServiceResponse> {
    let url = `aov/AssetsDiagram/${assetKey}?diagramStatus=`;
    let response : AxiosResponse<IAssetDiagramServiceResponse>;
    url += published ? 'Published': 'Draft';
    response = await this.http.get<IAssetDiagramServiceResponse>(url);
    let assetDiagram: IAssetDiagramServiceResponse = response.data;
    // Try to decompress.  If failed, then assume this string is not compressed and use raw string from API response
    if (!assetDiagram) {
      assetDiagram = {
        assetKey: assetKey,
        lastModifiedDate: new Date(),
        lastModifiedByName: '',
        status: published ? Status.Published : Status.Draft,
        diagram: '{}',
        locked: false,
        equipmentConfigKey: equipmentConfigKey
      } as IAssetDiagramServiceResponse;
    }
    const rawAssetDiagram = assetDiagram.diagram;
    try {
      const decompressedBuffer = Buffer.from(rawAssetDiagram, 'hex');
      const decompressed = await ungzip(decompressedBuffer);
      assetDiagram.diagram = decompressed.toString();
    } catch (error) {
      assetDiagram.diagram = rawAssetDiagram;
    }
    return assetDiagram;
  }

}
