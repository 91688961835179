import { IAsset } from '@/view-models/asset/assets-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '../../store';
import sharedAxiosInstance from '@/services/common/api-service';
import { AssetBurnerService } from '@/services/asset/asset-burner-service';
import ConfigFactory from '@/services/config/config';

export interface IAssetStoreState {
    assets: IAsset[];
    selectedAsset: undefined | IAsset;
    receivedAssetKey: string; // This is the string received from the parent app (if present)
}

export interface IAssetStoreGetters extends GetterTree<IAssetStoreState, IRootState> {
    allAssets(state: IAssetStoreState): IAsset[];
    receivedAssetKey(state: IAssetStoreState): string;
    childAssets(state: IAssetStoreState, parentKey: undefined | string): IAsset[];
    selectedAsset(state: IAssetStoreState): null | IAsset;
    parentAsset(state: IAssetStoreState, currentAsset: IAsset): IAsset | undefined;
    findAssetParentType(state: IAssetStoreState): (currentNode: IAsset, typeName: string) => IAsset | undefined;
    findAsset(state: IAssetStoreState): (assetKey: string) => IAsset | undefined;
}

export interface IAssetStoreMutations extends MutationTree<IAssetStoreState> {
    selectAsset(state: IAssetStoreState, selected: IAsset): void;
    updateReceivedAssetKey(state: IAssetStoreState, newReceivedAssetKey: string): void;
}

export interface IAssetStoreActions extends ActionTree<IAssetStoreState, IRootState> {
    loadAssets(context: IAssetContext): void;
}

export type IAssetContext = ActionContext<IAssetStoreState, IRootState>;

const findParentType = (state: IAssetStoreState, currentNode: IAsset, typeName: string): IAsset | undefined => {
        let returnValue: IAsset | undefined;
        if (currentNode.type === typeName) {
            returnValue = currentNode;
        } else {
            if (currentNode.parentKey !== undefined) {
                const parentAsset = state.assets.find((a: IAsset) => a.key === currentNode.parentKey);
                if (parentAsset !== undefined) {
                    returnValue = findParentType(state, parentAsset, typeName);
                }
            }
        }
        return returnValue;
    };

export const AssetStore = {
    namespaced: true as true,
    state: {
        assets: new Array<IAsset>(),
        receivedAssetKey: '',
        selectedAsset: {},
    } as IAssetStoreState,
    getters:  {
        allAssets(state: IAssetStoreState): null | IAsset[] {
            return state.assets;
        },
        childAssets(state: IAssetStoreState, parentKey: undefined | string) {
            // Get child assets for the specified parent
            // To get the root level assets, pass in undefined for the parent key.
            if (state.assets) {
            return state.assets
                .filter((asset) => asset.parentKey === parentKey)
                .sort((asset) => asset.orderIndex);
            } else {
                return Array<IAsset>();
            }
        },
        selectedAsset(state: IAssetStoreState): IAsset | undefined {
            return state.selectedAsset;
        },
        receivedAssetKey(state: IAssetStoreState): string {
          return state.receivedAssetKey;
        },
        parentAsset(state: IAssetStoreState, currentAsset: IAsset): IAsset | undefined {
            if (currentAsset && currentAsset.parentKey !== undefined) {
                return state.assets.find((asset: IAsset) => asset.key === currentAsset.parentKey);
            } else {
                return undefined;
            }
        },
        findAssetParentType: (state: IAssetStoreState) =>
            (currentNode: IAsset, typeName: string): IAsset | undefined => {
                return findParentType(state, currentNode, typeName);
            },
        findAsset: (state: IAssetStoreState) =>
            (assetKey: string): IAsset | undefined => {
                return state.assets.find((a: IAsset) => a.key === assetKey);
            }
    } as IAssetStoreGetters,
    mutations: {
        selectAsset(state: IAssetStoreState, selected: IAsset): void {
            state.selectedAsset = selected;
        },
        updateReceivedAssetKey(state: IAssetStoreState, newReceivedAssetKey: string): void {
          state.receivedAssetKey = newReceivedAssetKey;
        }
    } as IAssetStoreMutations,
    actions: {
        async loadAssets(context: IAssetContext): Promise<void> {
            const conf = await ConfigFactory.GetConfig();
            const assetBurnerService = new AssetBurnerService(sharedAxiosInstance,
            process.env.VUE_APP_KES_PORTAL_API_BASE_URL ?
            process.env.VUE_APP_KES_PORTAL_API_BASE_URL :
            conf.get('umApiUrl'));
            const response = await assetBurnerService.getCustomerAssets();
            context.state.assets = response;
        }
    } as IAssetStoreActions
};
