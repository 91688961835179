import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IBurnerReading } from '@/view-models/burnerReading/burner-reading';

export class BurnerReadingService extends BaseService {

  constructor(axiosInstance: AxiosInstance, adbApiUrl: string) {
    super(adbApiUrl, axiosInstance);
  }

  public async getBurnerReadings(equipmentConfigKey: string, assetKey: string): Promise<IBurnerReading> {
    const url = `aov/BurnersReading/asset/${equipmentConfigKey}/${assetKey}`;
    return (await this.httpClient.get<IBurnerReading>(url));
  }

}
