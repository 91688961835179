import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store, { VuexModuleNamespaces } from '..';
import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import { Nil } from '@/utils/types';
import {
  VariableTreeNodeData,
  VariableNodesTable,
  VariableTreeNode
} from '@/view-models/variables';
import { AssetTreeNodesNormalizer } from '@/utils/tree/asset-node-converters';
import { TreeFilterEnum } from '@/enums/variables';
import { TreeTraverseHelper } from '@/utils/tree/tree-traverse-helper';
import { VariableService } from '@/services/variables/variable-service';
import { Vue } from 'vue-property-decorator';
import { AssetStore } from '../assetStore/assetStore';
import { IAsset } from '@/view-models/asset/assets-view-models';
import { VariableFolderStore } from '../variableFolder/variableFolderStore';
export interface IInputsTreeModule {
  // State
  loadingNodesTable: boolean;
  inMergePhase: boolean;
  nodesTable: VariableNodesTable;
  rootAsset: Nil<IAssignmentTreeNodeViewModel>;
  selectedNode: VariableTreeNode;
  selectedNodeKeysForMerge: Array<string>;
  selectedTreeEnum: TreeFilterEnum;
  customParentNode: VariableTreeNode;
  searchString: string;
  // Getters
  readonly rootAssetNode: Nil<VariableTreeNode>;
  nodeByKey: (nodeKey: string, trees: VariableTreeNode[]) => VariableTreeNode;
  // Mutations
  setLoadingNodesTable(loading: boolean): void;
  setSearchString(searchStr: string): void;
  setNodesTable(nodesTable: VariableNodesTable): void;
  setRootAsset(asset: Nil<IAssignmentTreeNodeViewModel>): void;
  toggleNodeIsOpen(node: VariableTreeNode): void;
  setCustomParentNode(node: VariableTreeNode): void;
  clearAll(): void;
  // Actions
  loadTree(): Promise<void>;
}

@Module({ dynamic: true, store, name: 'inputs-tree' })
export class InputsTreeModule extends VuexModule implements IInputsTreeModule {
  // State
  public loadingNodesTable: boolean = false;
  public inMergePhase: boolean = false;
  public nodesTable: VariableNodesTable = {};
  public rootAsset: Nil<IAssignmentTreeNodeViewModel> = null;
  public selectedNode!: VariableTreeNode;
  public selectedNodeKeysForMerge: string[] = [];
  public selectedTreeEnum: TreeFilterEnum = TreeFilterEnum.All;
  public searchedNodeKeys: string[] = [];
  public customParentNode!: VariableTreeNode;
  public searchString: string = '';

  // Getters
  public get rootAssetNode(): Nil<VariableTreeNode> {
     return this.rootAsset == null ? null : this.nodesTable[this.rootAsset.key];
  }

  public get treeHelper(): TreeTraverseHelper<VariableTreeNodeData> {
    return new TreeTraverseHelper<VariableTreeNodeData>(this.nodesTable);
  }

  public get filteredNodesTable(): VariableNodesTable {
    const inputs: VariableNodesTable = {};
    Object.keys(this.nodesTable).forEach((k) => {
      const item = this.nodesTable[k];
      if (item) {
        inputs[k] = item;
      }
    });
    return inputs;
  }

  public get searchedNodesTable(): VariableNodesTable {
    if ( this.searchString === '') {
      return this.nodesTable;
    }
    const inputs: VariableNodesTable = {};
    Object.keys(this.nodesTable).forEach((k) => {
      const item = this.nodesTable[k];
      if (this.searchedNodeKeys?.includes (k)) {
        inputs[k] = item;
        let parentNode: VariableTreeNode;
        let currentInputNode: VariableTreeNode = this.nodesTable[item.parentKey!];
        while (currentInputNode) {
          if (currentInputNode.parentKey === currentInputNode.key || Object.keys(inputs).includes(currentInputNode.key)) {
            break;
          }
          if (!Object.keys(inputs).includes(currentInputNode.key)) {
            inputs[currentInputNode.key] = this.nodesTable[currentInputNode.key];
          }
          parentNode = this.nodesTable[currentInputNode.parentKey!];
          currentInputNode = parentNode;
        }
      }
    });
    return inputs;
  }

  public get nodeByKey() {
    return (nodeKey: string, trees: VariableTreeNode[]): VariableTreeNode => {
      for (const treeNode of trees) {
        const currentInputNode = recursiveFindNode(treeNode, nodeKey);
        if (currentInputNode != null) {
          return currentInputNode;
        }
      }
      return null!;
    };
  }

  public get nodeByKeys() {
    return (nodeKey: string): VariableTreeNode => {
      for (const treeNode of this.treeHelper.allNodes) {
        const currentInputNode = recursiveFindNode(treeNode, nodeKey);
        if (currentInputNode != null) {
          return currentInputNode;
        }
      }
      return null!;
    };
  }

  // Mutations
  @Mutation
  public setSearchString(searchStr: string): void {
    this.searchString = searchStr;
  }

  @Mutation
  public setLoadingNodesTable(loading: boolean): void {
    this.loadingNodesTable = loading;
  }

  @Mutation
  public setNodesTable(nodesTable: VariableNodesTable): void {
    this.nodesTable = nodesTable;
  }

  @Mutation
  public setRootAsset(asset: Nil<IAssignmentTreeNodeViewModel>): void {
    this.rootAsset = asset;
  }

  @Mutation
  public toggleNodeIsOpen(node: VariableTreeNode): void {
    if (node.key in this.nodesTable) {
      const updated = Object.assign({}, node, { isOpen: !(node.isOpen ?? false) });
      Vue.set(this.nodesTable, node.key, updated);
    }
  }

  @Mutation
  public closeParent(parentKey: VariableTreeNode): void {
    if (parentKey.key in this.nodesTable) {
      const updated = Object.assign({}, parentKey, {isOpen: false});
      Vue.set(this.nodesTable, parentKey.key, updated);
    }
  }

  @Mutation
  public setSelectedNode(node: VariableTreeNode): void {
    const nodeToSet = Object.assign({}, node);
    this.selectedNode = nodeToSet;
  }

  @Mutation
  public setCustomParentNode(node: VariableTreeNode): void {
    const nodeToSet = Object.assign({}, node);
    this.customParentNode = nodeToSet;
  }

  @Mutation
  public clearNode() {
    this.selectedNode = Object.assign({});
  }

  @Mutation
  public setSelectedTreeEnum(value: TreeFilterEnum): void {
    this.selectedTreeEnum = value;
  }

  @Mutation
  public setSearchedNodeKeys(keys: string[]): void {
    this.searchedNodeKeys = keys;
  }

  @Mutation
  public lockVariableByKey(data : { node: VariableTreeNode, childNode: VariableTreeNode}): void {
    let variableKey = '';
    data.node.childrenKeys.forEach((childKey) => {
      if (childKey in this.nodesTable) {
        const nodeKeys = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKeys.name}`](childKey);
        if (nodeKeys !== undefined) {
          variableKey = nodeKeys.variableKey;
          if (variableKey.includes(childKey)) {
            data.childNode.isLocked = true;
          }
        }
      }
    });
  }

  @Mutation
  public lockAllVariableByKey(data : { node: VariableTreeNode, childNode: VariableTreeNode}): void {
    data.node.childrenKeys.forEach((childKey) => {
      if (childKey in this.nodesTable) {
        if (data.childNode.isLeaf) {
          data.childNode.isLocked = true;
        }
      }
    });
  }

  @Mutation
  public unlockVariableByKey(data : { variableKey: string, selectedNode: VariableTreeNode}): void {
    if (data.selectedNode.data?.variableKey! in this.nodesTable) {
      const updated = Object.assign({}, data.selectedNode, { isLocked: !(data.selectedNode.isLocked ?? false) });
      Vue.set(this.nodesTable, data.selectedNode.data?.variableKey!, updated);
    }
  }

  @Mutation
  public clearAll(): void {
    for (const treeNode of this.treeHelper.allNodes) {
      treeNode.clearSelf();
    }
  }

  // Actions
  @Action({ rawError: true })
  public async loadTree(): Promise<void> {
    this.setLoadingNodesTable(true);
    const selectedAsset: IAsset = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
    const conf = await ConfigFactory.GetConfig();
    const assetDiagramService = new VariableService(sharedAxiosInstance,
                    process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
                    process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
                    conf.get('adbApiUrl'));

    try {
      this.setRootAsset(selectedAsset);
      const levels = await assetDiagramService.getVariableList(selectedAsset.key);
      const normalizer = new AssetTreeNodesNormalizer();
      normalizer.convertNodes(levels.assetHierarchy);
      if (normalizer.hasNodes) {
        normalizer.convertVariables(levels.variables);
      }
      const table = normalizer.processChildren().treeNodesTable;
      this.setNodesTable(table);
    } finally {
      this.setLoadingNodesTable(false);
    }
  }

  @Action({ rawError: true })
  public async openParentNodes(nodeKey: string): Promise<void> {
    let currentInputNode: VariableTreeNode = this.nodeByKey(nodeKey, this.treeHelper.allNodes);
    let parentNode: VariableTreeNode;

    do {
      if (currentInputNode.parentKey === currentInputNode.key) {
        break;
      }
      parentNode = this.nodeByKey(currentInputNode.parentKey!, this.treeHelper.allNodes);
      if (parentNode) {
        parentNode.isOpen = true;
      }
      currentInputNode = parentNode;
    } while (currentInputNode);
  }
}

export default getModule(InputsTreeModule, store);

function recursiveFindNode(node: any, currentNodeKey: string): VariableTreeNode {
  if (node?.key === currentNodeKey) {
    return node;
  }
  return null!;
}
