

















import { Vue, Component } from 'vue-property-decorator';
import FolderItem from '@/components/common/FolderItem.vue';
import Loading from '@/components/common/Loading.vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { DiagramStore } from '@/store/diagram/diagramStore';
import { BurnerFolderStore } from '@/store/burnerFolder/burnerFolderStore';
import { IFolder } from '@/view-models/common/folder-view-models';

@Component({
  name: 'burner-selector',
  components: {
    FolderItem,
    Loading
  }
})
export default class BurnerSelector extends Vue {

  get isLoading() {
    return store.getters[`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.isLoading.name}`];
  }

  get backgroundUrl() {
      return require('../../../assets/images/icons/Search.svg');
  }

  get burners() {
    return store.getters[`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.rootFolders.name}`];
  }

  get selectedCount() {
    return store.getters[`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.selectedCount.name}`];
  }

  get notLockedCount() {
    return store.getters[`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.notLockedCount.name}`];
  }

  get isDiagramLocked(): boolean {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isDiagramLocked.name}`];
  }

  public lockAll(): void {
    store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.lockAll.name}`);
  }

  private async mounted() {
    window.addEventListener('resize', this.resizeBurnerSelectorMenu);
    this.resizeBurnerSelectorMenu();
  }

  private selectAll(): void {
    store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.selectAll.name}`);
  }

  private clearAll(): void {
    store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.clearAll.name}`);
  }

  private itemSelected(item: IFolder, args: MouseEvent) {
    if (!item.isSelected) {
      if (args.shiftKey) {
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.selectRange.name}`, item);
      } else if (args.metaKey || args.ctrlKey) {
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.selectSelf.name}`, item);
      } else {
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.clearAll.name}`);
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.selectSelf.name}`, item);
      }
    }
  }

  private resizeBurnerSelectorMenu(): void {
    const burnerSelectorMenu: HTMLElement | null | undefined =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('burner-selector');
    const editorElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor');
    const toolbarElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor-toolbar-container');
    if ( burnerSelectorMenu !== null && burnerSelectorMenu !== undefined ) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      burnerSelectorMenu.style.height = gridHeight + 'px';
    }
  }

}
