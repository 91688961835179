



































































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { GridStore } from '@/store/grid/gridStore';
import { IBurnerDetail } from '@/view-models/burner/burner-details-view-models';
import { VariableFolderStore } from '@/store/variableFolder/variableFolderStore';
import { VariableTreeNode } from '@/view-models/variables';

Vue.use(BootstrapVue);

@Component({
  name: 'burner-settings-menu'
})
export default class BurnerSettingsMenu extends Vue {

  private isSideMenuOpen: boolean = false;
  private burnerSettings: boolean = false;

  private mounted() {
    window.addEventListener('resize', this.resizeBurnerSettingsMenu);
  }

  private toggleSideMenu() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`);
    this.isSideMenuOpen = !this.isSideMenuOpen;
    this.resizeBurnerSettingsMenu();
    if (!this.isSideMenuOpen) {
      this.shrinkBurnerSettingsMenu();
    }
  }

  get isBurnerSettingsEnabled(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.isBurnerSettingsEnabled.name}`];
  }

  get isVariableSettingsEnabled(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.isVariableSettingsEnabled.name}`];
  }

  get selectedBurnerDetails(): IBurnerDetail[] {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.selectedBurnerDetails.name}`];
  }

  get selectedVariableDetails(): VariableTreeNode[] {
    return store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getSelectedVariables.name}`];
  }

  get selectedVariablePathDetails(): {} {
    return store.state.variableFolder.selectedVariablePathValue;
  }

  private getVariablePathValue(variableKey: string): string {
    const selectedVar = store.state.variableFolder.selectedVariablePathValue.find((item) => item.name === variableKey);
    return selectedVar?.value!;
  }

  private resizeBurnerSettingsMenu(): void {
    const burnerSettingsMenu: HTMLElement | null | undefined =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('burner-settings-menu');
    const editorElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor');
    const toolbarElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor-toolbar-container');
    if ( burnerSettingsMenu !== null && burnerSettingsMenu !== undefined ) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0) - 30;
      burnerSettingsMenu.style.height = gridHeight + 'px';
      burnerSettingsMenu.style.width = this.isSideMenuOpen ? '25rem' : '40px';
      burnerSettingsMenu.style.overflowY = 'auto';
    }
  }

  private shrinkBurnerSettingsMenu(): void {
    const burnerSettingsMenu: HTMLElement | null | undefined =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('burner-settings-menu');
    if ( burnerSettingsMenu !== null && burnerSettingsMenu !== undefined ) {
      burnerSettingsMenu.style.height = '40px';
      burnerSettingsMenu.style.width = '40px';
      burnerSettingsMenu.style.overflowY = 'hidden';
    }
  }

}
