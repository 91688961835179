








































import Vue from 'vue';
import Grid from './components/Grid.vue';
import EditorToolbar from './components/EditorToolbar.vue';
import SideMenu from './components/SideMenu.vue';
import BurnerSettingsMenu from './components/BurnerSettingsMenu.vue';
import LockedModal from './components/modals/LockedModal.vue';
import LayoutChangedModal from './components/modals/LayoutChangedModal.vue';
import LayoutAssetVariablesChangedModal from './components/modals/LayoutAssetVariablesChangedModal.vue';
import ZoomButton from './components/ZoomButton.vue';
import Loading from '@/components/common/Loading.vue';
import { Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { BurnerFolderStore } from '@/store/burnerFolder/burnerFolderStore';
import { AppStore } from '@/store/app/appStore';
import { AssetStore } from '@/store/assetStore/assetStore';
import { ErrorStore } from '@/store/error/errorStore';
import { GridStore } from '@/store/grid/gridStore';
import { DiagramStore } from '@/store/diagram/diagramStore';
import IAssetDiagramServiceResponse from '@/view-models/assetDiagram/asset-response-view-models';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import DiscardSaveModal from '@/components/editor/components/modals/DiscardSaveModal.vue';
import { IAsset } from '@/view-models/asset/assets-view-models';
import { VariableFolderStore } from '@/store/variableFolder/variableFolderStore';
import inputsTree from '@/store/storeModules/inputs-tree';
import { IVariableListServiceResponse } from '@/view-models/variables/variable-folder-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'editor',
  components: {
    Grid,
    EditorToolbar,
    SideMenu,
    BurnerSettingsMenu,
    LockedModal,
    ZoomButton,
    Loading,
    Breadcrumb,
    DiscardSaveModal,
    LayoutChangedModal,
    LayoutAssetVariablesChangedModal
  }
})
export default class Editor extends Vue {

  private isScrolling: number = 0;
  private isResizing: number = 0;
  private successfullyLoadedDiagram: boolean = false;
  private selectedAssetKey = '';
  private diagram: IAssetDiagramServiceResponse | undefined;
  private utilitiesLink: string = '/utilities';

  get isSideMenuHidden(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.isArrangeShapeMode.name}`];
  }

  get isBurnerSettingsEnabled(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.isBurnerSettingsEnabled.name}`];
  }

  get isLoading() {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isLoading.name}`];
  }

  get isSavePending(): boolean {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isSavePending.name}`];
  }

  get selectedAsset(): IAsset {
    return store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
  }

  get siteName(): string {
    return this.findAssetParentTypeName(this.selectedAsset, 'Site');
  }
  get gridLayoutChanged(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.getGridLayoutChanged.name}`];
  }

  private get isLayoutAssetVariablesChanged(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.getIsLayoutAssetVariablesChanged.name}`];
  }

  private async mounted() {
    // Load grid
    store.commit(`${VuexModuleNamespaces.diagram}/${DiagramStore.mutations.updateLoading.name}`, true);
    store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.updateLoading.name}`, true);
    store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateIsGridFrozen.name}`, true);
    const selectedAsset = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
    this.selectedAssetKey = selectedAsset?.key;
    // When navigating within the app from preview back to editor, the selected asset is already set
    // so we don't need to refetch based on param id.
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    if (this.$route?.params.id && this.$route.params.id !== this.selectedAssetKey) {
      if (!(await this.findAssetAndSetSelected())) {
        return;
      }
    }

    store.dispatch(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.actions.loadVariables.name}`, this.selectedAsset).then((response: IVariableListServiceResponse) => {
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateAssetVariables.name}`, response.variables);
    });

    await this.loadBurnersAndLockUntilBoundToGrid();

    await this.loadDiagram();
    if (this.successfullyLoadedDiagram) {
      await this.bindDiagram();
    }

    // Prep and size grid
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.cancelArrangeShapeMode.name}`);
    store.commit(`${VuexModuleNamespaces.diagram}/${DiagramStore.mutations.updateLoading.name}`, false);
    store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.updateLoading.name}`, false);
    window.addEventListener('resize', this.sizeGridOnWindowResize);
    this.resizeGrid();
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.unselectAllArrangeRects.name}`);
  }

  private async findAssetAndSetSelected(): Promise<boolean> {
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
        action: async () =>
            store.dispatch(`${VuexModuleNamespaces.asset}/${AssetStore.actions.loadAssets.name}`),
        errorMsg: 'Error loading asset',
        routeHomeAfterError: true
      });

    const foundAsset =
        store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.findAsset.name}`](this.$route.params.id);
    if (!foundAsset) {
      this.$router.push({ name: 'NotFound' });
      return false;
    }
    store.commit(`${VuexModuleNamespaces.asset}/${AssetStore.mutations.selectAsset.name}`, foundAsset);
    (this.$refs.editorToolbar as EditorToolbar)?.getPublishedVersion();
    this.selectedAssetKey = foundAsset?.key;
    return true;
  }

  private async loadBurnersAndLockUntilBoundToGrid(): Promise<void> {
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => {
        await store.dispatch(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.actions.loadBurners.name}`);
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.updateLoading.name}`, false);
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.lockAll.name}`);
      },
      errorMsg: 'Error loading burners. ',
      routeHomeAfterError: true
    });
  }

  private async bindDiagram() {
    inputsTree.loadTree();
    store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.setIsLongTerm.name}`, true);

    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => {
        await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.bindAssetDiagram.name}`,
                             this.diagram);
        await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.initializeDiagramSaver.name}`);
        if (this.diagram?.locked) {
          this.showLockedModal();
          store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateIsGridFrozen.name}`, true);
        } else {
          store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateIsGridFrozen.name}`, false);
        }
        if (this.gridLayoutChanged) {
          this.showLayoutChangedModal();
        }
        if (this.isLayoutAssetVariablesChanged) {
          this.showLayoutAssetVariablesChangedModal();
        }
      }, errorMsg: 'Error loading and binding asset diagram. 1',
      routeHomeAfterError: false
    });
  }

  private async loadDiagram() {
    this.successfullyLoadedDiagram = false;
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => {
        this.diagram = await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.retrieveAssetDiagram.name}`, this.selectedAssetKey);
        this.successfullyLoadedDiagram = true;
      },
      errorMsg: 'Error loading and binding asset diagram. ',
      routeHomeAfterError: false
    });
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.sizeGridOnWindowResize);
  }

  private showLockedModal() {
    this.$bvModal.show('locked-modal');
  }
  private showLayoutChangedModal() {
    this.$bvModal.show('layout-changed-modal');
  }

  private showLayoutAssetVariablesChangedModal() {
    this.$bvModal.show('layout-asset-variables-changed-modal');
  }

  private trimGridOnScroll(): void {
    window.clearTimeout(this.isScrolling);
    this.isScrolling = window.setTimeout(() => {
      // Commenting this out v1.  Need to discuss desired behaviors
      // if we want to implement this feature. Possible to combine this
      // feature with zoom to fit? DBB 5/22/2020
      store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`);
    }, 500);
  }

  public redirectToUtilities() {
    if ((window as any).eftEventBus != null) {
      (window as any).eftEventBus.$emit('GLOBAL_ROUTE_CHANGE', this.utilitiesLink);
    } else {
      window.location.href = this.utilitiesLink;
    }
  }

  private sizeGridOnWindowResize(): void {
    window.clearTimeout(this.isResizing);
    this.isResizing = window.setTimeout(() => {
      this.resizeGrid();
    }, 200);
  }

  private resizeGrid(): void {
    const editorElement =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor');
    const toolbarElement =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor-toolbar-container');
    const gridElement: HTMLElement | null | undefined =
      document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('grid');
    if ( gridElement !== null && gridElement !== undefined ) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      const gridWidth = editorElement?.clientWidth ?? 1000;
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateEditorGridWidth.name}`,
                   gridWidth);
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateEditorGridHeight.name}`,
                   gridHeight);
      store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.drawGridLines.name}`, true);
    }
  }

  private returnHome() {
    if (this.isSavePending) {
      this.$bvModal.show('discard-save-modal');
    } else {
      store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
      // tslint:disable-next-line
      store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`);
      this.$router.push('/');
    }
  }

  private findAssetParentTypeName(currentNode: IAsset, typeName: string): string {
    const parent = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.findAssetParentType.name}`](currentNode, typeName);
    return parent ? parent.name : '';
  }

}
