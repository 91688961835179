

















import { Vue, Component, Watch, Emit, Prop, Model } from 'vue-property-decorator';
import Fuse from 'fuse.js';
import { eventBus } from '@/utils/event-bus';
import { newGuid } from '@/utils/string';
import inputsTree from '@/store/storeModules/inputs-tree';

@Component({
  name: 'object-search',
})
export default class ObjectSearch<T = Record<string, any>> extends Vue {

  @Prop({ required: true })
  public documents!: T[];
  @Prop({ default: () => ['name'] })
  public searchFields!: string[];
  @Prop({ default: null })
  public placeHolder!: string;
  @Model('input', { required: true, default: null })
  public searchResults!: string[];

  public uuid: string = newGuid();
  public searchString: string = '';

  private timeoutId: any = null;
  private fuseOptions: Fuse.IFuseOptions<T> = {
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 200,
    minMatchCharLength: 2,
    keys: [
      'name'
    ]
  };

  get clearVisible(): boolean {
    return this.searchString != null && this.searchString !== '';
  }

  public showFocus() {
    eventBus.$emit('calculator-clear-focus', true);
  }

  private mounted(): void {
    this.onSearchResultsChange([]);
    eventBus.$on('search-clicked', () => {
      this.$nextTick().then(() => {
        if (this.$refs.searchField != null) {
          const searchField: HTMLInputElement = this.$refs.searchField as HTMLInputElement;
          searchField.focus();
        }
      });
    });
  }

  private getResults(): void {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.setSearchResults, 400);
  }

  public setSearchResults(): void {
    inputsTree.setSearchString(this.searchString);
    if (this.documents != null) {
      const fuse = new Fuse(this.documents, this.fuseOptions);
      const results = fuse.search(this.searchString).map((row: any) => row.item);
      this.onSearchResultsChange(results);
    }
    this.timeoutId = null;
  }

  public clearSearch(): void {
    this.searchString = '';
    inputsTree.setSearchString(this.searchString);
  }

  @Watch('searchString')
  public onSearchStringChange(): void {
    this.getResults();
  }

  @Emit('input')
  public onSearchResultsChange(results: any[]): void {
    this.showEmptyResults(this.searchString.trim() !== '' && !results.length);
  }

  @Emit('showEmptyResults')
  public showEmptyResults(unused: boolean): void {
    // eslint-disable-next-line no-console
    console.log(unused);
  }
}
