import { IPortalAppSdk } from '../../../../../CommonLibrary/models/portal-parent-sdk';
import { ensureTrailing } from './string';
export * from '../../../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../../../CommonLibrary/modules/auth/auth0';

export function parentEventBus() {
  return (window as any).eftEventBus as Vue;
}

export function changeParentRoute(newParentRoute: string) {
  if (inParentApp()) {
    parentEventBus().$emit('GLOBAL_ROUTE_CHANGE', newParentRoute);
  } else {
    window.location.href = newParentRoute;
  }
}

export function inParentApp() {
  return parentEventBus() != null;
}

export const APP_TAG = 'asset-diagram' as const;

export function findAppBaseUrl() {
  const url = process.env.VUE_APP_BASE_URL ?? findAppScriptSourceOrigin();
  return ensureTrailing(url, '/');
}

export function findAppScriptSourceOrigin() {
  const el: HTMLScriptElement = document.getElementById(`${APP_TAG}-script`) as any;
  return ensureTrailing(el == null ? '' : new URL(el.src).origin, '/');
}

export function getParentAppSdk(): IPortalAppSdk {
  return document.getElementById(APP_TAG) as any;
}
