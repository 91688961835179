var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasNode)?_c('div',{class:['tree-node animate-in __scroll-container']},[_c('div',{class:[
      'tree-node__title',
      {
        'tree-node__title-wrapper': _vm.isNodeChildren
      },
      { 'is-selected': _vm.node.isSelected && !_vm.node.isLocked && _vm.node.isLeaf }
    ],on:{"mousedown":function($event){return _vm.selectCurrentNode(_vm.node, $event)}}},[(!_vm.node.isLeaf)?_c('icon-button',{staticClass:"tree-node__title-expand-icon",attrs:{"icon-class":_vm.toggleIconClass},on:{"click":function($event){return _vm.toggleNodeExpansion(_vm.node)}}}):_vm._e(),_c('div',{staticClass:"tree-node__outer-wrapper",attrs:{"draggable":_vm.node.isLeaf && !_vm.node.isLocked,"disabled":_vm.node.isLocked}},[_c('div',{staticClass:"tree-node__wrapper"},[_c('h5',{class:[
            'tree-node__title-name',
            'cut-off',
            { 'is-leaf': _vm.node.isLeaf },
            { 'is-locked': _vm.node.isLocked },
            { 'is-selected-header': _vm.node.isSelected && !_vm.node.isLocked && _vm.node.isLeaf }
          ],attrs:{"title":_vm.node.name}},[_vm._v(" "+_vm._s(_vm.node.name)+" ")])])])],1),(!_vm.node.isLeaf && _vm.node.isOpen)?_c('div',{staticClass:"tree-node__children"},[(!_vm.hasChildren)?_c('h5',{staticClass:"tree-node__children-message"},[_vm._v(" "+_vm._s(_vm.$t("variableSelector.noVariablesAvailable"))+" ")]):_vm._l((_vm.childNodes),function(childNode,index){return _c('tree-node',{key:((childNode.key) + "-" + index),attrs:{"node":childNode,"nodes-table":_vm.nodesTable}})})],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }