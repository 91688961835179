








































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import {
  adEventBus,
  adEvents,
} from '@/services/viewer/eventBus/asset-diagram-event-bus';

Vue.use(BootstrapVue);

@Component({
  name: 'comment-confirm-discard-modal',
})
export default class CommentConfirmDiscardModal extends Vue {
  public confirmDiscardComment() {
    this.$bvModal.hide('comment-confirm-discard-modal');
    adEventBus.$emit(adEvents.discardCommentConfirmed);
  }

  private cancel(): void {
    this.$bvModal.hide('comment-confirm-discard-modal');
  }
}
