









































































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store';
import { BurnerReadingViewerStore } from '@/store/viewer/burnerReading/burnerReadingStore';
import { BurnerLayoutViewerStore } from '@/store/viewer/burnerLayout/burnerLayoutStore';
import { IFuelLine, IAirRegister } from '@/view-models/viewer/burnerReading/burner-reading';
import Loading from '@/components/viewer/common/Loading.vue';
import HelperMethods from '@/shared/helper-methods';
import { convertHeatUnits } from '@/services/utils/heat-release-conversion';
import { IBurnerInfo } from '@/view-models/burnerReading/burner-reading';
import { FuelType } from '@/enums/DiagramApp';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';

Vue.use(BootstrapVue);

@Component({
  name: 'burner-readings-new',
  components: {
    Loading
  }
})
export default class BurnerReadingsNew extends Vue {

  public isLoading: boolean = false;
  private showTooltip: boolean = false;
  private burnerReadingsDetails: IBurnerInfo = {} as IBurnerInfo;
  private fuelType = FuelType;

  public async initializeSelection(selectedBurnerKey: string) {
    this.isLoading = true;
    // Fetching AirRegister and burner details. 
    this.burnerReadingsDetails = await store.dispatch(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.actions.getBurnerReadingsByKey.name}`,
                                                selectedBurnerKey);

    await store.dispatch(`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.actions.updateBurnerReadings.name}`,
                         this.burnerReadingsDetails);
    // Burner path                     
    await store.dispatch(`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.actions.getBurnerDetailBySelectedBurnerKey.name}`,
                         selectedBurnerKey);

    this.isLoading = false;
  }

  get additionalAirRegisters(): IAirRegister[] {
    return store.getters[
      `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.additionalAirRegisters.name}`
      ];
  }

  get stale() {
    return HelperMethods.isNullOrUndefined(store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getStaleRecommendationThreshold.name}`]);
  }

  get isInternalUser(): boolean {
    return !store.state.userViewer.currentUser.isCustomerUser;
  }

  get showRecommendationColor(): boolean {
    return this.emaBurnerDetail.opportunityScore > 0;
  }

 get receivedAssetKey(): string {
    return store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.receivedAssetKey.name}`];
  }

  get emberRecommendationsFlag(): boolean {
    const foundAsset =
      store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.findAsset.name}`](this.receivedAssetKey);
    if (foundAsset) {
      return foundAsset.showEmberRecommendations;
    }
    return false;
  }

  get showFields(): boolean {
    if (this.isInternalUser ||
     (!this.isInternalUser && this.stale && this.emberRecommendationsFlag)) {
      return true;
    }
    return false;
  }

  get fuelLines(): IFuelLine[] {
    let fuelLines: IFuelLine[] = [];
        this.emaBurnerDetail?.fuelLines?.forEach((fuelLineA: IFuelLine) => {
          if (fuelLineA.subType === FuelType.None) {
            fuelLines.push(fuelLineA);
          } else {
            // Check if threeway valve is already pushed or not.
            let fuelLine = fuelLines.find((item) => item.referenceKey === fuelLineA.referenceKey);
            if (!fuelLine) { fuelLines.push(this.getThreeWayValveFuelLine(fuelLineA)); }
          }});

    return fuelLines;
  }

  private getThreeWayValveFuelLine(fuelLine: IFuelLine) {
    let threeWayValveFuelLines: IFuelLine[] = this.emaBurnerDetail.fuelLines.filter((fuelLineX: IFuelLine) => fuelLine.referenceKey === fuelLineX.referenceKey);
    threeWayValveFuelLines = HelperMethods.sortLabelArray(threeWayValveFuelLines, 'sortOrder');
    return {
              name: threeWayValveFuelLines[0].name + ' / ' +  threeWayValveFuelLines[1].name,
              referenceKey:fuelLine.referenceKey,
              subType: FuelType.ThreeWayValve,
              isFiring: false,
              threeWayValveOnName: threeWayValveFuelLines[0].isOn ? threeWayValveFuelLines[0].name! : threeWayValveFuelLines[1].name!
            };
  }

  get heatReleaseUnits() {
    return store.getters[`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.selectedHeatUnit.name}`];
  }

  get heatReleaseValue() {
    return this.convertHeatValue(this.burnerReadingsDetails.heatRelease);
  }

  get fetchComplete(): boolean {
    return store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getIsFetchComplete.name}`];
  }

  get emaBurnerDetail() {
    return store.state.burnerReadingViewer.selectedBurnerDetails;
  }

  get hasAirRegisters(): boolean {
    let airRegisters =  store.getters[`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.airRegisters.name}`];
    airRegisters = airRegisters.filter((item: IAirRegister) => item.currentSetting !== -999999);
    return (airRegisters && airRegisters.length > 0) ? true : false;
  }

  get primaryAirRegister() {
    const airRegisters =  store.getters[`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.airRegisters.name}`];
    let primaryIndex = airRegisters.findIndex((item: IAirRegister) => item.isPrimary);
    if (!primaryIndex || primaryIndex < 0) { primaryIndex = 0;}
    return airRegisters.length ? airRegisters[primaryIndex] : null;
  }

  private getAirRegisterName(name: string): string {
    if (name.toLowerCase().includes('airregister') || name.toLowerCase().includes('air register')) {
      return name;
    } else {
      return name + ' ' + this.$t('burner.airRegister');
    }
  }

  private currentSettingValueByName(airRegisterName: string) {
    if (!this.emaBurnerDetail || !this.emaBurnerDetail.airRegisters) { return 0; }
    let airRegister = this.emaBurnerDetail?.airRegisters.find((ar) => ar.name === airRegisterName);
    return airRegister? airRegister.current : 0;
  }

   private convertHeatValue(heatInWatts: number): string {
    return convertHeatUnits({
      watts: heatInWatts,
      desiredUnit: this.heatReleaseUnits
    });
  }

}
