import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IHBBurnerListServiceResponse } from '@/view-models/burner/burner-response-view-models';

export class HierarchyBuilderService extends BaseService {

  constructor(axiosInstance: AxiosInstance, serviceApiUrl: string) {
    super(`${serviceApiUrl}`, axiosInstance);
  }

  public async getBurnerList(selectedAssetKey: string): Promise<IHBBurnerListServiceResponse> {
    const url = `heater/${selectedAssetKey}/burnertree`;
    return (await this.httpClient.get<IHBBurnerListServiceResponse>(url));
  }

}
