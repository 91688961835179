import { getAuthToken } from '@/services/auth/auth';
import { AppStore } from '@/store/app/appStore';
import { AssetStore } from '@/store/assetStore/assetStore';
import { UserStore } from '@/store/user/userStore';

const authInterceptor = async (config: any) => {
  const authorization = 'Authorization';
  const token = await getAuthToken();

  if (config.url.indexOf('s3.amazonaws.com') > -1) {
    return config;
  } else {
    config.headers[authorization] = `Bearer ${token}`;
    if (!config.baseURL.includes('hierarchy-builder')) {
      // Do not add auth token when uploading direct to S3
      const tokenData: any = JSON.parse(atob(token.split('.')[1]));
      const userId = tokenData['https://auth.onpointsolutions.com/claims/userKey'];
      const correlationId = 'OnPoint-Correlation-Id';
      const medaData = 'OnPoint-MetaData';
      const timeStamp = AppStore.state && AppStore.state.actionTime ? AppStore.state.actionTime : '';
      const metadata = {
        customerId: UserStore.state && UserStore.state.customerAssetInfo ?
          UserStore.state.customerAssetInfo.customerId : '',
        customerName: UserStore.state && UserStore.state.customerAssetInfo ?
          UserStore.state.customerAssetInfo.customerName : '',
        assetId: AssetStore.state && AssetStore.state.selectedAsset ? AssetStore.state.selectedAsset.key : '',
        assetName: AssetStore.state && AssetStore.state.selectedAsset ? AssetStore.state.selectedAsset.name : '',
      };
      config.headers[correlationId] = `LE|${userId}|${timeStamp}`;
      config.headers[medaData] = btoa(JSON.stringify(metadata));
    }
    return config;
  }
};

export default authInterceptor;
