

































import { Vue, Component } from 'vue-property-decorator';
import FolderItem from '@/components/common/FolderItem.vue';
import Loading from '@/components/common/Loading.vue';
import store, { VuexModuleNamespaces } from '@/store/';
import TreeNode from '@/components/editor/components/TreeNode.vue';
import ObjectSearch from '@/components/editor/components/ObjectSearch.vue';
import inputsTree from '@/store/storeModules/inputs-tree';
import { TreeTraverseHelper } from '@/utils/tree/tree-traverse-helper';
import { VariableTreeNode, VariableTreeNodeData } from '@/view-models/variables';
import { AssetStore } from '@/store/assetStore/assetStore';
import { Events, ISubscription, subscription } from '@/utils/event-bus';
import { Nil } from '@/utils/types';
import { elementId } from '@/utils/component-utils';
import { TreeFilterEnum } from '@/enums/variables';
import { VariableFolderStore } from '@/store/variableFolder/variableFolderStore';
import { adEventBus, adEvents } from '@/services/viewer/eventBus/asset-diagram-event-bus';

@Component({
  name: 'variable-selector',
  components: {
    FolderItem,
    Loading,
    TreeNode,
    ObjectSearch
  }
})
export default class VariableSelector extends Vue {

  public showEmptyResults: boolean = false;
  public objectSearchKey = 1;
  public noRecords: boolean = false;
  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;
  private leafSelectedSub: Nil<ISubscription> = null;
  private nodeToggledSub: Nil<ISubscription> = null;

  public get nodesList(): VariableTreeNode[] {
    return this.treeHelper.allNodes;
  }

  public get isLoadingTree(): boolean {
    return this.asset == null && inputsTree.loadingNodesTable;
  }

  get results(): any[] {
    return inputsTree.searchedNodeKeys || [];
  }

  set results(values: any[]) {
    inputsTree.setSearchedNodeKeys(values?.map(value=>value.key));
    if (values && values.length > 0) {
      inputsTree.openParentNodes(values[0].key);
    }
  }

  get asset() {
    return store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
  }

  public get nodesTable() {
    return inputsTree.filteredNodesTable;
  }

  public get searchedNodeTable() {
    return inputsTree.searchedNodesTable;
  }

  public get rootNode(): VariableTreeNode {
    return inputsTree.rootAssetNode!;
  }

  private get treeHelper(): TreeTraverseHelper<VariableTreeNodeData> {
    return new TreeTraverseHelper<VariableTreeNodeData>(this.nodesTable);
  }

  public get filteredNodesTable() {
    return this.results.length > 0 ? this.searchedNodeTable : this.nodesTable;
  }

  public clearAll() {
    store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearSelectedVariables.name}`);
  }

  private created() {
    adEventBus.$on(adEvents.deleteVariable, (variableKeys: string[]) => {
      variableKeys.forEach((variableKey) => {
        const selectedNode = inputsTree.nodeByKey(variableKey, this.nodesList);
        inputsTree.unlockVariableByKey({variableKey, selectedNode: selectedNode});
      });
    });
  }

  private async mounted() {
    this.leafSelectedSub = subscription(Events.VariableTreeNodeLeafSelected, this.onNodeSelected);
    this.nodeToggledSub = subscription(Events.VariableTreeNodeToggleIsOpen, this.onNodeToggledIsOpen);
    window.addEventListener('resize', this.resizeVariableSelectorMenu);
    this.resizeVariableSelectorMenu();
    store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.setVariableFolders.name}`, this.filteredNodesTable);
  }

  private updated() {
    this.noRecords = (Object.keys(this.nodesTable).length == 0 && Object.keys(this.searchedNodeTable).length == 0);
  }

  public onNodeSelected(unused: VariableTreeNode) {
    // eslint-disable-next-line no-console
    console.log(unused);
  }

  public selectFilter(val: TreeFilterEnum) {
    inputsTree.setSelectedTreeEnum(val);
  }

  public onNodeToggledIsOpen(node: VariableTreeNode) {
    inputsTree.toggleNodeIsOpen(node);
  }

  private resizeVariableSelectorMenu(): void {
    const variableSelectorMenu: HTMLElement | null | undefined =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('variable-selector');
    const editorElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor');
    const toolbarElement =
        document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('editor-toolbar-container');
    if ( variableSelectorMenu !== null && variableSelectorMenu !== undefined ) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      variableSelectorMenu.style.height = gridHeight + 'px';
    }
  }

  private destroyed() {
    this.clearAdEventBus();
  }

  private clearAdEventBus() {
    adEventBus.$off();
  }

}
