import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState, VuexModuleNamespaces } from '@/store';
import { burnerLayoutConfig } from '@/assets/configs/burnerLayoutConfig';
import { IBurnerVariableConfig } from '@/view-models/burnerLayout/burner-layout-view-models';
import { HeatUnit } from '@/services/utils/heat-release-conversion';
import { IViewerBurner, IFuelLine, IAirRegister } from '@/view-models/burnerReading/burner-reading';
import { IBurnerDetail } from '@/view-models/burner/burner-details-view-models';
import { BurnerFolder } from '@/view-models/burner/burner-folder-view-models';
import { IBurner, Burner } from '@/view-models/burner/burner-view-models';
import { ErrorStore } from '@/store/error/errorStore';
import { BurnerLayoutConfigEnum } from '@/enums/BurnerLayoutConfigEnum';

export type IBurnerLayoutContext = ActionContext<IBurnerLayoutStoreState, IRootState>;

export type IBurnerLayoutStoreGetters = GetterTree<IBurnerLayoutStoreState, IRootState>;

export interface IBurnerLayoutStoreState {
  layout: IBurnerVariableConfig[];
  additionalAirRegisters: IAirRegister[];
  selectedHeatUnit: HeatUnit;
  fuelLines: IFuelLine[];
  selectedBurnerDetail: IBurnerDetail;
}

export interface IBurnerColorUpdate {
  varName: string;
  toValue: string;
}

export interface IBurnerPositionUpdate {
  varName: string;
  toValue: number;
}

export interface IBurnerShowUpdate {
  varName: string;
  toValue: boolean;
}

export interface IBurnerLayoutStoreMutations extends MutationTree<IBurnerLayoutStoreState> {
  setBurnerLayout(state: IBurnerLayoutStoreState, newLayout: IBurnerVariableConfig[]): void;
  updateHeatUnit(state: IBurnerLayoutStoreState, selectedHeatUnit: HeatUnit): void;
}

export interface IBurnerLayoutStoreActions extends ActionTree<IBurnerLayoutStoreState, IRootState> {
  updateColor(burnerLayoutContext: IBurnerLayoutContext, target: IBurnerColorUpdate): void;
  updatePosition(burnerLayoutContext: IBurnerLayoutContext, target: IBurnerPositionUpdate): void;
  updateShow(burnerLayoutContext: IBurnerLayoutContext, burnerShowUpdate: IBurnerShowUpdate): void;
  updateBurnerReadings(burnerLayoutContext: IBurnerLayoutContext, burnerReadingsUpdate: IViewerBurner): Promise<void>;
  getBurnerDetailBySelectedBurnerKey(burnerLayoutContext: IBurnerLayoutContext,
                                     selectedBurnerKey: string): Promise<void>;
}

export const BurnerLayoutStore = {
  namespaced: true,
  state: {
    layout: burnerLayoutConfig,
    additionalAirRegisters: [],
    selectedHeatUnit: HeatUnit.MMBTU_HR,
    fuelLines: [],
    selectedBurnerDetail: {
      burnerName: '',
      burnerPath: '',
      key: ''
    }
  } as IBurnerLayoutStoreState,
  getters: {
    burnerLayout(burnerLayoutState: IBurnerLayoutStoreState): object {
      return burnerLayoutState.layout;
    },
    fuelLines(burnerLayoutState: IBurnerLayoutStoreState): object {
      return burnerLayoutState.fuelLines;
    },
    selectedHeatUnit(burnerLayoutState: IBurnerLayoutStoreState): HeatUnit {
      return burnerLayoutState.selectedHeatUnit;
    },
    selectedBurnerDetail(burnerLayoutState: IBurnerLayoutStoreState): IBurnerDetail {
      return burnerLayoutState.selectedBurnerDetail;
    },
    additionalAirRegisters(burnerLayoutState: IBurnerLayoutStoreState): IAirRegister[] {
      return burnerLayoutState.additionalAirRegisters;
    },
  } as IBurnerLayoutStoreGetters,
  mutations: {
    setBurnerLayout(state: IBurnerLayoutStoreState, newLayout: IBurnerVariableConfig[]): void {
      if (newLayout.find((burnerConfig) => burnerConfig.name !== BurnerLayoutConfigEnum.assetVariables )) {
          newLayout?.push({
            name: BurnerLayoutConfigEnum.assetVariables,
            show: false
          });
      }
      state.layout = newLayout;
    },
    updateHeatUnit(state: IBurnerLayoutStoreState, selectedHeatUnit: HeatUnit) {
      state.selectedHeatUnit = selectedHeatUnit;
    }
  } as IBurnerLayoutStoreMutations,
  actions: {
     updateColor(burnerLayoutContext: IBurnerLayoutContext, burnerColorUpdate: IBurnerColorUpdate) {
       const target = burnerLayoutContext.state.layout
         .find((x) => x.name === burnerColorUpdate.varName);
       if (target) {
         const swapTarget = burnerLayoutContext.state.layout
           .find((x) => x.color === burnerColorUpdate.toValue);
         const swapColor = target.color;
         target.color = burnerColorUpdate.toValue;
         if (swapTarget) {
           swapTarget.color = swapColor;
         }
       }
       burnerLayoutContext.commit('diagram/triggerStateChanged', null, { root: true });
     },
     updatePosition(burnerLayoutContext: IBurnerLayoutContext, burnerPositionUpdate: IBurnerPositionUpdate) {
       const target = burnerLayoutContext.state.layout
         .find((x) => x.name === burnerPositionUpdate.varName);
       const indexArrayMap = [
         {
           position: 'topLeft'
         },
         {
           position: 'topRight'
         },
         {
           position: 'center'
         },
         {
           position: 'bottomLeft'
         },
         {
           position: 'bottomRight'
         }
       ];
       const position = indexArrayMap[burnerPositionUpdate.toValue].position;
       if (target) {
         const swapTarget = burnerLayoutContext.state.layout
           .find((x) => x.position === position && x.name !== burnerPositionUpdate.varName);
         const swapPosition = target.position;
         target.position = position;
         if (swapTarget) {
           swapTarget.position = swapPosition;
         }
       }
       burnerLayoutContext.commit('diagram/triggerStateChanged', null, { root: true });
     },
     async updateBurnerReadings(burnerLayoutContext: IBurnerLayoutContext,
                                burnerReadingsUpdate: IViewerBurner): Promise<void> {
       burnerLayoutContext.state.layout.forEach((burnerReading) => {
         // Zero out burner reading and additional air registers
         burnerReading.value = undefined;
         burnerLayoutContext.state.additionalAirRegisters = [];
         // Update burner readings
         if (burnerReading.name === 'heatRelease') {
           burnerReading.value = burnerReadingsUpdate.heatRelease;
         } else if (burnerReading.name === 'outOfControl') {
           if (burnerReadingsUpdate.outOfControllableRange) {
             burnerReading.value = 1;
           } else {
             burnerReading.value = 0;
           }
         } else if (burnerReading.name === 'eqRatio') {
           burnerReading.value = burnerReadingsUpdate.equivalenceRatio;
         } else if (burnerReading.name === 'opportunity') {
           burnerReading.value = burnerReadingsUpdate.opportunityScore;
         } else if (burnerReading.name === 'recommendedAir') {
           if (burnerReadingsUpdate.airRegisters !== null &&
               burnerReadingsUpdate.airRegisters !== undefined &&
               burnerReadingsUpdate.airRegisters.length > 0) {
                 // If "primary" is in burnerReadingsUpdate, display this.  Else, display the 0th index item
                 let primaryIndex = 0;
                 burnerReadingsUpdate.airRegisters.forEach((airRegister, index) => {
                   if (airRegister.name.toUpperCase() === 'PRIMARY') {
                     primaryIndex = index;
                     return;
                   }
                 });
                 burnerReading.value = burnerReadingsUpdate.airRegisters[primaryIndex].recommendedSetting;
                 // Add the non-primary burners to the additional burners
                 burnerReadingsUpdate.airRegisters.forEach((airRegister, index) => {
                   if (index !== primaryIndex) {
                     burnerLayoutContext.state.additionalAirRegisters.push(airRegister);
                   }
                 });
           } else {
             burnerReading.value = undefined;
           }
         } else if (burnerReading.name === 'currentAir') {
           if (burnerReadingsUpdate.airRegisters !== null &&
               burnerReadingsUpdate.airRegisters !== undefined &&
               burnerReadingsUpdate.airRegisters.length > 0) {
                 // If "primary" is in burnerReadingsUpdate, display this.  Else, display the 0th index item
                 let primaryIndex = 0;
                 burnerReadingsUpdate.airRegisters.forEach((airRegister, index) => {
                   if (airRegister.name.toUpperCase() === 'PRIMARY') {
                     primaryIndex = index;
                     return;
                   }
                 });
                 burnerReading.value = burnerReadingsUpdate.airRegisters[primaryIndex].currentSetting;
                 // Add the non-primary burners to the additional burners
                 burnerReadingsUpdate.airRegisters.forEach((airRegister, index) => {
                   if (index !== primaryIndex) {
                     burnerLayoutContext.state.additionalAirRegisters.push(airRegister);
                   }
                 });
           } else {
             burnerReading.value = undefined;
           }
         }
       });
       if (burnerReadingsUpdate.fuelLines !== null && burnerReadingsUpdate.fuelLines !== undefined) {
         burnerLayoutContext.state.fuelLines = burnerReadingsUpdate.fuelLines;
       }
     },
     updateShow(burnerLayoutContext: IBurnerLayoutContext, burnerShowUpdate: IBurnerShowUpdate) {
       const target = burnerLayoutContext.state.layout
         .find((x) => x.name === burnerShowUpdate.varName);
       if (target) {
         target.show = burnerShowUpdate.toValue;
       }
       burnerLayoutContext.commit('diagram/triggerStateChanged', null, { root: true });
     },
     async getBurnerDetailBySelectedBurnerKey(burnerLayoutContext: IBurnerLayoutContext,
                                              selectedBurnerKey: string): Promise<void> {
        try {
          const selectedBurner: IBurner = Burner.getBurnerByKey(selectedBurnerKey);
          const burnerDetail: IBurnerDetail= {
            burnerName: '',
            burnerPath: '',
            key: selectedBurnerKey
          };
          burnerDetail.burnerName = selectedBurner.name;
          const burnersFolderKey = selectedBurner.emberHierarchyLevelKey;
          let path = '';
          if (burnersFolderKey) {
            const burnerPath: string[] = BurnerFolder.getBurnerFolderByEmberHierarchyLevelKey(burnersFolderKey);
            burnerPath.reverse();
            burnerPath.forEach((level)=> {
              path += level + '/';
            });
            path = path + selectedBurner.name;
          }
          burnerDetail.burnerPath = path;
          burnerLayoutContext.state.selectedBurnerDetail = burnerDetail;
        } catch (err) {
          const errorString = 'Error finding the selected burner. \n';
          await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.setError.name}`, {
            error: err,
            errorMessageString: errorString,
            handleError: false,
            routeHomeAfterError: false
          });
        }
    },
  } as IBurnerLayoutStoreActions
};
