



















































































import { Vue, Component } from 'vue-property-decorator';
import { IBurnerVariableConfig } from '@/view-models/burnerLayout/burner-layout-view-models';
import store, { VuexModuleNamespaces } from '@/store/';
import { BurnerLayoutStore } from '@/store/burnerLayout/burnerLayoutStore';
import { DiagramStore } from '@/store/diagram/diagramStore';
import { BootstrapVue } from 'bootstrap-vue';
import LiveDisplay from '@/components/common/LiveDisplay.vue';
import { adEventBus, adEvents } from '@/services/eventBus/asset-diagram-event-bus';
import { burnerLayoutConfig } from '@/assets/configs/burnerLayoutConfig';
import { BurnerLayoutConfigEnum } from '@/enums/BurnerLayoutConfigEnum';

Vue.use(BootstrapVue);

@Component({
  name: 'burner-layout',
  components: {
    LiveDisplay,
  },
})
export default class BurnerLayout extends Vue {
  get isDiagramLocked(): boolean {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isDiagramLocked.name}`];
  }

  get layout(): IBurnerVariableConfig[] {
    return store.getters[`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.getters.burnerLayout.name}`];
  }

  public positions = {
    topLeft: 'Top Left',
    topRight: 'Top Right',
    center: 'Center',
    bottomLeft: 'Bottom Left',
    bottomRight: 'Bottom Right',
  };

  public layoutDisplay = {
    heatRelease: 'Heat Release',
    outOfControl: 'Out Of Ctrl. Range Icon',
    eqRatio: 'Equivalence Ratio',
    opportunity: 'Opportunity',
    recommendedAir: 'Recommended Air Register',
    currentAir: 'Current Air Register',
    assetVariables: 'Asset Variables'
  };

  public colors = ['purple', 'green', 'orange', 'white', 'brown'];

  private store = store;
  private BurnerLayoutConfigEnum = BurnerLayoutConfigEnum;

   private get burnerLayoutConfig() {
    // Asset variables should be shown only if 'long-term-vaiables' is OFF and 'short-term-vaiables' is ON.
    return burnerLayoutConfig.filter((item) => item.name !== BurnerLayoutConfigEnum.assetVariables);
  }
  public availableColors(burnerLayoutName: string): string[] {
    return this.colors.filter((color: string) => {
      return color !== this.layout.find((x) => x.name === burnerLayoutName)?.color;
    });
  }

  public updateCheckBox(update: any): void {
    store.dispatch(
      `${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.actions.updateShow.name}`, update
    );
  }

  public availablePositions(burnerLayoutName: string): string[] {
    const positions: any[] = Object.entries(this.positions).map(([key, value], positionIndex) => {
      if (key !== this.layout.find((x) => x.name === burnerLayoutName)?.position) {
        return { name: value, index: positionIndex };
      } else {
        return null;
      }
    });

    return positions.filter((position: any) => position !== null);
  }

  public onColorChange(data: any) {
    if (!store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isDiagramLocked.name}`]) {
      store.dispatch(`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.actions.updateColor.name}`, {
        varName: data.varName,
        toValue: data.toValue,
      });
      adEventBus.$emit(adEvents.changedDisplay);
    }
  }

  public onPositionChange(data: any) {
    if (!store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isDiagramLocked.name}`]) {
      store.dispatch(`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.actions.updatePosition.name}`, {
        varName: data.varName,
        toValue: data.toValue,
      });
      adEventBus.$emit(adEvents.changedDisplay);
    }
  }
}
