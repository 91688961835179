import { AxiosInstance } from 'axios';
import BaseService from '@/services/viewer/common/base-service';

export default class EntityLogService extends BaseService {

  constructor(entityLogsApiBaseUrl: string, axios: AxiosInstance) {
    super(`${entityLogsApiBaseUrl}/aov`, axios);
  }

  public async flagCheck(parentEntityKey: string): Promise<string[]> {
    const url: string = `Logs/composite/${parentEntityKey}/flag-check`;
    return this.httpClient.get<string[]>(url);
  }
}
