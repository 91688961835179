import store, { IRootState, VuexModuleNamespaces } from '../../index';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { fabric } from 'fabric';
import { gridConfig } from '@/assets/configs/gridConfig';
import {
  IGridContents,
  ICanvasContents,
  IHorizontalSpaceline,
  IToolTipPosition,
  IVerticalSpaceline,
  IBurnerDisplayAttributes,
} from '@/view-models/viewer/assetDiagram/grid-view-models';
import { IBurner, Burner } from '@/view-models/viewer/burner/burner-view-models';
import { adEventBus, adEvents } from '@/services/viewer/eventBus/asset-diagram-event-bus';
import {
  IVariableInfo,
  IViewerBurner,
  PrimaryDisplayAttribute,
  ViewerBurnerOptionProperties,
} from '@/view-models/viewer/burnerReading/burner-reading';
import { BurnerReading } from '@/view-models/viewer/burnerReading/burner-reading-view-model';
import { convertHeatUnits, HeatUnit } from '@/services/viewer/utils/heat-release-conversion';
import { BurnerFolderViewerStore } from '@/store/viewer/burnerFolder/burnerFolderStore';
import ConfigFactory from '@/services/config/config';
import sharedAxiosInstance from '@/services/viewer/common/api-service';
import EntityLogService from '@/services/viewer/entityLogs/entity-log-service';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';
import { BurnerReadingViewerStore } from '@/store/viewer/burnerReading/burnerReadingStore';
import HelperMethods from '@/shared/helper-methods';
import { IAssetReportVariableViewModel } from '@/view-models/variables';
import { VariableFolderStore } from '@/store/variableFolder/variableFolderStore';
import { MeasurementConversion, UnitOfMeasurementEnum } from '@/enums/variables';
import { IZoomStatus } from '@/view-models/assetDiagram/grid-view-models';

export interface IPreviewGridStoreViewerState {
  previewGrid: fabric.Canvas;
  previousPreviewGridBoxSize: number;
  previewGridBoxSize: number;
  allVariables: IAssetReportVariableViewModel[];
}

export interface IPreviewGridStoreActions extends ActionTree<IPreviewGridStoreViewerState, IRootState> {
  alignTopLeft(gridContext: IPreviewGridContext): void;
  removeArrangeShapePadding(gridContext: IPreviewGridContext): void;
  resizeObjectsForPreview(gridContext: IPreviewGridContext, isWindowResize: boolean): void;
  setArrangeShapePadding(gridContext: IPreviewGridContext): void;
  setBurnerGroupMouseDownHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
  setBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
  setBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
  setBurnerPadding(gridContext: IPreviewGridContext): void;
  setCanvasBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
  setCanvasBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
  checkBurnerFlags(gridContext: IPreviewGridContext): void;
  toggleBurnerSelection(gridContext: IPreviewGridContext, selectedBurnerKey: string): void;
  trimGridToFit(gridContext: IPreviewGridContext): void;
  updateBurnerGroupDisplay(gridContext: IPreviewGridContext, burnerDisplayAttributes: IBurnerDisplayAttributes): void;
  updateVariableGroupDisplay(gridContext: IPreviewGridContext): void;
  updateGridContentsfromJSON(gridContext: IPreviewGridContext, gridContents: IGridContents): Promise<void>;
  zoomIn(gridContext: IPreviewGridContext): void;
  zoomOut(gridContext: IPreviewGridContext): void;
  getZoomStatus(gridContext: IPreviewGridContext): IZoomStatus;
}

export interface IPreviewGridStoreGetters extends GetterTree<IPreviewGridStoreViewerState, IRootState> {
  previewGrid(gridState: IPreviewGridStoreViewerState): fabric.Canvas;
  randomPreviewValue(gridState: IPreviewGridStoreViewerState): string;
  previewGridBoxSize(gridState: IPreviewGridStoreViewerState): number;
  // longTermFlag(): boolean;
}

export interface IPreviewGridStoreMutations extends MutationTree<IPreviewGridStoreViewerState> {
  updatePreviewGrid(gridState: IPreviewGridStoreViewerState, payload: fabric.Canvas): void;
  updatePreviewGridWidth(gridState: IPreviewGridStoreViewerState, newWidth: number): void;
  updatePreviewGridHeight(gridState: IPreviewGridStoreViewerState, newHeight: number): void;
  resetStore(gridState: IPreviewGridStoreViewerState): void;
  updatePreviewGridBoxSize(gridState: IPreviewGridStoreViewerState, newBoxSize: number): void;
}

export type IPreviewGridContext = ActionContext<IPreviewGridStoreViewerState, IRootState>;

const initEntityLogService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new EntityLogService(
    process.env.VUE_APP_ENTITY_LOGS_API_BASE_URL
      ? process.env.VUE_APP_ENTITY_LOGS_API_BASE_URL
      : conf.get('entityLogsUrl'),
    sharedAxiosInstance
  );
};

export function applyDisplayStylesToBurnerGroup(
  burnerGroup: fabric.Group,
  burnerDisplayAttributes: IBurnerDisplayAttributes
) {
  const currentViewerBurner = BurnerReading.currentBurnerReading.getBurner(burnerGroup.data.objectKey);
  const burnerGroupDisplayValue = getBurnerDisplayValue(burnerDisplayAttributes, currentViewerBurner);
  const flag = store.getters[ `${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getEmberRecommendationsFlag.name}`];
  const staleFlag = store.getters[ `${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getStaleRecommendationThreshold.name}`];
  const burnerGroupObjects = burnerGroup.getObjects();
  for (const burnerGroupObject of burnerGroupObjects) {
    applyToBurners(burnerGroupObject, burnerGroupDisplayValue, flag, staleFlag, burnerDisplayAttributes, currentViewerBurner);
  }
  burnerGroup.sendToBack();
}

export function applyToBurners(burnerGroupObject: fabric.Object, burnerGroupDisplayValue: string, flag: boolean, staleFlag: boolean, burnerDisplayAttributes: IBurnerDisplayAttributes, currentViewerBurner: IViewerBurner | undefined) {
  if (burnerGroupObject.type === 'text') {
    const burnerText = burnerGroupObject as fabric.Text;
    // get the burner text according to selected burner display attributes
    burnerText.text = String(burnerGroupDisplayValue);
    burnerText.fontFamily = gridConfig.previewGridFontFamily;
    fillColor(flag, burnerDisplayAttributes, burnerGroupObject, staleFlag, currentViewerBurner);
  } else if (burnerGroupObject.type === 'rect') {
    burnerGroupObject.stroke = 'rgba(0,0,0,0)';
    burnerGroupObject.fill = gridConfig.previewGridBoxColor;
    if (currentViewerBurner?.isBurnerOff()) {
      burnerGroupObject.fill = gridConfig.previewGridBoxOffColor;
    }
  }
}

export function applyToVariables(variableGroup: fabric.Object, variableGroupDisplayValue: IVariableInfo[]) {
  variableGroupDisplayValue.forEach(element => {
    if (element.variableKey === variableGroup.group?.data?.objectKey) {
      if (element.data !== null) {
        let variableString = '';
        switch (element.unitOfMeasurement) {

        case UnitOfMeasurementEnum.Percent:
          element.unitOfMeasurement = MeasurementConversion.Percent;
          break;
        case UnitOfMeasurementEnum.degC:
          element.unitOfMeasurement = MeasurementConversion.degC;
          break;
        case UnitOfMeasurementEnum.Bool:
          element.unitOfMeasurement = MeasurementConversion.Bool;
          break;
        case UnitOfMeasurementEnum.Unitless:
          element.unitOfMeasurement = MeasurementConversion.Unitless;
          break;
        }
        variableString = element.data + ' ' + element.unitOfMeasurement;
        if (variableGroup.type === 'text') {
          const variableText = variableGroup as fabric.Text;
          // get the variable text according to selected variable display attributes
          if (variableString.length > 10) {
            variableString = element.data.substring(element.data.length - 7) + '..';
          }
          variableText.text = variableString;
          variableText.fontFamily = gridConfig.previewGridFontFamily;
          variableGroup.fill = gridConfig.previewNoChangesRecommendedFontColor;
        } else if (variableGroup.type === 'rect') {
          variableGroup.stroke = 'rgba(0,0,0,0)';
          variableGroup.fill = gridConfig.previewGridBoxColor;
        }
      } else {
        if (variableGroup.type === 'text') {
          const variableText = variableGroup as fabric.Text;
          // get the variable text according to selected variable display attributes
          variableText.text = '-';
          variableText.fontFamily = gridConfig.previewGridFontFamily;
          variableGroup.fill = gridConfig.previewNoChangesRecommendedFontColor;
        } else if (variableGroup.type === 'rect') {
          variableGroup.stroke = 'rgba(0,0,0,0)';
          variableGroup.fill = gridConfig.previewGridBoxColor;
        }
      }
    }
  });
}

export function fillColor(flag: boolean, burnerDisplayAttributes: IBurnerDisplayAttributes, burnerGroupObject: fabric.Object, staleFlag: boolean, currentViewerBurner: IViewerBurner | undefined) {
  if (flag && burnerDisplayAttributes.burnerDisplay === PrimaryDisplayAttribute.CURRENT_AIR_REGISTER) {
    burnerGroupObject.fill = gridConfig.previewNoChangesRecommendedFontColor;
  } else if (!flag && burnerDisplayAttributes.burnerDisplay === PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER) {
    burnerGroupObject.fill = (currentViewerBurner?.isChangeRecommended()) ? gridConfig.previewChangesRecommendedFontColor : gridConfig.previewNoChangesRecommendedFontColor;
  }
  if (!HelperMethods.isNullOrUndefined(staleFlag) && store.state.userViewer.currentUser.isCustomerUser) {
    burnerGroupObject.fill = gridConfig.previewNoChangesRecommendedFontColor;
  }
}

export function getBurnerDropDownPropertyMap() {
  const result = new Map();

  result.set(PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER, ViewerBurnerOptionProperties.airRegister);
  result.set(PrimaryDisplayAttribute.CURRENT_AIR_REGISTER, ViewerBurnerOptionProperties.airRegister);
  result.set(PrimaryDisplayAttribute.OPPORTUNITY, ViewerBurnerOptionProperties.opportunity);
  result.set(PrimaryDisplayAttribute.EQUIVALENCE_RATIO, ViewerBurnerOptionProperties.equivalenceRatio);
  result.set(PrimaryDisplayAttribute.HEAT_RELEASE, ViewerBurnerOptionProperties.heatRelease);
  result.set(PrimaryDisplayAttribute.DRY_O2, ViewerBurnerOptionProperties.dryO2);
  result.set(PrimaryDisplayAttribute.WET_O2, ViewerBurnerOptionProperties.wetO2);

  return result;
}

export function calculateToolTipPosition(
  target: fabric.Group,
  gridBoxSize: number,
  toolTipSpan: HTMLSpanElement
): IToolTipPosition | undefined {
  const gridBoxWidth = gridBoxSize;
  const toolTipWidth = toolTipSpan.offsetWidth;
  const toolTipHeight = toolTipSpan.offsetHeight;
  const leftOffset = (toolTipWidth - gridBoxWidth) / 2;
  const grid: HTMLSpanElement | null | undefined = document
          ?.querySelector('#asset-diagram-viewer')
          ?.shadowRoot?.getElementById('preview-grid-container');
  if (target.top !== undefined && target.left !== undefined) {
    // Prevent tooltip from getting out of screen bounds
    let toolTipLeft = target.left - leftOffset;
    let toolTipTop;

   // if target left is at 10 then show tooltip at 10; padding a little bit
    // if target is at the right end, then set the tooltip left where its total width is just visible
    if (target.left <= 10) {
      toolTipLeft = 10;
    } else if (target.left + toolTipWidth > target.canvas!.getWidth()) {
      toolTipLeft = target.canvas!.getWidth() - toolTipWidth - 10;
    }
    // if target top is at 10 just drop the tooltip just below target with a 25 padding
      toolTipTop = (target.top - 30) > grid?.scrollTop! + 50 ? (target.top - toolTipHeight) : (target.top + toolTipHeight + 20);
    return { left: toolTipLeft, top: toolTipTop };
  }
  return undefined;
}

export function getBurnerDisplayValue(
  burnerDisplayAttributes: IBurnerDisplayAttributes,
  currentViewerBurner: IViewerBurner | undefined
): string {
  const burnerProperty: keyof IViewerBurner = getBurnerDropDownPropertyMap().get(burnerDisplayAttributes.burnerDisplay);
  const staleFlag = store.getters[ `${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getStaleRecommendationThreshold.name}`];

  if (!burnerProperty || !currentViewerBurner) {
    return '-';
  }

  if (!HelperMethods.isNullOrUndefined(staleFlag) && store.state.userViewer.currentUser.isCustomerUser && burnerDisplayAttributes.burnerDisplay !== PrimaryDisplayAttribute.CURRENT_AIR_REGISTER) {
    return '-';
  }

  let displayValue: string = '';

  if (burnerProperty === 'airRegisters') {
    if (burnerDisplayAttributes.burnerDisplay === PrimaryDisplayAttribute.CURRENT_AIR_REGISTER) {
      displayValue = currentViewerBurner.getBurnerReadingValue(burnerProperty, 'currentSetting');
    } else if (burnerDisplayAttributes.burnerDisplay === PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER) {
      displayValue = currentViewerBurner.getBurnerReadingValue(burnerProperty, 'recommendedSetting');
    }
  } else {
    displayValue = currentViewerBurner.getBurnerReadingValue(burnerProperty);
  }

  if (burnerDisplayAttributes.burnerDisplay === PrimaryDisplayAttribute.HEAT_RELEASE) {
    displayValue = convertHeatUnits({
      watts: displayValue,
      desiredUnit: burnerDisplayAttributes.heatReleaseUnit as HeatUnit,
    });
  }

  if (displayValue !== '') {
    if (burnerDisplayAttributes.burnerDisplay !== PrimaryDisplayAttribute.OPPORTUNITY && burnerDisplayAttributes.burnerDisplay !== PrimaryDisplayAttribute.DRY_O2 && burnerDisplayAttributes.burnerDisplay !== PrimaryDisplayAttribute.WET_O2) {
      displayValue = parseFloat(displayValue)
        .toFixed(2)
        .substring(0, 4);
    }
  } else {
    displayValue = '-';
  }
  return displayValue === '' ? '-' : displayValue;
}

export async function toggleBurnerFlag(burnerGroup: fabric.Group, isFlagged: boolean) {
  // Get burners.  If unknown burner.  Mark as unknown and return.  If known, remove unknown icon.
  const burner = store.getters[
    `${VuexModuleNamespaces.burnerFolderViewer}/${BurnerFolderViewerStore.getters.getBurnerByKey.name}`
  ](burnerGroup.data.objectKey);
  if (burner === undefined) {
    burnerGroup.visible = false;
  } else {
    burnerGroup.visible = true;
  }
  // Toggle source of flag check based on featuer flag
  if (isFlagged) {
    const flagSvgString =
    '<svg width="12px" height="12px" viewBox="0 0 24 24" fill="#00BFFF"><path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z" /></svg>';
    fabric.loadSVGFromString(flagSvgString, (objects, options) => {
      const loadedSVG = fabric.util.groupSVGElements(objects, options);
      loadedSVG.top = -20;
      loadedSVG.left = -20;
      loadedSVG.type = 'flag';
      // Add icon not already addded
      let hasIcon = false;
      burnerGroup.getObjects().forEach((object) => {
        if (object.type === 'flag') {
          hasIcon = true;
        }
      });
      if (!hasIcon) {
        burnerGroup.add(loadedSVG);
      }
    });
  } else {
    // If the group contained the flag and is no longer flagged then remove the flag.
    burnerGroup.getObjects().forEach((object) => {
      if (object.type === 'flag') {
        burnerGroup.remove(object);
      }
    });
  }
}

export const PreviewGridViewerStore = {
  namespaced: true as true,
  state: {
    previewGrid: new fabric.Canvas('', {}),
    previousPreviewGridBoxSize: gridConfig.previewGridBoxSize,
    previewGridBoxSize: gridConfig.previewGridBoxSize,
    allVariables: []
  } as IPreviewGridStoreViewerState,
  getters: {
    previewGrid(gridState: IPreviewGridStoreViewerState): fabric.Canvas {
      return gridState.previewGrid;
    },
    previewGridBoxSize(gridState: IPreviewGridStoreViewerState): number {
      return gridState.previewGridBoxSize;
    }
  },
  mutations: {
    updatePreviewGrid(gridState: IPreviewGridStoreViewerState, payload: fabric.Canvas) {
      gridState.previewGrid = payload;
    },
    updatePreviewGridWidth(gridState: IPreviewGridStoreViewerState, newWidth: number) {
      gridState.previewGrid.setWidth(newWidth);
    },
    updatePreviewGridHeight(gridState: IPreviewGridStoreViewerState, newHeight: number) {
      gridState.previewGrid.setHeight(newHeight);
    },
    resetStore(gridState: IPreviewGridStoreViewerState) {
      gridState.previewGrid = new fabric.Canvas('', {});
    },
    updatePreviewGridBoxSize(gridState: IPreviewGridStoreViewerState, newBoxSize: number) {
      gridState.previousPreviewGridBoxSize = gridState.previewGridBoxSize;
      gridState.previewGridBoxSize = newBoxSize;
    },
  },
  actions: {
    async checkBurnerFlags(gridContext: IPreviewGridContext): Promise<void> {
      const gridObjects = gridContext.state.previewGrid.getObjects();
      const entityLogService : EntityLogService = await initEntityLogService();
      const assetKey = store.getters[ `${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.receivedAssetKey.name}`];
      // Toggle source of flag check based on featuer flag
      const flaggedBurners = await entityLogService.flagCheck(assetKey);
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          const burnerGroup = gridObject as fabric.Group;
          if (flaggedBurners.indexOf(burnerGroup.data.objectKey) > -1) {
            await toggleBurnerFlag(burnerGroup, true);
          } else {
            await toggleBurnerFlag(burnerGroup, false);
          }
        }
      }
      gridContext.state.previewGrid.renderAll();
    },
    // async checkMaintenanceFlags(gridContext: IPreviewGridContext): Promise<void> {
    //   const gridObjects = gridContext.state.previewGrid.getObjects() as fabric.Group[];
    //   const assetKey = store.getters[ `${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.receivedAssetKey.name}`];
    //   const conf = await ConfigFactory.GetConfig();
    //   const service = new EMABurnerDetailsService(
    //     sharedAxiosInstance,
    //     conf.get('emaApiUrl')
    //   );
    //   const response = await service.getEMABurners(assetKey);

    //   let filteredBurners = [];
    //   for (const gridObject of response) {
    //     if (gridObject.operationType === 'Maintenance') {
    //       filteredBurners.push(gridObject.key);
    //     }
    //   }
    //   for (const grid of gridObjects) {
    //     if (grid.name === gridConfig.burnerGroupName && filteredBurners.indexOf(grid.data.objectKey) > -1) {
    //       await updateMaintenance(grid);
    //     }
    //   }
    //   gridContext.state.previewGrid.renderAll();
    // },
    toggleBurnerSelection(gridContext: IPreviewGridContext, selectedBurnerKey: string): void {
      const gridObjects = gridContext.state.previewGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          const burnerGroup = gridObject as fabric.Group;
          const burnerGroupObjects = burnerGroup.getObjects();
          if (burnerGroup.data) {
            for (const burnerGroupObject of burnerGroupObjects) {
              if (burnerGroupObject.type === 'rect') {
                burnerGroupObject.stroke =
                  burnerGroup.data.objectKey === selectedBurnerKey
                    ? gridConfig.burnerHighlightColor
                    : 'rgba(0,0,0,0)';
                // Stroke Uniform needs to be toggled as well in order for stroke to render
                burnerGroupObject.set('strokeUniform', burnerGroup.data.objectKey === selectedBurnerKey);
              }
            }
          }
        }
      }
      gridContext.state.previewGrid.renderAll();
    },
    setCanvasBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.canvas?.on('mouse:out', (event: fabric.IEvent) => {
        const target: fabric.Group = event.target as fabric.Group;
        // Set shadow
        if (target && target === burnerGroup) {
          target.setShadow();
          target.hoverCursor = 'default';
          burnerGroup.canvas?.renderAll();
        }
      });
    },
    setBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.on('mouseout', () => {
        // Set tooltip
        gridContext.state.previewGrid.renderAll();
        const toolTipSpan: HTMLSpanElement | null | undefined = document
          ?.querySelector('#asset-diagram-viewer')
          ?.shadowRoot?.getElementById('preview-grid-tooltip');
        if (toolTipSpan) {
          toolTipSpan.classList.remove('show');
        }
      });
    },
    setCanvasBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.canvas?.on('mouse:over', (event: fabric.IEvent) => {
        const target: fabric.Group = event.target as fabric.Group;
        // Set shadow
        if (target && target === burnerGroup) {
          target.setShadow('0px 2px 5px rgba(0, 0, 0, 0.3)');
          target.hoverCursor = 'pointer';
          burnerGroup.canvas?.renderAll();
        }
      });
    },
    setBurnerGroupMouseDownHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.on('mousedown', () => {
        if (burnerGroup.data) {
          adEventBus.$emit(adEvents.trySelectBurner, burnerGroup.data.objectKey);
        }
      });
    },
    setBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.on('mouseover', (event: fabric.IEvent) => {
        const target: fabric.Group = event.target as fabric.Group;
        // Set tooltip
        const burnerKey: string = event.target?.data.objectKey;
        const hoveredBurner: IBurner = Burner.getBurnerByKey(burnerKey);
        const toolTipSpan: HTMLSpanElement | null | undefined = document
          ?.querySelector('#asset-diagram-viewer')
          ?.shadowRoot?.getElementById('preview-grid-tooltip');
        if (target && target.name === gridConfig.burnerGroupName) {
          if (toolTipSpan) {
            if (hoveredBurner?.name) {
              toolTipSpan.innerHTML = hoveredBurner.name;
              const toolTipPosition: IToolTipPosition | undefined = calculateToolTipPosition(
                event.target as fabric.Group,
                gridContext.state.previewGridBoxSize,
                toolTipSpan
              );
              if (toolTipPosition) {
                toolTipSpan.style.left = toolTipPosition.left + 'px';
                toolTipSpan.style.top = toolTipPosition.top - 5 + 'px';
                toolTipSpan.classList.add('show');
              }
            }
          }
        } else if (target && target.name === gridConfig.variableGroupName) {
          const variableKey: string = event.target?.data.objectKey;
          const hoveredVariable: IVariableInfo[] = store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getVariableDetails.name}`];
          const hoveredVar: IAssetReportVariableViewModel = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKey.name}`](variableKey);
          let data = '';
          hoveredVariable.forEach(element => {
            if (variableKey === element.variableKey) {
              if (element.data !== null) {
                data = 'Variable Path: ' + hoveredVar.displayValues[0] + '<br/>Value: ' + element.data + ' ' + element.unitOfMeasurement;
              } else {
                data = 'Variable Path: ' + hoveredVar.displayValues[0] + '<br/>Value: ';
              }
            }
          });
          if (toolTipSpan) {
            if (data != null) {
              toolTipSpan.innerHTML = data;
              const toolTipPosition: IToolTipPosition | undefined = calculateToolTipPosition(
                event.target as fabric.Group,
                gridContext.state.previewGridBoxSize,
                toolTipSpan
              );
              if (toolTipPosition) {
                toolTipSpan.style.left = toolTipPosition.left + 'px';
                toolTipSpan.style.top = toolTipPosition.top - 5 + 'px';
                toolTipSpan.classList.add('show');
              }
            }
          }
        }
      });
    },
    async updateGridContentsfromJSON(gridContext: IPreviewGridContext, gridContents: IGridContents): Promise<void> {
      // Disable event listener for adding objects
      gridContext.state.previewGrid.off('object:added');
      // Reset the preview gridbox size
      gridContext.commit('updatePreviewGridBoxSize', gridConfig.previewGridBoxSize);
      // Load grid from JSON
      if (gridContents?.contents && gridContents.contents.objects?.length > 0) {
        gridContext.state.previewGrid.loadFromJSON(
          gridContents.contents,
          gridContext.state.previewGrid.renderAll.bind(gridContext.state.previewGrid)
        );
      } else {
        const emptyGrid: ICanvasContents = {
          version: '3.6.3',
          objects: [],
        };
        gridContext.state.previewGrid.loadFromJSON(
          emptyGrid,
          gridContext.state.previewGrid.renderAll.bind(gridContext.state.previewGrid)
        );
      }
      // Grab previous gridbox size
      gridContext.state.previousPreviewGridBoxSize = gridContents?.gridBoxSize ?? gridConfig.defaultGridBoxSize;
      // Pre process objects for preview
      const gridObjects = gridContext.state.previewGrid.getObjects();
      // Style, hide, move, and set click listeners
      for (const gridObject of gridObjects) {
        // Make everything unselectable
        gridObject.selectable = false;
        // Stop event propagation on all arrange shapes
        if (
          gridObject.name === gridConfig.arrangeShapeName ||
          gridObject.name === gridConfig.arrangeShapeSizeControlsName ||
          gridObject.name === gridConfig.arrangeShapeControlsName ||
          gridObject.name === gridConfig.tempArrangeShapeName ||
          gridObject.name === gridConfig.tempArrangeShapeSizeControlsName ||
          gridObject.name === gridConfig.tempArrangeShapeControlsName
        ) {
          gridObject.evented = false;
        }
        // Make spacelines invisible
        if (gridObject.name === gridConfig.arrangeShapeName && gridObject.type === 'line') {
          gridObject.opacity = 0;
          // Link burner group text to value, not burner name
        } else if (gridObject.name === gridConfig.burnerGroupName) {
          const burnerGroup = gridObject as fabric.Group;
          // Set click handlers for burner
          gridContext.dispatch('setBurnerGroupMouseDownHandler', burnerGroup);
          gridContext.dispatch('setBurnerGroupMouseOutHandler', burnerGroup);
          gridContext.dispatch('setBurnerGroupMouseOverHandler', burnerGroup);
          gridContext.dispatch('setCanvasBurnerGroupMouseOutHandler', burnerGroup);
          gridContext.dispatch('setCanvasBurnerGroupMouseOverHandler', burnerGroup);
          const flag = store.getters[ `${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getEmberRecommendationsFlag.name}`];
          applyDisplayStylesToBurnerGroup(burnerGroup, {
            burnerDisplay: flag ? PrimaryDisplayAttribute.CURRENT_AIR_REGISTER:
                                PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER,
            heatReleaseUnit: HeatUnit.MMBTU_HR,
          });
          // Hide label backgrounds and style label fonts
        } else if (gridObject.name === gridConfig.variableGroupName) {
          const variableExists: boolean = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKey.name}`](gridObject.data.objectKey);
          if (variableExists) {
            const variableGroup = gridObject as fabric.Group;
            const variableGroupObjects = variableGroup.getObjects();
            const hoveredVariable: IVariableInfo[] = store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getVariableDetails.name}`];
            if (hoveredVariable !== null) {
              for (const variableGroupElemen of variableGroupObjects) {
                applyToVariables(variableGroupElemen, hoveredVariable);
              }
              variableGroup.sendToBack();
              gridContext.dispatch('setBurnerGroupMouseOutHandler', variableGroup);
              gridContext.dispatch('setBurnerGroupMouseOverHandler', variableGroup);
              gridContext.dispatch('setCanvasBurnerGroupMouseOutHandler', variableGroup);
              gridContext.dispatch('setCanvasBurnerGroupMouseOverHandler', variableGroup);
            }
          } else {
              gridContext.state.previewGrid.remove(gridObject);
          }
          // Hide label backgrounds and style label fonts
        } else if (gridObject.name === gridConfig.labelName) {
          const labelGroup = gridObject as fabric.Group;
          const labelGroupObjects = labelGroup.getObjects();
          for (const labelGroupObject of labelGroupObjects) {
            if (labelGroupObject.type === 'i-text') {
              const labelText = labelGroupObject as fabric.IText;
              labelText.fontWeight = gridConfig.previewGridFontWeight;
              labelText.fontFamily = gridConfig.previewGridFontFamily;
            } else {
              labelGroupObject.opacity = 0;
            }
          }
        }
      }
      gridContext.state.previewGrid.renderAll();
      await gridContext.dispatch('checkBurnerFlags');
      //await gridContext.dispatch('checkMaintenanceFlags');

    },
    updateBurnerGroupDisplay(gridContext: IPreviewGridContext, burnerDisplayAttributes: IBurnerDisplayAttributes): void {
      const gridObjects = gridContext.state.previewGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.burnerGroupName) {
          const burnerGroup = gridObject as fabric.Group;
          applyDisplayStylesToBurnerGroup(burnerGroup, burnerDisplayAttributes);
        }
      }
      gridContext.state.previewGrid.renderAll();
    },
    updateVariableGroupDisplay(gridContext: IPreviewGridContext): void {
      const gridObjects = gridContext.state.previewGrid.getObjects();
      for (const gridObject of gridObjects) {
        if (gridObject.name === gridConfig.variableGroupName) {
          const variableExists: boolean = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKey.name}`](gridObject.data.objectKey);
          if (variableExists) {
            const variableGroup = gridObject as fabric.Group;
            const variableGroupObjects = variableGroup.getObjects();
            const hoveredVariable: IVariableInfo[] = store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getVariableDetails.name}`];
            if (hoveredVariable !== null) {
              for (const variableGroupElemen of variableGroupObjects) {
                applyToVariables(variableGroupElemen, hoveredVariable);
              }
            }
          }
        }
      }
      gridContext.state.previewGrid.renderAll();
    },
    resizeObjectsForPreview(gridContext: IPreviewGridContext, isWindowResize: boolean): void {
      // Adjust grid object size for groups

       let scaleFactor = gridContext.state.previewGridBoxSize / gridContext.state.previousPreviewGridBoxSize;
      if (isWindowResize) {
        scaleFactor = 1;
      }
      // Resize burner and label groups
      const gridGroups = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
      for (const gridObject of gridGroups) {
        if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'rect') {
              groupObject.set('height', gridContext.state.previewGridBoxSize);
              groupObject.set('width', gridObject.name === gridConfig.variableGroupName ? gridConfig.previewVariableGridBoxWidth : gridContext.state.previewGridBoxSize);
              groupObject.setCoords();
            } else if (groupObject.type === 'text') {
              const burnerText = groupObject as fabric.Text;
              const currentFontSize = burnerText.get('fontSize');
              if (currentFontSize) {
                burnerText.set('fontSize', currentFontSize * scaleFactor);
                burnerText.set('objectCaching', false);
                groupObject.setCoords();
              }
            }
          });
          const originalHeight = gridObject.get('height');
          const originalLeftCoord = gridObject.get('left');
          const originalTopCoord = gridObject.get('top');
          if (originalHeight !== undefined && originalLeftCoord !== undefined && originalTopCoord !== undefined) {
            const newLeftCoord =
              (originalLeftCoord / (originalHeight - gridConfig.burnerLineBorderWidth)) * gridContext.state.previewGridBoxSize;
            const newTopCoord =
              (originalTopCoord / (originalHeight - gridConfig.burnerLineBorderWidth)) * gridContext.state.previewGridBoxSize;
            gridObject.set('height', gridContext.state.previewGridBoxSize + gridConfig.burnerLineBorderWidth);
            gridObject.set('width', (gridObject.name === gridConfig.variableGroupName ? gridConfig.previewVariableGridBoxWidth : gridContext.state.previewGridBoxSize) + gridConfig.burnerLineBorderWidth);
            gridObject.set('left', newLeftCoord);
            gridObject.set('top', newTopCoord);
            gridObject.set('objectCaching', false);
            gridObject.setCoords();
          }
        } else if (gridObject.name === gridConfig.labelName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'i-text') {
              const labelText = groupObject as fabric.IText;
              const textWidthVal = labelText.get('width');
              const textHeightVal = labelText.get('height');
              const textLeftVal = labelText.get('left');
              const textTopVal = labelText.get('top');
              const fontSizeVal = labelText.get('fontSize');
              if (
                textWidthVal !== undefined &&
                textHeightVal !== undefined &&
                textLeftVal !== undefined &&
                textTopVal !== undefined &&
                fontSizeVal !== undefined
              ) {
                labelText.set({
                  width: textWidthVal * scaleFactor,
                  height: textHeightVal * scaleFactor,
                  left: textLeftVal * scaleFactor,
                  top: textTopVal * scaleFactor,
                  fontSize: fontSizeVal * scaleFactor,
                  objectCaching: false,
                });
                labelText.setCoords();
              }
            }
          });
          const heightVal = gridObject.get('height');
          const widthVal = gridObject.get('width');
          const leftVal = gridObject.get('left');
          const topVal = gridObject.get('top');
          if (heightVal !== undefined && widthVal !== undefined && leftVal !== undefined && topVal !== undefined) {
            gridObject.set({
              left: leftVal * scaleFactor,
              top: topVal * scaleFactor,
              width: widthVal * scaleFactor,
              height: heightVal * scaleFactor,
              objectCaching: false,
            });
            gridObject.setCoords();
          }
        }
      }
      // Adjust size for arrange shape lines
      const lineObjects = gridContext.state.previewGrid.getObjects('line') as fabric.Line[];
      for (const gridObject of lineObjects) {
        if (gridObject.name === gridConfig.arrangeShapeName || gridObject.name === gridConfig.tempArrangeShapeName) {
          const x1Val = gridObject.get('x1');
          const y1Val = gridObject.get('y1');
          const x2Val = gridObject.get('x2');
          const y2Val = gridObject.get('y2');
          const topVal = gridObject.get('top');
          const leftVal = gridObject.get('left');
          if (
            x1Val !== undefined &&
            x2Val !== undefined &&
            y1Val !== undefined &&
            y2Val !== undefined &&
            topVal !== undefined &&
            leftVal !== undefined
          ) {
            gridObject.set({
              x1: x1Val * scaleFactor,
              x2: x2Val * scaleFactor,
              y1: y1Val * scaleFactor,
              y2: y2Val * scaleFactor,
              top: topVal * scaleFactor,
              left: leftVal * scaleFactor,
            });
            gridObject.setCoords();
          }
        }
      }
      // Adjust size for arrange shape rects
      const rectObjects = gridContext.state.previewGrid.getObjects('rect') as fabric.Rect[];
      for (const gridObject of rectObjects) {
        if (gridObject.name === gridConfig.arrangeShapeName || gridObject.name === gridConfig.tempArrangeShapeName) {
          const sizeControlHeight = gridObject.get('height');
          const sizeControlWidth = gridObject.get('width');
          const sizeControlLeftCoord = gridObject.get('left');
          const sizeControlTopCoord = gridObject.get('top');
          if (
            sizeControlHeight !== null &&
            sizeControlHeight !== undefined &&
            sizeControlWidth !== null &&
            sizeControlWidth !== undefined &&
            sizeControlLeftCoord !== null &&
            sizeControlLeftCoord !== undefined &&
            sizeControlTopCoord !== null &&
            sizeControlTopCoord !== undefined
          ) {
            gridObject.set({
              width: sizeControlWidth * scaleFactor,
              height: sizeControlHeight * scaleFactor,
              left: sizeControlLeftCoord * scaleFactor,
              top: sizeControlTopCoord * scaleFactor,
            });
            gridObject.setCoords();
          }
        }
      }
      // Adjust size for arrange shape ellipses
      const ellipseObjects = gridContext.state.previewGrid.getObjects('ellipse') as fabric.Ellipse[];
      for (const gridObject of ellipseObjects) {
        if (gridObject.name === gridConfig.arrangeShapeName || gridObject.name === gridConfig.tempArrangeShapeName) {
          const sizeControlHeight = gridObject.get('ry');
          const sizeControlWidth = gridObject.get('rx');
          const sizeControlLeftCoord = gridObject.get('left');
          const sizeControlTopCoord = gridObject.get('top');
          if (
            sizeControlHeight !== null &&
            sizeControlHeight !== undefined &&
            sizeControlWidth !== null &&
            sizeControlWidth !== undefined &&
            sizeControlLeftCoord !== null &&
            sizeControlLeftCoord !== undefined &&
            sizeControlTopCoord !== null &&
            sizeControlTopCoord !== undefined
          ) {
            gridObject.set({
              rx: sizeControlWidth * scaleFactor,
              ry: sizeControlHeight * scaleFactor,
              left: sizeControlLeftCoord * scaleFactor,
              top: sizeControlTopCoord * scaleFactor,
            });
            gridObject.setCoords();
          }
        }
      }
      gridContext.dispatch('trimGridToFit');
      gridContext.dispatch('setArrangeShapePadding');
      gridContext.dispatch('setBurnerPadding');
      gridContext.state.previewGrid.renderAll();
    },
    setArrangeShapePadding(gridContext: IPreviewGridContext): void {
      // Remove existing arrange shape padding
      gridContext.dispatch('removeArrangeShapePadding');
      // Find all arrange shapes
      const horizontalSpacelines: IHorizontalSpaceline[] = [];
      const verticalSpacelines: IVerticalSpaceline[] = [];
      const lineObjects = gridContext.state.previewGrid.getObjects('line') as fabric.Line[];
      for (const gridObject of lineObjects) {
        if (gridObject.name === gridConfig.arrangeShapeName || gridObject.name === gridConfig.tempArrangeShapeName) {
          const x1Val = gridObject.get('x1');
          const y1Val = gridObject.get('y1');
          const x2Val = gridObject.get('x2');
          const y2Val = gridObject.get('y2');
          const topVal = gridObject.get('top');
          const leftVal = gridObject.get('left');
          const widthVal = gridObject.get('width');
          const heightVal = gridObject.get('height');
          if (
            x1Val !== undefined &&
            x2Val !== undefined &&
            y1Val !== undefined &&
            y2Val !== undefined &&
            topVal !== undefined &&
            leftVal !== undefined &&
            widthVal !== undefined &&
            heightVal !== undefined
          ) {
            if (y1Val === y2Val) {
              horizontalSpacelines.push({
                low: leftVal - widthVal / 2 - 0.1,
                high: leftVal + widthVal / 2 - 0.1,
                top: topVal,
                left: leftVal,
                y: topVal,
              });
            } else if (x2Val === x1Val) {
              verticalSpacelines.push({
                low: topVal - heightVal / 2 - 0.1,
                high: topVal + heightVal / 2 - 0.1,
                top: topVal,
                left: leftVal,
                x: leftVal,
              });
            }
          }
        }
      }
      // Adjust burners for spacelines
      const burnerObjects = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
      for (const gridObject of burnerObjects) {
        if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            const burnerGroupX = gridObject.get('left');
            const burnerGroupY = gridObject.get('top');
            if (burnerGroupX !== undefined && burnerGroupY !== undefined) {
              if (groupObject.type === 'rect') {
                let lineOnTop = false;
                let lineOnBottom = false;
                let lineOnLeft = false;
                let lineOnRight = false;
                horizontalSpacelines.forEach((horizontalSpaceline) => {
                  // Find spaceline on top of burner square
                  if (Math.abs(burnerGroupY - horizontalSpaceline.y) < 0.01) {
                    if (horizontalSpaceline.low <= burnerGroupX && burnerGroupX <= horizontalSpaceline.high) {
                      lineOnTop = true;
                    }
                  }
                  // Find spaceline on bottom of burner square
                  if (Math.abs(burnerGroupY + gridContext.state.previewGridBoxSize - horizontalSpaceline.y) < 0.01) {
                    if (horizontalSpaceline.low <= burnerGroupX && burnerGroupX <= horizontalSpaceline.high) {
                      lineOnBottom = true;
                    }
                  }
                });
                // Add horizontal padding
                if (lineOnTop && lineOnBottom) {
                  groupObject.set('height', gridContext.state.previewGridBoxSize - 2 * gridConfig.arrangeSpacePadSize);
                } else if (lineOnTop) {
                  groupObject.set('height', gridContext.state.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                  gridObject.set(
                    'top',
                    burnerGroupY + gridConfig.arrangeSpacePadSize - 2 * gridConfig.burnerLineBorderWidth
                  );
                } else if (lineOnBottom) {
                  groupObject.set('height', gridContext.state.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                  gridObject.set(
                    'top',
                    burnerGroupY - gridConfig.arrangeSpacePadSize + 2 * gridConfig.burnerLineBorderWidth
                  );
                }
                verticalSpacelines.forEach((verticalSpaceline) => {
                  // Find spaceline on left of burner square
                  if (Math.abs(burnerGroupX - verticalSpaceline.x) < 0.01) {
                    if (verticalSpaceline.low <= burnerGroupY && burnerGroupY <= verticalSpaceline.high) {
                      lineOnLeft = true;
                    }
                  }
                  // Find spaceline on right of burner square
                  if (Math.abs(burnerGroupX + gridContext.state.previewGridBoxSize - verticalSpaceline.x) < 0.01) {
                    if (verticalSpaceline.low <= burnerGroupY && burnerGroupY <= verticalSpaceline.high) {
                      lineOnRight = true;
                    }
                  }
                });
                // Add vertical padding
                if (lineOnLeft && lineOnRight) {
                  groupObject.set('width', gridContext.state.previewGridBoxSize - 2 * gridConfig.arrangeSpacePadSize);
                } else if (lineOnLeft) {
                  groupObject.set('width', gridContext.state.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                  gridObject.set(
                    'left',
                    burnerGroupX + gridConfig.arrangeSpacePadSize - 2 * gridConfig.burnerLineBorderWidth
                  );
                } else if (lineOnRight) {
                  groupObject.set('width', gridContext.state.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                  gridObject.set(
                    'left',
                    burnerGroupX - gridConfig.arrangeSpacePadSize + 2 * gridConfig.burnerLineBorderWidth
                  );
                }
              }
            }
          });
        }
      }
    },
    removeArrangeShapePadding(gridContext: IPreviewGridContext): void {
      const burnerObjects = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
      for (const gridObject of burnerObjects) {
        if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'rect') {
              groupObject.set('height', gridContext.state.previewGridBoxSize);
              groupObject.set('width', gridObject.name === gridConfig.variableGroupName ? gridConfig.previewVariableGridBoxWidth : gridContext.state.previewGridBoxSize);
              groupObject.setCoords();
            }
          });
          const currentTop = gridObject.get('top');
          const currentLeft = gridObject.get('left');
          if (currentTop !== undefined && currentLeft !== undefined) {
            const newTop = Math.round(currentTop / gridContext.state.previewGridBoxSize) * gridContext.state.previewGridBoxSize;
            const newLeft = Math.round(currentLeft / gridContext.state.previewGridBoxSize) * gridContext.state.previewGridBoxSize;
            gridObject.set('top', newTop);
            gridObject.set('left', newLeft);
            gridObject.setCoords();
          }
        }
      }
    },
    setBurnerPadding(gridContext: IPreviewGridContext): void {
      const burnerObjects = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
      for (const gridObject of burnerObjects) {
        if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          const groupObjects = gridObject.getObjects();
          groupObjects.forEach((groupObject) => {
            if (groupObject.type === 'rect') {
              const currentHeight = groupObject.get('height');
              const currentWidth = groupObject.get('width');
              if (
                currentHeight !== null &&
                currentHeight !== undefined &&
                currentWidth !== null &&
                currentWidth !== undefined
              ) {
                groupObject.set('height', currentHeight - 2);
                groupObject.set('width', currentWidth - 2);
                groupObject.setCoords();
              }
            }
          });
        }
      }
    },
    alignTopLeft(gridContext: IPreviewGridContext): void {
      // Find offset cords
      let lowestYCoord = gridContext.state.previewGrid.getHeight();
      let lowestXCoord = gridContext.state.previewGrid.getWidth();
      const gridObjects = gridContext.state.previewGrid.getObjects();
      for (const gridObject of gridObjects) {
        const leftCoord = gridObject.getBoundingRect().left;
        const topCoord = gridObject.getBoundingRect().top;
        if (leftCoord !== null && leftCoord !== undefined &&
            lowestXCoord !== null && lowestXCoord !== undefined &&
            leftCoord < lowestXCoord) {
          lowestXCoord = leftCoord;
        }
        if (topCoord !== null && topCoord !== undefined &&
            lowestYCoord !== null && lowestYCoord !== undefined &&
            topCoord < lowestYCoord) {
          lowestYCoord = topCoord;
        }
      }
      // Offset objects
      for (const gridObject of gridObjects) {
        const currentTop = gridObject.top;
        const currentLeft = gridObject.left;
        if (currentTop !== null && currentTop !== undefined &&
            currentLeft !== null && currentLeft !== undefined &&
            lowestYCoord !== null && lowestYCoord !== undefined &&
            lowestXCoord !== null && lowestXCoord !== undefined) {
          // Set group coords
          gridObject.top = currentTop - lowestYCoord;
          gridObject.left = currentLeft - lowestXCoord;
          gridObject.setCoords();
        }
      }
      // Render
      gridContext.state.previewGrid.renderAll();
    },
    trimGridToFit(gridContext: IPreviewGridContext): void {
      // Adjust grid size from objects
      const gridElement:HTMLElement | null | undefined = document
        ?.querySelector('#asset-diagram-viewer')
        ?.shadowRoot?.getElementById('preview-grid-container');

      let highestYCoord = 0;
      let highestXCoord = 0;
      if (gridElement !== null && gridElement !== undefined) {
        let desiredGridHeight = gridElement.offsetHeight;
        let desiredGridWidth = gridElement.offsetWidth;
      const gridObjects = gridContext.state.previewGrid.getObjects();
      for (const gridObject of gridObjects) {
        let leftCoord = gridObject.get('left');
        let topCoord = gridObject.get('top');
        // Add width/height to rect
        if (gridObject.type === 'rect') {
          const objectWidth = (gridObject as fabric.Rect).get('width');
          const objectHeight = (gridObject as fabric.Rect).get('height');
          const scaleX = (gridObject as fabric.Rect).get('scaleX');
          const scaleY = (gridObject as fabric.Rect).get('scaleY');
          if (leftCoord !== null && leftCoord !== undefined &&
              objectWidth !== null && objectWidth !== undefined &&
              topCoord !== null && topCoord !== undefined &&
              objectHeight !== null && objectHeight !== undefined &&
              scaleX && scaleY) {
            leftCoord = leftCoord + ((objectWidth * scaleX) / 2);
            topCoord = topCoord + ((objectHeight * scaleY) / 2);
          }
        }
        // Add width/height to ellipse
        if (gridObject.type === 'ellipse') {
          const objectWidth = (gridObject as fabric.Ellipse).get('width');
          const objectHeight = (gridObject as fabric.Ellipse).get('height');
          const scaleX = (gridObject as fabric.Ellipse).get('scaleX');
          const scaleY = (gridObject as fabric.Ellipse).get('scaleY');
          if (leftCoord !== null && leftCoord !== undefined &&
              objectWidth !== null && objectWidth !== undefined &&
              topCoord !== null && topCoord !== undefined &&
              objectHeight !== null && objectHeight !== undefined &&
              scaleX && scaleY) {
            leftCoord = leftCoord + ((objectWidth * scaleX) / 2);
            topCoord = topCoord + ((objectHeight * scaleY) / 2);
          }
        }
        if (leftCoord !== null && leftCoord !== undefined &&
            leftCoord > highestXCoord) {
          highestXCoord = leftCoord;
        }
        if (topCoord !== null && topCoord !== undefined &&
            topCoord > highestYCoord) {
          highestYCoord = topCoord;
        }
      }
      if (desiredGridWidth < highestXCoord) {
        desiredGridWidth = highestXCoord;
      }
      if (desiredGridHeight < highestYCoord) {
        desiredGridHeight = highestYCoord;
      }
      // Add two squares to provide a buffer
      gridContext.commit('updatePreviewGridWidth', desiredGridWidth +  (2 * gridContext.state.previewGridBoxSize));
      gridContext.commit('updatePreviewGridHeight', desiredGridHeight + (2 * gridContext.state.previewGridBoxSize));
      gridContext.state.previewGrid.renderAll();
    }
    },
    zoomIn(gridContext: IPreviewGridContext): void {
      gridContext.state.previewGrid.discardActiveObject();
      const newBoxSize =
        gridContext.getters.previewGridBoxSize * (1 + gridConfig.zoomFactor);
      gridContext.commit('updatePreviewGridBoxSize', newBoxSize);
       gridContext.dispatch('resizeObjectsForPreview', false);
      gridContext.state.previewGrid.renderAll();
    },
    zoomOut(gridContext: IPreviewGridContext): void {
      gridContext.state.previewGrid.discardActiveObject();
      const newBoxSize =
        gridContext.getters.previewGridBoxSize * (1 - gridConfig.zoomFactor);
      gridContext.commit('updatePreviewGridBoxSize', newBoxSize);
       gridContext.dispatch('resizeObjectsForPreview', false);
      gridContext.state.previewGrid.renderAll();
    },
    getZoomStatus(gridContext: IPreviewGridContext): IZoomStatus {
      const nextZoomInValue =
        gridContext.getters.previewGridBoxSize * (1 + gridConfig.zoomFactor);
      const nextZoomOutValue =
        gridContext.getters.previewGridBoxSize * (1 - gridConfig.zoomFactor);
      const maxBoxSize =
        gridConfig.defaultGridBoxSize * gridConfig.maxZoomMagnification;
      const minBoxSize =
        gridConfig.defaultGridBoxSize / gridConfig.minZoomMagnification;
      return {
        zoomInDisabled: nextZoomInValue >= maxBoxSize ? true : false,
        zoomOutDisabled: nextZoomOutValue <= minBoxSize ? true : false,
        gridBoxSize: gridContext.getters.previewGridBoxSize,
      };
    },
  },
};
