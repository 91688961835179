import Vue from 'vue';

export const adEventBus = new Vue();

export const adEvents = {
  // Modals
  discardCommentConfirmed: 'discard-comment-confirmed',

  // Dropdown menu
  changedDisplay: 'changedDisplay',

  // Burner selection,
  isBurnerSelected: 'is-burner-selected',
  deselectBurner: 'deselectBurner',
  trySelectBurner: 'trySelectBurner',
  selectBurnerConfirmed: 'selectBurnerConfirmed',
  selectBurner: 'selectBurner',

  // Side Menu
  tryCloseSideMenu: 'tryCloseSideMenu',
  closeSideMenu: 'closeSideMenu',

  // Variable
  deleteVariable: 'deleteVariable'
};
