







import Vue from 'vue';
import VueRouter from 'vue-router';
import { Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import Error from './components/viewer/modals/Error.vue';
import { authGuard } from './services/auth/auth';
import Loading from './components/common/Loading.vue';
import i18n from '@/i18n';
import AssetDiagramBuilder from './AssetDiagramBuilder.vue';
import AssetDiagramViewer from './AssetDiagramViewer.vue';
import { LayoutApp } from '@/enums/DiagramApp';

Vue.use(BootstrapVue);

export const assetDiagramRouter = new VueRouter({
  mode: 'history'
});

assetDiagramRouter.beforeEach(authGuard);

@Component({
  name: 'app',
  components: {
    Error,
    Loading,
    AssetDiagramViewer,
    AssetDiagramBuilder
  },
  router: assetDiagramRouter,
  i18n
})
export default class AssetDiagram extends Vue {
  // VUE.JS Props
  @Prop({ required: true, default: '' })
  public assetkey!: string;

  @Prop({required: true, default: ''})
  public timestamp!: string;

  @Prop({ required: true })
  public customerAssetDetails!: string;

  @Prop({required: true, default: ''})
  public diagramApp!: string;

  public layoutApp = LayoutApp;

  private deactivated() {
    this.$destroy();
  }

}
