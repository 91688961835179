import { IFolder, IFolderItem } from '@/view-models/common/folder-view-models';
import { IAsset } from './assets-view-models';
import store, { VuexModuleNamespaces } from '../../store';
import { AssetStore } from '@/store/assetStore/assetStore';

export class AssetFolder implements IFolder {
  public isOpen: boolean = true;
  public key: string;
  public parentKey: string;
  public name: string;
  public type: string;
  public childFolders: AssetFolder[] = [];

  public get isSelected(): boolean {
    return false;
  }
  public get childCount(): number {
    return this.childFolders.length;
  }
  public get visibleChildFolders(): IFolder[] {
    return this.childFolders;
  }
  public get isVisible(): boolean {
    return this.type !== 'AssetGroup';
  }
  public get isLink(): boolean {
    return (this.type === 'Heater') || (this.type === 'Tower');
  }
  public get isDraggable(): boolean {
    return false;
  }
  public get visibleChildItems(): IFolderItem[] {
    return [];
  }
  public get isActive(): boolean {
    return false;
  }
  public get isReadonly(): boolean {
    return (this.type !== 'Heater') && (this.type !== 'Tower');
  }
  public get isExpandable(): boolean {
    return (this.type !== 'Heater') && (this.type !== 'Tower');
  }
  get someChildrenSelected(): boolean {
    return false;
  }
  public get childItemCount(): number {
    return 0;
  }
  public get allCount(): number {
    return 0;
  }

  constructor(
    data: IAsset
  ) {
      this.name = data.name;
      this.parentKey = data.parentKey || '';
      this.key = data.key;
      this.type = data.type;
    }

    public selectSelf(): void {
      const selectedAsset: IAsset[] =
          store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.allAssets.name}`].find(
            (asset: IAsset) => asset.key === this.key
          );
      store.commit(`${VuexModuleNamespaces.asset}/${AssetStore.mutations.selectAsset.name}`, selectedAsset);
    }
  }
