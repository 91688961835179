import { IAsset } from '@/view-models/asset/assets-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '@/store';
import sharedAxiosInstance from '@/services/common/api-service';
import { AssetBurnerService } from '@/services/asset/asset-burner-service';
import ConfigFactory from '@/services/config/config';

export interface IAssetStoreViewerState {
  assets: IAsset[];
  selectedAsset: undefined | IAsset;
  receivedAssetKey: string; // This is the string received from the parent app (if present)
}

export interface IAssetStoreGetters
  extends GetterTree<IAssetStoreViewerState, IRootState> {
  allAssets(state: IAssetStoreViewerState): IAsset[];
  receivedAssetKey(state: IAssetStoreViewerState): string;
  childAssets(state: IAssetStoreViewerState, parentKey: undefined | string): IAsset[];
  selectedAsset(state: IAssetStoreViewerState): null | IAsset;
  findAssetParentType(state: IAssetStoreViewerState): (currentNode: IAsset, typeName: string) => IAsset | undefined;
  findAsset(state: IAssetStoreViewerState): (assetKey: string) => IAsset | undefined;
}

export interface IAssetStoreMutations extends MutationTree<IAssetStoreViewerState> {
  selectAsset(state: IAssetStoreViewerState, selected: IAsset): void;
  updateReceivedAssetKey(state: IAssetStoreViewerState, newReceivedAssetKey: string): void;
}

export interface IAssetStoreActions
  extends ActionTree<IAssetStoreViewerState, IRootState> {
  loadAssets(context: IAssetContext): void;
}

export type IAssetContext = ActionContext<IAssetStoreViewerState, IRootState>;

const findParentType = (state: IAssetStoreViewerState, currentNode: IAsset, typeName: string): IAsset | undefined => {
  let returnValue: IAsset | undefined;
  if (currentNode.type === typeName) {
    returnValue = currentNode;
  } else {
    if (currentNode.parentKey !== undefined) {
      const parentAsset = state.assets.find(
        (a: IAsset) => a.key === currentNode.parentKey
      );
      if (parentAsset !== undefined) {
        returnValue = findParentType(state, parentAsset, typeName);
      }
    }
  }
  return returnValue;
};

export const AssetViewerStore = {
  namespaced: true as true,
  state: {
    assets: new Array<IAsset>(),
    receivedAssetKey: '',
    selectedAsset: {},
  } as IAssetStoreViewerState,
  getters: {
    allAssets(state: IAssetStoreViewerState): null | IAsset[] {
      return state.assets;
    },
    childAssets(state: IAssetStoreViewerState, parentKey: undefined | string) {
      // Get child assets for the specified parent
      // To get the root level assets, pass in undefined for the parent key.
      if (state.assets) {
        return state.assets
          .filter((asset) => asset.parentKey === parentKey)
          .sort((asset) => asset.orderIndex);
      } else {
        return Array<IAsset>();
      }
    },
    selectedAsset(state: IAssetStoreViewerState): IAsset | undefined {
      return state.selectedAsset;
    },
    receivedAssetKey(state: IAssetStoreViewerState): string {
      return state.receivedAssetKey;
    },
    findAssetParentType: (state: IAssetStoreViewerState) => (currentNode: IAsset, typeName: string): IAsset | undefined => {
      return findParentType(state, currentNode, typeName);
    },
    findAsset: (state: IAssetStoreViewerState) => (assetKey: string): IAsset | undefined => {
      return state.assets.find((a: IAsset) => a.key === assetKey);
    },
  } as IAssetStoreGetters,
  mutations: {
    selectAsset(state: IAssetStoreViewerState, selected: IAsset): void {
      state.selectedAsset = selected;
    },
    updateReceivedAssetKey(state: IAssetStoreViewerState, newReceivedAssetKey: string): void {
      state.receivedAssetKey = newReceivedAssetKey;
    },
  } as IAssetStoreMutations,
  actions: {
    async loadAssets(context: IAssetContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const assetBurnerService = new AssetBurnerService(
        sharedAxiosInstance,
        process.env.VUE_APP_KES_PORTAL_API_BASE_URL
          ? process.env.VUE_APP_KES_PORTAL_API_BASE_URL
          : conf.get('umApiUrl')
      );
      const response = await assetBurnerService.getCustomerAssets();
      context.state.assets = response;
    },
  } as IAssetStoreActions,
};
