import {
  IBurnerFolder,
  BurnerFolder,
} from '@/view-models/viewer/burner/burner-folder-view-models';
import { IBurner } from '@/view-models/viewer/burner/burner-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState, VuexModuleNamespaces } from '../../index';
import sharedAxiosInstance from '@/services/viewer/common/api-service';
import ConfigFactory from '@/services/config/config';
import { IAsset } from '@/view-models/viewer/asset/assets-view-models';
import {
  IBurnerListServiceResponse,
  IHBBurnerListServiceResponse,
} from '@/view-models/viewer/burner/burner-response-view-models';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';
import { HierarchyBuilderService } from '@/services/hb/hb-service';

export interface IBurnerFolderStoreViewerState {
  burnerFolders: IBurnerFolder[];
  burners: IBurner[];
  lastSelected: IBurnerFolder | undefined;
  loading: boolean;
  burnerListResponse:
    | IBurnerListServiceResponse
    | IHBBurnerListServiceResponse
    | undefined;
}

export interface IBurnerFolderStoreGetters
  extends GetterTree<IBurnerFolderStoreViewerState, IRootState> {
  allBurners(state: IBurnerFolderStoreViewerState): IBurner[];
  allBurnerFolders(state: IBurnerFolderStoreViewerState): IBurnerFolder[];
  rootFolders(state: IBurnerFolderStoreViewerState): IBurnerFolder[];
  notLockedCount(state: IBurnerFolderStoreViewerState): number;
  selectedCount(state: IBurnerFolderStoreViewerState): number;
  isLoading(state: IBurnerFolderStoreViewerState): boolean;
  getBurnerByKey(
    state: IBurnerFolderStoreViewerState
  ): (key: string) => IBurner | undefined;
  getBurnersByKeys(
    state: IBurnerFolderStoreViewerState
  ): (keys: string[]) => IBurner[];
  getBurnerFolderByEmberHierarchyLevelKey(
    state: IBurnerFolderStoreViewerState
  ): (key: string) => string[];
}

export interface IBurnerFolderStoreMutations
  extends MutationTree<IBurnerFolderStoreViewerState> {
  setBurners(state: IBurnerFolderStoreViewerState, burners: IBurner[]): void;
  selectSelf(state: IBurnerFolderStoreViewerState, selected: IBurnerFolder): void;
  selectAll(state: IBurnerFolderStoreViewerState): void;
  selectRange(state: IBurnerFolderStoreViewerState, end: IBurnerFolder): void;
  clearAll(state: IBurnerFolderStoreViewerState): void;
  lockAll(state: IBurnerFolderStoreViewerState): void;
  unlockAll(state: IBurnerFolderStoreViewerState): void;
  updateLoading(state: IBurnerFolderStoreViewerState, isLoading: boolean): void;
}

export interface IBurnerFolderStoreActions
  extends ActionTree<IBurnerFolderStoreViewerState, IRootState> {
  loadBurners(context: IBurnerFolderContext): Promise<void>;
  parseBurnerTree(
    context: IBurnerFolderContext,
    loaded: IBurnerListServiceResponse
  ): Promise<void>;
}

export type IBurnerFolderContext = ActionContext<
  IBurnerFolderStoreViewerState,
  IRootState
>;

// Takes the folder to apply the change to.
// Applies the change and then checks to see if there are any
// children that need to be recursed and applies it to them as well.
// If the loop should be exited, action will return true
// (hence the use of some instead of foreach).
function recurseApply(folder: IBurnerFolder, action: (f: IBurnerFolder) => boolean) {
  if (!action(folder)) {
    if (folder.children && folder.children.length > 0) {
      folder.children.some((child) => recurseApply(child, action));
    }
  }
}

function recurseLevels(
  hierarchy: any,
  key: string,
  path: string[],
  action: (f: IBurnerFolder) => boolean
) {
  if (action(hierarchy)) {
    hierarchy.forEach(
      (level: { key: string; name: string; parentKey: string }) => {
        if (level.key === key) {
          path.push(level.name);
          if (level.parentKey) {
            recurseLevels(hierarchy, level.parentKey, path, action);
          }
        }
      }
    );
  }
}

export const BurnerFolderViewerStore = {
  namespaced: true as true,
  state: {
    burnerFolders: Array<IBurnerFolder>(),
    burners: [],
    lastSelected: undefined,
    loading: false,
    burnerListResponse: undefined,
  } as IBurnerFolderStoreViewerState,
  getters: {
    allBurners(state: IBurnerFolderStoreViewerState): IBurner[] {
      return state.burners;
    },
    isLoading(state: IBurnerFolderStoreViewerState): boolean {
      return state.loading;
    },
    getBurnerByKey: (state: IBurnerFolderStoreViewerState) => (key: string): IBurner | undefined => {
      return state.burners.find((burner) => burner.key === key);
    },
    getBurnersByKeys: (state: IBurnerFolderStoreViewerState) => (keys: string[]): IBurner[] => {
      const selectedBurners: IBurner[] = [];
      state.burners.forEach((burner) => {
        if (keys.includes(burner.key)) {
          selectedBurners.push(burner);
        }
      });
      return selectedBurners;
    },
    getBurnerFolderByEmberHierarchyLevelKey: (state: IBurnerFolderStoreViewerState) => (key: string): string[] => {
      const path: string[] = [];
      recurseLevels(
        state.burnerListResponse?.emberHierarchyLevels,
        key,
        path,
        () => {
          return true;
        }
      );
      return path;
    },
    allBurnerFolders(state: IBurnerFolderStoreViewerState): null | IBurnerFolder[] {
      return state.burnerFolders;
    },
    rootFolders(state: IBurnerFolderStoreViewerState): IBurnerFolder[] {
      return state.burnerFolders
        .filter((folder) => !folder.parentKey)
        .sort((a, b) => (a.name! < b.name! ? -1 : 1));
    },
    selectedCount(state: IBurnerFolderStoreViewerState): number {
      let count = 0;
      state.burnerFolders
        .filter((folder) => folder.parentKey === '')
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (item.isSelected) {
              count++;
            }
            // don't short circuit
            return false;
          });
        });
      return count;
    },
    notLockedCount(state: IBurnerFolderStoreViewerState): number {
      let count = 0;
      state.burnerFolders
        .filter((folder) => folder.parentKey === '')
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (!item.locked && item.details) {
              count++;
            }
            // don't short circuit
            return false;
          });
        });
      return count;
    },
    selectedBurners(state: IBurnerFolderStoreViewerState): IBurnerFolder[] {
      const selectedBurners: IBurnerFolder[] = [];
      state.burnerFolders
        .filter((folder) => folder.parentKey === '')
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (item.isSelected) {
              selectedBurners.push(item);
            }
            // don't short circuit
            return false;
          });
        });
      return selectedBurners;
    },
  } as IBurnerFolderStoreGetters,
  mutations: {
    setBurners(state: IBurnerFolderStoreViewerState, burners: IBurner[]): void {
      state.burners = burners;
    },
    updateLoading(state: IBurnerFolderStoreViewerState, isLoading: boolean) {
      state.loading = isLoading;
    },
    selectSelf(state: IBurnerFolderStoreViewerState, selected: IBurnerFolder): void {
      selected.selectSelf();
      state.lastSelected = selected;
    },
    clearSelf(state: IBurnerFolderStoreViewerState, selected: IBurnerFolder): void {
      selected.clearSelf();
      state.lastSelected = undefined;
    },
    selectRange(state: IBurnerFolderStoreViewerState, end: IBurnerFolder): void {
      if (state.lastSelected === undefined || state.lastSelected.key === end.key) {
        end.selectSelf();
        state.lastSelected = end;
      } else {
        let foundStart = false;
        let foundEnd = false;
        // Either lastselected or end could actually be the start depending on the order of clicks.
        state.burnerFolders.forEach((folder) => {
          recurseApply(folder, (item) => {
            if (foundEnd) {
              return foundEnd;
            }
            if ((state.lastSelected?.key === item.key || end.key === item.key) &&
            item.children.length === 0 && item.locked) {
              foundEnd = foundStart;
              foundStart = true;
            }
            if (foundStart && item.children.length === 0 && !item.locked) {
              item.selectSelf();
            }
            return foundEnd;
          });
        });
      }
    },
    selectAll(state: IBurnerFolderStoreViewerState): void {
      state.burnerFolders.forEach((folder) => {
        recurseApply(folder, (item) => {
          if (item.children.length === 0 && !item.locked) {
            item.selectSelf();
            state.lastSelected = item;
          }
          return false;
        });
      });
    },
    lockBurnerByKey(state: IBurnerFolderStoreViewerState, key: string): void {
      state.burnerFolders.forEach((folder) => {
        recurseApply(folder, (item) => {
          if (item.children.length === 0 && item.key === key) {
            item.lockSelection();
          }
          return false;
        });
      });
    },
    unlockBurnerByKey(state: IBurnerFolderStoreViewerState, key: string): void {
      state.burnerFolders.forEach((folder) => {
        recurseApply(folder, (item) => {
          if (item.children.length === 0 && item.key === key) {
            item.unlockSelection();
          }
          return false;
        });
      });
    },
    lockAll(state: IBurnerFolderStoreViewerState): void {
      state.burnerFolders.forEach((folder) => {
        recurseApply(folder, (item) => {
          if (item.children.length === 0 && !item.locked) {
            item.lockSelection();
          }
          return false;
        });
      });
    },
    unlockAll(state: IBurnerFolderStoreViewerState): void {
      state.burnerFolders.forEach((folder) => {
        recurseApply(folder, (item) => {
          if (item.children.length === 0) {
            item.unlockSelection();
          }
          return false;
        });
      });
    },
    clearAll(state: IBurnerFolderStoreViewerState): void {
      state.burnerFolders.forEach((folder) => {
        recurseApply(folder, (item) => {
          if (item.children.length === 0 && !item.locked) {
            item.clearSelf();
          }
          return false;
        });
      });
      state.lastSelected = undefined;
    },
  } as IBurnerFolderStoreMutations,
  actions: {
    async loadBurners(context: IBurnerFolderContext): Promise<void> {
      // Returning burners list based on selected asset from service.
      const selectedAsset: IAsset = store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.selectedAsset.name}`];
      const selectedAssetKey = selectedAsset.key;
      const conf = await ConfigFactory.GetConfig();
      let response: IBurnerListServiceResponse | IHBBurnerListServiceResponse = {
        burners: [],
        emberHierarchyLevels: [],
      };
      // If new burnertree API FF is active, then replace info retrieved from previous API
      try {
        const hbService = new HierarchyBuilderService(sharedAxiosInstance, process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL ? process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL : conf.get('hbApiUrl'));
        response = await hbService.getBurnerList(selectedAssetKey);
        // Add in legacy attributes burner information
        response.burners.forEach((newBurner: any) => {
          newBurner.key = newBurner.burnerIdentifier; // NEW
          newBurner.name = newBurner.burnerName; // NEW
        });
      } catch (error) {
        // Catch 404s and mimick to "no burners found" response.  Else throw error
        if (error.response.status === 404) {
          response.emberHierarchyLevels = [];
          response.burners = [];
        } else {
          // Reset burners
          context.state.burners = [];
          context.state.burnerFolders = [];
          throw error;
        }
      }
      context.state.burnerListResponse = response;
      await context.dispatch('parseBurnerTree', response);
    },
    async parseBurnerTree(context: IBurnerFolderContext, loaded: IBurnerListServiceResponse): Promise<void> {
      const folders = Array<IBurnerFolder>();
      if (loaded.emberHierarchyLevels?.length === 0) {
        // Some burners have no hierarchy levels. Force one so that the rest of the implementation works.
        const parent = new BurnerFolder({
          name: 'Zone 1',
          key: 'parent',
          parentKey: '',
        });

        folders.push(parent);
        // Now loop through the burner array and attach the details
        loaded.burners.forEach((burner) => {
          const newFolder = new BurnerFolder(burner);
          // Because there is no hierarchy, force a fake parent key to allow proper graph transveral.
          newFolder.parentKey = parent.key;
          newFolder.details = burner;
          parent.children.push(newFolder);
        });
      } else {
          loaded.emberHierarchyLevels.forEach((hierarchy) => {
            folders.push(new BurnerFolder(hierarchy));
          });
          // Now loop through the burner array and attach the details
          loaded.burners.forEach((burner) => {
            const parent = folders.find(
              (p) => p.key === burner.emberHierarchyLevelKey
            );
            if (parent) {
              const newFolder = new BurnerFolder(burner);
              newFolder.details = burner;
              parent.children.push(newFolder);
            }
          });
          // Loop back through the burners and build the tree
          folders.filter((b) => b.parentKey).forEach((child) => {
            const parent = folders.find((p) => p.key === child.parentKey);
            if (parent) {
              parent.children.push(child);
            }
          });
      }
      // Order the children
      // Need to here instead of in the child object because causes recursive binding
      // If this is the burner itself, sort by the orderIndex
      // otherwise sort by the node name.
      folders
        .filter((parent) => parent.childCount! > 0)
        .forEach((parent) => {
          parent.children.sort((a, b) => {
            if (a.details && b.details) {
              return (a.details?.orderIndex ?? 0) - (b.details?.orderIndex ?? 0);
            }
            return a.name! > b.name! ? 1 : -1;
          });
        });
      context.state.burnerFolders = folders
        .filter((folder) => folder.parentKey === '')
        .sort((a, b) => (a.name! < b.name! ? -1 : 1));

      context.commit('setBurners', loaded.burners);
    },
  } as IBurnerFolderStoreActions,
};
