import { ITreeNode, TreeNodesTable } from '@/view-models/tree';
import {
  IAssetHierarchyNodeViewModel,
  IAssetReportVariableViewModel,
  IAssetNodeViewModel,
  VariableTreeNodeData
} from '@/view-models/variables';

export class AssetTreeNodesNormalizer {

  private childrenKeysByParents: Record<string, string[]> = {};
  public treeNodesTable: TreeNodesTable<VariableTreeNodeData> = {};

  public get hasNodes(): boolean {
    return Object.keys(this.treeNodesTable).length > 0;
  }

  public convertNodes(list: IAssetHierarchyNodeViewModel[]): this {
    this.treeNodesTable = list.reduce((acc, hierarchyNode) => {
      if (!(hierarchyNode.key in acc)) {
        acc[hierarchyNode.key] = this.convertHierarchyNodeToLevelNode(hierarchyNode);
        this.collectParentChildrenKeys(acc[hierarchyNode.key]);
        acc = (hierarchyNode?.availableNodes ?? [])
          .reduce((acc2, assetNode) => {
            acc[hierarchyNode.key].childrenKeys.push(assetNode.nodeKey);
            // This is to deal with Asset straight input node having the same key,
            // but with a suffix of Data in the name
            const isDataNode: boolean = assetNode.parentNodeKey ===  hierarchyNode.key;
            assetNode.nodeKey = isDataNode ? `${assetNode.nodeKey}-data` :  assetNode.nodeKey;
            assetNode.hierarchyPath = `${assetNode.hierarchyPath}`;
            acc2[assetNode.nodeKey] = this.convertNodeToLevelNode(assetNode);
            this.collectParentChildrenKeys(acc2[assetNode.nodeKey]);
            return acc2;
          }, acc);
      }

      return acc;
    }, this.treeNodesTable);
    return this;
  }

  public convertVariables(list: IAssetReportVariableViewModel[]): this {
    this.treeNodesTable = list.reduce((acc, input) => {
      const leaf = this.convertInputToLevelNode(input);
      // This is to deal with Asset straight input node having the same key, but with a suffix of Data in the name
      leaf.parentKey = `${leaf.parentKey}-data`;
      this.collectParentChildrenKeys(leaf);
      acc[leaf.key] = leaf;
      return acc;
    }, this.treeNodesTable);

    return this;
  }

  public processChildren(): this {
    for (const parentKey of Object.keys(this.childrenKeysByParents)) {
      if (parentKey in this.treeNodesTable) {
        this.treeNodesTable[parentKey].childrenKeys = this.childrenKeysByParents[parentKey];
      }
    }
    return this;
  }

  private collectParentChildrenKeys(level: ITreeNode<VariableTreeNodeData>) {
    if (level.parentKey != null && level.parentKey !== level.key) {
      if (!(level.parentKey in this.childrenKeysByParents)) {
        this.childrenKeysByParents[level.parentKey] = [];
      }
      this.childrenKeysByParents[level.parentKey].push(level.key);
    }
  }

  private convertHierarchyNodeToLevelNode(node: IAssetHierarchyNodeViewModel): ITreeNode<VariableTreeNodeData> {
    let nodeName = '';
    let nodeNameArray :string[] = [];
    let isRootNode = false;
    if (node.availableNodes[0].parentNodeKey?.includes('root.')) {
      nodeNameArray = node.key?.split('.');
      nodeName = nodeNameArray[nodeNameArray.length-1];
    } else {
      node.availableNodes.forEach((element) => {
        if (element.nodeKey === element.parentNodeKey && element.nodeKey === node.parentKey) {
          isRootNode = true;
        }
        nodeNameArray = node.availableNodes[0].hierarchyPath?.split('/')!;
      });
      const nodeNames = nodeNameArray.length > 2 ? nodeNameArray[nodeNameArray.length-2] : nodeNameArray[nodeNameArray.length-1];
      nodeName = isRootNode ? nodeNameArray[0] : nodeNames;
    }
    return {
      key: node.key,
      name: nodeName,
      parentKey: node.parentKey,
      isOpen: false,
      show: true,
      isLeaf: false,
      isSelected: false,
      data: null,
      childrenKeys: [],
      variablePath: node.availableNodes[0].hierarchyPath,
      isLocked: false,
      selectSelf(){},
      clearSelf(){}
    };
  }

  private convertNodeToLevelNode(node: IAssetNodeViewModel): ITreeNode<VariableTreeNodeData> {
    return {
      key: node.nodeKey,
      name: node.nodeName,
      parentKey: node.parentNodeKey,
      isOpen: false,
      show: true,
      isLeaf: false,
      isSelected: false,
      data: null,
      childrenKeys: [],
      variablePath: node.hierarchyPath,
      isLocked: false,
      selectSelf(){},
      clearSelf(){}
    };
  }

  private convertInputToLevelNode(input: IAssetReportVariableViewModel): ITreeNode<VariableTreeNodeData> {
    const variableParentKey = input.variableKey?.split('#');
    const key = input.variableKey!;
    const name = input.displayValues?.join(',');
    return {
      key,
      name,
      parentKey: variableParentKey ? variableParentKey[1] : '',
      isOpen: false,
      show: true,
      isLeaf: true,
      isSelected: false,
      data: input,
      childrenKeys: [],
      isLocked: false,
      selectSelf(){},
      clearSelf(){}
    };
  }
}

export function getUniqueVariableKey(input: IAssetReportVariableViewModel) {
  if (input.variableKey) {
    let variableKeySplit = input.variableKey.split('#');
    return `${variableKeySplit[2]}`;
  }
}
