


















import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import BurnerLayout from '../BurnerLayout.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'burner-layout-modal',
  components: {
    BurnerLayout
  }
})
export default class BurnerLayoutModal extends Vue {

  private onDone(): void {
    this.$bvModal.hide('burner-layout-modal');
  }

}
