






























































































import { Vue, Component } from 'vue-property-decorator';
import { IBurnerVariableConfig } from '@/view-models/burnerLayout/burner-layout-view-models';
import store, { VuexModuleNamespaces } from '@/store/';
import { BurnerLayoutStore } from '@/store/burnerLayout/burnerLayoutStore';
import { convertHeatUnits } from '@/services/utils/heat-release-conversion';

@Component({
  name: 'live-display'
})
export default class LiveDisplay extends Vue {

  private translateColor(color: string): string {
    if (color === 'brown') {
      return '#C87253';
    } else if (color === 'orange') {
      return '#F5A623';
    } else if (color === 'green') {
      return '#79C821';
    } else if (color === 'purple') {
      return '#8673F6';
    } else if (color === 'white') {
      return '#DADADA';
    } else {
      return '#000000';
    }
  }

  private convertHeatValue(heatInWatts: number): string {
    return convertHeatUnits({
      watts: heatInWatts,
      desiredUnit: store.getters[`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.getters.selectedHeatUnit.name}`]
    });
  }

  get layout(): IBurnerVariableConfig[] {
    return store.getters[`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.getters.burnerLayout.name}`];
  }

}
