






























import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store';
import { GridStore } from '@/store/grid/gridStore';

Vue.use(BootstrapVue);

@Component({
  name: 'layout-asset-variables-changed-modal'
})
export default class LayoutAssetVariablesChangedModal extends Vue {

  private get getLayoutAssetVariablesChanged() {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.getLayoutAssetVariablesChanged.name}`].join(', <br/>');
  }
  private onAcknowledge(): void {
    this.$bvModal.hide('layout-asset-variables-changed-modal');
  }

}
