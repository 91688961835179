


















































// Libraries
import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import { TreeTraverseHelper } from '@/utils/tree/tree-traverse-helper';
import { alphabeticSorter } from '@/utils/array-utils';
// View Models
import { ITreeNode, TreeNodesTable } from '@/view-models/tree';
// Components
import IconButton from '@/components/editor/components/dsm/IconButton.vue';
import { Events, eventBus } from '@/utils/event-bus';
// store
import {
  IAssetReportVariableViewModel, VariableTreeNode
} from '@/view-models/variables';
import { Nil } from '@/utils/types';
import inputsTree from '@/store/storeModules/inputs-tree';
import store, { VuexModuleNamespaces } from '@/store';
import { GridStore } from '@/store/grid/gridStore';
import { VariableFolderStore } from '@/store/variableFolder/variableFolderStore';
import { DiagramStore } from '@/store/diagram/diagramStore';

@Component({
  name: 'tree-node',
  components: {
    IconButton,
  },
})
export default class TreeNode<T> extends Vue {
  @Model('input', { default: null })
  public node!: Nil<ITreeNode<IAssetReportVariableViewModel>>;

  @Prop({ required: true })
  public nodesTable!: TreeNodesTable<T>;

   private get treeHelper(): TreeTraverseHelper<T> {
    return new TreeTraverseHelper<T>(this.nodesTable);
  }
  private get isNodeChildren(): boolean {
    return this.node ? this.node.isLeaf : false;
  }

  get isDiagramLocked(): boolean {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isDiagramLocked.name}`];
  }

  get isVariableSettingsEnabled(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.isVariableSettingsEnabled.name}`];
  }

  public latest:boolean=true;

  public get hasNode(): boolean {
    return this.node != null;
  }
  public get toggleIconClass(): string {

    return (this.node && this.node.isOpen) ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline';
  }
  public get hasChildren(): boolean {
    if (this.node) {
      return this.node.childrenKeys?.length > 0;
    } else {
      return false;
    }
  }
  public get children(): ITreeNode<T>[] {
    return Array.from(
      new Set(this.treeHelper.getChildrenNodes(this.node!) ?? [])
    );
  }
  private get isSelected(): boolean {
    return inputsTree.selectedNode
      ? this.node!.key === inputsTree.selectedNode.key
      : false;
  }

  public get childNodes(): ITreeNode<T>[] {
    const alphaSorter = alphabeticSorter<ITreeNode<T>>((node) => node.name);
    const nodes: ITreeNode<T>[] = [];
    const leaves: ITreeNode<T>[] = [];
    for (const child of this.children) {
      if (child.isLeaf) {
        leaves.push(child);
      } else {
        nodes.push(child);
      }
    }
    nodes.sort(alphaSorter);
    leaves.sort(alphaSorter);
    return [...nodes, ...leaves];
  }

  public toggleNodeExpansion(node: VariableTreeNode) {
    node.childrenKeys.forEach((item) => {
      if (this.isDiagramLocked) {
          const allNodes = inputsTree.nodeByKey(item, inputsTree.treeHelper.allNodes);
          inputsTree.lockAllVariableByKey({node, childNode: allNodes});
      } else {
        const gridVariable = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKeys.name}`](item);
        if (gridVariable !== undefined){
          const allNodes = inputsTree.nodeByKey(item, inputsTree.treeHelper.allNodes);
          inputsTree.lockVariableByKey({node, childNode: allNodes});
        }
      }
    });
    eventBus.$emit(Events.VariableTreeNodeToggleIsOpen, this.node);
  }
  public selectCurrentNode(node: VariableTreeNode, args: MouseEvent): void {
    const selectedVariables = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getSelectedVariables.name}`];
    if (args.metaKey || args.ctrlKey) {
      store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.selectSelf.name}`, node);
    } else {
      if (selectedVariables.length === 1) {
        store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearSelectedVariables.name}`);
      }
    }
    if (this.isVariableSettingsEnabled) {
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.discardActiveSelection.name}`);
    }

    if (this.isNodeChildren && !this.isSelected) {
      inputsTree.setSelectedNode(node);
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.setSelectedNode.name}`, node);
    }
    if (!this.isSelected) {
      store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.setVariable.name}`, node);
    }
    store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.clearAll.name}`);
    store.commit(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.mutations.selectSelf.name}`, node);
  }

}
