










import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import AssetSelector from './components/assetSelector/AssetSelector.vue';
import Editor from './components/editor/Editor.vue';
import Preview from './components/preview/Preview.vue';
import PageNotFound from './components/pageNotFound/PageNotFound.vue';
import Error from './components/modals/Error.vue';
import { authGuard } from './services/auth/auth';
import { adEventBus } from './services/eventBus/asset-diagram-event-bus';
import store, { VuexModuleNamespaces } from '@/store/';
import { AppStore } from '@/store/app/appStore';
import Loading from './components/common/Loading.vue';
import moment from 'moment';
import i18n from '@/i18n';
import { UserStore } from '@/store/user/userStore';
import { appRouteBase } from './utils/from-parent';

Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.filter('formatDateLong', (value: number) => {
  if (value) {
    return moment(String(value)).format('MMMM D, YYYY [at] h:mm a');
  } else {
    return '-';
  }
});

Vue.filter('formatUsername', (value: string) => {
  if (value) {
    return value;
  } else {
    return '-';
  }
});

Vue.filter('formatToInteger', (value: number) => {
  if (value === -999999 || value === undefined || value === null) {
    return '-';
  } else {
    return Math.trunc(value);
  }
});

const routes = [
  { path: '/', component: AssetSelector, name: 'AssetSelector' },
  { path: '/editor/:id', component: Editor, name: 'Editor' },
  { path: '/preview/:id', component: Preview, name: 'Preview' },
  { path: '/notFound', component: PageNotFound, name: 'NotFound' },
  { path: '*', component: PageNotFound, name: 'Unknown' }
];

export const assetDiagramRouter = new VueRouter({
  mode: 'history',
  base: appRouteBase(),
  routes
});

assetDiagramRouter.beforeEach(authGuard);

@Component({
  name: 'app',
  components: {
    Error,
    Loading
  },
  router: assetDiagramRouter,
  i18n
})
export default class AssetDiagramBuilder extends Vue {

  @Prop({ required: true })
  public customerAssetDetails!: string;

  private deactivated() {
    // Get rid of old vue instance
    this.$destroy();
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$off(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
  }

  private created() {
    // Clear leftover listeners, if present.
    this.clearAdEventBus();
    // Listen for route changes
    this.$router.afterEach(() => {
      store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateIsLoading.name}`, false);
    });
  }

  private mounted() {
    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
    this.onCustomerOrAssetChange();
  }

  private beforeDestroy() {
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  private handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?utilities\/ember\/layout-editor[\/]?/)) {
      store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateIsLoading.name}`, false);
      const currentRelativeRoute = parentRoute.path.replace(/^\/?utilities\/ember\/layout-editor[\/]?/, '/');
      if (currentRelativeRoute !== this.$route.path) {
        this.$router.push(currentRelativeRoute);
      }
    }
  }

  private clearAdEventBus() {
    adEventBus.$off();
  }

  get isLoading() {
    return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.isLoading.name}`];
  }

  @Watch('customerAssetDetails', {​ immediate: true, deep: true }​)
  public onCustomerOrAssetChange(): void {
    if (this.customerAssetDetails) {
      store.commit(`${VuexModuleNamespaces.user}/${UserStore.mutations.updateCustomerAssetInfo.name}`, JSON.parse(this.customerAssetDetails));
    }
  }
}
