

























import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { IDiagramObject, IAssetDiagram } from '@/view-models/viewer/assetDiagram/asset-diagram';
import store, { VuexModuleNamespaces } from '@/store';
import { BurnerFolderViewerStore } from '@/store/viewer/burnerFolder/burnerFolderStore';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';
import { DiagramViewerStore } from '@/store/viewer/diagram/diagramStore';
import { GridViewerStore } from '@/store/viewer/grid/gridStore';
import { PreviewGridViewerStore } from '@/store/viewer/previewGrid/previewGridStore';
import { adEventBus, adEvents } from '@/services/viewer/eventBus/asset-diagram-event-bus';
import Loading from '@/components/viewer/common/Loading.vue';
import AssetVariables from '@/components/viewer/assetVariables/AssetVariables.vue';
import { IViewerBurner, ReadingsCache } from '@/view-models/viewer/burnerReading/burner-reading';
import { fabric } from 'fabric';
import { ErrorViewerStore } from '@/store/viewer/error/errorStore';
import { AppViewerStore } from '@/store/viewer/app/appStore';
import { AssetVariableStore } from '@/store/viewer/assetVariable/assetVariableStore';
import { IBurnerVariableConfig } from '@/view-models/viewer/burnerLayout/burner-layout-view-models';
import { BurnerLayoutViewerStore } from '@/store/viewer/burnerLayout/burnerLayoutStore';
import { BurnerReadingViewerStore } from '@/store/viewer/burnerReading/burnerReadingStore';
import ZoomButton from '@/components/editor/components/ZoomButton.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'grid-preview',
  components: {
    Loading,
    AssetVariables,
    ZoomButton
  },
})
export default class GridPreview extends Vue {
  private diagramObjects: IDiagramObject[] = [];
  private currentAssetDiagram: IAssetDiagram | null = null;
  private isLoading: boolean = false;
  private isResizing: number = 0;
  private currentViewerBurner: IViewerBurner | undefined = undefined;
  private selectedBurnerKey: string = '';
  private displayAttributes: ReadingsCache = {};
  private showPanel: boolean = false;
  private selectedAssetKey: string = '';

  get assetVariablesList() {
    return store.getters[`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.getters.getAssetVariableslist.name}`];
  }

  get layout(): IBurnerVariableConfig[] {
    return store.getters[`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.burnerLayout.name}`];
  }

  get showAssetVariables() {
    return false;
  }

  get staleRecommendationThreshold() {
    return store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getStaleRecommendationThreshold.name}`];
  }

  public created() {
    adEventBus.$on(adEvents.changedDisplay, async (data: any) => {
      if (data !== undefined && data !== null) {
        await store.dispatch(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.tryExecute.name}`, {
          action: async () =>
            store.dispatch(
              `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.updateBurnerGroupDisplay.name}`,
              {
                burnerDisplay: data.currentDisplay,
                heatReleaseUnit: data.currentHeatReleaseUnit,
              }
            ),
          errorMsg: 'Error updating burner display values - ',
        });
      }
    });
    adEventBus.$on(adEvents.deselectBurner, () => {
      this.selectedBurnerKey = '';
      store.dispatch(
        `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.toggleBurnerSelection.name}`,
        ''
      );
    });
    adEventBus.$on(adEvents.selectBurner, (key: string) => {
      this.selectedBurnerKey = key;
      store.dispatch(
        `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.toggleBurnerSelection.name}`,
        key
      );
    });
  }

  public async mounted() {
    // Reset side menu
    adEventBus.$emit(adEvents.isBurnerSelected, false);
    adEventBus.$emit(adEvents.deselectBurner);
    await this.initializeGridPreview();
    this.initializeAssetVariables();
  }

  public async initializeGridPreview() {
    this.isLoading = true;

    // Initialize preview canvas
    const canvasElement: HTMLElement | null | undefined = document
      ?.querySelector('#asset-diagram-viewer')
      ?.shadowRoot?.getElementById('preview-canvas');
    if (canvasElement !== null && canvasElement !== undefined) {
      store.commit(
        `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.mutations.updatePreviewGrid.name}`,
        new fabric.Canvas(canvasElement as HTMLCanvasElement, {
          selection: false,
          selectionFullyContained: true,
          preserveObjectStacking: true,
          includeDefaultValues: false,
          hoverCursor: 'default',
        })
      );
    }

    const selectedAsset = store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.selectedAsset.name}`];
    this.selectedAssetKey = selectedAsset?.key;
    store.commit(`${VuexModuleNamespaces.appViewer}/${AppViewerStore.mutations.updateActionTime.name}`);
    // If refreshing on this screen, re-retrieve the diagram
    if (this.$route?.params.id && this.$route.params.id !== this.selectedAssetKey) {
      // Find the asset and set selected in the store;
      await store.dispatch(`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.actions.loadAssets.name}`);
      const foundAsset = store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.findAsset.name}`](
        this.$route.params.id
      );
      if (!foundAsset) {
        await this.$router.push({ name: 'NotFound' });
      }
      store.commit(`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.mutations.selectAsset.name}`, foundAsset);
      this.selectedAssetKey = foundAsset.key;
      // Retrieve and bind the asset diagram
      await store.dispatch(`${VuexModuleNamespaces.burnerFolderViewer}/${BurnerFolderViewerStore.actions.loadBurners.name}`);
      const diagram = await store.dispatch(
        `${VuexModuleNamespaces.diagramViewer}/${DiagramViewerStore.actions.retrieveAssetDiagram.name}`,
        this.selectedAssetKey
      );
      await store.dispatch(`${VuexModuleNamespaces.diagramViewer}/${DiagramViewerStore.actions.bindAssetDiagram.name}`, diagram);
    }

    // Load the grid contents into the preview
    let gridObjects = store.getters[`${VuexModuleNamespaces.gridViewer}/${GridViewerStore.getters.gridContents.name}`];
    await store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.updateGridContentsfromJSON.name}`,gridObjects);

    // Resize the preview grid and set listeners
    this.resizeGrid(false);

    // Set the preview object size
    store.dispatch(
      `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.resizeObjectsForPreview.name}`,
      false
    );

    // Align objects to top left
    store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.alignTopLeft.name}`);

    // Trim excess grid
    this.resizeGrid(true);

    this.isLoading = false;
  }

  public async initializeAssetVariables() {
    if (store.getters[`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.getters.getVariablePaths.name}`].length > 0) {
      await store.dispatch(`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.actions.retrieveAssetVariablesData.name}`,
                        this.selectedAssetKey);
    }
  }

  public isFlagged(key: string): boolean {
    const burner = store.getters[
      `${VuexModuleNamespaces.burnerFolderViewer}/${BurnerFolderViewerStore.getters.getBurnerByKey.name}`
    ](key);
    if (burner !== null && burner !== undefined) {
      return burner.isFlagged;
    } else {
      return false;
    }
  }

  private resizeGrid(isTrimToFit: boolean): void {
    const editorElement = document?.querySelector('#asset-diagram-viewer')?.shadowRoot?.getElementById('preview');
    const toolbarElement = document
      ?.querySelector('#asset-diagram-viewer')
      ?.shadowRoot?.getElementById('preview-toolbar-container');
    const gridElement: HTMLElement | null | undefined = document
      ?.querySelector('#asset-diagram-viewer')
      ?.shadowRoot?.getElementById('preview-grid');
    if (gridElement !== null && gridElement !== undefined) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      const gridWidth = editorElement?.clientWidth ?? 1000;
      store.commit(
        `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.mutations.updatePreviewGridWidth.name}`, gridWidth
      );
      store.commit(
        `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.mutations.updatePreviewGridHeight.name}`, gridHeight);
      if (isTrimToFit) {
        store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.trimGridToFit.name}`);
      } else {
        store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.resizeObjectsForPreview.name}`,
          true);
      }
    }
  }
}
