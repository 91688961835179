



































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { ErrorStore } from '@/store/error/errorStore';
import { GridStore } from '@/store/grid/gridStore';
import Loading from '@/components/common/Loading.vue';
import ConfigFactory from '@/services/config/config';
import { AssetDiagramService } from '@/services/assetDiagram/asset-diagram-service';
import axios from 'axios';
import authInterceptor from '@/services/common/service-interceptor';
import { AppStore } from '@/store/app/appStore';

Vue.use(BootstrapVue);

@Component({
  name: 'discard-save-modal',
  components: {
    Loading
  }
})
export default class DiscardSaveModal extends Vue {

  public isDone: boolean = false;
  public isLoading: boolean = false;

  private mounted() {
    this.isDone = false;
    this.isLoading = false;
  }

  private onDiscard(): void {
    this.isDone = false;
    this.$bvModal.hide('discard-save-modal');
    this.returnHome();
  }

  private async onSave() {
    this.isDone = false;
    this.isLoading = true;
    // This API call uses its own axios instance (not the shared one) becuase
    // the gzip compression might cause race conditions with other API calls
    // causing base URIs to get crossed
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    const privateAxiosInstance = axios.create();
    privateAxiosInstance.interceptors.request.use(authInterceptor);
    const conf = await ConfigFactory.GetConfig();
    const assetDiagramService = new AssetDiagramService(privateAxiosInstance,
      process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
      process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
      conf.get('adbApiUrl'));
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
        action: async () => assetDiagramService.saveCurrentAssetDiagram(false),
        errorMsg: 'Error saving asset',
        routeHomeAfterError: true
      });
    this.isLoading = false;
    this.$bvModal.hide('discard-save-modal');
    this.returnHome();
  }

  private returnHome(): void {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    // tslint:disable-next-line
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`);
    this.$router.push('/');
  }

}
