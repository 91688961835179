export interface ILog {
    message: string;
    applicationName: string;
    browserName: string;
    browserVersion: string;
    frontendFeature: string;
    logLevel: string;
    additionalProperty: { [key: string]: string };
    stackTrace: string;
}

export enum LogFeature {
    'AD-PublishLayout' = 'Publish the edited diagram of asset ',
    'AD-PreviewLayout' = 'Opened preview of the asset '
}
