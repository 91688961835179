export interface ILog {
    message: string;
    applicationName: string;
    browserName: string;
    browserVersion: string;
    frontendFeature: string;
    logLevel: string;
    additionalProperty: { [key: string]: string };
    stackTrace: string;
}

export enum LogFeature {
    'AD-LE-Opened' = 'Layout Viewer tab was opened with asset ',
    'AD-EditAssetClicked' = 'Edit asset diagram of ',
    'AD-FilterChanged' = 'Show values for burners for applied filter ',
 	'AD-BurnerClicked' = 'Burner selection changed to '
}
