export enum LayoutApp {
    AssetDiagramBuilder = 'builder',
    AssetDiagramViewer = 'viewer'
}

export enum AlignLabelOptions {
    AlignLeft = 'Align to Left',
    AlignCenter = 'Align to Center',
    AlignRight = 'Align to Right',
    AlignTop = 'Align to Top',
    AlignMiddle = 'Align to Middle',
    AlignBottom = 'Align to Bottom',
    DistributeHorizontal = 'Distribute Horizontally',
    DistributeVertical = 'Distribute Vertically'
}

export enum FuelType {
    ThreeWayValve = 'ThreeWayValve',
    None = 'None'
}
