







































// Vue Dependencies
import { Component, Vue } from 'vue-property-decorator';
import store, { VuexModuleNamespaces } from '@/store/';
import { GridStore } from '@/store/grid/gridStore';
import { adEventBus, adEvents } from '@/services/viewer/eventBus/asset-diagram-event-bus';

@Component({
  name: 'entity-select'
})
export default class EntitySelect extends Vue {

  get activeLabelCount() {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.getActiveLabelCount.name}`];
  }

	get activeBurnerCount() {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.getActiveBurnersCount.name}`];
  }

  get activeVariableCount() {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.getActiveVariableCount.name}`];
  }

  private editLabel() {
    store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateSelectedGridLabelContents.name}`);
  }

  private rotateClock(direction: boolean) {
    store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.rotateClock.name}`, direction);
  }

  private deleteActiveSelection() {
    if (this.activeLabelCount > 0) {
			this.deleteSelectedLabels();
		}
    if (this.activeBurnerCount > 0) {
			this.deleteSelectedBurners();
		}
    if (this.activeVariableCount > 0) {
			this.deleteSelectedVariables();
		}
    this.discardActiveSelection();
  }

  private deleteSelectedLabels() {
    store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.deleteSelectedLabels.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.removeLabelShadows.name}`);
  }

	private deleteSelectedBurners() {
		store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.deleteSelectedBurners.name}`);
	}

  private async deleteSelectedVariables() {
    const selectedVariableKeys:string[] = await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.deleteSelectedVariables.name}`);
    adEventBus.$emit(adEvents.deleteVariable, selectedVariableKeys);
	}

  private discardActiveSelection() {
    store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.discardActiveSelection.name}`);
  }

}
