import BaseService from '@/services/common/base-service';
import { AssetDiagram } from '@/view-models/assetDiagram/asset-diagram-view-models';
import { Status } from '@/view-models/assetDiagram/asset-diagram';
import { AxiosInstance, AxiosResponse } from 'axios';
import IAssetDiagramServiceResponse from '@/view-models/assetDiagram/asset-response-view-models';
import { gzip, ungzip } from 'node-gzip';

export class AssetDiagramService extends BaseService {

  constructor(axiosInstance: AxiosInstance, adbApiUrl: string) {
    super(adbApiUrl, axiosInstance);
  }

  public async saveCurrentAssetDiagram(publish: boolean): Promise<IAssetDiagramServiceResponse> {
    let response : AxiosResponse<IAssetDiagramServiceResponse>;
    const assetDiagram = AssetDiagram.currentAssetDiagram;
    // Compress asset diagram
    const compressed = await gzip(assetDiagram.diagram);
    const compressedString = compressed.toString('hex');
    assetDiagram.diagram = compressedString;
    // Generate payload and Save diagram
    const payload = {
      assetKey: assetDiagram.assetKey,
      diagram: assetDiagram.diagram,
      equipmentConfigKey: assetDiagram.equipmentConfigKey,
      status: publish ? Status.Published : assetDiagram.status,
      variableDiagram: assetDiagram.variableDiagram
    };

    response = await this.http.post<IAssetDiagramServiceResponse>('aov/AssetsDiagram', payload);
    let assetDiagramResponse: IAssetDiagramServiceResponse = response.data;
    // Decompress asset diagram in response
    const decompressedBuffer = Buffer.from(assetDiagramResponse.diagram, 'hex');
    const decompressed = await ungzip(decompressedBuffer);
    assetDiagramResponse.diagram = decompressed.toString();
    return assetDiagramResponse;
  }
  public async getAssetDiagram(assetKey: string, published: boolean, equipmentConfigKey: string | undefined): Promise<IAssetDiagramServiceResponse> {
    let url = `aov/AssetsDiagram/${assetKey}?diagramStatus=`;
    let response : AxiosResponse<IAssetDiagramServiceResponse>;
    url += published ? 'Published': 'Draft';
    response = await this.http.get<IAssetDiagramServiceResponse>(url);
    let assetDiagram: IAssetDiagramServiceResponse = response.data;
    if (!assetDiagram) {
      assetDiagram = {
        assetKey: assetKey,
        lastModifiedDate: new Date(),
        lastModifiedByName: '',
        status: published ? Status.Published : Status.Draft,
        diagram: '1f8b08000000000000038591c14e83401086df65ce84482860b9a9170f262678f0d0f4b0c014d6c00e0eb3c54a7877b76dd44d93ea716777beff9b9d196aad1a563de43334aceb47d44d2b90a749169c0aafba9616f22889d373e19e3e5ef427421e67e12a808a8ca091f108d8238f9a0ce410876998420054be6175bcdc6c972580d2b241eed481accbd8cc6054ef48d0a292023b5423baa6b1a50972618b010c346a3933858627dc091c333b6257299926e3ce7bd5598789c2d53a4bd6719466d912fcc05dd6f3eec169327517f4ef46ef35be17ca055ed7284984fa0b93c1f2d0a1af1225bec230108b355a0eff818bd3ffff921b46f4665c7954c68afa1e4d8df59de6ebe0caed07d963122bd3f8b6371eb5b2ccaee14fa25bc5a5e7d46af190b7cb76f902fa172ef45d020000',
        locked: false,
        equipmentConfigKey: equipmentConfigKey,
        variableDiagram: {
          variableDetails: []
        }
      } as IAssetDiagramServiceResponse;
    }
    // Try to decompress.  If failed, then assume this string is not compressed and use raw string from API response
    const rawAssetDiagram = assetDiagram.diagram;
    try {
      const decompressedBuffer = Buffer.from(rawAssetDiagram, 'hex');
      const decompressed = await ungzip(decompressedBuffer);
      assetDiagram.diagram = decompressed.toString();
    } catch (error) {
      assetDiagram.diagram = rawAssetDiagram;
    }
    return assetDiagram;
  }
}
