










import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  name: 'variable-block',
  components: {
  }
})
export default class VariableBlock extends Vue {
  @Prop({ required: true })
  public data!: string;
  private showLabel: boolean = false;
}
