import { render, staticRenderFns } from "./AssetDiagramBuilder.vue?vue&type=template&id=5b172a4c&"
import script from "./AssetDiagramBuilder.vue?vue&type=script&lang=ts&"
export * from "./AssetDiagramBuilder.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./AssetDiagramBuilder.vue?vue&type=style&index=0&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports