import { IGridConfig } from '@/view-models/assetDiagram/grid-view-models';

export const gridConfig: IGridConfig = {
  defaultGridBoxSize: 37.4,
  variableGridBoxWidth: 74.8,
  gridLineColor: '#5B6375', // NOTE: gridline color is hardcoded until color appears in Invision theme. DBB - 3/20/20
  gridLineName: 'gridline',
  zoomFactor: 0.1,
  minZoomMagnification: 4,
  maxZoomMagnification: 2,
  arrangeShapeName: 'arrangeShape',
  tempArrangeShapeName: 'tempArrangeShape',
  arrangeShapeControlsName: 'arrangeShapeControlsName',
  tempArrangeShapeControlsName: 'tempArrangeShapeControlsName',
  tempArrangeShapeSizeControlsName: 'tempArrangeShapeSizeControlsName',
  arrangeShapeSizeControlsName: 'arrangeShapeSizeControlsName',
  arrangeShapeStrokeWidth: 2,
  arrangeSpaceColor: '#00BFFF',
  arrangeSpaceColorWhite: '#FFFFFF',
  arrangeSpaceColorGrey: '#C0C0C0',
  arrangeSpaceCircleSize: 7,
  arrangeShapeControlsFont: 'Arial',
  arrangeShapeControlsFontWeight: 'bold',
  arrangeSpacePadSize: 5,
  burnerShadowName: 'burnerShadow',
  labelShadowName: 'labelShadow',
  shadowColor: '#20222A',
  shadowBorderColor: '#00BFFF',
  burnerLineColor: '#979797',
  burnerFont: 'arial',
  burnerFontWeight: 'normal',
  burnerFontColor: '#DADADA',
  burnerGroupName: 'burnerGroup',
  burnerLineBorderWidth: 1,
  labelShadowFontColor: '#00BFFF',
  labelName: 'label',
  labelBackgroundColor: '#4E5266',
  labelFontColor: '#DADADA',
  burnerHighlightColor: '#00BFFF',
  previewGridFontWeight: 'normal',
  previewGridFontFamily: 'Source Sans Pro',
  previewGridBoxSize: 50,
  previewVariableGridBoxWidth: 100,
  previewGridBoxColor: '#333643',
  previewGridBoxOffColor: '#20222a',
  previewChangesRecommendedFontColor: '#F5A623',
  previewNoChangesRecommendedFontColor: '#FFFFFF',
  variableShadowName: 'variableShadow',
  variableGroupName: 'variableGroup'
};
