import { render, staticRenderFns } from "./BurnerDetailsSideMenuNew.vue?vue&type=template&id=c4e0000c&scoped=true&"
import script from "./BurnerDetailsSideMenuNew.vue?vue&type=script&lang=ts&"
export * from "./BurnerDetailsSideMenuNew.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./BurnerDetailsSideMenuNew.vue?vue&type=style&index=0&id=c4e0000c&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c4e0000c",
  null
  ,true
)

export default component.exports