







































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { DiagramStore } from '@/store/diagram/diagramStore';
import { ErrorStore } from '@/store/error/errorStore';
import { AssetStore } from '@/store/assetStore/assetStore';
import IAssetDiagramServiceResponse from '@/view-models/assetDiagram/asset-response-view-models';
import { AssetDiagramService } from '@/services/assetDiagram/asset-diagram-service';
import ConfigFactory from '@/services/config/config';
import Loading from '@/components/common/Loading.vue';
import axios from 'axios';
import authInterceptor from '@/services/common/service-interceptor';
import { AppStore } from '@/store/app/appStore';

Vue.use(BootstrapVue);

@Component({
  name: 'reset-modal',
  components: {
    Loading
  }
})
export default class ResetModal extends Vue {

  public message: string = '';
  public lastPublished: IAssetDiagramServiceResponse | null = null;
  public isDone: boolean = false;
  public isLoading: boolean = false;
  public isInitializing: boolean = false;

  public async initializeReset() {
    this.isDone = false;
    this.isLoading = true;
    this.isInitializing = true;
    try {
      store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
      const assetKey = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`]?.key;
      this.lastPublished = await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.retrievePublishedDiagram.name}`, assetKey);
      if (this.lastPublished === undefined || this.lastPublished === null || this.lastPublished.diagram === '{}') {
        this.message = this.$t('confirmModal.clearWorkSpaceMessage').toString();
      } else {
        this.message = this.$t('confirmModal.resetDesignMessage').toString();
      }
    } catch (err) {
      const errorString = 'Error retrieving last published asset. \n';
      await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.setError.name}`, {
        error: err,
        errorString,
        handleError: true,
        routeHomeAfterError: false
      });
      this.$bvModal.hide('reset-modal');
    }
    this.isLoading = false;
    this.isInitializing = false;
  }

  private mounted() {
    this.isDone = false;
    this.isLoading = false;
    this.isInitializing = false;
  }

  private onCancel(): void {
    this.$bvModal.hide('reset-modal');
  }

  private onOk(): void {
    this.$bvModal.hide('reset-modal');
  }

  private async onReset() {
    try {
      this.isLoading = true;
      store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
      store.dispatch(
        `${VuexModuleNamespaces.diagram}/${DiagramStore.actions.bindAssetDiagram.name}`,
        this.lastPublished);
      // Manually save previous draft
      // This API call uses its own axios instance (not the shared one) becuase
      // the gzip compression might cause race conditions with other API calls
      // causing base URIs to get crossed
      const privateAxiosInstance = axios.create();
      privateAxiosInstance.interceptors.request.use(authInterceptor);
      const conf = await ConfigFactory.GetConfig();
      const assetDiagramService = new AssetDiagramService(privateAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
        conf.get('adbApiUrl'));
      await assetDiagramService.saveCurrentAssetDiagram(false);
      // Force save to remove previous draft
      store.commit(`${VuexModuleNamespaces.diagram}/${DiagramStore.mutations.triggerStateChanged.name}`);
    } catch (err) {
      const errorString = 'Error resetting asset. \n';
      await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.setError.name}`, {
        error: err,
        errorString,
        handleError: true,
        routeHomeAfterError: true
      });
    }
    this.isDone = true;
    this.isLoading = false;
    this.isInitializing = false;
  }
}
