import { ITreeNode, TreeNodesTable } from '@/view-models/tree';
import { IAssetReportVariableViewModel } from '@/view-models/variables';
import { isStringEmpty } from '../string';

export class TreeTraverseHelper<T> {
  constructor(
    private readonly nodesTable: TreeNodesTable<T>
  ) {}

  public get allNodes(): ITreeNode<T>[] {
    return Object.values(this.nodesTable) ?? [];
  }

  public getRootNodes(rootAssetKey: string): ITreeNode<T>[] {
    return this.allNodes.filter(
      (node) => isStringEmpty(node.parentKey) || node.parentKey === rootAssetKey
    );
  }
  public getChildrenNodes(parent: ITreeNode<IAssetReportVariableViewModel>): ITreeNode<T>[] {
    return this.getNodesByKeys(parent.childrenKeys);
  }
  public getNodesByKeys(nodeKeys: string[]): ITreeNode<T>[] {
    const nodes: ITreeNode<T>[] = [];
    for (const nodeKey of nodeKeys) {
      if (nodeKey in this.nodesTable) {
        nodes.push(this.nodesTable[nodeKey]);
      }
    }

    return nodes;
  }
  public getNodesAncestors(nodes: ITreeNode<T>[], ancestors: TreeNodesTable<T> = {}): TreeNodesTable<T> {
    for (const node of nodes) {
      if (node.parentKey != null && !(node.parentKey in ancestors)) {
        const parent = this.nodesTable[node.parentKey];
        ancestors[node.parentKey] = parent;
        if (parent.childrenKeys.length > 0) {
          const children = this.getNodesByKeys(parent.childrenKeys);
          ancestors = this.getNodesAncestors(children, ancestors);
        }
      }
    }

    return ancestors;
  }
  public getNodesFilteredByKeysWithAncestry(nodeKeys: string[]): ITreeNode<T>[] {
    const filtered = this.getNodesByKeys(nodeKeys);
    const ancestors = this.getNodesAncestors(filtered);
    return [...filtered, ...Object.values(ancestors)];
  }
}
