





























import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'locked-modal'
})
export default class LockedModal extends Vue {

  private onAcknowledge(): void {
    this.$bvModal.hide('locked-modal');
  }

}
