// Interface Dependencies
import store, { IRootState, VuexModuleNamespaces } from '../../store';
// Vuex Dependencies
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
// Library Dependencies
import { Subject, merge } from 'rxjs';
import { debounceTime, map, filter } from 'rxjs/operators';
import { AssetDiagramService } from '@/services/assetDiagram/asset-diagram-service';
import sharedAxiosInstance from '@/services/common/api-service';
import { continuousSaveConfig } from '@/assets/configs/continuousSaveConfig';
import IAssetDiagramServiceResponse from '@/view-models/assetDiagram/asset-response-view-models';
import { IAssetDiagram } from '@/view-models/assetDiagram/asset-diagram';
import ConfigFactory from '@/services/config/config';
import { BurnerFolderStore } from '@/store//burnerFolder/burnerFolderStore';
import { BurnerLayoutStore } from '@/store/burnerLayout/burnerLayoutStore';
import { GridStore } from '@/store/grid/gridStore';
import { ErrorStore } from '@/store/error/errorStore';
import axios from 'axios';
import authInterceptor from '@/services/common/service-interceptor';
import { AppStore } from '../app/appStore';
import LoggerService from '@/services/logger/logger-service';
import { LogFeature } from '@/view-models/common/log-model';
import { IVariableDiagram } from '@/view-models/assetVariables/asset-variables-view-models';

export type IDiagramContext = ActionContext<IDiagramStoreState, IRootState>;

export type IDiagramStoreGetters = GetterTree<IDiagramStoreState, IRootState>;

export interface IDiagramStoreState {
  immediateChangedSubject: Subject<void>;
  stateChangedSubject: Subject<void>;
  isDiagramLocked: boolean;
  lastModifiedByName: string;
  lastModifiedDate: Date;
  status: string;
  diagram: string;
  loading: boolean;
  equipmentConfigKey: string;
  isSavePending: boolean;
  variableDiagram: IVariableDiagram[];
}

export interface IDiagramStoreMutations extends MutationTree<IDiagramStoreState> {
  triggerStateChanged(state: IDiagramStoreState): void;
  updateIsDiagramLocked(state: IDiagramStoreState, newIsDiagramLocked: boolean): void;
  updateLastModifiedByName(state: IDiagramStoreState, newLastModifiedByName: string): void;
  updateLastModifiedDate(state: IDiagramStoreState, newLastModifiedDate: Date): void;
  updateStatus(state: IDiagramStoreState, newStatus: string): void;
  updateDiagram(state: IDiagramStoreState, newDiagram: string): void;
  updateLoading(state: IDiagramStoreState, isLoading: boolean): void;
  updateEquipmentConfigKey(state: IDiagramStoreState, newEquipmentConfigKey: string): void;
  updateVariableDiagram(state: IDiagramStoreState, newVariableDiagram: IVariableDiagram): void;
}

export interface IDiagramStoreActions extends ActionTree<IDiagramStoreState, IRootState> {
  initializeDiagramSaver(context: IDiagramContext): void;
  retrieveAssetDiagram(context: IDiagramContext, assetKey: string): Promise<IAssetDiagramServiceResponse>;
  retrievePublishedDiagram(context: IDiagramContext, assetKey: string): Promise<IAssetDiagramServiceResponse>;
  bindAssetDiagram(context: IDiagramContext, response: IAssetDiagramServiceResponse): Promise<void>;
  publish(context: IDiagramContext): Promise<void>;
  flushDiagramSaver(diagramContext: IDiagramContext): void;
}

export const DiagramStore = {
  namespaced: true,
  state: {
    stateChangedSubject: new Subject<void>(),
    immediateChangedSubject: new Subject<void>(),
    isDiagramLocked: false,
    lastModifiedByName: '',
    lastModifiedDate: new Date(),
    status: '',
    diagram: '',
    loading: false,
    equipmentConfigKey: '',
    isSavePending: false,
    variableDiagram: []
  } as IDiagramStoreState,
  getters: {
    isDiagramLocked(state: IDiagramStoreState): null | boolean {
      return state.isDiagramLocked;
   },
    lastModifiedByName(state: IDiagramStoreState): null | string {
      return state.lastModifiedByName;
   },
    lastModifiedDate(state: IDiagramStoreState): null | Date {
      return state.lastModifiedDate;
   },
    status(state: IDiagramStoreState): null | string {
      return state.status;
   },
    diagram(state: IDiagramStoreState): null | string {
      return state.diagram;
   },
   isLoading(state: IDiagramStoreState): boolean {
     return state.loading;
   },
   isSavePending(state: IDiagramStoreState): boolean {
     return state.isSavePending;
   },
   equipmentConfigKey(state: IDiagramStoreState): null | string {
    return state.equipmentConfigKey;
   },
   variableDiagram(state: IDiagramStoreState): IVariableDiagram[] {
    return state.variableDiagram;
   }
  } as IDiagramStoreGetters,
  mutations: {
    triggerStateChanged(state: IDiagramStoreState): void {
      state.isSavePending = true;
      state.stateChangedSubject.next();
    },
    updateIsDiagramLocked(state: IDiagramStoreState, newIsDiagramLocked: boolean) {
      state.isDiagramLocked = newIsDiagramLocked;
    },
    updateLastModifiedByName(state: IDiagramStoreState, newLastModifiedByName: string) {
      state.lastModifiedByName = newLastModifiedByName;
    },
    updateLastModifiedDate(state: IDiagramStoreState, newLastModifiedDate: Date) {
      state.lastModifiedDate = newLastModifiedDate;
    },
    updateStatus(state: IDiagramStoreState, newStatus: string) {
      state.status = newStatus;
    },
    updateDiagram(state: IDiagramStoreState, newDiagram: string) {
      state.diagram = newDiagram;
    },
    updateLoading(state: IDiagramStoreState, isLoading: boolean) {
      state.loading = isLoading;
    },
    updateEquipmentConfigKey(state: IDiagramStoreState, newEquipmentConfigKey: string) {
      state.equipmentConfigKey = newEquipmentConfigKey;
    },
    updateVariableDiagram(state: IDiagramStoreState, newVariableDiagram: IVariableDiagram[]) {
      state.variableDiagram = newVariableDiagram;
    }
  } as IDiagramStoreMutations,
  actions: {
    initializeDiagramSaver(diagramContext: IDiagramContext): void {
      // Should only subscribe the save pipeline once per app instance.
      if (diagramContext.state.stateChangedSubject.observers.length > 0) { return; }
      const delayedObservable =  diagramContext.state.stateChangedSubject.pipe(
        debounceTime(continuousSaveConfig.continuousSaveInterval)
      );
      merge(delayedObservable, diagramContext.state.immediateChangedSubject).pipe(
        filter(() => {
            return !diagramContext.state.isDiagramLocked
              && !diagramContext.state.loading;
        }),
        map(async () => {
        // This API call uses its own axios instance (not the shared one) becuase
        // the gzip compression might cause race conditions with other API calls
        // causing base URIs to get crossed
        store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
        const privateAxiosInstance = axios.create();
        privateAxiosInstance.interceptors.request.use(authInterceptor);
        const conf = await ConfigFactory.GetConfig();
        const assetDiagramService = new AssetDiagramService(privateAxiosInstance,
          process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
          process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
          conf.get('adbApiUrl'));
        // Note: this service call is returning an awaited promise, i.e., the
        // response from the service regardless of success/failure.  This requires
        // errors to be manually handled in the 'subscribe' block.  It is possible
        // to do this async and include a call to catchError.  Refer to
        // https://rxjs-dev.firebaseapp.com/api/operators/catchError for specs
        return await assetDiagramService.saveCurrentAssetDiagram(false);
        })
      ).subscribe(async (promiseResponse) => {
        try {
          // Based on returned value, update the last updated date/user
          // If the state changed from unlocked to locked, make sure the
          // diagram is refreshed because it has been updated by another user
          // and lock the diagram down from the user being able to edit it further.
          const response = await promiseResponse;
          if (response) {
            if ((response?.lastModifiedByName !== diagramContext.state.lastModifiedByName
                || response?.locked !== diagramContext.state.isDiagramLocked)) {
              await diagramContext.dispatch('bindAssetDiagram', response);
            } else {
              diagramContext.commit('updateLastModifiedByName', response.lastModifiedByName);
              diagramContext.commit('updateLastModifiedDate', response.lastModifiedDate);
            }
          }
          diagramContext.state.isSavePending = false;
        } catch (err) {
          const errorString = 'Error saving asset diagram. \n';
          await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.setError.name}`, {
            error: err,
            errorString,
            handleError: true,
            routeHomeAfterError: false
          });
        }
      });
    },
    async flushDiagramSaver(diagramContext: IDiagramContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const loggerService: LoggerService = new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
      const additionalData = {
        assetKey: store.state.asset.selectedAsset?.key ?? ''
      };
      const message = LogFeature['AD-PreviewLayout'] + store.state.asset.selectedAsset?.name;
      await loggerService.addLogs(message, 'AD-PreviewLayout', additionalData);
      diagramContext.state.isSavePending = false;
      diagramContext.state.immediateChangedSubject.next();
    },
    async retrieveAssetDiagram(diagramContext: IDiagramContext, assetKey: string)
      : Promise<IAssetDiagramServiceResponse> {
      diagramContext.state.isSavePending = false;
      diagramContext.commit('updateLoading', true);
      const conf = await ConfigFactory.GetConfig();
      const assetDiagramService = new AssetDiagramService(sharedAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
        conf.get('adbApiUrl'));
      const response = await assetDiagramService.getAssetDiagram(assetKey, false, store.state.asset.selectedAsset?.equipmentConfigKey);
      diagramContext.commit('updateLoading', false);
      return response;
    },
    async retrievePublishedDiagram(diagramContext: IDiagramContext, assetKey: string)
      : Promise<IAssetDiagramServiceResponse> {
      diagramContext.state.isSavePending = false;
      const conf = await ConfigFactory.GetConfig();
      const assetDiagramService = new AssetDiagramService(sharedAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
        conf.get('adbApiUrl'));
      const response = await assetDiagramService.getAssetDiagram(assetKey, true, store.state.asset.selectedAsset?.equipmentConfigKey);
      return response;
    },
    async bindAssetDiagram(diagramContext: IDiagramContext, response: IAssetDiagramServiceResponse): Promise<void> {
      diagramContext.state.isSavePending = false;
      diagramContext.commit('updateLoading', true);
      diagramContext.commit('updateIsDiagramLocked', response.locked);
      diagramContext.commit('updateLastModifiedByName', response.lastModifiedByName);
      diagramContext.commit('updateLastModifiedDate', response.lastModifiedDate);
      diagramContext.commit('updateStatus', response.status);
      diagramContext.commit('updateDiagram', response.diagram);
      diagramContext.commit('updateEquipmentConfigKey', response.equipmentConfigKey);
      diagramContext.commit('updateVariableDiagram', response.variableDiagram);
      // Temporarily unlock burners.
      // The ones that are still on the diagram will get locked wuen updating from JSON below.
      // This is needed during reset and reloading from the data store.
      store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.unlockAll.name}`);
      if (response.diagram !== '{}') {
        let parsedDiagram: string | IAssetDiagram = response.diagram;
        while (typeof(parsedDiagram) === 'string') {
          parsedDiagram = JSON.parse(parsedDiagram);
        }
        store.commit(`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.mutations.setBurnerLayout.name}`,
                     parsedDiagram.burnerlayout);
        await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.updateGridContentsfromJSON.name}`,
                             parsedDiagram.diagram);
      } else {
        await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.updateGridContentsfromJSON.name}`,
                             {contents: []});
      }
      if (response.locked) {
        store.commit(`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.mutations.lockAll.name}`);
      }
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateIsGridFrozen.name}`, response.locked);
      diagramContext.commit('updateLoading', false);
    },
    async publish(diagramContext: IDiagramContext): Promise<void> {
      diagramContext.state.isSavePending = false;
      // This API call uses its own axios instance (not the shared one) becuase
      // the gzip compression might cause race conditions with other API calls
      // causing base URIs to get crossed
      const privateAxiosInstance = axios.create();
      privateAxiosInstance.interceptors.request.use(authInterceptor);
      const conf = await ConfigFactory.GetConfig();
      const assetDiagramService = new AssetDiagramService(privateAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
        conf.get('adbApiUrl'));
      await assetDiagramService.saveCurrentAssetDiagram(true).then(async (resp) => {
        const conf = await ConfigFactory.GetConfig();
        const loggerService: LoggerService = new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
        const additionalData = {
          assetKey: store.state.asset.selectedAsset?.key ?? ''
        };
        const message = LogFeature['AD-PublishLayout'] + store.state.asset.selectedAsset?.name;
        await loggerService.addLogs(message, 'AD-PublishLayout', additionalData);
        await diagramContext.dispatch('bindAssetDiagram', resp);
      });
    }
  } as IDiagramStoreActions
};
