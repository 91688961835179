

















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import BurnerReadingsNew from './BurnerReadingsNew.vue';
import store, { VuexModuleNamespaces } from '@/store';
import {
  adEventBus,
  adEvents,
} from '@/services/viewer/eventBus/asset-diagram-event-bus';
import { BurnerLayoutViewerStore } from '@/store/viewer/burnerLayout/burnerLayoutStore';
import { BurnerFolderViewerStore } from '@/store/viewer/burnerFolder/burnerFolderStore';
import CommentConfirmDiscardModal from '@/components/viewer/assetViewer/components/modals/CommentConfirmDiscardModal.vue';
import { PreviewGridViewerStore } from '@/store/viewer/previewGrid/previewGridStore';
import { AppViewerStore } from '@/store/viewer/app/appStore';
import { UserViewerStore } from '@/store/viewer/user/userStore';
import { IBurnerDetail } from '@/view-models/burner/burner-details-view-models';
import { BurnerReadingViewerStore } from '@/store/viewer/burnerReading/burnerReadingStore';

Vue.use(BootstrapVue);

@Component({
  name: 'burner-details-side-menu',
  components: {
    BurnerReadingsNew,
    CommentConfirmDiscardModal,
  },
})
export default class BurnerDetailsSideMenuNew extends Vue {
  // Vue JS Props...
  @Prop({ required: true })
  public assetKey!: string;

  private burnerKey: string = '';
  private sidePanelVisible: boolean = false;
  private isCommentingModeActiveInPL: boolean = false;

  get customerAndAssetDetails(): string {
    return JSON.stringify(store.getters[`${VuexModuleNamespaces.userViewer}/${UserViewerStore.getters.customerAssetDetails.name}`]);
  }
  public mounted() {
    this.closeSideMenu();
  }

  public setCommentingMode(event: CustomEvent) {
    this.isCommentingModeActiveInPL = event.detail[0];
  }

  public async updateFlagNotification() {
    store.commit(`${VuexModuleNamespaces.appViewer}/${AppViewerStore.mutations.updateActionTime.name}`);
    await store.dispatch(`${VuexModuleNamespaces.burnerFolderViewer}/${BurnerFolderViewerStore.actions.loadBurners.name}`);
    await store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.checkBurnerFlags.name}`);
  }

  public async created() {
    adEventBus.$on(adEvents.trySelectBurner, async (key: string) => {
      if (this.isCommentingModeActiveInPL) {
        this.$bvModal.show('comment-confirm-discard-modal');
        adEventBus.$on(adEvents.discardCommentConfirmed, () => {
          this.selectNewBurnerConfirmed(key);
          this.isCommentingModeActiveInPL = false;
        });
      } else {
        this.selectNewBurnerConfirmed(key);
      }
    });

    // React to when side panel is okay to close
    adEventBus.$on(adEvents.closeSideMenu, async () => {
      this.closeSideMenu();
    });
  }

  public closeSideMenu() {
    if (this.isCommentingModeActiveInPL) {
      this.$bvModal.show('comment-confirm-discard-modal');
      adEventBus.$on(adEvents.discardCommentConfirmed, () => {
        adEventBus.$emit(adEvents.isBurnerSelected, false);
        this.sidePanelVisible = false;
        adEventBus.$emit(adEvents.deselectBurner);
        this.isCommentingModeActiveInPL = false;
        this.burnerKey = '';
      });
    } else {
      adEventBus.$emit(adEvents.isBurnerSelected, false);
      this.sidePanelVisible = false;
      adEventBus.$emit(adEvents.deselectBurner);
      this.burnerKey = '';
    }
  }

  public async selectNewBurnerConfirmed(burnerKey: string) {
    // Set burner key and respond to burner selection event
    this.burnerKey = burnerKey;
    adEventBus.$emit(adEvents.selectBurner, this.burnerKey);

    adEventBus.$emit(adEvents.isBurnerSelected, true);
    this.sidePanelVisible = true;

    // Update burner readings and burner logs
    store.commit(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.mutations.setSelectedBurnerDetails.name}`, this.burnerKey);
    const burnerReadings = this.$refs.burnerReadings as BurnerReadingsNew;
    await burnerReadings && burnerReadings.initializeSelection(this.burnerKey);

  }

  get getBurnerReadingsTabTitle() {
    const selectedBurnerDetail =
      store.getters[
        `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters?.selectedBurnerDetail.name}`
      ];
    if (selectedBurnerDetail.burnerName !== null ||
        selectedBurnerDetail.burnerName !== undefined ||
        selectedBurnerDetail.burnerName !== '' ) {
      return selectedBurnerDetail.burnerName;
    } else {
      return '';
    }
  }

   get selectedBurnerDetail(): IBurnerDetail {
    return store.getters[`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.selectedBurnerDetail.name}`];
  }
}
