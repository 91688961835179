import store, { IRootState, VuexModuleNamespaces } from '..';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { fabric } from 'fabric';
import { gridConfig } from '@/assets/configs/gridConfig';
import { IGridContents, ICanvasContents, IHorizontalSpaceline, IToolTipPosition,
         IVerticalSpaceline } from '@/view-models/assetDiagram/grid-view-models';
import { IBurner, Burner } from '@/view-models/burner/burner-view-models';
import { VariableFolderStore } from '../variableFolder/variableFolderStore';
import { IAssetReportVariableViewModel } from '@/view-models/variables';

export interface IPreviewGridStoreState {
  previewGrid: fabric.Canvas;
  previousEditorGridBoxSize: number;
}

export interface IPreviewGridStoreActions
  extends ActionTree<IPreviewGridStoreState, IRootState> {
    setCanvasBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
    setCanvasBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
    setBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
    setBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void;
    updateGridContentsfromJSON(gridContext: IPreviewGridContext, gridContents: IGridContents): void;
    resizeObjectsForPreview(gridContext: IPreviewGridContext, isWindowResize: boolean): void;
    setArrangeShapePadding(gridContext: IPreviewGridContext): void;
    removeArrangeShapePadding(gridContext: IPreviewGridContext): void;
    setBurnerPadding(gridContext: IPreviewGridContext): void;
    alignTopLeft(gridContext: IPreviewGridContext): void;
    trimGridToFit(gridContext: IPreviewGridContext): void;
}

export interface IPreviewGridStoreGetters
  extends GetterTree<IPreviewGridStoreState, IRootState> {
    previewGrid(gridState: IPreviewGridStoreState): fabric.Canvas;
    randomPreviewValue(gridState: IPreviewGridStoreState): string;
}

export interface IPreviewGridStoreMutations
  extends MutationTree<IPreviewGridStoreState> {
    updatePreviewGrid(gridState: IPreviewGridStoreState, payload: fabric.Canvas): void;
    updatePreviewGridWidth(gridState: IPreviewGridStoreState, newWidth: number): void;
    updatePreviewGridHeight(gridState: IPreviewGridStoreState, newHeight: number): void;
}

export type IPreviewGridContext = ActionContext<IPreviewGridStoreState, IRootState>;

export function calculateToolTipPosition(target: fabric.Group,
                                         gridBoxSize: number,
                                         toolTipSpan: HTMLSpanElement): IToolTipPosition | undefined {
  const gridBoxWidth = gridBoxSize;
  const toolTipWidth = toolTipSpan.offsetWidth;
  const toolTipHeight = toolTipSpan.offsetHeight;
  const leftOffset = (toolTipWidth - gridBoxWidth) / 2;
  if (target.top !== undefined && target.left !== undefined) {
    // Prevent tooltip from getting out of screen bounds
    let toolTipLeft = target.left - leftOffset;
    let toolTipTop = target.top - toolTipHeight;

    // if target left is at 10 then show tooltip at 10; padding a little bit
    // if target is at the right end, then set the tooltip left where its total width is just visible
    if (target.left <= 10) {
      toolTipLeft = 10;
    } else if (target.left + toolTipWidth > target.canvas!.getWidth()) {
      toolTipLeft = target.canvas!.getWidth() - toolTipWidth - 10;
    }

    // if target top is at 10 just drop the tooltip just below target with a 25 padding
    if (target.top <= 10) {
      toolTipTop = target.top + target.height! + 25;
    }

    return {left: toolTipLeft, top: toolTipTop};
  }
  return undefined;
}

export const PreviewGridStore = {
  namespaced: true as true,
  state: {
    previewGrid: new fabric.Canvas('', {}),
    previousEditorGridBoxSize: gridConfig.defaultGridBoxSize,
  } as IPreviewGridStoreState,
  getters: {
    previewGrid(gridState: IPreviewGridStoreState): fabric.Canvas {
      return gridState.previewGrid;
    },
    randomPreviewValue(): string {
      const rand = Math.random() * 10 + Math.random();
      return rand.toFixed(2);
    }
  },
  mutations: {
    updatePreviewGrid(gridState: IPreviewGridStoreState, payload: fabric.Canvas) {
      gridState.previewGrid = payload;
    },
    updatePreviewGridWidth(gridState: IPreviewGridStoreState, newWidth: number) {
      gridState.previewGrid.setWidth(newWidth);
    },
    updatePreviewGridHeight(gridState: IPreviewGridStoreState, newHeight: number) {
      gridState.previewGrid.setHeight(newHeight);
    },
  },
  actions: {
    setCanvasBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.canvas?.on('mouse:out', (event: fabric.IEvent) => {
        const target: fabric.Group = event.target as fabric.Group;
        // Set shadow
        if (target && target === burnerGroup) {
          target.setShadow(undefined);
          target.hoverCursor = 'default';
          burnerGroup.canvas?.renderAll();
        }
      });
    },
    setBurnerGroupMouseOutHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.on('mouseout', () => {
        // Set tooltip
        gridContext.state.previewGrid.renderAll();
        const toolTipSpan: HTMLSpanElement | null | undefined =
           document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('preview-grid-tooltip');
        if (toolTipSpan) {
          toolTipSpan.classList.remove('show');
        }
      });
    },
    setCanvasBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.canvas?.on('mouse:over', (event: fabric.IEvent) => {
        const target: fabric.Group = event.target as fabric.Group;
        // Set shadow
        if (target && target === burnerGroup) {
          target.setShadow('0px 2px 5px rgba(0, 0, 0, 0.3)');
          target.hoverCursor = 'pointer';
          burnerGroup.canvas?.renderAll();
        }
      });
    },
    setBurnerGroupMouseOverHandler(gridContext: IPreviewGridContext, burnerGroup: fabric.Group): void {
      burnerGroup.on('mouseover', (event: fabric.IEvent) => {
        const target: fabric.Group = event.target as fabric.Group;
        // Set tooltip
        const burnerKey: string = event.target?.data.objectKey;
        const hoveredBurner: IBurner = Burner.getBurnerByKey(burnerKey);
        const toolTipSpan: HTMLSpanElement | null | undefined =
           document?.querySelector('#asset-diagram-builder')?.shadowRoot?.getElementById('preview-grid-tooltip');
        if (target && target.name === gridConfig.burnerGroupName) {
          if (toolTipSpan) {
            if (hoveredBurner?.name) {
              toolTipSpan.innerHTML = hoveredBurner.name;
              const toolTipPosition: IToolTipPosition | undefined =
                calculateToolTipPosition((event.target as fabric.Group),
                                         gridConfig.previewGridBoxSize, toolTipSpan);
              if (toolTipPosition) {
                toolTipSpan.style.left = toolTipPosition.left + 'px';
                toolTipSpan.style.top = (toolTipPosition.top - 5) + 'px';
                toolTipSpan.classList.add('show');
              }
            }
          }
        } else if (target && target.name === gridConfig.variableGroupName) {
          const variableKey: string = event.target?.data.objectKey;
          const hoveredVariable: IAssetReportVariableViewModel = store.getters[`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.getters.getVariableByKey.name}`](variableKey);
          if (toolTipSpan) {
            if (hoveredVariable.displayValues) {
              toolTipSpan.innerHTML = hoveredVariable.displayValues[0];
              const gridBoxWidth = gridConfig.previewGridBoxSize;
              toolTipSpan.style.fontSize = gridBoxWidth / 2 - 3 + 'px';
              const toolTipPosition:
                | IToolTipPosition
                | undefined = calculateToolTipPosition(
                event.target as fabric.Group,
                gridConfig.previewGridBoxSize,
                toolTipSpan
              );
              if (toolTipPosition) {
                toolTipSpan.style.left = toolTipPosition.left + 'px';
                toolTipSpan.style.top = toolTipPosition.top - 5 + 'px';
                toolTipSpan.classList.add('show');
              }
            }
          }
        }
      });
    },
    updateGridContentsfromJSON(gridContext: IPreviewGridContext, gridContents: IGridContents): void {
      // Disable event listener for adding objects
      gridContext.state.previewGrid.off('object:added');
      // Load grid from JSON
      if (gridContents?.contents && gridContents.contents.objects?.length > 0) {
        gridContext.state.previewGrid.loadFromJSON(gridContents.contents,
                  gridContext.state.previewGrid.renderAll.bind(gridContext.state.previewGrid));
      } else {
        const emptyGrid: ICanvasContents = {
          version: '3.6.3',
          objects: []
        };
        gridContext.state.previewGrid.loadFromJSON(emptyGrid,
                  gridContext.state.previewGrid.renderAll.bind(gridContext.state.previewGrid));
      }
      // Grab previous gridbox size
      gridContext.state.previousEditorGridBoxSize = gridContents?.gridBoxSize ?? gridConfig.defaultGridBoxSize;
      // Pre process objects for preview
      const gridObjects = gridContext.state.previewGrid.getObjects();
      // Style, hide, move, and set click listeners
      for (const gridObject of gridObjects) {
        // Make everything unselectable
        gridObject.selectable = false;
        // Stop event propagation on all arrange shapes
        if (gridObject.name === gridConfig.arrangeShapeName ||
            gridObject.name === gridConfig.arrangeShapeSizeControlsName ||
            gridObject.name === gridConfig.arrangeShapeControlsName ||
            gridObject.name === gridConfig.tempArrangeShapeName ||
            gridObject.name === gridConfig.tempArrangeShapeSizeControlsName ||
            gridObject.name === gridConfig.tempArrangeShapeControlsName) {
          gridObject.evented = false;
        }
        // Make spacelines invisible
        if (gridObject.name === gridConfig.arrangeShapeName &&
            gridObject.type === 'line') {
          gridObject.opacity = 0;
        // Link burner group text to value, not burner name
        } else if (gridObject.name === gridConfig.burnerGroupName || gridObject.name === gridConfig.variableGroupName) {
          const burnerGroup = gridObject as fabric.Group;
          // Set click handlers for burner
          gridContext.dispatch('setBurnerGroupMouseOutHandler', burnerGroup);
          gridContext.dispatch('setBurnerGroupMouseOverHandler', burnerGroup);
          gridContext.dispatch('setCanvasBurnerGroupMouseOutHandler', burnerGroup);
          gridContext.dispatch('setCanvasBurnerGroupMouseOverHandler', burnerGroup);
          const burnerGroupObjects = burnerGroup.getObjects();
          for (const burnerGroupObject of burnerGroupObjects) {
            if (burnerGroupObject.type === 'text') {
              const burnerText = burnerGroupObject as fabric.Text;
              const rand = Math.random() * 10 + Math.random();
              burnerText.text = rand.toFixed(2);
              burnerText.fontWeight = gridConfig.previewGridFontWeight;
              burnerText.fontFamily = gridConfig.previewGridFontFamily;
              if (Math.random() < 0.5) {
                burnerGroupObject.fill = gridConfig.previewNoChangesRecommendedFontColor;
              } else {
                burnerGroupObject.fill = gridConfig.previewChangesRecommendedFontColor;
              }
            } else if (burnerGroupObject.type === 'rect') {
              burnerGroupObject.stroke = 'rgba(0,0,0,0)';
              // Random setting for fill
              if (Math.random() < 0.5) {
                burnerGroupObject.fill = gridConfig.previewGridBoxColor;
              } else {
                burnerGroupObject.fill = gridConfig.previewGridBoxOffColor;
              }
            }
          }
          burnerGroup.sendToBack();
        // Hide label backgrounds and style label fonts
        } else if (gridObject.name === gridConfig.labelName) {
          const labelGroup = gridObject as fabric.Group;
          const labelGroupObjects = labelGroup.getObjects();
          for (const labelGroupObject of labelGroupObjects) {
            if (labelGroupObject.type === 'i-text') {
              const labelText = labelGroupObject as fabric.IText;
              labelText.fontWeight = gridConfig.previewGridFontWeight;
              labelText.fontFamily = gridConfig.previewGridFontFamily;
            } else {
              labelGroupObject.opacity = 0;
            }
          }
        }
      }
      gridContext.state.previewGrid.renderAll();
   },
   resizeObjectsForPreview(gridContext: IPreviewGridContext, isWindowResize: boolean): void {
     // Adjust grid object size for groups
     let scaleFactor = gridConfig.previewGridBoxSize / gridContext.state.previousEditorGridBoxSize;
     if (isWindowResize) {
       scaleFactor = 1;
     }
     // Resize burner and label groups
     const gridGroups = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
     for (const gridObject of gridGroups) {
       if (gridObject.name === gridConfig.burnerGroupName) {
         const groupObjects = gridObject.getObjects();
         groupObjects.forEach((groupObject) => {
           if (groupObject.type === 'rect') {
             groupObject.set('height', gridConfig.previewGridBoxSize);
             groupObject.set('width', gridConfig.previewGridBoxSize);
             groupObject.setCoords();
           } else if (groupObject.type === 'text') {
             const burnerText = groupObject as fabric.Text;
             const currentFontSize = burnerText.get('fontSize');
             if (currentFontSize) {
               burnerText.set('fontSize', currentFontSize * scaleFactor);
               burnerText.set('objectCaching', false);
               groupObject.setCoords();
             }
           }
         });
         const originalHeight = gridObject.get('height');
         const originalLeftCoord = gridObject.get('left');
         const originalTopCoord = gridObject.get('top');
         if (originalHeight !== undefined && originalLeftCoord !== undefined
             && originalTopCoord !== undefined) {
           const newLeftCoord = (originalLeftCoord / (originalHeight - gridConfig.burnerLineBorderWidth))
                                 * gridConfig.previewGridBoxSize;
           const newTopCoord = (originalTopCoord / (originalHeight - gridConfig.burnerLineBorderWidth))
                                * gridConfig.previewGridBoxSize;
           gridObject.set('height', gridConfig.previewGridBoxSize + gridConfig.burnerLineBorderWidth);
           gridObject.set('width', gridConfig.previewGridBoxSize + gridConfig.burnerLineBorderWidth);
           gridObject.set('left', newLeftCoord);
           gridObject.set('top', newTopCoord);
           gridObject.set('objectCaching', false);
           gridObject.setCoords();
         }
       } else if (gridObject.name === gridConfig.labelName) {
         const groupObjects = gridObject.getObjects();
         groupObjects.forEach((groupObject) => {
           if (groupObject.type === 'i-text') {
             const labelText = groupObject as fabric.IText;
             const textWidthVal = labelText.get('width');
             const textHeightVal = labelText.get('height');
             const textLeftVal = labelText.get('left');
             const textTopVal = labelText.get('top');
             const fontSizeVal = labelText.get('fontSize');
             if (textWidthVal !== undefined && textHeightVal !== undefined &&
                 textLeftVal !== undefined && textTopVal !== undefined &&
                 fontSizeVal !== undefined) {
               labelText.set({
                 width: textWidthVal * scaleFactor,
                 height: textHeightVal * scaleFactor,
                 left: textLeftVal * scaleFactor,
                 top: textTopVal * scaleFactor,
                 fontSize: fontSizeVal * scaleFactor,
                 objectCaching: false,
               });
               labelText.setCoords();
             }
           }
         });
         const heightVal = gridObject.get('height');
         const widthVal = gridObject.get('width');
         const leftVal = gridObject.get('left');
         const topVal = gridObject.get('top');
         if (heightVal !== undefined && widthVal !== undefined &&
             leftVal !== undefined && topVal !== undefined) {
           gridObject.set({
             left: leftVal * scaleFactor,
             top: topVal * scaleFactor,
             width: widthVal * scaleFactor,
             height: heightVal * scaleFactor,
             objectCaching: false
           });
           gridObject.setCoords();
         }
       }
     }
     // Adjust size for arrange shape lines
     const lineObjects = gridContext.state.previewGrid.getObjects('line') as fabric.Line[];
     for (const gridObject of lineObjects) {
       if (gridObject.name === gridConfig.arrangeShapeName ||
           gridObject.name === gridConfig.tempArrangeShapeName) {
         const x1Val = gridObject.get('x1');
         const y1Val = gridObject.get('y1');
         const x2Val = gridObject.get('x2');
         const y2Val = gridObject.get('y2');
         const topVal = gridObject.get('top');
         const leftVal = gridObject.get('left');
         if (x1Val !== undefined && x2Val !== undefined && y1Val !== undefined
           && y2Val !== undefined && topVal !== undefined && leftVal !== undefined) {
           gridObject.set({
             x1: x1Val * scaleFactor,
             x2: x2Val * scaleFactor,
             y1: y1Val * scaleFactor,
             y2: y2Val * scaleFactor,
             top: topVal * scaleFactor,
             left: leftVal * scaleFactor,
           });
           gridObject.setCoords();
         }
       }
     }
     // Adjust size for arrange shape rects
     const rectObjects = gridContext.state.previewGrid.getObjects('rect') as fabric.Rect[];
     for (const gridObject of rectObjects) {
       if (gridObject.name === gridConfig.arrangeShapeName ||
           gridObject.name === gridConfig.tempArrangeShapeName) {
            const sizeControlHeight = gridObject.get('height');
            const sizeControlWidth = gridObject.get('width');
            const sizeControlLeftCoord = gridObject.get('left');
            const sizeControlTopCoord = gridObject.get('top');
            if (sizeControlHeight !== null && sizeControlHeight !== undefined &&
                sizeControlWidth !== null && sizeControlWidth !== undefined &&
                sizeControlLeftCoord !== null && sizeControlLeftCoord !== undefined &&
                sizeControlTopCoord !== null && sizeControlTopCoord !== undefined) {
            gridObject.set({
              width: sizeControlWidth * scaleFactor,
              height: sizeControlHeight * scaleFactor,
              left: sizeControlLeftCoord * scaleFactor,
              top: sizeControlTopCoord * scaleFactor,
            });
            gridObject.setCoords();
          }
       }
     }
     // Adjust size for arrange shape ellipses
     const ellipseObjects = gridContext.state.previewGrid.getObjects('ellipse') as fabric.Ellipse[];
     for (const gridObject of ellipseObjects) {
       if (gridObject.name === gridConfig.arrangeShapeName ||
           gridObject.name === gridConfig.tempArrangeShapeName) {
            const sizeControlHeight = gridObject.get('ry');
            const sizeControlWidth = gridObject.get('rx');
            const sizeControlLeftCoord = gridObject.get('left');
            const sizeControlTopCoord = gridObject.get('top');
            if (sizeControlHeight !== null && sizeControlHeight !== undefined &&
                sizeControlWidth !== null && sizeControlWidth !== undefined &&
                sizeControlLeftCoord !== null && sizeControlLeftCoord !== undefined &&
                sizeControlTopCoord !== null && sizeControlTopCoord !== undefined) {
            gridObject.set({
              rx: sizeControlWidth * scaleFactor,
              ry: sizeControlHeight * scaleFactor,
              left: sizeControlLeftCoord * scaleFactor,
              top: sizeControlTopCoord * scaleFactor,
            });
            gridObject.setCoords();
          }
       }
     }
     gridContext.dispatch('trimGridToFit');
     gridContext.dispatch('setArrangeShapePadding');
     gridContext.dispatch('setBurnerPadding');
     gridContext.state.previewGrid.renderAll();
   },
   setArrangeShapePadding(gridContext: IPreviewGridContext): void {
     // Remove existing arrange shape padding
     gridContext.dispatch('removeArrangeShapePadding');
     // Find all arrange shapes
     const horizontalSpacelines: IHorizontalSpaceline[] = [];
     const verticalSpacelines: IVerticalSpaceline[] = [];
     const lineObjects = gridContext.state.previewGrid.getObjects('line') as fabric.Line[];
     for (const gridObject of lineObjects) {
       if (gridObject.name === gridConfig.arrangeShapeName || gridObject.name === gridConfig.tempArrangeShapeName) {
         const x1Val = gridObject.get('x1');
         const y1Val = gridObject.get('y1');
         const x2Val = gridObject.get('x2');
         const y2Val = gridObject.get('y2');
         const topVal = gridObject.get('top');
         const leftVal = gridObject.get('left');
         const widthVal = gridObject.get('width');
         const heightVal = gridObject.get('height');
         if (x1Val !== undefined &&
             x2Val !== undefined &&
             y1Val !== undefined &&
             y2Val !== undefined &&
             topVal !== undefined &&
             leftVal !== undefined &&
             widthVal !== undefined &&
             heightVal !== undefined) {
           if (y1Val === y2Val) {
            horizontalSpacelines.push({
              low: leftVal - (widthVal / 2) - .1,
              high: leftVal + (widthVal / 2) - .1,
              top: topVal,
              left: leftVal,
              y: topVal
            });
           } else if (x2Val === x1Val) {
            verticalSpacelines.push({
              low: topVal - (heightVal / 2) - .1,
              high: topVal + (heightVal / 2) - .1,
              top: topVal,
              left: leftVal,
              x: leftVal
            });
           }
         }
       }
     }
     // Adjust burners for spacelines
     const burnerObjects = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
     for (const gridObject of burnerObjects) {
       if (gridObject.name === gridConfig.burnerGroupName) {
         const groupObjects = gridObject.getObjects();
         groupObjects.forEach((groupObject) => {
           const burnerGroupX = gridObject.get('left');
           const burnerGroupY = gridObject.get('top');
           if (burnerGroupX !== undefined && burnerGroupY !== undefined) {
             if (groupObject.type === 'rect') {
               let lineOnTop = false;
               let lineOnBottom = false;
               let lineOnLeft = false;
               let lineOnRight = false;
               horizontalSpacelines.forEach((horizontalSpaceline) => {
                 // Find spaceline on top of burner square
                 if (Math.abs(burnerGroupY - horizontalSpaceline.y) < 0.01) {
                   if (horizontalSpaceline.low <= burnerGroupX && burnerGroupX <= horizontalSpaceline.high) {
                     lineOnTop = true;
                   }
                 }
                 // Find spaceline on bottom of burner square
                 if (Math.abs((burnerGroupY + gridConfig.previewGridBoxSize) - horizontalSpaceline.y) < 0.01) {
                   if (horizontalSpaceline.low <= burnerGroupX && burnerGroupX <= horizontalSpaceline.high) {
                     lineOnBottom = true;
                   }
                 }
               });
               // Add horizontal padding
               if (lineOnTop && lineOnBottom) {
                 groupObject.set('height', gridConfig.previewGridBoxSize - (2 * gridConfig.arrangeSpacePadSize));
               } else if (lineOnTop) {
                 groupObject.set('height', gridConfig.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                 gridObject.set('top', burnerGroupY + gridConfig.arrangeSpacePadSize -
                                (2 * gridConfig.burnerLineBorderWidth));
               } else if (lineOnBottom) {
                 groupObject.set('height', gridConfig.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                 gridObject.set('top', burnerGroupY - gridConfig.arrangeSpacePadSize +
                                (2 * gridConfig.burnerLineBorderWidth));
               }
               verticalSpacelines.forEach((verticalSpaceline) => {
                 // Find spaceline on left of burner square
                 if (Math.abs(burnerGroupX - verticalSpaceline.x) < 0.01) {
                   if (verticalSpaceline.low <= burnerGroupY && burnerGroupY <= verticalSpaceline.high) {
                     lineOnLeft = true;
                   }
                 }
                 // Find spaceline on right of burner square
                 if (Math.abs((burnerGroupX + gridConfig.previewGridBoxSize) - verticalSpaceline.x) < 0.01) {
                   if (verticalSpaceline.low <= burnerGroupY && burnerGroupY <= verticalSpaceline.high) {
                     lineOnRight = true;
                   }
                 }
               });
               // Add vertical padding
               if (lineOnLeft && lineOnRight) {
                 groupObject.set('width', gridConfig.previewGridBoxSize - (2 * gridConfig.arrangeSpacePadSize));
               } else if (lineOnLeft) {
                 groupObject.set('width', gridConfig.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                 gridObject.set('left', burnerGroupX + gridConfig.arrangeSpacePadSize -
                                (2 * gridConfig.burnerLineBorderWidth));
               } else if (lineOnRight) {
                 groupObject.set('width', gridConfig.previewGridBoxSize - gridConfig.arrangeSpacePadSize);
                 gridObject.set('left', burnerGroupX - gridConfig.arrangeSpacePadSize +
                                (2 * gridConfig.burnerLineBorderWidth));
               }
             }
           }
         });
       }
     }
   },
   removeArrangeShapePadding(gridContext: IPreviewGridContext): void {
     const burnerObjects = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
     for (const gridObject of burnerObjects) {
       if (gridObject.name === gridConfig.burnerGroupName) {
         const groupObjects = gridObject.getObjects();
         groupObjects.forEach((groupObject) => {
           if (groupObject.type === 'rect') {
             groupObject.set('height', gridConfig.previewGridBoxSize);
             groupObject.set('width', gridConfig.previewGridBoxSize);
             groupObject.setCoords();
           }
         });
         const currentTop = gridObject.get('top');
         const currentLeft = gridObject.get('left');
         if (currentTop !== undefined && currentLeft !== undefined) {
           const newTop = (Math.round(currentTop / gridConfig.previewGridBoxSize)
                           * gridConfig.previewGridBoxSize);
           const newLeft = (Math.round(currentLeft / gridConfig.previewGridBoxSize)
                           * gridConfig.previewGridBoxSize);
           gridObject.set('top', newTop);
           gridObject.set('left', newLeft);
           gridObject.setCoords();
         }
       }
     }
   },
   setBurnerPadding(gridContext: IPreviewGridContext): void {
     const burnerObjects = gridContext.state.previewGrid.getObjects('group') as fabric.Group[];
     for (const gridObject of burnerObjects) {
       if (gridObject.name === gridConfig.burnerGroupName) {
         const groupObjects = gridObject.getObjects();
         groupObjects.forEach((groupObject) => {
           if (groupObject.type === 'rect') {
             const currentHeight = groupObject.get('height');
             const currentWidth = groupObject.get('width');
             if (currentHeight !== null && currentHeight !== undefined &&
                 currentWidth !== null && currentWidth !== undefined) {
               groupObject.set('height', currentHeight - 2);
               groupObject.set('width', currentWidth - 2);
               groupObject.setCoords();
            }
           }
         });
       }
     }
   },
   alignTopLeft(gridContext: IPreviewGridContext): void {
     // Find offset cords
     let lowestYCoord = gridContext.state.previewGrid.getHeight();
     let lowestXCoord = gridContext.state.previewGrid.getWidth();
     const gridObjects = gridContext.state.previewGrid.getObjects();
     for (const gridObject of gridObjects) {
       const leftCoord = gridObject.getBoundingRect().left;
       const topCoord = gridObject.getBoundingRect().top;
       if (leftCoord !== null && leftCoord !== undefined &&
           lowestXCoord !== null && lowestXCoord !== undefined &&
           leftCoord < lowestXCoord) {
         lowestXCoord = leftCoord;
       }
       if (topCoord !== null && topCoord !== undefined &&
           lowestYCoord !== null && lowestYCoord !== undefined &&
           topCoord < lowestYCoord) {
         lowestYCoord = topCoord;
       }
     }
     // Offset objects
     for (const gridObject of gridObjects) {
       const currentTop = gridObject.top;
       const currentLeft = gridObject.left;
       if (currentTop !== null && currentTop !== undefined &&
           currentLeft !== null && currentLeft !== undefined &&
           lowestYCoord !== null && lowestYCoord !== undefined &&
           lowestXCoord !== null && lowestXCoord !== undefined) {
         // Set group coords
         gridObject.top = currentTop - lowestYCoord;
         gridObject.left = currentLeft - lowestXCoord;
         gridObject.setCoords();
       }
     }
     // Render
     gridContext.state.previewGrid.renderAll();
   },
   trimGridToFit(gridContext: IPreviewGridContext): void {
     // Adjust grid size from objects
     let highestYCoord = 0;
     let highestXCoord = 0;
     let desiredGridWidth = gridContext.state.previewGrid.getWidth();
     let desiredGridHeight = gridContext.state.previewGrid.getHeight();
     const gridObjects = gridContext.state.previewGrid.getObjects();
     for (const gridObject of gridObjects) {
       let leftCoord = gridObject.get('left');
       let topCoord = gridObject.get('top');
       // Add width/height to rect
       if (gridObject.type === 'rect') {
         const objectWidth = (gridObject as fabric.Rect).get('width');
         const objectHeight = (gridObject as fabric.Rect).get('height');
         const scaleX = (gridObject as fabric.Rect).get('scaleX');
         const scaleY = (gridObject as fabric.Rect).get('scaleY');
         if (leftCoord !== null && leftCoord !== undefined &&
             objectWidth !== null && objectWidth !== undefined &&
             topCoord !== null && topCoord !== undefined &&
             objectHeight !== null && objectHeight !== undefined &&
             scaleX && scaleY) {
           leftCoord = leftCoord + ((objectWidth * scaleX) / 2);
           topCoord = topCoord + ((objectHeight * scaleY) / 2);
         }
       }
       // Add width/height to ellipse
       if (gridObject.type === 'ellipse') {
         const objectWidth = (gridObject as fabric.Ellipse).get('width');
         const objectHeight = (gridObject as fabric.Ellipse).get('height');
         const scaleX = (gridObject as fabric.Ellipse).get('scaleX');
         const scaleY = (gridObject as fabric.Ellipse).get('scaleY');
         if (leftCoord !== null && leftCoord !== undefined &&
             objectWidth !== null && objectWidth !== undefined &&
             topCoord !== null && topCoord !== undefined &&
             objectHeight !== null && objectHeight !== undefined &&
             scaleX && scaleY) {
           leftCoord = leftCoord + ((objectWidth * scaleX) / 2);
           topCoord = topCoord + ((objectHeight * scaleY) / 2);
         }
       }
       if (leftCoord !== null && leftCoord !== undefined &&
           leftCoord > highestXCoord) {
         highestXCoord = leftCoord;
       }
       if (topCoord !== null && topCoord !== undefined &&
           topCoord > highestYCoord) {
         highestYCoord = topCoord;
       }
     }
     if (desiredGridWidth < highestXCoord) {
       desiredGridWidth = highestXCoord;
     }
     if (desiredGridHeight < highestYCoord) {
       desiredGridHeight = highestYCoord;
     }
     // Add two squares to provide a buffer
     gridContext.commit('updatePreviewGridWidth', desiredGridWidth +  (2 * gridConfig.previewGridBoxSize));
     gridContext.commit('updatePreviewGridHeight', desiredGridHeight + (2 * gridConfig.previewGridBoxSize));
     gridContext.state.previewGrid.renderAll();
   }
  }
};
