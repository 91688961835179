export enum AccessSettingEnum {
  Dashboards = 'Dashboards',
  Reports = 'Reports',
  Internal = 'Internal',
  All= 'All'
}

export enum MeasurementTypesEnum {
  Unknown = 'Unknown',
  Boolean = 'Boolean',
  Numeric = 'Numeric',
  Concentration = 'Concentration',
  Distance = 'Distance',
  Energy = 'Energy',
  FlowRate = 'FlowRate',
  O2MoleFraction = 'O2MoleFraction',
  Pressure = 'Pressure',
  Temperature = 'Temperature',
  Area = 'Area',
  Text = 'Text',
  FuelPropertyEnergy = 'FuelPropertyEnergy',
  FuelPressure = 'FuelPressure'
}

export enum UnitOfMeasurementEnum {
  // #region Generic Units
  Default = 'Default',
  Percent = 'Percent',
  Bool = 'Bool',
  Float = 'Float',
  Fraction = 'Fraction',
  String = 'String',
  // #endregion

  // #region Pressure Units
  Pa = 'Pa',
  psi = 'psi',
  psig = 'psig',
  psia = 'psia',
  inH2O = 'inH2O',
  mmH2O = 'mmH2O',
  inHg = 'inHg',
  bar = 'bar',
  barg = 'barg',
  mbar = 'mbar',
  mbarg = 'mbarg',
  kgcm2 = 'kgcm2',
  // #endregion

  // #region Concentration Units
  ppm = 'ppm',
  // #endregion

  // #region Temperature Units
  degF = 'degF',
  degC = 'degC',
  degR = 'degR',
  degK = 'degK',
  // #endregion

  // #region Distance Units
  ft = 'ft',
  in = 'in',
  m = 'm',
  mm = 'mm',
  // #endregion

  // #region Flow Rate Units
  kgs = 'kgs',
  kghr = 'kghr',
  lbms = 'lbms',
  lbmhr = 'lbmhr',
  klbmhr = 'klbmhr',
  // #endregion

  // #region Duty (Energy) Units
  W = 'W',
  MW = 'MW',
  Gcalhr = 'Gcalhr',
  MMBTUhr = 'MMBTUhr',
  // #endregion

  // #region FuelPropertyEnergy
  BTUscf = 'BTUscf',
  kCalkg = 'kCalkg',
  Jkg = 'Jkg',
  // #endregion

  // #region Area Units
  sqft = 'sqft',
  sqin = 'sqin',
  sqm = 'sqm',
  sqcm = 'sqcm',
  // #endregion

  // #region O2Mole Fraction
  DRY = 'DRY', // O2MoleFraction
  WET = 'WET', // O2MoleFraction
  // #endregion
  Unitless = 'Unitless'
}

export enum MeasurementConversion {
  'Percent' = '%',
  'degC' = '°C',
  'Unitless' = '',
  'Bool' = ''
}

export enum VariableMergeActionEnum {
  Merge = 'Merge',
  UnMerge = 'UnMerge',
  None = 'None'
}

export enum TreeFilterEnum {
  All = 'All',
  New = 'New',
  Merged = 'Merged',
  Custom = 'Custom'
}

export const ModelEfficacyMetrics = 'ModelEfficacyMetrics';
