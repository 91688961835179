







// Vue Dependencies
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// Vuex Dependencies
import store, { VuexModuleNamespaces } from '@/store/';
import { GridStore } from '@/store/grid/gridStore';
// Library Dependencies
import { fabric } from 'fabric';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'Grid'
})
export default class Grid extends Vue {

  private arrowKeyList = [
    'ArrowUp',
    'ArrowDown',
    'ArrowRight',
    'ArrowLeft'
  ];

  private mounted() {
    this.$nextTick().then(() => this.loadGrid());
  }

  private loadGrid() {
    const canvasElement: HTMLElement | null | undefined =
        document?.getElementById('asset-diagram-builder')?.shadowRoot?.getElementById('can');
    if (canvasElement) {
      fabric.util.addListener(document.body, 'keydown', (option: KeyboardEvent) => {
        if (!store.state.grid.editingLabel) {
          this.moveSelectedLabels(option);
        }
      });
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateEditorGrid.name}`,
          new fabric.Canvas(canvasElement as HTMLCanvasElement, {
            selection: true,
            selectionFullyContained: true,
            preserveObjectStacking: true,
            includeDefaultValues: false,
            selectionKey: 'ctrlKey'
          }));
      store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.setGridListeners.name}`);
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.updateIsGridFrozen.name}`,
          true);
    }
  }

  private moveSelectedLabels(event: KeyboardEvent) {
    if (this.arrowKeyList.indexOf(event.code) >= 0) {
      event.preventDefault();
      store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.moveSelectedLables.name}`, event);
    }
  }
}
