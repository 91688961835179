





























import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'layout-changed-modal'
})
export default class LayoutChangedModal extends Vue {

  private onAcknowledge(): void {
    this.$bvModal.hide('layout-changed-modal');
  }

}
