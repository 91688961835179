



















import { Vue, Component } from 'vue-property-decorator';
import { IAsset } from '@/view-models/asset/assets-view-models';
import store, { VuexModuleNamespaces } from '@/store/';
import { AssetStore } from '@/store/assetStore/assetStore';

@Component({
  name: 'asset-details'
})
export default class AssetDetails extends Vue {

  get selectedAsset(): IAsset {
    return store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
  }

  get customerName(): string {
    return this.findAssetParentTypeName(this.selectedAsset, 'Customer');
  }

  get siteName(): string {
    return this.findAssetParentTypeName(this.selectedAsset, 'Site');
  }

  get unitName(): string {
    return this.findAssetParentTypeName(this.selectedAsset, 'SiteGroup');
  }

  private findAssetParentTypeName(currentNode: IAsset, typeName: string): string {
    const parent =
        store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.findAssetParentType.name}`]
                     (currentNode, typeName);
    return parent ? parent.name : '-';
  }

}

