import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState, VuexModuleNamespaces } from '@/store';
import { burnerLayoutConfig } from '@/assets/configs/burnerLayoutConfig';
import { IBurnerVariableConfig } from '@/view-models/viewer/burnerLayout/burner-layout-view-models';
import { HeatUnit } from '@/services/viewer/utils/heat-release-conversion';
import {
  IViewerBurner,
  IFuelLine,
  IAirRegister,
} from '@/view-models/viewer/burnerReading/burner-reading';
import { IBurnerDetail } from '@/view-models/viewer/burner/burner-details-view-models';
import { BurnerFolder } from '@/view-models/viewer/burner/burner-folder-view-models';
import { IBurner, Burner } from '@/view-models/viewer/burner/burner-view-models';
import { ErrorViewerStore } from '@/store/viewer/error/errorStore';
import ConfigFactory from '@/services/config/config';
import LoggerService from '@/services/viewer/logger/logger-service';
import sharedAxiosInstance from '@/services/common/api-service';
import { LogFeature } from '@/view-models/viewer/common/log-model';

export type IBurnerLayoutContext = ActionContext<
  IBurnerLayoutStoreViewerState,
  IRootState
>;

export type IBurnerLayoutStoreGetters = GetterTree<
  IBurnerLayoutStoreViewerState,
  IRootState
>;

export interface IBurnerLayoutStoreViewerState {
  layout: IBurnerVariableConfig[];
  additionalAirRegisters: IAirRegister[];
  selectedHeatUnit: HeatUnit;
  fuelLines: IFuelLine[];
  airRegisters: IAirRegister[];
  selectedBurnerDetail: IBurnerDetail;
}

export interface IBurnerColorUpdate {
  varName: string;
  toValue: string;
}

export interface IBurnerPositionUpdate {
  varName: string;
  toValue: number;
}

export interface IBurnerShowUpdate {
  varName: string;
  toValue: boolean;
}

export interface IBurnerLayoutStoreMutations
  extends MutationTree<IBurnerLayoutStoreViewerState> {
  setBurnerLayout(
    state: IBurnerLayoutStoreViewerState,
    newLayout: IBurnerVariableConfig[]
  ): void;
  updateHeatUnit(
    state: IBurnerLayoutStoreViewerState,
    selectedHeatUnit: HeatUnit
  ): void;
}

export interface IBurnerLayoutStoreActions
  extends ActionTree<IBurnerLayoutStoreViewerState, IRootState> {
  updateColor(
    burnerLayoutContext: IBurnerLayoutContext,
    target: IBurnerColorUpdate
  ): void;
  updatePosition(
    burnerLayoutContext: IBurnerLayoutContext,
    target: IBurnerPositionUpdate
  ): void;
  updateShow(
    burnerLayoutContext: IBurnerLayoutContext,
    burnerShowUpdate: IBurnerShowUpdate
  ): void;
  updateBurnerReadings(
    burnerLayoutContext: IBurnerLayoutContext,
    burnerReadingsUpdate: IViewerBurner
  ): Promise<void>;
  getBurnerDetailBySelectedBurnerKey(
    burnerLayoutContext: IBurnerLayoutContext,
    selectedBurnerKey: string
  ): Promise<void>;
}

export const BurnerLayoutViewerStore = {
  namespaced: true,
  state: {
    layout: burnerLayoutConfig,
    additionalAirRegisters: [],
    selectedHeatUnit: HeatUnit.MMBTU_HR,
    fuelLines: [],
    airRegisters: [],
    selectedBurnerDetail: {
      burnerName: '',
      burnerPath: '',
      key: '',
    },
  } as IBurnerLayoutStoreViewerState,
  getters: {
    burnerLayout(burnerLayoutState: IBurnerLayoutStoreViewerState): object {
      return burnerLayoutState.layout;
    },
    fuelLines(burnerLayoutState: IBurnerLayoutStoreViewerState): object {
      return burnerLayoutState.fuelLines;
    },
    airRegisters(burnerLayoutState: IBurnerLayoutStoreViewerState): object {
      return burnerLayoutState.airRegisters;
    },
    selectedHeatUnit(burnerLayoutState: IBurnerLayoutStoreViewerState): HeatUnit {
      return burnerLayoutState.selectedHeatUnit;
    },
    selectedBurnerDetail(
      burnerLayoutState: IBurnerLayoutStoreViewerState
    ): IBurnerDetail {
      return burnerLayoutState.selectedBurnerDetail;
    },
    additionalAirRegisters(
      burnerLayoutState: IBurnerLayoutStoreViewerState
    ): IAirRegister[] {
      return burnerLayoutState.additionalAirRegisters;
    },
  } as IBurnerLayoutStoreGetters,
  mutations: {
    setBurnerLayout(
      state: IBurnerLayoutStoreViewerState,
      newLayout: IBurnerVariableConfig[]
    ): void {
      state.layout = newLayout;
    },
    updateHeatUnit(state: IBurnerLayoutStoreViewerState, selectedHeatUnit: HeatUnit) {
      state.selectedHeatUnit = selectedHeatUnit;
    },
  } as IBurnerLayoutStoreMutations,
  actions: {
    updateColor(burnerLayoutContext: IBurnerLayoutContext, burnerColorUpdate: IBurnerColorUpdate) {
      const target = burnerLayoutContext.state.layout.find(
        (x) => x.name === burnerColorUpdate.varName
      );
      if (target) {
        const swapTarget = burnerLayoutContext.state.layout.find(
          (x) => x.color === burnerColorUpdate.toValue
        );
        const swapColor = target.color;
        target.color = burnerColorUpdate.toValue;
        if (swapTarget) {
          swapTarget.color = swapColor;
        }
      }
      burnerLayoutContext.commit('diagram/triggerStateChanged', null, { root: true });
    },
    updatePosition(burnerLayoutContext: IBurnerLayoutContext, burnerPositionUpdate: IBurnerPositionUpdate) {
      const target = burnerLayoutContext.state.layout.find((x: any) => x.name === burnerPositionUpdate.varName);
      const indexArrayMap = [
        { position: 'topLeft' },
        { position: 'topRight' },
        { position: 'center' },
        { position: 'bottomLeft' },
        { position: 'bottomRight' }
      ];
      const position = indexArrayMap[burnerPositionUpdate.toValue].position;
      if (target) {
        const swapTarget = burnerLayoutContext.state.layout.find((x: any) => x.position === position && x.name !== burnerPositionUpdate.varName);
        const swapPosition = target.position;
        target.position = position;
        if (swapTarget) {
          swapTarget.position = swapPosition;
        }
      }
      burnerLayoutContext.commit('diagram/triggerStateChanged', null, { root: true });
    },
    async updateBurnerReadings(burnerLayoutContext: IBurnerLayoutContext, burnerReadingsUpdate: IViewerBurner): Promise<void> {
      burnerLayoutContext.state.layout.forEach((burnerReading: any) => {
        // Zero out burner reading and additional air registers
        burnerReading.value = undefined;

        // Update burner readings
				switch (burnerReading.name) {
					case 'heatRelease': burnerReading.value = burnerReadingsUpdate.heatRelease;
						break;
					case 'outOfControl': burnerReading.value = burnerReadingsUpdate.outOfControllableRange ? 1 : 0;
						break;
					case 'eqRatio': burnerReading.value = burnerReadingsUpdate.equivalenceRatio;
						break;
					case 'opportunity': burnerReading.value = burnerReadingsUpdate.opportunityScore;
						break;
					case 'recommendedAir':
					case 'currentAir': burnerLayoutContext.state.airRegisters = burnerReadingsUpdate.airRegisters!;
            burnerLayoutContext.state.additionalAirRegisters = [];
						if (Array.isArray(burnerReadingsUpdate.airRegisters) && burnerReadingsUpdate.airRegisters.length) {
							let primaryIndex = burnerReadingsUpdate.airRegisters.findIndex((item) => item.isPrimary);
							if (!primaryIndex || primaryIndex < 0) {
								primaryIndex = 0;
							}
							burnerReading.value = burnerReading.name === 'currentAir' ? burnerReadingsUpdate.airRegisters[primaryIndex].currentSetting : burnerReadingsUpdate.airRegisters[primaryIndex].recommendedSetting;
              burnerLayoutContext.state.additionalAirRegisters = burnerReadingsUpdate.airRegisters.filter((airRegister, index) => {
								return index !== primaryIndex;
							});
						} else {
							burnerReading.value = undefined;
						}
						break;
					default:
				}
      });
      if (Array.isArray(burnerReadingsUpdate.fuelLines)) {
        burnerLayoutContext.state.fuelLines = burnerReadingsUpdate.fuelLines;
      }
    },
    updateShow(burnerLayoutContext: IBurnerLayoutContext, burnerShowUpdate: IBurnerShowUpdate) {
      const target = burnerLayoutContext.state.layout.find((x) => x.name === burnerShowUpdate.varName);
      if (target) {
        target.show = burnerShowUpdate.toValue;
      }
      burnerLayoutContext.commit('diagram/triggerStateChanged', null, {
        root: true,
      });
    },
    async getBurnerDetailBySelectedBurnerKey(burnerLayoutContext: IBurnerLayoutContext, selectedBurnerKey: string): Promise<void> {
      try {
        const selectedBurner: IBurner = Burner.getBurnerByKey(selectedBurnerKey);
        const burnerDetail: IBurnerDetail = {
          burnerName: '',
          burnerPath: '',
          key: selectedBurnerKey,
        };
        burnerDetail.burnerName = selectedBurner.name;
        const burnersFolderKey = selectedBurner.emberHierarchyLevelKey;
        let path = '';
        if (burnersFolderKey) {
          const burnerPath: string[] = BurnerFolder.getBurnerFolderByEmberHierarchyLevelKey(burnersFolderKey);
          burnerPath.reverse();
          burnerPath.forEach((level) => {
            path += level + '/';
          });
          path = path + selectedBurner.name;
        }
        burnerDetail.burnerPath = path;
        const conf = await ConfigFactory.GetConfig();
        const loggerService: LoggerService = new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
        const additionalData = {
          burnerKey: burnerDetail.key
        };
        const message = LogFeature['AD-BurnerClicked'] + burnerDetail.burnerName;
        await loggerService.addLogs(message, 'AD-BurnerClicked', additionalData);
        burnerLayoutContext.state.selectedBurnerDetail = burnerDetail;
      } catch (err) {
        const errorString = 'Error finding the selected burner. \n';
        await store.dispatch(
          `${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.setError.name}`,
          {
            error: err,
            errorMessageString: errorString,
            handleError: false,
            routeHomeAfterError: false,
          }
        );
        burnerLayoutContext.state.selectedBurnerDetail = {
          burnerName: `Unknown Burner (${selectedBurnerKey})`,
          burnerPath: '--',
          key: selectedBurnerKey,
        };
      }
    },
  } as IBurnerLayoutStoreActions,
};
