import ConfigFactory from '@/services/config/config';
import store, { VuexModuleNamespaces } from '@/store/';
import { ErrorStore } from '@/store/error/errorStore';
import { inParentApp, getParentAppSdk, IAuthClient, authClient as parentAuthClient } from '@/utils/from-parent';

let authClient: IAuthClient | null = null;

async function getAuthClient(): Promise<IAuthClient> {
  if (authClient == null) {
    const conf = await ConfigFactory.GetConfig();
    let client = getParentAppSdk()?.authClient;
    if (client == null) {
      client = parentAuthClient;
      await client.init({
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN ?
          process.env.VUE_APP_AUTH0_DOMAIN :
          conf.get('domain'),
        authClientID: process.env.VUE_APP_AUTH0_CLIENT_ID ?
          process.env.VUE_APP_AUTH0_CLIENT_ID :
          conf.get('clientId'),
        authAudience: process.env.VUE_APP_AUTH0_AUDIENCE ?
          process.env.VUE_APP_AUTH0_AUDIENCE :
          conf.get('audience'),
        authRedirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI ?
          process.env.VUE_APP_AUTH0_REDIRECT_URI :
          ((window as any).eftEventBus ? conf.get('redirectUriParent') : conf.get('redirectUriStandalone'))
      });
    }
    authClient = client;
  }
  return authClient as IAuthClient;
}

export async function getAuthToken(): Promise<any> {
  const client = await getAuthClient();
  return client.getAuthToken();
}

export const authGuard = async (to: any, from: any, next: () => void) => {
  // Silently check for the presence of a token
  getAuthToken().then(() => {
    next();
  }).catch(async () => {
    const client = await getAuthClient();
    client.loginWithRedirect();
  });
};

if (!inParentApp()) {
  window.addEventListener('load', async () => {
    if ( window.location.search.includes('code=') &&
        window.location.search.includes('state=')) {
      try {
        // Only process this event if you are expecting it, i.e., your Auth0 client is initialized
        if (authClient !== null) {
          const client = await getAuthClient();
          await client.handleRedirectCallback();
        }
      } catch (err) {
        const errorString = 'Authorization error. \n' + JSON.stringify(err);
        await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.setError.name}`, {
          error: errorString,
          handleError: true,
          routeHomeAfterError: false
        });
      }
    }
  });
}
