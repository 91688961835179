

















































import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
import ViewerToolbar from '@/components/viewer/assetViewer/components/ViewerToolbar.vue';
import BurnerDetailsSideMenuNew from '@/components/viewer/assetViewer/components/BurnerDetailsSideMenuNew.vue';
import store, { VuexModuleNamespaces } from '@/store';
import { AppViewerStore } from '@/store/viewer/app/appStore';
import { ErrorViewerStore } from '@/store/viewer/error/errorStore';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';
import { BurnerReadingViewerStore } from '@/store/viewer/burnerReading/burnerReadingStore';
import { BurnerFolderViewerStore } from '@/store/viewer/burnerFolder/burnerFolderStore';
import { DiagramViewerStore } from '@/store/viewer/diagram/diagramStore';
import GridPreview from '@/components/viewer/common/GridPreview.vue';
import Loading from '@/components/viewer/common/Loading.vue';
import { adEventBus, adEvents } from '@/services/viewer/eventBus/asset-diagram-event-bus';
import { UserViewerStore } from '@/store/viewer/user/userStore';
import { GridViewerStore } from '@/store/viewer/grid/gridStore';
import { PreviewGridViewerStore } from '@/store/viewer/previewGrid/previewGridStore';
import { AssetVariableStore } from '@/store/viewer/assetVariable/assetVariableStore';
import { IBurnerVariableConfig } from '@/view-models/burnerLayout/burner-layout-view-models';
import { BurnerLayoutViewerStore } from '@/store/viewer/burnerLayout/burnerLayoutStore';
import { BurnerLayoutConfigEnum } from '@/enums/BurnerLayoutConfigEnum';
import { VariableFolderStore } from '@/store/variableFolder/variableFolderStore';

@Component({
  name: 'asset-viewer',
  components: {
    ViewerToolbar,
    GridPreview,
    Loading,
    BurnerDetailsSideMenuNew
  }
})
export default class AssetViewer extends Vue {
  @Prop({ required: true })
  public assetkey!: string;

  private isBurnerSelected: boolean = false;
  private isLoading: boolean = false;
  private isNotFound: boolean = false;
  private isNoPublishedFound: boolean = false;
  private refreshTimer: any;
  public staleTooltip: boolean = false;

  @Watch('assetkey', { immediate: true })
  public async onAssetKeyChange(newKey: string, oldKey: string): Promise<void> {
    if ( newKey && newKey !== oldKey) {
      store.commit(`${VuexModuleNamespaces.diagramViewer}/${DiagramViewerStore.mutations.resetStore.name}`);
      store.commit(`${VuexModuleNamespaces.gridViewer}/${GridViewerStore.mutations.resetStore.name}`);
      store.commit(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.mutations.resetStore.name}`);
      await this.bindAsset();
    }
  }

  get lastEmberUpdate() {
    return store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getLastReadingDate.name}`];
  }

  get staleRecommendationThreshold() {
    return store.getters[`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.getters.getStaleRecommendationThreshold.name}`];
  }

  get staleRecommendationImg() {
    return require('../../../assets/images/icons/BurnerLayout/stale-recommendation.svg');
  }

  get currentUser(): any {
    return store.state.userViewer.currentUser;
  }

  get isInternalUser(): boolean {
    return this.currentUser.isSuperUser! || !this.currentUser.isCustomerUser;
  }

   get emberRecommendationsFlag(): boolean {
    const foundAsset =
      store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.findAsset.name}`](this.receivedAssetKey);
    if (foundAsset) {
      return foundAsset.showEmberRecommendations;
    }
    return false;
  }
  get showRecommendations(): boolean {
    return this.isInternalUser || (this.currentUser.isCustomerUser && this.emberRecommendationsFlag);
  }

   get assetVariablesList() {
    return store.getters[`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.getters.getAssetVariableslist.name}`];
  }

  get layout(): IBurnerVariableConfig[] {
    return store.getters[`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.burnerLayout.name}`];
  }

  get showAssetVariables() {
    return this.layout.find(flag => flag.name === BurnerLayoutConfigEnum.assetVariables)?.show ?? false;
  }

  get isAssetVariablesVisible() {
    return this.assetVariablesList.length > 0 && this.showAssetVariables;
  }

  // Lifecycle hooks...
  public created() {
    adEventBus.$on(adEvents.isBurnerSelected, (value: boolean) => {
      this.isBurnerSelected = value;
    });
  }

  public async bindAsset(): Promise<void> {
    this.isLoading = true;
    // Fetch diagram and burner info
    store.commit(`${VuexModuleNamespaces.appViewer}/${AppViewerStore.mutations.updateActionTime.name}`);
    this.getUserDetails();
    const storedKey = this.receivedAssetKey;
    if (storedKey !== this.assetkey) {
      store.commit(`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.mutations.updateReceivedAssetKey.name}`, this.assetkey);

      // Find the asset and set selected in the store;
      await store.dispatch(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.tryExecute.name}`, {
        action: async () => store.dispatch(`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.actions.loadAssets.name}`),
        errorMsg: 'Error loading asset.'
      });

      const found = store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.findAsset.name}`](this.assetkey);
      if (!found) {
        this.isLoading = false;
        this.isNotFound = true;
        return;
      } else {
        store.commit(`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.mutations.selectAsset.name}`, found);
      }
    }
    await store.dispatch(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.tryExecute.name}`, {
      action: this.loadAndBindDiagram,
      errorMsg: 'Error loading Published Diagram'
    });
    // Load burner info
    await store.dispatch(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.tryExecute.name}`, {
      action: async () => store.dispatch(`${VuexModuleNamespaces.burnerFolderViewer}/${BurnerFolderViewerStore.actions.loadBurners.name}`),
      errorMsg: 'Error retrieving burner information.'
    });
      await store.dispatch(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.tryExecute.name}`, {
        action: async () => store.dispatch(`${VuexModuleNamespaces.variableFolder}/${VariableFolderStore.actions.loadPreviewVariables.name}`, this.assetkey),
        errorMsg: 'Error retrieving variable information.'
      });
    // Do not retrieve burner readings for unknown assets to avoid throwing error
    if (this.isNotFound || this.isNoPublishedFound) {
      return;
    }

     await store.dispatch(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.tryExecute.name}`, {
      action: async () => store.dispatch(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.actions.getEMABurnerList.name}`),
      errorMsg: 'Error retrieving EMA burner list.'
    });

    await store.dispatch(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.actions.tryExecute.name}`, {
      action: async () => store.dispatch(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.actions.retrieveBurnerReadingsLT.name}`),
      errorMsg: 'Error retrieving burner reading.'
    });

    this.isLoading = false;
  }

  public async getUserDetails(): Promise<void> {
    await store.dispatch(`${VuexModuleNamespaces.userViewer}/${UserViewerStore.actions.getCurrentUser.name}`);
  }

  public get receivedAssetKey(): string {
    return store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.receivedAssetKey.name}`];
  }

  public async loadAndBindDiagram(): Promise<void> {
    const diagram = await store.dispatch(`${VuexModuleNamespaces.diagramViewer}/${DiagramViewerStore.actions.retrievePublishedDiagram.name}`, this.assetkey);
    if (diagram.variableDiagram?.variableDetails?.length > 0) {
      store.commit(`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.mutations.setVariableDetails.name}`, diagram.variableDiagram?.variableDetails);
    }
    if (diagram.diagram === '{}') {
      this.isLoading = false;
      this.isNotFound = true;
      this.isNoPublishedFound = true;
    } else {
      await store.dispatch(`${VuexModuleNamespaces.diagramViewer}/${DiagramViewerStore.actions.bindAssetDiagram.name}`, diagram);
    }
  }

  public toggleWarningTooltip(value: boolean) {
    this.staleTooltip = value;
  }
}
