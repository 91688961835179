import store, { VuexModuleNamespaces } from '@/store/';
import { BurnerFolderStore } from '@/store/burnerFolder/burnerFolderStore';

export interface IBurner {
  airRegisters?: any[];
  emberHierarchyLevelKey?: string; // UUID
  fuelLines?: any[];
  groupKey: string; // UUID
  isFlagged: boolean;
  lastReadingTime: string; // DateTime
  lastReadingType: string;
  lastReadingWasCorrection: boolean;
  opportunityScore: number;
  orderIndex: number;
  key: string; // UUID
  name: string;
  createdAt: string; // DateTime
  burnerConfigKey?: string; // MAPPED FROM HB
  burnerConfigZoneType?: string; // MAPPED FROM HB
  burnerConfigName?: string; // MAPPED FROM HB
  burnerIdentifier?: string; // MAPPED FROM HB
  burnerName?: string; // MAPPED FROM HB
  eloName?: string; // MAPPED FROM HB
  opportunityPriority?: string; // MAPPED FROM HB
}

export interface IHBBurner {
  burnerConfigKey: string;
  burnerConfigZoneType: string;
  burnerConfigName: string;
  burnerIdentifier: string;
  burnerName: string;
  eloName: string;
  emberHierarchyLevelKey: string;
  orderIndex: number;
  opportunityPriority: string;
}

export class Burner implements IBurner {

  public static getBurnerByKey(key: string): IBurner {
    return store.getters[`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.getBurnerByKey.name}`]
                        (key);
  }

  public static getBurnersByKeys(keys: string[]): IBurner[] {
    return store.getters[`${VuexModuleNamespaces.burnerFolder}/${BurnerFolderStore.getters.getBurnersByKeys.name}`]
                        (keys);
  }

  public airRegisters: [];
  public emberHierarchyLevelKey: string; // UUID
  public fuelLines: [];
  public groupKey: string; // UUID
  public isFlagged: boolean;
  public lastReadingTime: string; // DateTime
  public lastReadingType: string;
  public lastReadingWasCorrection: boolean;
  public opportunityScore: number;
  public orderIndex: number;
  public key: string; // UUID
  public name: string;
  public createdAt: string; // DateTime

  constructor(data: any) {
    this.airRegisters = data.airRegisters ;
    this.emberHierarchyLevelKey = data.emberHierarchyLevelKey;
    this.fuelLines = data.fuelLines;
    this.groupKey = data.groupKey;
    this.isFlagged = data.isFlagged;
    this.lastReadingTime = data.lastReadingTime;
    this.lastReadingType = data.lastReadingType;
    this.lastReadingWasCorrection = data.lastReadingWasCorrection;
    this.opportunityScore = data.opportunityScore;
    this.orderIndex = data.orderIndex;
    this.key = data.key;
    this.name = data.name;
    this.createdAt = data.createdAt;
  }
}
