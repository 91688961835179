import { IBurnerVariableConfig } from '@/view-models/burnerLayout/burner-layout-view-models';

export const burnerLayoutConfig: IBurnerVariableConfig[] = [
  {
    name: 'heatRelease',
    show: true,
    position: 'topLeft',
    color: 'brown',
    value: 1.4975931677 // Converts to 5.11
  },
  {
    name: 'outOfControl',
    show: true,
    value: 1
  },
  {
    name: 'eqRatio',
    show: true,
    position: 'bottomLeft',
    color: 'purple',
    value: 1.15
  },
  {
    name: 'opportunity',
    show: true,
    position: 'bottomRight',
    color: 'green',
    value: 4
  },
  {
    name: 'recommendedAir',
    show: true,
    position: 'center',
    color: 'orange',
    value: 10
  },
  {
    name: 'currentAir',
    show: true,
    position: 'topRight',
    color: 'white',
    value: 8
  },
  {
    name: 'assetVariables',
    show: false
  }
];
