import {
  IBurnerReading,
  IBurnerInfo,
  PrimaryDisplayAttribute,
  IViewerBurner,
  IAirRegister,
  IVariableInfo,
} from '@/view-models/viewer/burnerReading/burner-reading';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState, VuexModuleNamespaces } from '../../index';
import { BurnerReadingService } from '@/services/viewer/burnerReading/burner-reading-service';
import sharedAxiosInstance from '@/services/viewer/common/api-service';
import ConfigFactory from '@/services/config/config';
import { BurnerLayoutViewerStore } from '@/store/viewer/burnerLayout/burnerLayoutStore';
import { DiagramViewerStore } from '@/store/viewer/diagram/diagramStore';
import { PreviewGridViewerStore } from '@/store/viewer/previewGrid/previewGridStore';
import { IBurnerDetails, IAirRegiterDetails } from '@/view-models/viewer/burnerReading/burner-details';
import { IAsset } from '@/view-models/viewer/asset/assets-view-models';
import { EMABurnerDetailsService } from '@/services/viewer/emaBurnerDetails/ema-burner-details-service';
import { AssetViewerStore } from '@/store/viewer/assetStore/assetStore';
import LoggerService from '@/services/viewer/logger/logger-service';
import { LogFeature } from '@/view-models/viewer/common/log-model';
import { UserViewerStore } from '../user/userStore';

export interface IBurnerReadingStoreViewerState {
  burnerReading: IBurnerReading;
  isFetchComplete: boolean;
  primaryDisplayAttribute: PrimaryDisplayAttribute;
  showEmberRecommendations: boolean;
  emaBurnerList: IBurnerDetails[];
  selectedBurnerDetails: IBurnerDetails;
}

export interface IBurnerReadingStoreGetters
  extends GetterTree<IBurnerReadingStoreViewerState, IRootState> {
  getBurnerReadings(state: IBurnerReadingStoreViewerState): IBurnerReading;
  getIsFetchComplete(state: IBurnerReadingStoreViewerState): boolean;
  getLastReadingDate(state: IBurnerReadingStoreViewerState): string;
  getPrimaryDisplayAttribute(
    state: IBurnerReadingStoreViewerState
  ): PrimaryDisplayAttribute;
  getEMABurnerList(state: IBurnerReadingStoreViewerState): IBurnerDetails[];
  getSelectedBurnerDetails(state: IBurnerReadingStoreViewerState): IBurnerDetails;
  getVariableDetails(state: IBurnerReadingStoreViewerState): IVariableInfo[];
}

export interface IBurnerReadingStoreMutations
  extends MutationTree<IBurnerReadingStoreViewerState> {
  updatePrimaryDisplayAttribute(
    state: IBurnerReadingStoreViewerState,
    selectedDisplayAttribute: PrimaryDisplayAttribute
  ): void;
  setShowEmberRecommendations(
    state: IBurnerReadingStoreViewerState,
    value: boolean
  ): void;
  setSelectedBurnerDetails(
    state: IBurnerReadingStoreViewerState,
    burnerKey: string
  ): void;
}

export interface IBurnerReadingStoreActions
  extends ActionTree<IBurnerReadingStoreViewerState, IRootState> {
  retrieveBurnerReadingsLT(context: IBurnerReadingContext): Promise<void>;
  retrieveBurnerReadings(context: IBurnerReadingContext): Promise<void>;
  getBurnerReadingsByKey(context: IBurnerReadingContext,selectedBurnerKey: string): Promise<IBurnerInfo>;
  getEMABurnerList(context: IBurnerReadingContext): Promise<void>;
}

export type IBurnerReadingContext = ActionContext<
  IBurnerReadingStoreViewerState,
  IRootState
>;

export const UNKNOWN_READINGS: IBurnerInfo = {
  key: '',
  name: '',
  heatRelease: -999999,
  equivalenceRatio: -999999,
  outOfControllableRange: false,
  opportunityScore: -999999,
  dryO2: '',
  wetO2: '',
  airRegisters: [
    {
      name: '',
      currentSetting: -999999,
      recommendedSetting: -999999,
      isPrimary: true,
    },
  ],
  fuelLines: [],
};

export const BurnerReadingViewerStore = {
  namespaced: true as true,
  state: {
    burnerReading: {},
    isFetchComplete: false,
    showEmberRecommendations: false,
    primaryDisplayAttribute: PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER,
    selectedBurnerDetails: {},
    emaBurnerList: {},
  } as IBurnerReadingStoreViewerState,
  getters: {
    getBurnerReadings(state: IBurnerReadingStoreViewerState): IBurnerReading {
      return state.burnerReading;
    },
    getIsFetchComplete(state: IBurnerReadingStoreViewerState): boolean {
      return state.isFetchComplete;
    },
    getLastReadingDate(state: IBurnerReadingStoreViewerState): string {
      return state.burnerReading.dateTime;
    },
    getStaleRecommendationThreshold(state: IBurnerReadingStoreViewerState): string | null {
      return state.burnerReading.staleRecommendationThreshold ? state.burnerReading.staleRecommendationThreshold : null;
    },
    getPrimaryDisplayAttribute(
      state: IBurnerReadingStoreViewerState
    ): PrimaryDisplayAttribute {
      return state.primaryDisplayAttribute;
    },
    getEmberRecommendationsFlag(
      state: IBurnerReadingStoreViewerState
    ): boolean {
      return state.showEmberRecommendations;
    },
    getEMABurnerList(state: IBurnerReadingStoreViewerState): IBurnerDetails[] {
      return state.emaBurnerList;
    },
    getSelectedBurnerDetails(state: IBurnerReadingStoreViewerState): IBurnerDetails {
      return state.selectedBurnerDetails;
    },
    getVariableDetails(state: IBurnerReadingStoreViewerState): IVariableInfo[] | null {
      if (state.burnerReading.variables !== undefined) {
        return state.burnerReading.variables;
      } else {
        return null;
      }
    }
  } as IBurnerReadingStoreGetters,
  mutations: {
    async updatePrimaryDisplayAttribute(state: IBurnerReadingStoreViewerState, selectedDisplayAttribute: PrimaryDisplayAttribute) {
      state.primaryDisplayAttribute = selectedDisplayAttribute;
      const conf = await ConfigFactory.GetConfig();
      const loggerService: LoggerService = new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
      const additionalData = {};
      const message = LogFeature['AD-FilterChanged'] + selectedDisplayAttribute;
      await loggerService.addLogs(message, 'AD-FilterChanged', additionalData);
    },
    setShowEmberRecommendations(
      state: IBurnerReadingStoreViewerState,
      value: boolean
    ): void {
      state.showEmberRecommendations = value;
    },
    setSelectedBurnerDetails(
      state: IBurnerReadingStoreViewerState,
      burnerKey: string
    ): void {
      state.selectedBurnerDetails = state.emaBurnerList.find((burner:IBurnerDetails) => burner.key === burnerKey) as IBurnerDetails;
    },
    updatePrimaryDisplayValuesFromEMA(state: IBurnerReadingStoreViewerState): void {
      state.burnerReading.burners?.forEach((burner: IViewerBurner) => {
        const emaBurnerDetails = state.emaBurnerList.find((emaBurner: IBurnerDetails) => emaBurner.key === burner.key);
        if (emaBurnerDetails) {
          burner.opportunityScore = emaBurnerDetails.opportunityScore;
          const emaBurnerPrimaryAirRegisterIndex = emaBurnerDetails.airRegisters.findIndex((airRegister: IAirRegiterDetails) => airRegister.isPrimary);
          if (emaBurnerPrimaryAirRegisterIndex > -1 && burner.airRegisters?.length) {
            const burnerPrimaryAirRegisterIndex = burner.airRegisters?.findIndex((ar: IAirRegister) => ar.isPrimary);
            if (burnerPrimaryAirRegisterIndex > -1) {
              burner.airRegisters[burnerPrimaryAirRegisterIndex].currentSetting = emaBurnerDetails.airRegisters[emaBurnerPrimaryAirRegisterIndex].current;
            }
          }
        }
      });
    },
  } as IBurnerReadingStoreMutations,
  actions: {
    async retrieveBurnerReadings(
      context: IBurnerReadingContext
    ): Promise<void> {
      // Returning burners readings based on selected equipmentConfigKey from service.
      //  store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
      const equipmentConfigKey =
        store.getters[
          `${VuexModuleNamespaces.diagramViewer}/${DiagramViewerStore.getters.equipmentConfigKey.name}`
        ];
      const conf = await ConfigFactory.GetConfig();
      const burnerReadingService = new BurnerReadingService(
        sharedAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL
          ? process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL
          : conf.get('adbApiUrl')
      );
      if (equipmentConfigKey) {
        const assetKey = store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.selectedAsset.name}`]?.key;
        const response = await burnerReadingService.getBurnerReadings(equipmentConfigKey, assetKey);
        // Refresh layout for current selected reading if set
        const selectedBurner =
          store.getters[
            `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.selectedBurnerDetail.name}`
          ];
        if (selectedBurner) {
          const refreshedBurner = await store.dispatch(
            `${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.actions.getBurnerReadingsByKey.name}`,
            selectedBurner.key
          );
          // Populate with burner readings
          await store.dispatch(
            `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.actions.updateBurnerReadings.name}`,
            refreshedBurner
          );
        }
        context.state.burnerReading = response;
        store.commit(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.mutations.updatePrimaryDisplayValuesFromEMA.name}`);
        // Update grid squares
        await store.dispatch(
          `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.updateBurnerGroupDisplay.name}`,
          {
            burnerDisplay: context.state.primaryDisplayAttribute,
            heatReleaseUnit:
              store.getters[
                `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.selectedHeatUnit.name}`
              ],
          }
        );
        context.state.isFetchComplete = true;
      }
    },
    async retrieveBurnerReadingsLT(
      context: IBurnerReadingContext
    ): Promise<void> {
      // Returning burners readings based on selected equipmentConfigKey from service.
      //  store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
      const equipmentConfigKey =
        store.getters[
          `${VuexModuleNamespaces.diagramViewer}/${DiagramViewerStore.getters.equipmentConfigKey.name}`
        ];
      const conf = await ConfigFactory.GetConfig();
      const burnerReadingService = new BurnerReadingService(
        sharedAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL
          ? process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL
          : conf.get('adbApiUrl')
      );
      if (equipmentConfigKey) {
        const assetKey = store.getters[`${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.selectedAsset.name}`]?.key;
        const customerKey = store.getters[`${VuexModuleNamespaces.userViewer}/${UserViewerStore.getters.currentUser.name}`]?.activeCustomerKey;
        const response = await burnerReadingService.getBurnerReadingsLT(equipmentConfigKey, assetKey, customerKey);
        // Refresh layout for current selected reading if set
        const selectedBurner =
          store.getters[
            `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.selectedBurnerDetail.name}`
          ];
        if (selectedBurner) {
          const refreshedBurner = await store.dispatch(
            `${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.actions.getBurnerReadingsByKey.name}`,
            selectedBurner.key
          );
          // Populate with burner readings
          await store.dispatch(
            `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.actions.updateBurnerReadings.name}`,
            refreshedBurner
          );
        }
        context.state.burnerReading = response;
        store.commit(`${VuexModuleNamespaces.burnerReadingViewer}/${BurnerReadingViewerStore.mutations.updatePrimaryDisplayValuesFromEMA.name}`);
        // Update grid squares
        await store.dispatch(
          `${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.updateBurnerGroupDisplay.name}`,
          {
            burnerDisplay: context.state.primaryDisplayAttribute,
            heatReleaseUnit:
              store.getters[
                `${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.getters.selectedHeatUnit.name}`
              ],
          }
        );
        await store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.updateVariableGroupDisplay.name}`);
        context.state.isFetchComplete = true;
      }
    },
    async getBurnerReadingsByKey(
      context: IBurnerReadingContext,
      selectedBurnerKey: string
    ): Promise<IBurnerInfo> {
      let foundBurner: IBurnerInfo | undefined = UNKNOWN_READINGS;
      if (
        context.state.burnerReading.burners !== null &&
        context.state.burnerReading.burners !== undefined
      ) {
        foundBurner = context.state.burnerReading.burners.find(
          (burner) => burner.key === selectedBurnerKey
        );
        if (!foundBurner) {
          foundBurner = UNKNOWN_READINGS;
        }
      }
      return foundBurner;
    },
    async getEMABurnerList(context: IBurnerReadingContext): Promise<void> {
      const selectedAsset: IAsset =
        store.getters[
          `${VuexModuleNamespaces.assetViewer}/${AssetViewerStore.getters.selectedAsset.name}`
        ];
      const selectedAssetKey = selectedAsset.key;
      const conf = await ConfigFactory.GetConfig();
      const service = new EMABurnerDetailsService(
        sharedAxiosInstance,
        conf.get('emaApiUrl')
      );
      if (selectedAssetKey) {
        const response = await service.getEMABurners(selectedAssetKey);
        context.state.emaBurnerList = response;
      }
    },
  } as IBurnerReadingStoreActions,
};
