import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IVariableListServiceResponse } from '@/view-models/variables/variable-folder-view-models';

export class VariableService extends BaseService {

  constructor(axiosInstance: AxiosInstance, serviceApiUrl: string) {
    super(`${serviceApiUrl}`, axiosInstance);
  }

  public getVariableList(selectedAssetKey: string): Promise<IVariableListServiceResponse> {
    const url = `aov/AssetsDiagram/get-assetvariables-hierarchy/${selectedAssetKey}`;
    return this.httpClient.get<IVariableListServiceResponse>(url);
  }

}
