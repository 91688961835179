import store, { VuexModuleNamespaces } from '@/store/';
import { IAsset } from '../asset/assets-view-models';
import { Burner, IBurner } from '../burner/burner-view-models';
import { gridConfig } from '@/assets/configs/gridConfig';
import { ICoordinate, IAssetDiagram, IDiagramObject, DiagramType, SpaceLinePosition, Status, IPosition } from './asset-diagram';
import { GridStore } from '@/store/grid/gridStore';
import { AssetStore } from '@/store/assetStore/assetStore';
import { BurnerLayoutStore } from '@/store/burnerLayout/burnerLayoutStore';
import { IVariableDiagram } from '../assetVariables/asset-variables-view-models';
import { DiagramStore } from '@/store/diagram/diagramStore';

interface ISpaceLine {
  top: number;
  left: number;
  length: number;
  direction: Direction;
}

class SpaceLine implements ISpaceLine {
  public top: number;
  public left: number;
  public length: number;
  public direction: Direction;

  constructor(top: number, left: number, length: number, direction: Direction) {
    this.top = top;
    this.left = left;
    this.length = length;
    this.direction = direction;
  }
}

enum Direction {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

// tslint:disable-next-line:max-classes-per-file
class DiagramObject implements IDiagramObject {

  public static compareByRow(a: IDiagramObject, b: IDiagramObject) {
    if (a.position.top < b.position.top) {
      return -1;
    } else if (a.position.top > b.position.top) {
      return 1;
    } else {
      if (a.position.left < b.position.left) {
        return -1;
      } else if (a.position.left > b.position.left) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  public static compareByColumn(a: IDiagramObject, b: IDiagramObject) {
    if (a.position.left < b.position.left) {
      return -1;
    } else if (a.position.left > b.position.left) {
      return 1;
    } else {
      if (a.position.top < b.position.top) {
        return -1;
      } else if (a.position.top > b.position.top) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  public static getGridBoxSize(): number {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.editorGridBoxSize.name}`];
  }

  public type: DiagramType;
  public position: IPosition;
  public key: string;
  public name: string;
  public coordinates: ICoordinate;
  public coordinateWidthOffset: number;
  public coordinateHeightOffset: number;
  public leftMargin: number;
  public topMargin: number;
  public hasVerticalSpaceLine: boolean;
  public hasHorizontalSpaceLine: boolean;
  public spaceLineVerticalPosition: SpaceLinePosition;
  public spaceLineHorizontalPosition: SpaceLinePosition;

  constructor(type: DiagramType, position: IPosition, key?: string) {
    this.type = type;
    this.position = position;
    this.key = key || '';
    if (key && type === DiagramType.Burner) {
      const burner: IBurner = Burner.getBurnerByKey(key);
      if (burner) {
        this.name = Burner.getBurnerByKey(key).name;
      } else {
        this.name = '';
      }
    } else {
      this.name = '';
    }
    this.coordinateWidthOffset = 0;
    this.coordinateHeightOffset = 0;
    this.leftMargin = 0;
    this.topMargin = 0;
    this.coordinates = {x: -1, y: -1};
    this.hasVerticalSpaceLine = false;
    this.hasHorizontalSpaceLine = false;
    this.spaceLineVerticalPosition = SpaceLinePosition.None;
    this.spaceLineHorizontalPosition = SpaceLinePosition.None;
  }

}

// tslint:disable-next-line:max-classes-per-file
export class AssetDiagram implements IAssetDiagram {
  public assetKey: string;
  public equipmentConfigKey: string | undefined;
  public status: Status;
  public diagram: string;
  public diagramContent: any = null;
  public assetDiagramObjects: IDiagramObject[] = [];
  private spaceLines: ISpaceLine[] = [];
  public variableDiagram?: IVariableDiagram[] = [];

  constructor(assetKey: string, equipmentConfigKey: string | undefined,  diagram: string, currentVariableDiagram?: IVariableDiagram[], status?: Status) {
    this.assetKey = assetKey;
    this.equipmentConfigKey = equipmentConfigKey;
    this.diagram = diagram;
    this.variableDiagram = currentVariableDiagram;
    this.status = status || Status.Draft;
  }

  static get currentAssetDiagram(): IAssetDiagram {
    const currentAsset: IAsset =
        store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
    // Force close menus and labels when retrieving diagram
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`);
    // Retrieve grid contents
    const diagram = store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.gridContents.name}`];
    const currentDiagram = JSON.stringify({
        diagram,
        burnerlayout: store.getters[`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.getters.burnerLayout.name}`]
    });
    const currentVariableDiagram = store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.variableDiagram.name}`];
    const currentAssetDiagram = new AssetDiagram(currentAsset.key, currentAsset.equipmentConfigKey, currentDiagram, currentVariableDiagram);
    currentAssetDiagram.diagramContent = diagram;
    return currentAssetDiagram;
  }

  public get numberOfRows(): number {
    const rowNumbers = this.assetDiagramObjects.map((diagramObject: IDiagramObject) => diagramObject.coordinates.y);
    if (rowNumbers.length === 0) {
      return 0;
    }
    const maxRowIndex = Math.max(...rowNumbers);
    return maxRowIndex + 1;
  }

  public loadBurnerPreviewArray(): void {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`);

    const diagramObjects: IDiagramObject[] = [];
    this.diagramContent.contents.objects.forEach((gridContent: any, index: number) => {
      if (gridContent.name === gridConfig.arrangeShapeName) {
        let gridSpaceLine: ISpaceLine | null = null;
        if (gridContent.width > 0) {
          const lineLeft: number = gridContent.left - Math.abs(gridContent.x1);
          gridSpaceLine =
            new SpaceLine(gridContent.top, lineLeft, gridContent.width, Direction.Horizontal);
        } else if (gridContent.height > 0) {
          const lineTop: number = (gridContent.top - Math.abs(gridContent.y1));
          gridSpaceLine =
            new SpaceLine(lineTop, gridContent.left, gridContent.height, Direction.Vertical);
        }

        if (gridSpaceLine) {
          this.spaceLines.push(gridSpaceLine);
        }
      }

      if (gridContent.name === gridConfig.burnerGroupName) {
        const gridDiagramObject = this.createDiagramBurner(gridContent);

        diagramObjects.push(gridDiagramObject);
      }
      if ( gridContent.name === gridConfig.labelName) {
        const gridDiagramObject = this.createDiagramLabel(gridContent, index);
        diagramObjects.push(gridDiagramObject);
      }
    });

    if (diagramObjects.length > 0) {
      let sortedByRow: IDiagramObject[] = diagramObjects.sort(DiagramObject.compareByRow);
      sortedByRow = this.assignDiagramYCoordinates(sortedByRow);
      let sortedByColumn: IDiagramObject[] = sortedByRow.sort(DiagramObject.compareByColumn);
      sortedByColumn = this.assignDiagramXCoordinates(sortedByColumn);
      this.assetDiagramObjects = sortedByColumn.sort(DiagramObject.compareByRow);
    }
  }

  public getNumOfColumnsByRow(row: number): number {
    const columnNumbers = this.assetDiagramObjects
      .filter((diagramObject: IDiagramObject) => diagramObject.coordinates.y === row)
      .map((diagramObject: IDiagramObject) => diagramObject.coordinates.x);
    if (columnNumbers.length > 0) {
      const maxColumnIndex = Math.max(...columnNumbers);
      return maxColumnIndex + 1;
    } else {
      return 1;
    }
  }

  public getObjectByCoordinates(x: number, y: number): IDiagramObject {
    const diagramObjectResult: IDiagramObject | undefined =
      this.assetDiagramObjects.find((diagramObject: IDiagramObject) => {
        return diagramObject.coordinates.x === x
          && diagramObject.coordinates.y === y;
      });
    if (diagramObjectResult) {
      return diagramObjectResult;
    } else {
      const emptyObject = new DiagramObject(DiagramType.Empty, {left: -1, top: -1}, 'burner'+x+y);
      emptyObject.coordinates.x = x;
      emptyObject.coordinates.y = y;
      return emptyObject;
    }
  }

  private assignDiagramXCoordinates(inputDiagramObjects: IDiagramObject[]): IDiagramObject[] {
    let column: number = 0;
    let diagramObjects: IDiagramObject[] = inputDiagramObjects;
    let compareObject: IDiagramObject | undefined = diagramObjects
      .find((diagramObject: IDiagramObject) => diagramObject.type === DiagramType.Burner);
    let firstBurnerIndex: number = 0;

    if (compareObject) {
      diagramObjects = diagramObjects.sort(DiagramObject.compareByColumn);
      firstBurnerIndex = diagramObjects.indexOf(compareObject);
    }
    while (compareObject) {
      const compareObjectIndex: number = diagramObjects.indexOf(compareObject);
      if (compareObjectIndex === firstBurnerIndex) {
        column = this.assignLabelX(diagramObjects, compareObject, column);
      }

      const oldCompareObject = compareObject;
      compareObject = this.assignBurnerX(diagramObjects, compareObject, column);

      if (compareObject) {
        if (oldCompareObject.type === DiagramType.Burner && compareObject.type === DiagramType.Burner) {
          this.assignLabelXBetweenBurners(diagramObjects, oldCompareObject, compareObject, column);
          const objectYDifference = compareObject?.position.left - oldCompareObject?.position.left;
          if (objectYDifference > DiagramObject.getGridBoxSize() * 1.5) {
            column += Math.round(objectYDifference/DiagramObject.getGridBoxSize()) - 1;
          }
        }
      } else {
        this.assignLastRowLabelsX(oldCompareObject, diagramObjects, column);
      }
      column++;
    }

    return diagramObjects;
  }

  private assignDiagramYCoordinates(inputDiagramObjects: IDiagramObject[]): IDiagramObject[] {
    let row: number = 0;
    let diagramObjects: IDiagramObject[] = inputDiagramObjects;
    this.assignSpaceLineLeft(diagramObjects);
    this.assignSpaceLineTop(diagramObjects);
    diagramObjects = diagramObjects.sort(DiagramObject.compareByRow);
    let compareObject: IDiagramObject | undefined = diagramObjects
      .find((diagramObject: IDiagramObject) => diagramObject.type === DiagramType.Burner);
    let firstBurnerIndex: number = 0;
    if (compareObject) {
      diagramObjects = diagramObjects.sort(DiagramObject.compareByRow);
      firstBurnerIndex = diagramObjects.indexOf(compareObject);
    }
    while (compareObject) {
      const compareObjectIndex: number = diagramObjects.indexOf(compareObject);
      if (compareObjectIndex === firstBurnerIndex) {
        row = this.assignLabelY(diagramObjects, compareObject, row);
      }

      const oldCompareObject = compareObject;
      compareObject = this.assignBurnerY(diagramObjects,
                                         compareObject,
                                         row);

      if (compareObject) {
        if (oldCompareObject.type === DiagramType.Burner && compareObject.type === DiagramType.Burner) {
          this.assignLabelYBetweenBurners(diagramObjects, oldCompareObject, compareObject, row);
          const objectYDifference = compareObject?.position.top - oldCompareObject?.position.top;
          if (objectYDifference > DiagramObject.getGridBoxSize() * 1.5) {
            row += Math.round(objectYDifference/DiagramObject.getGridBoxSize()) - 1;
          }
        }
      } else {
        this.assignLastRowLabelsY(oldCompareObject, diagramObjects, row);
      }
      row++;
    }

    return diagramObjects;
  }

  private assignSpaceLineTop(diagramObjects: IDiagramObject[]) {
    diagramObjects = diagramObjects.sort(DiagramObject.compareByColumn);
    const topPositions: number[] =
    diagramObjects
    .filter((diagramObject: IDiagramObject) => diagramObject.type === DiagramType.Burner)
    .map((diagramObject: IDiagramObject) => diagramObject.position.top);
    let minTop = 0;
    if (topPositions.length > 0) {
      minTop = Math.min(...topPositions);
    }

    this.spaceLines
      .filter((spaceLine: ISpaceLine) =>
        spaceLine.top > minTop && spaceLine.direction === Direction.Horizontal)
      .forEach((spaceLine: ISpaceLine) => {
        const spaceLineTopBurners: IDiagramObject[] = diagramObjects
          .filter((diagramObject: IDiagramObject) => {
            const burnerOffset: number = DiagramObject.getGridBoxSize() / 2;
            return (diagramObject.position.top > spaceLine.top - burnerOffset) &&
              (diagramObject.position.top < (spaceLine.top + burnerOffset)) &&
              (diagramObject.position.left > spaceLine.left - burnerOffset) &&
              (diagramObject.position.left < spaceLine.left + spaceLine.length) &&
              (diagramObject.type === DiagramType.Burner);
        });
        spaceLineTopBurners.forEach((diagramObject: IDiagramObject) => {
            const onlyBurners: IDiagramObject[] = diagramObjects
                .filter((spaceLineDiagramObject: IDiagramObject) => spaceLineDiagramObject.type === DiagramType.Burner);
            const currentIndex: number = onlyBurners.indexOf(diagramObject);
            if (currentIndex > 0) {
              diagramObject.hasHorizontalSpaceLine = true;
              if (diagramObject.spaceLineHorizontalPosition === SpaceLinePosition.None) {
                diagramObject.spaceLineHorizontalPosition = SpaceLinePosition.Top;
              } else {
                diagramObject.spaceLineHorizontalPosition = SpaceLinePosition.TopBottom;
              }
              const previousObject: IDiagramObject = onlyBurners[currentIndex -1];
              const previousTopLimit: number =
                diagramObject.position.top - (diagramObject.coordinateHeightOffset + 10);
              if (previousTopLimit <= previousObject.position.top &&
                  diagramObject.position.left === previousObject.position.left) {
                    previousObject.hasHorizontalSpaceLine = true;
                    if (previousObject.spaceLineHorizontalPosition === SpaceLinePosition.None) {
                      previousObject.spaceLineHorizontalPosition = SpaceLinePosition.Bottom;
                    } else {
                      previousObject.spaceLineHorizontalPosition = SpaceLinePosition.TopBottom;
                    }
              }
            }
         });
      });
  }

  private assignSpaceLineLeft(diagramObjects: IDiagramObject[]) {
    diagramObjects = diagramObjects.sort(DiagramObject.compareByRow);
    const leftPositions: number[] =
      diagramObjects
      .filter((diagramObject: IDiagramObject) => diagramObject.type === DiagramType.Burner)
      .map((diagramObject: IDiagramObject) => diagramObject.position.left);
    let minLeft = 0;
    if (leftPositions.length > 0) {
      minLeft = Math.min(...leftPositions);
    }

    this.spaceLines
      .filter((spaceLine: ISpaceLine) =>
        spaceLine.left > minLeft && spaceLine.direction === Direction.Vertical)
      .forEach((spaceLine: ISpaceLine) => {
        const spaceLineLeftBurners: IDiagramObject[] = diagramObjects.filter((diagramObject: IDiagramObject) => {
          const burnerOffset: number = DiagramObject.getGridBoxSize() / 2;
          return (diagramObject.position.left > spaceLine.left - burnerOffset) &&
                 (diagramObject.position.left < (spaceLine.left + burnerOffset)) &&
                 (diagramObject.position.top > spaceLine.top - burnerOffset) &&
                 (diagramObject.position.top < spaceLine.top + spaceLine.length) &&
                 (diagramObject.type === DiagramType.Burner);
        });
        spaceLineLeftBurners.forEach((diagramObject: IDiagramObject) => {
          const onlyBurners: IDiagramObject[] = diagramObjects
            .filter((spaceLineDiagramObject: IDiagramObject) => spaceLineDiagramObject.type === DiagramType.Burner);
          const currentIndex: number = onlyBurners.indexOf(diagramObject);
          if (currentIndex > 0) {
            diagramObject.hasVerticalSpaceLine = true;
            if (diagramObject.spaceLineVerticalPosition === SpaceLinePosition.None) {
              diagramObject.spaceLineVerticalPosition = SpaceLinePosition.Left;
            } else {
              diagramObject.spaceLineVerticalPosition = SpaceLinePosition.LeftRight;
            }
            const previousObject: IDiagramObject = onlyBurners[currentIndex -1];
            const previousLeftLimit: number =
              diagramObject.position.left - (previousObject.coordinateWidthOffset+10);
            if (previousLeftLimit <= previousObject.position.left &&
                diagramObject.position.top === previousObject.position.top) {
              previousObject.hasVerticalSpaceLine = true;
              if (previousObject.spaceLineVerticalPosition === SpaceLinePosition.None) {
                previousObject.spaceLineVerticalPosition = SpaceLinePosition.Right;
              } else {
                previousObject.spaceLineVerticalPosition = SpaceLinePosition.LeftRight;
              }
            }
          }
        });
      });

  }

  private assignLastRowLabelsY(oldCompareObject: IDiagramObject, diagramObjects: IDiagramObject[], row: number) {
    const compareTop = oldCompareObject.position.top;
    const compareBottom = oldCompareObject.position.top + oldCompareObject.coordinateWidthOffset;
    const labelObjects = diagramObjects.filter((diagramObject: IDiagramObject) => {
      return (diagramObject.type === DiagramType.Label
        && diagramObject.position.top >= compareTop);
    });
    labelObjects.forEach((labelObject: IDiagramObject) => {
      if (labelObject.position.top >= compareTop && labelObject.position.top <= compareBottom) {
        labelObject.coordinates.y = row;
      } else {
        labelObject.coordinates.y = row + 1;
      }
      labelObject.topMargin = 25;
    });
  }

  private assignLastRowLabelsX(oldCompareObject: IDiagramObject, diagramObjects: IDiagramObject[], column: number) {
    const compareLeft = oldCompareObject.position.left;
    const compareRight = oldCompareObject.position.left + oldCompareObject.coordinateHeightOffset;
    const labelObjects = diagramObjects.filter((diagramObject: IDiagramObject) => {
      return (diagramObject.type === DiagramType.Label
        && diagramObject.position.left >= compareLeft);
    });
    labelObjects.forEach((labelObject: IDiagramObject) => {
      if (labelObject.position.left >= compareLeft && labelObject.position.left <= compareRight) {
        labelObject.coordinates.x = column;
      } else {
        labelObject.coordinates.x = column + 1;
      }

      const positioningFactor =
      (labelObject.position.left -
        (oldCompareObject.position.left + oldCompareObject.coordinateHeightOffset))
          / oldCompareObject.coordinateHeightOffset;
      if ((positioningFactor * 100) > 15 ) {
        labelObject.leftMargin = 50;
      } else {
        labelObject.leftMargin = 5;
      }
    });
  }

  private createDiagramLabel(gridContent: any, index: number): IDiagramObject {
    const gridDiagramObject: IDiagramObject =
      new DiagramObject(DiagramType.Label, { left: gridContent.left, top: gridContent.top });
    gridDiagramObject.key = 'label' + index;
    if (gridContent.objects !== null && gridContent.objects !== undefined) {
      gridContent.objects.forEach((gridObject: any) => {
        if (gridObject.type === 'i-text') {
          gridDiagramObject.name = gridObject.text;
        }
        if (gridObject.type === 'rect') {
          gridDiagramObject.coordinateWidthOffset = gridObject.width;
          gridDiagramObject.coordinateHeightOffset = gridObject.height;
        }
      });
    }
    return gridDiagramObject;
  }

  private createDiagramBurner(gridContent: any): IDiagramObject {
    const gridDiagramObject: IDiagramObject = new DiagramObject(DiagramType.Burner,
                                               { left: gridContent.left,
                                                 top: gridContent.top },
                                                 gridContent.data.objectKey);
    if (gridContent.objects !== null && gridContent.objects !== undefined) {
      gridContent.objects.forEach((gridObject: any) => {
        if (gridObject.type === 'rect') {
          gridDiagramObject.coordinateWidthOffset = gridObject.width;
          gridDiagramObject.coordinateHeightOffset = gridObject.height;
        }
      });
    }
    return gridDiagramObject;
  }

  private assignLabelY(diagramObjects: IDiagramObject[], compareObject: IDiagramObject, startRow: number): number {
    let row: number = startRow;
    const compareObjectIndex = diagramObjects.indexOf(compareObject);
    const labelObjects: IDiagramObject[] = diagramObjects
      .filter((diagramObject: IDiagramObject, index: number) => {
      return diagramObject.type === DiagramType.Label && index < compareObjectIndex;
    });
    let isLabelRow = false;
    if (labelObjects !== null && labelObjects !== undefined) {
      labelObjects.forEach((labelObject: IDiagramObject) => {
        if (labelObject.position.top < compareObject?.position.top!) {
          labelObject.coordinates.y = row;
          isLabelRow = true;
          labelObject.topMargin = 25;
        }
      });
    }
    if (isLabelRow) {
      row++;
    }

    return row;
  }

  private assignLabelX(diagramObjects: IDiagramObject[], compareObject: IDiagramObject, startColumn: number): number {
    let column: number = startColumn;
    const compareObjectIndex = diagramObjects.indexOf(compareObject);
    const labelObjects: IDiagramObject[] = diagramObjects
      .filter((diagramObject: IDiagramObject, index: number) => {
        return diagramObject.type === DiagramType.Label && index < compareObjectIndex;
      });
    let isLabelColumn = false;
    if (labelObjects !== undefined && labelObjects !== null) {
      labelObjects.forEach((labelObject: IDiagramObject) => {
        if (labelObject.position.left < compareObject?.position.left!) {
          labelObject.coordinates.x = column;
          isLabelColumn = true;
        }
      });
    }
    const labelWidths: number[] = labelObjects
      .map((diagramObject: IDiagramObject) => diagramObject.coordinateWidthOffset);
    const maxWidth: number = Math.max(...labelWidths);
    if (isLabelColumn) {
      column++;
      if (maxWidth > DiagramObject.getGridBoxSize()) {
        let columnWidth: number = DiagramObject.getGridBoxSize();
        while (columnWidth < maxWidth) {
          column++;
          columnWidth += DiagramObject.getGridBoxSize();
        }
      }
      if (labelObjects !== undefined && labelObjects !== null) {
        labelObjects.forEach((diagramObject: IDiagramObject) => {
          const totalColumnSpace: number = DiagramObject.getGridBoxSize() * column;
          const totalDifference: number = totalColumnSpace - diagramObject.coordinateWidthOffset;
          const positioningFactor: number = (totalDifference) / DiagramObject.getGridBoxSize();
          if ((positioningFactor * 100) > 15) {
            diagramObject.leftMargin = 50;
          } else {
            diagramObject.leftMargin = 0;
          }
        });
      }
    }
    return column;
  }

  private assignLabelYBetweenBurners(diagramObjects: IDiagramObject[],
                                     oldCompareObject: IDiagramObject,
                                     compareObject: IDiagramObject,
                                     row: number) {
    const labelObjects: IDiagramObject[] =  diagramObjects.filter((diagramObject: IDiagramObject) => {
      return ((diagramObject.position.top < compareObject?.position.top!
        && diagramObject.position.top > oldCompareObject?.position.top)
        && diagramObject.type === DiagramType.Label);
    });
    let compareTop = oldCompareObject.position.top;
    let addedLabels = 0;
    while (compareTop < compareObject.position.top && addedLabels < labelObjects.length) {
      const compareBottom = compareTop + oldCompareObject.coordinateHeightOffset;
      if (labelObjects !== undefined && labelObjects !== null) {
        labelObjects.forEach((labelObject: IDiagramObject) => {
          if (labelObject.position.top >= compareTop && labelObject.position.top <= compareBottom) {
            labelObject.coordinates.y = row;
            const positioningFactor =
            (labelObject.position.top - compareTop) / oldCompareObject.coordinateHeightOffset;
            if ((positioningFactor * 100) > 40 ) {
              labelObject.topMargin = 70;
            } else {
              labelObject.topMargin = 25;
            }
            addedLabels++;
          }
        });
      }
      compareTop = compareBottom;
      row++;
    }
  }

  private assignLabelXBetweenBurners(diagramObjects: IDiagramObject[],
                                     oldCompareObject: IDiagramObject,
                                     compareObject: IDiagramObject,
                                     column: number) {
    const labelObjects: IDiagramObject[] =  diagramObjects.filter((diagramObject: IDiagramObject) => {
      return ((diagramObject.position.left < compareObject?.position.left!
        && diagramObject.position.left > oldCompareObject?.position.left)
        && diagramObject.type === DiagramType.Label);
    });
    let compareLeft = oldCompareObject.position.left;
    let addedLabels = 0;
    while (compareLeft < compareObject.position.left && addedLabels < labelObjects.length) {
      const compareRight = compareLeft + oldCompareObject.coordinateWidthOffset;
      if (labelObjects !== undefined && labelObjects !== null) {
        labelObjects.forEach((labelObject: IDiagramObject) => {
          if (labelObject.position.left >= compareLeft && labelObject.position.left <= compareRight) {
            labelObject.coordinates.x = column;
            const positioningFactor =
              (labelObject.position.left - oldCompareObject.position.left) / DiagramObject.getGridBoxSize();
            if ((positioningFactor * 100) > 15 ) {
              labelObject.leftMargin = 50;
            } else {
              labelObject.leftMargin = 0;
            }
            addedLabels++;
          }
        });
      }
      compareLeft = compareRight;
      column++;
    }
  }

  private assignBurnerY(diagramObjects: IDiagramObject[],
                        compareObject: IDiagramObject,
                        row: number): IDiagramObject | undefined {
    diagramObjects
    .filter((diagramObject: IDiagramObject) => {
      const compareTop: number = compareObject.position.top;
      const currentTop: number = diagramObject.position.top;

      return (currentTop > compareTop - DiagramObject.getGridBoxSize() / 2) &&
             (currentTop < compareTop + DiagramObject.getGridBoxSize() / 2);

    })
    .forEach((diagramObject: IDiagramObject) => diagramObject.coordinates.y = row);
    const newCompareObject: IDiagramObject | undefined = diagramObjects
      .find((diagramObject: IDiagramObject) => {
        return (diagramObject.position.top > compareObject?.position.top! &&
                diagramObject.type === DiagramType.Burner &&
                diagramObject.coordinates.y < 0);
      });
    return newCompareObject;
  }

  private assignBurnerX(diagramObjects: IDiagramObject[],
                        compareObject: IDiagramObject,
                        column: number): IDiagramObject | undefined {

    diagramObjects
    .filter((diagramObject: IDiagramObject) => {
      const compareLeft: number = compareObject.position.left;
      const currentLeft: number = diagramObject.position.left;

      return (currentLeft > compareLeft - DiagramObject.getGridBoxSize() / 2) &&
             (currentLeft < compareLeft + DiagramObject.getGridBoxSize() / 2);
    })
    .forEach((diagramObject: IDiagramObject) => diagramObject.coordinates.x = column);
    const newCompareObject: IDiagramObject | undefined = diagramObjects
      .find((diagramObject: IDiagramObject) => {
        return (diagramObject.position.left > compareObject?.position.left! &&
                diagramObject.type === DiagramType.Burner &&
                diagramObject.coordinates.x < 0);
    });
    return newCompareObject;
  }

  // tslint:disable-next-line:member-ordering
  public getObjectsByRow(row: number): IDiagramObject[] {
    return this.assetDiagramObjects.filter((diagramObject: IDiagramObject) => {
      return diagramObject.coordinates.y === row;
    });
  }

}
