































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { AssetFolder } from '@/view-models/asset/asset-folder-view-models';
import FolderItem from '@/components/common/FolderItem.vue';
import Loading from '@/components/common/Loading.vue';
import { IFolder } from '@/view-models/common/folder-view-models';
import store, { VuexModuleNamespaces } from '@/store/';
import { ErrorStore } from '@/store/error/errorStore';
import { AssetStore } from '@/store/assetStore/assetStore';
import { GridStore } from '@/store/grid/gridStore';
import { AppStore } from '@/store/app/appStore';
import { AssetFolderStore } from '@/store/assetFolder/assetFolderStore';

@Component({
  name: 'asset-selector',
  components: {
    FolderItem,
    Loading,
  },
})
export default class AssetSelector extends Vue {
  public folders: AssetFolder[] = [];
  public searchString: string = '';
  public isLoading: boolean = false;

  public async mounted() {
    // Handle route changes from parent
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$on(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
    await this.refreshData();
  }

  public beforeDestroy() {
    // Used when integrated into parent app
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$off(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
  }

  public async handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?utilities\/ember\/layout-editor[\/]?/)) {
      store.commit(
        `${VuexModuleNamespaces.app}/${AppStore.mutations.updateIsLoading.name}`,
        false
      );
      let currentRelativeRoute = parentRoute.path.replace(
        /^\/?utilities\/ember\/layout-editor/,
        '/'
      );
      currentRelativeRoute = currentRelativeRoute.replace(/\/\//g, '/');
      if (currentRelativeRoute === '/') {
        await this.refreshData();
        this.searchString = '';
      }
    }
  }

  public async refreshData() {
    // Reload assets when navigating back to this screen
    store.dispatch(
      `${VuexModuleNamespaces.assetFolder}/${AssetFolderStore.actions.resetAssetFolder.name}`
    );
    this.isLoading = true;
    await store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: this.loadTrees,
        errorMsg: 'Error loading assets.',
      }
    );
    this.isLoading = false;
  }

  public async loadTrees() {
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    await store.dispatch(
      `${VuexModuleNamespaces.asset}/${AssetStore.actions.loadAssets.name}`
    );
    store.dispatch(
      `${VuexModuleNamespaces.assetFolder}/${AssetFolderStore.actions.loadAssetFolder.name}`
    );
    store.dispatch(
      `${VuexModuleNamespaces.grid}/${GridStore.actions.hideMenus.name}`
    );
    this.folders =
      store.getters[
        `${VuexModuleNamespaces.assetFolder}/${AssetFolderStore.getters.allAssetFolders.name}`
      ];
  }

  public parentSelectItem(value: IFolder): void {
    value.selectSelf();
    this.$router.push(`editor/${value.key}`);
  }

  get backgroundUrl() {
    return require('../../assets/images/icons/Search.svg');
  }

  get customers(): IFolder[] {
    const customers =
      store.getters[
        `${VuexModuleNamespaces.assetFolder}/${AssetFolderStore.getters.rootFolders.name}`
      ];
    return customers;
  }
}
