import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import LoggerService from '@/services/viewer/logger/logger-service';
import { IRootState } from '@/store';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';

export interface IAppStoreViewerState {
    isLoading: boolean;
    actionTime: number;
}

export interface LogPayload {
  message: string;
  feature: string;
  additionalData: { [key: string]: string };
}

export interface AppViewerStoreGetters extends GetterTree<IAppStoreViewerState, IRootState> {
  isLoading(state: IAppStoreViewerState): boolean;
}

export interface AppViewerStoreMutations extends MutationTree<IAppStoreViewerState> {
  updateIsLoading(state: IAppStoreViewerState, newIsLoading: boolean): void;
  updateActionTime(state: IAppStoreViewerState): void;
}

export interface AppViewerStoreActions extends ActionTree<IAppStoreViewerState, IRootState> {
  addApplicationStartLog(context: AppViewerContext, payload: LogPayload): Promise<void>;
}

export type AppViewerContext = ActionContext<IAppStoreViewerState, IRootState>;

export const AppViewerStore = {
    namespaced: true as true,
    state: {
        isLoading: true,
        actionTime: new Date().getTime()
    } as IAppStoreViewerState,
    getters:  {
      isLoading(state: IAppStoreViewerState): boolean {
        return state.isLoading;
      }
    } as AppViewerStoreGetters,
    mutations: {
      updateIsLoading(state: IAppStoreViewerState, newIsLoading: boolean) {
        state.isLoading = newIsLoading;
      },
      updateActionTime(state: IAppStoreViewerState) {
        state.actionTime = new Date().getTime();
      },
    } as AppViewerStoreMutations,
    actions: {
      async addApplicationStartLog(context: AppViewerContext, payload: LogPayload) {
        const conf = await ConfigFactory.GetConfig();
        const loggerService: LoggerService = new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
        await loggerService.addLogs(payload.message, payload.feature, payload.additionalData);
      }
    } as AppViewerStoreActions
};
