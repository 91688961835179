















import { Vue, Component, Prop } from 'vue-property-decorator';
import VariableBlock from '@/components/viewer/assetVariables/components/VariableBlock.vue';
import store, { VuexModuleNamespaces } from '@/store';
import { AssetVariableStore } from '@/store/viewer/assetVariable/assetVariableStore';
import { IVariableDisplay, IVariableDetail, IVariableData } from '@/view-models/viewer/assetVariables/asset-variables-response';
@Component({
  name: 'asset-variables',
  components: {
    VariableBlock
  }
})
export default class AssetVariables extends Vue {
 @Prop({ required: true })
  public assetVariables!: IVariableData[];

  private variablesFirstRow: IVariableDisplay[] = [];
  private variablesSecondRow: IVariableDisplay[] = [];

  get variableDetails() {
    return store.getters[`${VuexModuleNamespaces.assetVariable}/${AssetVariableStore.getters.getVariableDetails.name}`];
  }

  get getVariablesFirstRow() {
    return this.variablesFirstRow.sort((a,b) => a.position - b.position);
  }

  get getVariablesSecondRow() {
    return this.variablesSecondRow.sort((a,b) => a.position - b.position);
  }

  public created() {
    this.variableDetails.forEach((variable: IVariableDetail) => {
      const variableValue = this.assetVariables.find((data:any) => data.path === variable.path)?.value;
      if (variable.position.charAt(0) === '1') {
        this.variablesFirstRow.push({label:variable.labelName, position: parseInt(variable.position.charAt(2)), value: variableValue ? variableValue+' °C' : '', path: variable.path});
      } else {
        this.variablesSecondRow.push({label:variable.labelName, position: parseInt(variable.position.charAt(2)), value: variableValue ?variableValue+' °C' : '', path: variable.path});
      }
    });
  }
}
