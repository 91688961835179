import Vue from 'vue';
import Vuex from 'vuex';
import { AppStore, IAppStoreState } from '@/store/app/appStore';
import { GridStore, IGridStoreState } from '@/store/grid/gridStore';
import { BurnerLayoutStore, IBurnerLayoutStoreState } from '@/store/burnerLayout/burnerLayoutStore';
import { AssetStore, IAssetStoreState } from '@/store/assetStore/assetStore';
import { BurnerFolderStore, IBurnerFolderStoreState } from '@/store/burnerFolder/burnerFolderStore';
import { AssetFolderStore, IAssetFolderStoreState } from '@/store/assetFolder/assetFolderStore';
import { DiagramStore, IDiagramStoreState } from '@/store/diagram/diagramStore';
import { ErrorStore, IErrorStoreState } from '@/store/error/errorStore';
import { IBurnerReadingStoreState, BurnerReadingStore } from '@/store/burnerReading/burnerReadingStore';
import { UserStore, IUserStoreState } from '@/store/user/userStore';
import { PreviewGridStore, IPreviewGridStoreState } from '@/store/previewGrid/previewGridStore';
import { AppViewerStore, IAppStoreViewerState } from './viewer/app/appStore';
import { IGridStoreViewerState } from './viewer/grid/__mocks__/gridStore';
import { IPreviewGridStoreViewerState, PreviewGridViewerStore } from './viewer/previewGrid/previewGridStore';
import { BurnerLayoutViewerStore, IBurnerLayoutStoreViewerState } from './viewer/burnerLayout/burnerLayoutStore';
import { AssetViewerStore, IAssetStoreViewerState } from './viewer/assetStore/assetStore';
import { BurnerFolderViewerStore, IBurnerFolderStoreViewerState } from './viewer/burnerFolder/burnerFolderStore';
import { DiagramViewerStore, IDiagramStoreViewerState } from './viewer/diagram/diagramStore';
import { ErrorViewerStore, IErrorStoreViewerState } from './viewer/error/errorStore';
import { BurnerReadingViewerStore, IBurnerReadingStoreViewerState } from './viewer/burnerReading/burnerReadingStore';
import { IUserStoreViewerState, UserViewerStore } from './viewer/user/userStore';
import { GridViewerStore } from './viewer/grid/gridStore';
import { AssetVariableStore, IVariableStoreViewerState } from './viewer/assetVariable/assetVariableStore';
import { IVariableFolderStoreState, VariableFolderStore } from './variableFolder/variableFolderStore';

Vue.use(Vuex);

export interface IRootState {
  app: IAppStoreState;
  grid: IGridStoreState;
  burnerLayout: IBurnerLayoutStoreState;
  asset: IAssetStoreState;
  assetFolder: IAssetFolderStoreState;
  burnerFolder: IBurnerFolderStoreState;
  diagram: IDiagramStoreState;
  error: IErrorStoreState;
  burnerReading: IBurnerReadingStoreState;
  user: IUserStoreState;
  previewGrid: IPreviewGridStoreState;
  appViewer: IAppStoreViewerState;
  gridViewer: IGridStoreViewerState;
  burnerLayoutViewer: IBurnerLayoutStoreViewerState;
  assetViewer: IAssetStoreViewerState;
  burnerFolderViewer: IBurnerFolderStoreViewerState;
  diagramViewer: IDiagramStoreViewerState;
  errorViewer: IErrorStoreViewerState;
  burnerReadingViewer: IBurnerReadingStoreViewerState;
  userViewer: IUserStoreViewerState;
  previewGridViewer: IPreviewGridStoreViewerState;
  assetVariable: IVariableStoreViewerState;
  variableFolder: IVariableFolderStoreState;
}

export default new Vuex.Store({
  modules: {
    app: AppStore,
    grid: GridStore,
    burnerLayout: BurnerLayoutStore,
    asset: AssetStore,
    assetFolder: AssetFolderStore,
    burnerFolder: BurnerFolderStore,
    diagram: DiagramStore,
    error: ErrorStore,
    burnerReading: BurnerReadingStore,
    user: UserStore,
    previewGrid: PreviewGridStore,
    appViewer: AppViewerStore,
    gridViewer: GridViewerStore,
    burnerLayoutViewer: BurnerLayoutViewerStore,
    assetViewer: AssetViewerStore,
    burnerFolderViewer: BurnerFolderViewerStore,
    diagramViewer: DiagramViewerStore,
    errorViewer: ErrorViewerStore,
    burnerReadingViewer: BurnerReadingViewerStore,
    userViewer: UserViewerStore,
    previewGridViewer: PreviewGridViewerStore,
    assetVariable: AssetVariableStore,
    variableFolder: VariableFolderStore
  }
});

// update these module namespaces as registered module names are update above
export const VuexModuleNamespaces = {
  app: 'app',
  grid: 'grid',
  burnerLayout: 'burnerLayout',
  asset: 'asset',
  assetFolder: 'assetFolder',
  burnerFolder: 'burnerFolder',
  diagram: 'diagram',
  error: 'error',
  burnerReading: 'burnerReading',
  user: 'user',
  previewGrid: 'previewGrid',
  appViewer: 'appViewer',
  gridViewer: 'gridViewer',
  previewGridViewer: 'previewGridViewer',
  burnerLayoutViewer: 'burnerLayoutViewer',
  assetViewer: 'assetViewer',
  burnerFolderViewer: 'burnerFolderViewer',
  diagramViewer: 'diagramViewer',
  errorViewer: 'errorViewer',
  burnerReadingViewer: 'burnerReadingViewer',
  userViewer: 'userViewer',
  assetVariable: 'assetVariable',
  variableFolder: 'variableFolder',
};
