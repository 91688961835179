import { render, staticRenderFns } from "./ResetModal.vue?vue&type=template&id=16164e81&scoped=true&"
import script from "./ResetModal.vue?vue&type=script&lang=ts&"
export * from "./ResetModal.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./ResetModal.vue?vue&type=style&index=0&id=16164e81&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "16164e81",
  null
  ,true
)

export default component.exports