import { IBurnerReading, IBurnerInfo, PrimaryDisplayAttribute } from '@/view-models/burnerReading/burner-reading';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState, VuexModuleNamespaces } from '..';
import { BurnerReadingService } from '@/services/burnerReading/burner-reading-service';
import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import { BurnerLayoutStore } from '@/store/burnerLayout/burnerLayoutStore';
export interface IBurnerReadingStoreState {
   burnerReading: IBurnerReading;
   isFetchComplete: boolean;
   primaryDisplayAttribute: PrimaryDisplayAttribute;
}

export interface IBurnerReadingStoreGetters extends GetterTree<IBurnerReadingStoreState, IRootState> {
  getBurnerReadings(state: IBurnerReadingStoreState): IBurnerReading;
  getIsFetchComplete(state: IBurnerReadingStoreState): boolean;
  getLastReadingDate(state: IBurnerReadingStoreState): string;
}

export interface IBurnerReadingStoreMutations extends MutationTree<IBurnerReadingStoreState> {
}

export interface IBurnerReadingStoreActions extends ActionTree<IBurnerReadingStoreState, IRootState> {
  retrieveBurnerReadings(context: IBurnerReadingContext): Promise<void>;
  getBurnerReadingsByKey(context: IBurnerReadingContext, selectedBurnerKey: string): Promise<IBurnerInfo>;
}

export type IBurnerReadingContext = ActionContext<IBurnerReadingStoreState, IRootState>;

export const UNKNOWN_READINGS: IBurnerInfo = {
  key: '',
  name: '',
  heatRelease: -999999,
  equivalenceRatio: -999999,
  outOfControllableRange: false,
  opportunityScore: -999999,
  airRegisters: [ {
    name: '',
    currentSetting: -999999,
    recommendedSetting: -999999
   }
  ],
  fuelLines: [],
};

export const BurnerReadingStore = {
   namespaced: true as true,
   state: {
       burnerReading: {},
       isFetchComplete: false,
       primaryDisplayAttribute: PrimaryDisplayAttribute.RECOMMENDED_AIR_REGISTER
   } as IBurnerReadingStoreState,
   getters: {
     getBurnerReadings(state: IBurnerReadingStoreState): IBurnerReading {
       return state.burnerReading;
     },
     getIsFetchComplete(state: IBurnerReadingStoreState): boolean {
       return state.isFetchComplete;
       },
     getLastReadingDate(state: IBurnerReadingStoreState): string {
        return state.burnerReading.dateTime;
     }
   } as IBurnerReadingStoreGetters,
   mutations: {
   } as IBurnerReadingStoreMutations,
   actions: {
     async retrieveBurnerReadings(context: IBurnerReadingContext): Promise<void> {
       // Returning burners readings based on selected equipmentConfigKey from service.
       // const equipmentConfigKey = store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.equipmentConfigKey.name}`];
        const conf = await ConfigFactory.GetConfig();
        const burnerReadingService = new BurnerReadingService(sharedAxiosInstance,
            process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
                process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
                conf.get('adbApiUrl'));
        const response = await burnerReadingService.getBurnerReadings('d8611aa1-2c9b-4e76-8f5f-e7e46af740a3','a5ad54e7-7907-4669-9e18-c02ac4bc02cd');

        // Refresh layout for current selected reading if set
        const selectedBurner = store.getters[`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.getters.selectedBurnerDetail.name}`];
        if (selectedBurner) {
          const refreshedBurner = await store.dispatch(`${VuexModuleNamespaces.burnerReading}/${BurnerReadingStore.actions.getBurnerReadingsByKey.name}`, selectedBurner.key);
          // Populate with burner readings
          await store.dispatch(`${VuexModuleNamespaces.burnerLayout}/${BurnerLayoutStore.actions.updateBurnerReadings.name}`, refreshedBurner);
        }
        context.state.burnerReading = response;
        context.state.isFetchComplete = true;
     },
     async getBurnerReadingsByKey(context: IBurnerReadingContext, selectedBurnerKey: string): Promise<IBurnerInfo> {
       let foundBurner: IBurnerInfo | undefined = UNKNOWN_READINGS;
       if (context.state.burnerReading.burners !== null &&
           context.state.burnerReading.burners !== undefined) {
          foundBurner = context.state.burnerReading.burners.find((burner) => burner.key === selectedBurnerKey);
          if (!foundBurner) {
            foundBurner = UNKNOWN_READINGS;
          }
       }
       return foundBurner;
     }
   } as IBurnerReadingStoreActions
};
