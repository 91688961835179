





























// Libraries
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { IFolder } from '@/view-models/common/folder-view-models';

Vue.use(BootstrapVue);

// Components
@Component({
  name: 'folder-item'
})
export default class FolderItem extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public folder!: IFolder;
  @Prop({ default: false })
  public isReadOnly!: boolean;
  @Prop({ default: false })
  public isRoot!: boolean;
  @Prop()
  public itemSelected?: (item: IFolder, args: MouseEvent) => void;
  @Prop({ default: '' })
  public searchString?: string;

  // Properties
  public isOpen: boolean = true;
  public errors: any;
  public folderCount: number = 10;
  public itemCount: number = 10;
  public linkFolders: IFolder[] = [];

  public mounted() {
    this.addLinkFolders(this.folder);
  }

  // Getters
  get foldersExpandable(): boolean {
    if (this.folder.visibleChildFolders) {
      const count: number = this.folder.visibleChildFolders.length;
      return count > 10 && this.folderCount <= count;
    } else {
      return false;
    }
  }

  get hasChildren(): boolean {
    if (this.folder.visibleChildFolders && this.folder.visibleChildItems) {
      return this.folder.visibleChildFolders.length > 0 || this.folder.visibleChildItems.length > 0;
    } else {
      return false;
    }
  }

  get itemsExpandable(): boolean {
    if (this.folder.visibleChildItems) {
      const count: number = this.folder.visibleChildItems.length;
      return count > 10 && this.itemCount <= count;
    } else {
      return false;
    }
  }

  get expandBackgroundUrl() {
    return require('../../assets/images/icons/Expand.svg');
  }

  get collapseBackgroundUrl() {
    return require('../../assets/images/icons/Collapse.svg');
  }

  public toggle(): void {
    this.folder.isOpen = !this.folder.isOpen;
  }

  public selectItem(event: MouseEvent): void {
    if (this.itemSelected !== undefined) {
      this.itemSelected(this.folder, event);
    }
  }

  public isFilterMatch(): boolean {
    if ((this.searchString === undefined || this.searchString.length === 0 )) {
      return true;
    } else {
      this.folder.isOpen = true;
      return this.linkFolders.some((linkFolder: IFolder) => this.containsSearchString(linkFolder.name!));
    }
  }

  private addLinkFolders(folder: IFolder) {
    if (folder.isLink) {
      this.linkFolders.push(folder);
    } else {
      if (folder.visibleChildFolders) {
        folder.visibleChildFolders
        .filter((childFolder) => childFolder.isLink)
        .forEach((childLinkFolder) => {
          this.linkFolders.push(childLinkFolder);
        });
      folder.visibleChildFolders
        .filter((noneLinkFolder: IFolder) => !noneLinkFolder.isLink)
        .forEach((noneLinkFolder: IFolder) => {
          return this.addLinkFolders(noneLinkFolder);
        });
      }
    }
  }

  private containsSearchString(name: string): boolean {
    if (this.searchString !== undefined) {
      return name.toUpperCase().includes(this.searchString.toUpperCase());
    } else {
      return true;
    }
  }
}
