import { render, staticRenderFns } from "./LockedModal.vue?vue&type=template&id=5c259302&scoped=true&"
import script from "./LockedModal.vue?vue&type=script&lang=ts&"
export * from "./LockedModal.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./LockedModal.vue?vue&type=style&index=0&id=5c259302&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5c259302",
  null
  ,true
)

export default component.exports