























































































































































import { Vue, Component } from 'vue-property-decorator';
import { IAsset } from '@/view-models/asset/assets-view-models';
import store, { VuexModuleNamespaces } from '@/store/';
import { AssetStore } from '@/store/assetStore/assetStore';
import { DiagramStore } from '@/store/diagram/diagramStore';
import { ErrorStore } from '@/store/error/errorStore';
import { GridStore } from '@/store/grid/gridStore';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import moment from 'moment';
import PublishModal from '@/components/editor/components/modals/PublishModal.vue';
import ResetModal from '@/components/editor/components/modals/ResetModal.vue';
import BurnerLayoutModal from '@/components/editor/components/modals/BurnerLayoutModal.vue';
import IAssetDiagramServiceResponse from '@/view-models/assetDiagram/asset-response-view-models';
import { ArrangeMode } from '@/view-models/assetDiagram/asset-diagram';
import { adEventBus, adEvents } from '@/services/eventBus/asset-diagram-event-bus';
import { changeParentRoute } from '@/utils/from-parent';
import { AppStore } from '@/store/app/appStore';
import { AlignLabelOptions } from '@/enums/DiagramApp';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.prototype.moment = moment;

@Component({
  name: 'editor-toolbar',
  components: {
    BurnerLayoutModal,
    PublishModal,
    ResetModal
  }
})
export default class EditorToolbar extends Vue{
  public lastPublished: IAssetDiagramServiceResponse | null = null;
  public alignLabelMenu: boolean = false;
  public alignLabelOption = AlignLabelOptions;

  get loading(): boolean {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isLoading.name}`];
  }

  get selectedAsset(): IAsset {
    return store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
  }

  get getActiveLabelCount(): boolean {
    return (store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.getActiveLabelCount.name}`] > 1);
  }

  get siteName(): string {
    return this.findAssetParentTypeName(this.selectedAsset, 'CustomerSite');
  }

  get isArrangeShapeMode(): boolean {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.isArrangeShapeMode.name}`];
  }

  get arrangeMode(): ArrangeMode {
    return store.getters[`${VuexModuleNamespaces.grid}/${GridStore.getters.arrangeMode.name}`];
  }

  public getImage(value: string): string {
    return require('../../../assets/images/icons/LabelAlignment/'+value+'.png');
  }

  public async getPublishedVersion() {
    // Called when selectedAsset set or when already set and EditorToolbar is remounted
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => {
        this.lastPublished =
          await store.dispatch(
            `${VuexModuleNamespaces.diagram}/${DiagramStore.actions.retrievePublishedDiagram.name}`,
            this.selectedAsset?.key
          );
        if (!this.lastPublished?.diagram || this.lastPublished?.diagram === '{}') {
          this.lastPublished = null;
        }
      },
      errorMsg: 'Error finding published version.',
      routeHomeAfterError: false
    });
  }

  private mounted() {
    // If we re-mount and selectedAsset is present, check published version again
    if (this.selectedAsset?.key) {
      this.getPublishedVersion();
    }
    adEventBus.$on(adEvents.publishDiagram, this.getPublishedVersion);
  }

  private findAssetParentTypeName(currentNode: IAsset, typeName: string): string {
    const parent = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.findAssetParentType.name}`](currentNode, typeName);
    return parent ? parent.name : '';
  }

  private redirectToADV() {
    changeParentRoute('/assets/heater/' + this.selectedAsset.key + '/layout');
  }

  private finishIsArrangeShapeMode() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.setArrangeShapeMode.name}`, false);
  }

  private cancelArrangeShapeMode() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.cancelArrangeShapeMode.name}`);
  }

  private onClickAddSpace() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.setArrangeMode.name}`, ArrangeMode.AddSpace);
  }

  private onClickAddCircle() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.setArrangeMode.name}`, ArrangeMode.AddCircle);
  }

  private onClickAddSquare() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.setArrangeMode.name}`, ArrangeMode.AddSquare);
  }

  private onClickArrange() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.setArrangeShapeMode.name}`, true);
  }

  private onClickHotKeys() {
    alert('Hotkeys modal not implemented');
  }

  get isDiagramLocked(): boolean {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.isDiagramLocked.name}`];
  }

  get lastModifiedByName(): string {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.lastModifiedByName.name}`];
  }

  get lastModifiedDate(): string {
    return store.getters[`${VuexModuleNamespaces.diagram}/${DiagramStore.getters.lastModifiedDate.name}`];
  }

  private async onPreview() {
    await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.flushDiagramSaver.name}`);
    this.$router.push(`/preview/${this.selectedAsset.key}`);
  }

  private onPublish() {
    store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    this.$bvModal.show('publish-modal');
  }

  private async onReset() {
    await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.forceCloseLabelEdit.name}`);
    adEventBus.$emit(adEvents.closeSideMenu);
    const resetModal = this.$refs.resetModal as ResetModal;
    resetModal.initializeReset();
    this.$bvModal.show('reset-modal');
  }

  private showLabelMenu() {
    if (this.getActiveLabelCount) {
      this.alignLabelMenu = true;
    }
  }

  public updatePosition(value: string) {
    store.commit(`${VuexModuleNamespaces.grid}/${GridStore.mutations.alignLabels.name}`, value);
    this.alignLabelMenu = false;
  }
}
