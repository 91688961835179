import ControllerBaseService from '@/services/viewer/common/controller-base-service';
import { AxiosInstance, AxiosResponse } from 'axios';
import { IBurnerReading } from '@/view-models/viewer/burnerReading/burner-reading';
import { IAssetVariablesServiceResponse } from '@/view-models/viewer/assetVariables/asset-variables-response';

export class BurnerReadingService extends ControllerBaseService {

  constructor(axiosInstance: AxiosInstance, adbApiUrl: string) {
    super(adbApiUrl, axiosInstance);
  }

  public async getBurnerReadingsLT(equipmentConfigKey: string, assetKey: string, customerKey: string): Promise<IBurnerReading> {
    let response : AxiosResponse<IBurnerReading>;
    response = await this.http.get<IBurnerReading>(this.url(`aov/BurnersReading/asset/${assetKey}?equipmentConfigKey=${equipmentConfigKey}&customerKey=${customerKey}`),{ headers: { 'api-version': '2.0' } });
    return (response.data);
  }

  public async getBurnerReadings(equipmentConfigKey: string, assetKey: string): Promise<IBurnerReading> {
    const url = `aov/BurnersReading/asset/${equipmentConfigKey}/${assetKey}`;
    let response : AxiosResponse<IBurnerReading>;
    response = await this.http.get<IBurnerReading>(url);
    return (response.data);
  }

  public async getVariableDetails(assetKey: string, variablePaths: string[]): Promise<IAssetVariablesServiceResponse> {
    const url = 'aov/BurnersReading/AssetVariablesData';
    let response : AxiosResponse<IAssetVariablesServiceResponse>;
    response = await this.http.post<IAssetVariablesServiceResponse>(url, {assetKey, variablePaths});
    return (response.data);
  }

}
