







































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { ErrorStore } from '@/store/error/errorStore';
import { DiagramStore } from '@/store/diagram/diagramStore';
import { GridStore } from '@/store/grid/gridStore';
import EditorToolbar from '@/components/editor/components/EditorToolbar.vue';
import Loading from '@/components/common/Loading.vue';
import { AppStore } from '@/store/app/appStore';

Vue.use(BootstrapVue);

@Component({
  name: 'publish-modal',
  components: {
    Loading
  }
})
export default class PublishModal extends Vue {

  public isDone: boolean = false;
  public isLoading: boolean = false;

  private mounted() {
    this.isDone = false;
    this.isLoading = false;
  }

  private onCancel(): void {
    this.isDone = false;
    this.$bvModal.hide('publish-modal');
  }

  private onOk(): void {
    this.isDone = false;
    this.$bvModal.hide('publish-modal');
  }

  private async onPublish(): Promise<void> {
    this.isLoading = true;
    try {
      store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.resetZoom.name}`);
      store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
      await store.dispatch(`${VuexModuleNamespaces.diagram}/${DiagramStore.actions.publish.name}`);
      await (this.$refs.editorToolbar as EditorToolbar)?.getPublishedVersion();
      await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.cancelArrangeShapeMode.name}`);
      await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.unselectAllArrangeRects.name}`);
    } catch (err) {
      const errorString = 'Error publishing asset. \n';
      await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.setError.name}`, {
        error: err,
        errorString,
        handleError: true,
        routeHomeAfterError: false
      });
      this.$bvModal.hide('publish-modal');
    }
    this.isDone = true;
    this.isLoading = false;
  }
}
