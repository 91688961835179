import { IRootState } from '../../index';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IMetadataDetails, IUser } from '@/view-models/viewer/user/user-view-models';
import { UserService } from '@/services/viewer/user/user-service';
import ConfigFactory from '@/services/config/config';
import axios from 'axios';
import authInterceptor from '@/services/viewer/common/service-interceptor';

export interface IUserStoreViewerState {
  currentUser: IUser;
  userInfoService: UserService;
  customerAssetInfo: IMetadataDetails;
}

export interface IUserStoreActions
  extends ActionTree<IUserStoreViewerState, IRootState> {
  getCurrentUser(context: IUserContext): Promise<void>;
}

export interface IUserStoreGetters
  extends GetterTree<IUserStoreViewerState, IRootState> {
  currentUser(state: IUserStoreViewerState): IUser;
}

export interface IUserStoreMutations
  extends MutationTree<IUserStoreViewerState> {
  updateCurrentUser(state: IUserStoreViewerState, user: IUser): void;
  updateCustomerAssetInfo(state: IUserStoreViewerState, details: IMetadataDetails): void;
}

const initUserService = async () => {
  const conf = await ConfigFactory.GetConfig();
  const privateAxios = axios.create();
  privateAxios.interceptors.request.use(authInterceptor);
  return new UserService(
    privateAxios,
    conf.get('umApiUrl')
  );
};

export type IUserContext = ActionContext<IUserStoreViewerState, IRootState>;

export const UserViewerStore = {
  namespaced: true as true,
  state: {
  } as IUserStoreViewerState,
  actions: {
    async getCurrentUser(context: IUserContext) {
      const userService = await initUserService();
      await userService.getUser().then((response: IUser) => {
        context.commit(UserViewerStore.mutations.updateCurrentUser.name, response);
      });
    }
  },
  getters: {
    currentUser(state: IUserStoreViewerState) {
      return state.currentUser;
    },
    customerAssetDetails(state: IUserStoreViewerState) {
      return state.customerAssetInfo;
    }
  },
  mutations: {
    updateCurrentUser(
      state: IUserStoreViewerState,
      user: IUser
    ) {
      state.currentUser = user;
    },
    updateCustomerAssetInfo(state: IUserStoreViewerState, details: IMetadataDetails) {
      state.customerAssetInfo = details;
    }
  }
};
