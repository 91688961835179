import Vue from 'vue';

export const eventBus = new Vue();
export default eventBus;

export enum Events {
  VariableTreeNodeLeafSelected = 'variable-tree::node-leaf-selected',
  VariableTreeNodeToggleIsOpen = 'variable-tree::node-toggle-is-open'
}

export enum ParentEvents {
  RouteChange = 'onpoint::routeChange',
  ActiveCustomerChanged = 'user::ActiveCustomerChanged'
}

export function configureEventBuses() {
  eventBus.$off();
}

export interface ISubscription {
  unsubscribe: () => void;
}

export const subscription = (event: Events, callback: (...args: any[]) => void): ISubscription => {
  eventBus.$on(event, callback);

  return {
    unsubscribe() {
      eventBus.$off(event, callback);
    }
  };
};
