import { IVariableDiagram } from '../assetVariables/asset-variables-view-models';
import { IBurnerVariableConfig } from '../burnerLayout/burner-layout-view-models';

export interface IAssetDiagram {
  burnerlayout?: IBurnerVariableConfig[];
  assetKey: string; // UUID
  equipmentConfigKey: string | undefined; // UUID
  diagram: string | Buffer; // Compressed buffer or string
  status: Status;
  diagramContent: any;
  assetDiagramObjects: IDiagramObject[];
  numberOfRows: number;
  variableDiagram?: IVariableDiagram[];
  loadBurnerPreviewArray(): void;
  getObjectsByRow(row: number): IDiagramObject[];
  getNumOfColumnsByRow(row: number): number;
  getObjectByCoordinates(x: number, y: number): IDiagramObject;
}

export interface IDiagramObject {
  type: DiagramType;
  position: IPosition;
  name: string;
  key: string;
  coordinates: ICoordinate;
  coordinateWidthOffset: number;
  coordinateHeightOffset: number;
  leftMargin: number;
  topMargin: number;
  hasVerticalSpaceLine: boolean;
  hasHorizontalSpaceLine: boolean;
  spaceLineVerticalPosition: SpaceLinePosition;
  spaceLineHorizontalPosition: SpaceLinePosition;
}

export enum Status {
  Published = 'published',
  Draft = 'draft'
}

export interface IPosition {
  left: number;
  top: number;
}

export enum DiagramType {
  Label = 'label',
  Burner = 'burner',
  Empty = 'empty'
}

export enum SpaceLinePosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  TopBottom = 'topBottom',
  LeftRight = 'leftRight',
  None = 'none'
}

export enum ArrangeMode {
  AddSpace = 'addSpace',
  AddCircle = 'addCircle',
  AddSquare = 'addSquare'
}

export interface ICoordinate {
  x: number;
  y: number;
}
