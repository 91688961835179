import BaseService from '@/services/viewer/common/base-service';
import { AxiosInstance } from 'axios';
import { IUser } from '@/view-models/viewer/user/user-view-models';

export class UserService extends BaseService {

  constructor(axios: AxiosInstance, umApiUrl: string) {
    super(umApiUrl, axios);
  }

  public async getUser(): Promise<IUser> {
    return this.httpClient.get<IUser>('users/current');
  }
}
