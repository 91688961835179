export enum SortDirection {
  Ascending,
  Descending
}

function nullComparer<T>(a: T, b: T) {
  if (b == null && a == null) {
    return 0;
  }
  if (a == null && b != null) {
    return -1;
  }
  if (a != null && b == null) {
    return 1;
  }

  return null; // neither are null, so cannot compare
}

function keepDirection(dir: SortDirection, ascendingValue: number) {
  return dir === SortDirection.Ascending && ascendingValue !== 0
  ? ascendingValue : getAscendingValue(ascendingValue);
}

function getAscendingValue(ascendingValue: number) {
  return ascendingValue != null ? ascendingValue * -1 : ascendingValue;
}

function alphabeticSorter(): (a: string, b: string) => number;
function alphabeticSorter<T>(selector: (o: T) => string): (a: T, b: T) => number;
function alphabeticSorter<T>(selector?: (o: T) => string) {
  return alphabeticSorterImpl(SortDirection.Ascending, selector);
}

function alphabeticSorterDesc(): (a: string, b: string) => number;
function alphabeticSorterDesc<T>(selector: (o: T) => string): (a: T, b: T) => number;
function alphabeticSorterDesc<T>(selector?: (o: T) => string) {
  return alphabeticSorterImpl(SortDirection.Descending, selector);
}

function alphabeticSorterImpl<T>(dir: SortDirection, selector?: (o: T) => string, locale: string = 'en-US') {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA: string = selector(a);
      const sB: string = selector(b);
      const res = keepDirection(dir, nullComparer(sA, sB)!);
      const ascendingValue = res != null ? res : sA.localeCompare(sB, locale, { sensitivity: 'base', numeric: true });
      return keepDirection(dir, ascendingValue);
    };
  }

  return (a: string, b: string) => {
    const res = nullComparer(a, b);
    const ascendingValue = res != null ? res : a.localeCompare(b, locale, { sensitivity: 'base', numeric: true });
    return keepDirection(dir, ascendingValue);
  };
}

function numericSorter(): (a: number, b: number) => number;
function numericSorter<T>(selector: (o: T) => number): (a: T, b: T) => number;
function numericSorter<T>(selector?: (o: T) => number) {
  return numericSorterImpl(SortDirection.Ascending, selector);
}

function numericSorterDesc(): (a: number, b: number) => number;
function numericSorterDesc<T>(selector: (o: T) => number): (a: T, b: T) => number;
function numericSorterDesc<T>(selector?: (o: T) => number) {
  return numericSorterImpl(SortDirection.Descending, selector);
}

function numericSorterImpl<T>(dir: SortDirection, selector?: (o: T) => number) {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = keepDirection(dir, nullComparer(sA, sB)!);
      const ascendingValue = res != null ? res : sA - sB;
      return keepDirection(dir, ascendingValue);
    };
  }
  return (a: number, b: number) => {
    const res = nullComparer(a, b);
    const ascendingValue = res != null ? res : a - b;
    return keepDirection(dir, ascendingValue);
  };
}

export type ObjectSorterType<TItem, TKey> = (selector: (o: TItem) => TKey) => ((a: TItem, b: TItem) => number);

export {
  alphabeticSorter,
  alphabeticSorterDesc,
  numericSorter,
  numericSorterDesc
};

export function distinctArray<T>(collection: Array<T>): Array<T> {
  return collection == null ? collection : Array.from(new Set(collection));
}

export function cloneArray<T>(array: Array<T>): Array<T> {
  return array != null ? array.slice(0) : [];
}

export function rangeArray(start: number, end: number, step: number = 1) {
  const range = [];
  for (let i = start; i <= end; i += step) {
    range.push(i);
  }
  return range;
}

export interface IPromiseFulfilledResult<T> {
  status: 'fulfilled';
  value: T;
}
export interface IPromiseRejectedResult {
  status: 'rejected';
  reason: string | Error;
}

export type PromiseSettled<T> = IPromiseFulfilledResult<T> | IPromiseRejectedResult;

export function settlePromises<T>(promises: Promise<T>[]): Promise<PromiseSettled<T>[]> {
  const mappedPromises = promises.map(async (promise: Promise<T>) => {
    try {
      const value = await promise;
      return {
        status: 'fulfilled',
        value
      } as IPromiseFulfilledResult<T>;
    } catch (reason) {
      return {
        status: 'rejected',
        reason
      } as IPromiseRejectedResult;
    }
  });
  return Promise.all(mappedPromises);
}
