import { AssetFolder } from '@/view-models/asset/asset-folder-view-models';
import { IAsset } from '@/view-models/asset/assets-view-models';
import store, { VuexModuleNamespaces } from '..';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState} from '..';
import { AssetStore } from '@/store/assetStore/assetStore';

export interface IAssetFolderStoreState {
    assetFolders: AssetFolder[];
}

export interface AssetFolderStoreGetters extends GetterTree<IAssetFolderStoreState, IRootState> {
    allAssetFolders(state: IAssetFolderStoreState): AssetFolder[];
    rootFolders(state: IAssetFolderStoreState): AssetFolder[];
}

export interface AssetFolderStoreMutations extends MutationTree<IAssetFolderStoreState> {
}

export interface AssetFolderStoreActions extends ActionTree<IAssetFolderStoreState, IRootState> {
  loadAssetFolder(context: AssetFolderContext): void;
  resetAssetFolder(context: AssetFolderContext): void;
}

export type AssetFolderContext = ActionContext<IAssetFolderStoreState, IRootState>;

export const AssetFolderStore = {
    namespaced: true as true,
    state: {
        assetFolders: Array<AssetFolder>(),
    } as IAssetFolderStoreState,
    getters:  {
        allAssetFolders(state: IAssetFolderStoreState): null | AssetFolder[] {
            return state.assetFolders;
        },
        rootFolders(state: IAssetFolderStoreState): AssetFolder[] {
            return state.assetFolders
                .filter((folder) => !folder.parentKey)
                .sort((a, b) => a.name < b.name ? -1 : 1);
        }
    } as AssetFolderStoreGetters,
    mutations: {
    } as AssetFolderStoreMutations,
    actions: {
        loadAssetFolder(context: AssetFolderContext): void {
            const folders = Array<AssetFolder>();
            store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.allAssets.name}`].forEach(
              (asset: IAsset) => {
                folders.push(new AssetFolder(asset));
            });

            // Loop back through the burners and build the tree
            folders
                .filter((b) => b.parentKey)
                .forEach((child) => {
                    const parent = folders.find((p) => p.key === child.parentKey);
                    if (parent) {
                        parent.childFolders.push(child);
                    }
                });

            // Order the children
            // Need to here instead of in the child object because causes recursive binding
            // If this is the burner itself, sort by the orderIndex
            // otherwise sort by the node name.
            folders
                .filter((parent) => parent.childCount > 0)
                .forEach((parent) => {
                    const sorted = parent.childFolders.sort((a, b) =>
                         a.name > b.name ? 1 : -1);
                    parent.childFolders = sorted;
            });
            context.state.assetFolders = folders;
        },
        resetAssetFolder(context: AssetFolderContext): void {
          context.state.assetFolders = Array<AssetFolder>();
        }
    } as AssetFolderStoreActions
};
