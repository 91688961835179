

























// Vue Dependencies
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
// Vuex Dependencies
import store, { VuexModuleNamespaces } from '@/store/';
import { GridStore } from '@/store/grid/gridStore';
// Library Dependencies
import { BootstrapVue } from 'bootstrap-vue';
import { PreviewGridViewerStore } from '@/store/viewer/previewGrid/previewGridStore';
import { IZoomStatus } from '@/view-models/assetDiagram/grid-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'zoom-button'
})
export default class ZoomButton extends Vue {
  @Prop({ default: false })
  public isViewer!: boolean;

  private isZoomOutDisabled: boolean = false;
  private isZoomInDisabled: boolean = false;

  private async zoomIn(): Promise<void> {
    let zoomStatus!: IZoomStatus;
    if (!this.isZoomInDisabled) {
      if (this.isViewer) {
        store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.zoomIn.name}`);
        zoomStatus = await store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.getZoomStatus.name}`);
      } else {
        store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.zoomIn.name}`);
        zoomStatus = await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.getZoomStatus.name}`);
      }
      this.isZoomInDisabled = zoomStatus.zoomInDisabled;
      this.isZoomOutDisabled = zoomStatus.zoomOutDisabled;
    }
  }

  private async zoomOut(): Promise<void> {
    let zoomStatus!: IZoomStatus;
    if (!this.isZoomOutDisabled) {
      if (this.isViewer) {
        store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.zoomOut.name}`);
        zoomStatus = await store.dispatch(`${VuexModuleNamespaces.previewGridViewer}/${PreviewGridViewerStore.actions.getZoomStatus.name}`);
      } else {
        store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.zoomOut.name}`);
        zoomStatus = await store.dispatch(`${VuexModuleNamespaces.grid}/${GridStore.actions.getZoomStatus.name}`);
      }
      this.isZoomOutDisabled = zoomStatus.zoomOutDisabled;
      this.isZoomInDisabled = zoomStatus.zoomInDisabled;
    }
  }

  private zoomToFit(): void {
    alert('Zoom to fit not implemented! In future sprints, I will trim excess scroll from the grid' +
    ' and make all objects fit to screen');
  }

}
