import Vue from 'vue';

export const adEventBus = new Vue();

export const adEvents = {
  // Publish
  publishDiagram: 'publishDiagram',

  // Modals
  mediaLibraryModalClosed: 'mediaLibraryModal::closed',

  // Media Library
  libraryMediaSelected: 'library-media-selected',
  selectedLibraryMediasCounted: 'selected-library-medias-counted',
  uploadingMedias: 'uploading-medias',

  // Media Viewer
  showMediaInPreviewer: 'show-media-in-previewer',
  viewerCarouselItemSelected: 'viewer-carousel-item-selected',

  // Dropdown menu
  changedDisplay: 'changedDisplay',

  // Burner selection,
  deselectBurner: 'deselectBurner',
  trySelectBurner: 'trySelectBurner',
  selectBurnerConfirmed: 'selectBurnerConfirmed',
  selectBurner: 'selectBurner',

  // Comment Editor
  tryCloseSideMenu: 'tryCloseSideMenu',
  closeSideMenu: 'closeSideMenu',
  commentAdded: 'commentAdded',
  openCommentEditor: 'openCommentEditor',
  closeCommentEditor: 'closeCommentEditor',
  tryOpenReplyEditor: 'tryOpenReplyEditor',
  openReplyEditorConfirmed: 'openReplyEditorConfirmed',
  openReplyEditor: 'openReplyEditor',
  closeReplyEditor: 'closeReplyEditor'
};
