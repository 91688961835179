

















import { Vue, Component } from 'vue-property-decorator';
import store, { VuexModuleNamespaces } from '@/store';
import { ErrorViewerStore } from '@/store/viewer/error/errorStore';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'error'
})
export default class Error extends Vue {

  private store = store;

  get error() {
    return store.getters[`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.getters.getError.name}`];
  }

  private onClose() {
    // Handle Error
    if (store.getters[`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.getters.getRouteHomeAfterError.name}`]) {
      this.$router.push('/');
    }
    // Clear Error
    store.commit(`${VuexModuleNamespaces.errorViewer}/${ErrorViewerStore.mutations.clearError.name}`);
  }

}
