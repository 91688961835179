



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { IAsset } from '@/view-models/asset/assets-view-models';
import store, { VuexModuleNamespaces } from '@/store/';
import { AssetStore } from '@/store/assetStore/assetStore';
import { BootstrapVue } from 'bootstrap-vue';
import PublishModal from '../editor/components/modals/PublishModal.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'preview-toolbar',
  components: {
    PublishModal
  }
})
export default class PreviewToolbar extends Vue {

  get selectedAsset(): IAsset {
    return store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.selectedAsset.name}`];
  }

  get siteName(): string {
    return this.findAssetParentTypeName(this.selectedAsset, 'Site');
  }

  private findAssetParentTypeName(
    currentNode: IAsset,
    typeName: string
  ): string {
    const parent = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.findAssetParentType.name}`](
      currentNode,
      typeName
    );
    return parent ? parent.name : '';
  }

  private onBack() {
    this.$router.push(`/editor/${this.selectedAsset.key}`);
  }

  private onPublish() {
    this.$bvModal.show('publish-modal');
  }
}
