import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import { VariableService } from '@/services/variables/variable-service';
import { IAssignmentTreeNodeViewModel } from '@/view-models/assignments';
import { IAssetNodeViewModel, IAssetReportVariableViewModel, VariableNodesTable, VariableTreeNode } from '@/view-models/variables';
import { IVariableListServiceResponse } from '@/view-models/variables/variable-folder-view-models';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { IRootState } from '..';

export interface IVariableFolderStoreState {
    lastSelected: VariableTreeNode;
    variables: VariableTreeNode[];
    variableFolders: VariableNodesTable;
    allVariables: IAssetReportVariableViewModel[];
    variable: VariableTreeNode;
    selectedVariablePathValue: [{name: string, value: string}];
    selectedVariables: VariableTreeNode[];
}

export interface IVariableFolderStoreGetters extends GetterTree<IVariableFolderStoreState, IRootState> {
    allVariables(state: IVariableFolderStoreState): VariableTreeNode[];
    selectedCount(state: IVariableFolderStoreState): number;
    getSelectedVariables(state: IVariableFolderStoreState): VariableTreeNode[];
}

export interface IVariableFolderStoreMutations extends MutationTree<IVariableFolderStoreState> {
    setVariables(state: IVariableFolderStoreState, variables: IAssetReportVariableViewModel[]): void;
    setVariableFolders(state: IVariableFolderStoreState, variableFolders: VariableNodesTable): void;
    setVariable(state: IVariableFolderStoreState, variable: VariableTreeNode): void;
    lockVariableByKey(state: IVariableFolderStoreState, key: string): void;
    selectSelf(state: IVariableFolderStoreState, selected: VariableTreeNode): void;
    clearSelf(state: IVariableFolderStoreState, selected: VariableTreeNode): void;
    clearAll(state: IVariableFolderStoreState): void;
    setSelectedvariables(state: IVariableFolderStoreState, variable: VariableTreeNode): void;
    clearSelectedVariables(state: IVariableFolderStoreState): void;
}

export interface IVariableFolderStoreActions extends ActionTree<IVariableFolderStoreState, IRootState> {
    loadVariables(context: IVariableFolderContext, rootAsset: IAssignmentTreeNodeViewModel): Promise<IVariableListServiceResponse>;
    loadPreviewVariables(context: IVariableFolderContext, assetKey: string): Promise<void>;
  }

export type IVariableFolderContext = ActionContext<IVariableFolderStoreState, IRootState>;

export const VariableFolderStore = {
    namespaced: true as true,
    state: {
        variables: [],
        variableFolders: {},
        allVariables: [],
        variable: '',
        selectedVariablePathValue: [{name: '', value: ''}],
        selectedVariables: []
    } as unknown as IVariableFolderStoreState,
    getters: {
        allVariables(state: IVariableFolderStoreState): VariableTreeNode[] {
            return state.variables;
        },
        getVariableByKey: (state: IVariableFolderStoreState) => (key: string): IAssetReportVariableViewModel | undefined => {
            return state.allVariables.find((variable) => variable.variableKey === key);
        },
        getVariableByKeys: (state: IVariableFolderStoreState) => (key: string): IAssetReportVariableViewModel | undefined => {
            return state.allVariables.find((variable) => variable.variableKey === key && variable.isLocked);
        },
        getgridVariables(state: IVariableFolderStoreState): VariableTreeNode[] {
            let variableArray: VariableTreeNode[] = [];
            state.variables.forEach((item) => {
                if (item.isLocked) {
                    variableArray.push(item);
                }
            });
            return variableArray;
        },
        selectedCount(state: IVariableFolderStoreState): number {
            let count = 0;
            state.variables.forEach((item) => {
                if (item.isSelected) {
                    count++;
                }
                return false;
            });
            return count;
        },
        getSelectedVariables(state: IVariableFolderStoreState): VariableTreeNode[] {
            return state.selectedVariables;
        },
    } as IVariableFolderStoreGetters,
    mutations: {
        setVariables(state: IVariableFolderStoreState, allVariables: IAssetReportVariableViewModel[]): void {
            state.allVariables = allVariables;
        },
        setVariableFolders(state: IVariableFolderStoreState, variableFolders: VariableNodesTable): void {
            state.variableFolders = variableFolders;
        },
        setVariable(state: IVariableFolderStoreState, variable: VariableTreeNode): void {
            state.variable = variable;
            if (variable.isLeaf && !variable.isLocked) {
                if (state.selectedVariables.length > 0) {
                    if (!state.selectedVariables.some(selectedVariable => selectedVariable.key === variable.key)) {
                        state.selectedVariables.push(variable);
                    }
                } else {
                    state.selectedVariables.push(variable);
                }
            }
            let variablePathArray: string[] = [];
            let variablePathName: string = '';
            let variableHierarchyPathValue: string = '';
            const variableFolderValues = Object.values(state.variableFolders);
            if (variable.variableKey) {
                variablePathArray = variable.variableKey.split('#');
                variableFolderValues.forEach((element) => {
                    const variablePathValue = element.availableNodes.find((p: IAssetNodeViewModel) => p.nodeKey === variablePathArray[1]);
                    if (variablePathValue) {
                        variableHierarchyPathValue = variablePathValue.hierarchyPath;
                        variablePathName = variable.variableKey;
                    }
                    state.selectedVariablePathValue.push({name: variablePathName, value: variableHierarchyPathValue});
                });
            }
        },
        setSelectedvariables(state: IVariableFolderStoreState, variable: VariableTreeNode): void {
            state.selectedVariables.push(variable);
        },
        selectSelf(state: IVariableFolderStoreState, selected: VariableTreeNode): void {
            if (selected.isLeaf) {
                selected.isSelected = true;
                state.lastSelected = selected;
            }
        },
        clearSelf(state: IVariableFolderStoreState, selected: VariableTreeNode): void {
            selected.clearSelf();
            selected.isSelected = false;
        },
        lockVariableByKey(state: IVariableFolderStoreState, key: string): void {
            state.allVariables.forEach((item) => {
                if (item.variableKey === key) {
                    item.isLocked = true;
                }
            });
        },
        unlockVariableByKey(state: IVariableFolderStoreState, key: string): void {
            state.allVariables.forEach((item) => {
                if (item.variableKey === key) {
                    item.isLocked = false;
                }
            });
        },
        clearAll(state: IVariableFolderStoreState): void {
            state.allVariables.forEach((item) => {
                if (!item.isLocked) {
                    item.selected = false;
                }
                return false;
            });
            state.variables.forEach((variable) => {
                variable.clearSelf();
            });
        },
        clearSelectedVariables(state: IVariableFolderStoreState): void {
            state.selectedVariables.forEach((item) => {
                item.isSelected = false;
            });
            state.selectedVariables = [];
        }
    } as IVariableFolderStoreMutations,
    actions: {
        async loadVariables(context: IVariableFolderContext, rootAsset: IAssignmentTreeNodeViewModel): Promise<IVariableListServiceResponse> {
            const selectedAssetKey = rootAsset.key;
            const conf = await ConfigFactory.GetConfig();
            const assetDiagramService = new VariableService(sharedAxiosInstance,
                    process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
                    process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
                    conf.get('adbApiUrl'));

            const levels = await assetDiagramService.getVariableList(selectedAssetKey);
            context.commit('setVariables', levels.variables);
            context.commit('setVariableFolders', levels.assetHierarchy);
            return levels;
        },
        async loadPreviewVariables(context: IVariableFolderContext, assetKey: string): Promise<void> {
            const conf = await ConfigFactory.GetConfig();
            const assetDiagramService = new VariableService(sharedAxiosInstance,
                    process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
                    process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
                    conf.get('adbApiUrl'));

            const levels = await assetDiagramService.getVariableList(assetKey);
            context.commit('setVariables', levels.variables);
        }
    } as IVariableFolderStoreActions
};
