// Interface Dependencies
import store, { IRootState, VuexModuleNamespaces } from '@/store';
// Vuex Dependencies
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
// Library Dependencies
import { AssetDiagramService } from '@/services/viewer/assetDiagram/asset-diagram-service';
import sharedAxiosInstance from '@/services/viewer/common/api-service';
import IAssetDiagramServiceResponse from '@/view-models/viewer/assetDiagram/asset-response-view-models';
import { IAssetDiagram } from '@/view-models/viewer/assetDiagram/asset-diagram';
import ConfigFactory from '@/services/config/config';
import { BurnerFolderViewerStore } from '@/store/viewer/burnerFolder/burnerFolderStore';
import { BurnerLayoutViewerStore } from '@/store/viewer/burnerLayout/burnerLayoutStore';
import { GridViewerStore } from '@/store/viewer/grid/gridStore';
import axios from 'axios';
import authInterceptor from '@/services/viewer/common/service-interceptor';

export type IDiagramContext = ActionContext<IDiagramStoreViewerState, IRootState>;

export type IDiagramStoreGetters = GetterTree<IDiagramStoreViewerState, IRootState>;

export interface IDiagramStoreViewerState {
  isDiagramLocked: boolean;
  lastModifiedByName: string;
  lastModifiedDate: Date;
  status: string;
  diagram: string;
  loading: boolean;
  equipmentConfigKey: string;
}

export interface IDiagramStoreMutations extends MutationTree<IDiagramStoreViewerState> {
  triggerStateChanged(state: IDiagramStoreViewerState): void;
  updateIsDiagramLocked(state: IDiagramStoreViewerState, newIsDiagramLocked: boolean): void;
  updateLastModifiedByName(state: IDiagramStoreViewerState, newLastModifiedByName: string): void;
  updateLastModifiedDate(state: IDiagramStoreViewerState, newLastModifiedDate: Date): void;
  updateStatus(state: IDiagramStoreViewerState, newStatus: string): void;
  updateDiagram(state: IDiagramStoreViewerState, newDiagram: string): void;
  updateLoading(state: IDiagramStoreViewerState, isLoading: boolean): void;
  updateEquipmentConfigKey(state: IDiagramStoreViewerState, newEquipmentConfigKey: string): void;
  resetStore(state: IDiagramStoreViewerState): void;
}

export interface IDiagramStoreActions extends ActionTree<IDiagramStoreViewerState, IRootState> {
  initializeDiagramSaver(context: IDiagramContext): void;
  retrieveAssetDiagram(context: IDiagramContext, assetKey: string): Promise<IAssetDiagramServiceResponse>;
  retrievePublishedDiagram(context: IDiagramContext, assetKey: string): Promise<IAssetDiagramServiceResponse>;
  bindAssetDiagram(context: IDiagramContext, response: IAssetDiagramServiceResponse): Promise<void>;
  publish(context: IDiagramContext): Promise<void>;
  flushDiagramSaver(diagramContext: IDiagramContext): void;
}

export const DiagramViewerStore = {
  namespaced: true,
  state: {
    isDiagramLocked: false,
    lastModifiedByName: '',
    lastModifiedDate: new Date(),
    status: '',
    diagram: '',
    loading: false,
    equipmentConfigKey: ''
  } as IDiagramStoreViewerState,
  getters: {
    isDiagramLocked(state: IDiagramStoreViewerState): null | boolean {
      return state.isDiagramLocked;
   },
    lastModifiedByName(state: IDiagramStoreViewerState): null | string {
      return state.lastModifiedByName;
   },
    lastModifiedDate(state: IDiagramStoreViewerState): null | Date {
      return state.lastModifiedDate;
   },
    status(state: IDiagramStoreViewerState): null | string {
      return state.status;
   },
    diagram(state: IDiagramStoreViewerState): null | string {
      return state.diagram;
   },
   isLoading(state: IDiagramStoreViewerState): boolean {
     return state.loading;
   },
   equipmentConfigKey(state: IDiagramStoreViewerState): null | string {
    return state.equipmentConfigKey;
  }
  } as IDiagramStoreGetters,
  mutations: {
    updateIsDiagramLocked(state: IDiagramStoreViewerState, newIsDiagramLocked: boolean) {
      state.isDiagramLocked = newIsDiagramLocked;
    },
    updateLastModifiedByName(state: IDiagramStoreViewerState, newLastModifiedByName: string) {
      state.lastModifiedByName = newLastModifiedByName;
    },
    updateLastModifiedDate(state: IDiagramStoreViewerState, newLastModifiedDate: Date) {
      state.lastModifiedDate = newLastModifiedDate;
    },
    updateStatus(state: IDiagramStoreViewerState, newStatus: string) {
      state.status = newStatus;
    },
    updateDiagram(state: IDiagramStoreViewerState, newDiagram: string) {
      state.diagram = newDiagram;
    },
    updateLoading(state: IDiagramStoreViewerState, isLoading: boolean) {
      state.loading = isLoading;
    },
    updateEquipmentConfigKey(state: IDiagramStoreViewerState, newEquipmentConfigKey: string) {
      state.equipmentConfigKey = newEquipmentConfigKey;
    },
    resetStore(state: IDiagramStoreViewerState) {
      state.diagram = '';
      state.isDiagramLocked = false;
      state.lastModifiedByName = '';
      state.status = '';
      state.diagram = '';
      state.loading = false;
      state.equipmentConfigKey = '';
    }
  } as IDiagramStoreMutations,
  actions: {
    async retrieveAssetDiagram(diagramContext: IDiagramContext, assetKey: string)
      : Promise<IAssetDiagramServiceResponse> {
      diagramContext.commit('updateLoading', true);
      // This API call uses its own axios instance (not the shared one) becuase
      // the gzip compression might cause race conditions with other API calls
      // causing base URIs to get crossed
      const privateAxiosInstance = axios.create();
      privateAxiosInstance.interceptors.request.use(authInterceptor);
      const conf = await ConfigFactory.GetConfig();
      const assetDiagramService = new AssetDiagramService(privateAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
        conf.get('adbApiUrl'));
      const response = await assetDiagramService.getAssetDiagram(assetKey, false, store.state.asset.selectedAsset?.equipmentConfigKey);
      diagramContext.commit('updateLoading', false);
      return response;
    },
    async retrievePublishedDiagram(diagramContext: IDiagramContext, assetKey: string)
      : Promise<IAssetDiagramServiceResponse> {
      // This API call uses its own axios instance (not the shared one) becuase
      // the gzip compression might cause race conditions with other API calls
      // causing base URIs to get crossed
      const privateAxiosInstance = axios.create();
      privateAxiosInstance.interceptors.request.use(authInterceptor);
      const conf = await ConfigFactory.GetConfig();
      const assetDiagramService = new AssetDiagramService(privateAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
        conf.get('adbApiUrl'));
      return assetDiagramService.getAssetDiagram(assetKey, true, store.state.asset.selectedAsset?.equipmentConfigKey);
    },
    async bindAssetDiagram(diagramContext: IDiagramContext, response: IAssetDiagramServiceResponse): Promise<void> {
      diagramContext.commit('updateLoading', true);
      diagramContext.commit('updateIsDiagramLocked', response.locked);
      diagramContext.commit('updateLastModifiedByName', response.lastModifiedByName);
      diagramContext.commit('updateLastModifiedDate', response.lastModifiedDate);
      diagramContext.commit('updateStatus', response.status);
      diagramContext.commit('updateDiagram', response.diagram);
      diagramContext.commit('updateEquipmentConfigKey', response.equipmentConfigKey);
      // Temporarily unlock burners.
      // The ones that are still on the diagram will get locked wuen updating from JSON below.
      // This is needed during reset and reloading from the data store.
      store.commit(`${VuexModuleNamespaces.burnerFolderViewer}/${BurnerFolderViewerStore.mutations.unlockAll.name}`);
      if (response.diagram !== '{}') {
        let parsedDiagram: string | IAssetDiagram = response.diagram;
        while (typeof(parsedDiagram) === 'string') {
          parsedDiagram = JSON.parse(parsedDiagram);
        }
        store.commit(`${VuexModuleNamespaces.burnerLayoutViewer}/${BurnerLayoutViewerStore.mutations.setBurnerLayout.name}`,
                     parsedDiagram.burnerlayout);
        await store.dispatch(`${VuexModuleNamespaces.gridViewer}/${GridViewerStore.actions.updateGridContentsfromJSON.name}`,
                             parsedDiagram.diagram);
      } else {
        await store.dispatch(`${VuexModuleNamespaces.gridViewer}/${GridViewerStore.actions.updateGridContentsfromJSON.name}`,
                             {contents: []});
      }
      if (response.locked) {
        store.commit(`${VuexModuleNamespaces.burnerFolderViewer}/${BurnerFolderViewerStore.mutations.lockAll.name}`);
      }
      diagramContext.commit('updateLoading', false);
    },
    async publish(diagramContext: IDiagramContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const assetDiagramService = new AssetDiagramService(sharedAxiosInstance,
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_ASSET_DIAGRAM_BUILDER_API_BASE_URL :
        conf.get('adbApiUrl'));
      const response = await assetDiagramService.saveCurrentAssetDiagram(true);
      await diagramContext.dispatch('bindAssetDiagram', response);
    }
  } as IDiagramStoreActions
};
